import React, { useEffect, useState } from 'react'
import CommonBanner from '../CommonBanner/CommonBanner'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import {apiUrlServer, getMySubscription} from '../../apiConfig'
import { useCookies } from 'react-cookie'
import { BulletList } from 'react-content-loader'
import { Link } from 'react-router-dom'
import useCustomTable from "../../Hooks/useCustomTable";
import { useLanguage } from '../../Context/LanguageContext'
import useCustomLoader from "../../Hooks/useCustomeLoader";
import useStocks from "../../Hooks/useStocks";
import useCustomTranslation from "../../Hooks/useTranslation";
import {abbreviateNumber} from "../../Hooks/useNumberFormatting";
const MySubscription = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(true);
  const [cookies] = useCookies(['token']);
  const { t } = useCustomTranslation();
  const {language} = useLanguage();
  const {customLoader} = useCustomLoader();
  const {paginationComponentOptions} = useStocks();

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const token = cookies.token;
        const response = await axios.get(`${apiUrlServer}${getMySubscription}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept-Language': language,
          },
        });

        if (response.data.status) {
          setSubscriptionData(response.data.data);
          console.log("test",response.data.data);
        } else {
          console.error('API request for subscription data was not successful:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching subscription data:', error);
      } finally {
        setIsLoadingSubscription(false);
      }
    };
    const fetchPayments = async () => {
      try {
        const token = cookies.token;
        const response = await axios.get(`${apiUrlServer}payment`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept-Language': language,
          },
        });

        if (response.data.status) {
          setPaymentData(response.data.data);
          console.log(response.data.data);
        } else {
          console.error('API request for payment data was not successful:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching payment data:', error);
      } finally {
        setIsLoadingPayment(false);
      }
    };
    fetchSubscriptionData();
    fetchPayments();
  }, [cookies.token,language]);

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Service ID',
      selector: row => row.service_id,
      sortable: true,
    },
    {
      name: 'Payment Method',
      selector: row => row.payment_method,
      sortable: true,
    },
    {
      name: 'Payment Status',
      selector: row => row.payment_status,
      sortable: true,
    },
    {
      name: 'Transaction Code',
      selector: row => row.transaction_code,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: row => row.created_at,
      sortable: true,
    },
  ];
  const {customStyles} = useCustomTable();
  console.log('paymentData:', paymentData);
  const customTitle = (
    <div className="custom-title-style">
      <h3>{t('Billing History')}</h3>
    </div>
  );
  return (
    <>
      <CommonBanner title={t('My Subscription')} />
      <div className="mySubscription">
        <div className="row" >
          {isLoadingSubscription || isLoadingPayment ? (
            <BulletList />
          ) : (
            <>
          <div className="col-md-3 ">
            {subscriptionData.map((subscription, index) => (
            <div  className="plan-card w-100" key={index}>
              <div className="card-body w-100">
                <div className="card-title">
                  <span className="plan-name">{subscription.plan.name}</span>
                  <span className="prices">
                      <span className="current-price">{abbreviateNumber(subscription.price)}</span> {' '}
                    {/*<del>{abbreviateNumber(subscription.discount_price)}</del> */}
                    <span>{t('SAR/Month')}</span>
                    </span>
                </div>
                <div className="card-description">{subscription.plan.description}</div>
                <div className="card-details"></div>
                <ul className="p-0 plans-list">
                  {subscription.plan.features.map((feature, featureIndex) => (
                    <li className="plans-benefits" key={featureIndex}>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.398438" width="20" height="20" rx="10" fill="#E8FFEB" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.247 6.55682L8.28036 12.3152L6.69703 10.6235C6.40536 10.3485 5.94703 10.3318 5.61369 10.5652C5.28869 10.8068 5.19703 11.2318 5.39703 11.5735L7.27202 14.6235C7.45536 14.9068 7.77202 15.0818 8.13036 15.0818C8.47202 15.0818 8.79702 14.9068 8.98036 14.6235C9.28036 14.2318 15.0054 7.40682 15.0054 7.40682C15.7554 6.64016 14.847 5.96516 14.247 6.54849V6.55682Z"
                          fill="#049C6B"
                        />
                      </svg>
                      {feature.name}
                    </li>
                  ))}
                </ul>
                <div>
                  <button  className="btn-subscribe">
                    {t('Renew')}
                  </button>
                </div>
              </div>
            </div>
            ))}
          </div>
          <div className="col-md-9">
            <div className="card-sub-details">
              <div className="card-title">
                <span>{t('Subscription Details')}</span>
              </div>
              <div className="card-subDate">
                {subscriptionData.map((subscription, index) => (
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.6562 7.5625H22.7188V6H21.1562V7.5625H10.8438V6H9.28125V7.5625H8.34375C7.05141 7.5625 6 8.61391 6 9.90625V23.6562C6 24.9486 7.05141 26 8.34375 26H23.6562C24.9486 26 26 24.9486 26 23.6562V9.90625C26 8.61391 24.9486 7.5625 23.6562 7.5625ZM24.4375 23.6562C24.4375 24.087 24.087 24.4375 23.6562 24.4375H8.34375C7.91297 24.4375 7.5625 24.087 7.5625 23.6562V13.3438H24.4375V23.6562ZM24.4375 11.7812H7.5625V9.90625C7.5625 9.47547 7.91297 9.125 8.34375 9.125H9.28125V10.6875H10.8438V9.125H21.1562V10.6875H22.7188V9.125H23.6562C24.087 9.125 24.4375 9.47547 24.4375 9.90625V11.7812Z" fill="#1D1A39"/>
                      <path d="M8.96875 14.9844H10.5312V16.5469H8.96875V14.9844ZM12.0938 14.9844H13.6562V16.5469H12.0938V14.9844ZM15.2188 14.9844H16.7812V16.5469H15.2188V14.9844ZM18.3438 14.9844H19.9062V16.5469H18.3438V14.9844ZM21.4688 14.9844H23.0312V16.5469H21.4688V14.9844ZM8.96875 18.1094H10.5312V19.6719H8.96875V18.1094ZM12.0938 18.1094H13.6562V19.6719H12.0938V18.1094ZM15.2188 18.1094H16.7812V19.6719H15.2188V18.1094ZM18.3438 18.1094H19.9062V19.6719H18.3438V18.1094ZM8.96875 21.2344H10.5312V22.7969H8.96875V21.2344ZM12.0938 21.2344H13.6562V22.7969H12.0938V21.2344ZM15.2188 21.2344H16.7812V22.7969H15.2188V21.2344ZM18.3438 21.2344H19.9062V22.7969H18.3438V21.2344ZM21.4688 18.1094H23.0312V19.6719H21.4688V18.1094Z" fill="#1D1A39"/>
                    </svg>
                  </span>
                  <span className="date-text">{t('Renwal Date')}</span>
                  <span>
                    <svg width="2" height="16" viewBox="0 0 2 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 0V16" stroke="#1D1A39" strokeWidth="2"/>
                    </svg>
                  </span>
                  <span className="date-number">{subscription.expire_at}</span>
                </div>
                    ))}
              </div>
              <div className="d-flex justify-content-center align-items-center w-100 gap-3">
                <Link to={"/subscription"} className='btn-subscribe'>
                  {t('Change Plan')}
                </Link>
                <button  className="btn-subscribe btn-pause">
                  {t('Pause Renewal')}
                </button>
              </div>
            </div>
            <div className="card-bill-history">

              {paymentData && paymentData.length > 0 && (
                  <DataTable
                      columns={columns}
                      data={paymentData}
                      title={customTitle}
                      pagination
                      customStyles={customStyles}
                      progressComponent={customLoader}
                      paginationComponentOptions={paginationComponentOptions}
                  />
              )}
            </div>
          </div>
            </>
            )}

        </div>
      </div>

    </>
  )
}

export default MySubscription