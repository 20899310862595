// useAuth.js
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {AuthContext} from "../Context/AuthContext";

export function useAuth() {
    const context = useContext(AuthContext);
    const navigate = useNavigate();

    if (!context) {
        throw new Error('useAuth must be used within an AuthContextProvider');
    }

    const { logout: contextLogout, ...rest } = context;

    const logout = () => {
        contextLogout();
        navigate('/login');
    };

    return { ...rest, logout };
}