import React from 'react';
import { AiOutlineFilter } from "react-icons/ai";
import DataTable from "react-data-table-component";
import useCustomTable from "../../Hooks/useCustomTable";
import useCustomTranslation from "../../Hooks/useTranslation";

const MyPositions = () => {
    const {t} = useCustomTranslation();
    const columns = [
        {
            name: t('Type'),
            selector: row => row.type,
            sortable: true,
        },
        {
            name: t('Symbol'),
            selector: row => row.symbol,
            sortable: true,
        },
        {
            name: t('Stock Name'),
            selector: row => row.name,
            sortable: true,
        },
        {
            name: t('Quantity'),
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: t('Cost'),
            selector: row => row.rate,
            sortable: true,
        },
        {
            name: t('Status'),
            selector: row => row.status,
            sortable: true,
        },
        {
            name: t('G/L'),
            selector: row => row.gl,
            sortable: true,
        }
        // Add more columns as needed
    ];
    const fakedata = [
        {
            id: 1,
            type: 'Buy',
            symbol: 'AAPL',
            name: 'Apple Inc.',
            quantity: 100,
            rate: 150.0,
            status: 'Completed',
            gl: 'Loser',
        },
        {
            id: 2,
            type: 'Sell',
            symbol: 'GOOGL',
            name: 'Alphabet Inc.',
            quantity: 50,
            rate: 2000.0,
            status: 'Pending',
            gl: 'Gainer',
        },
        {
            id: 3,
            type: 'Buy',
            symbol: 'AMZN',
            name: 'Amazon.com, Inc.',
            quantity: 75,
            rate: 3000.0,
            status: 'Completed',
            gl: 'Loser',
        },
        {
            id: 4,
            type: 'Sell',
            symbol: 'MSFT',
            name: 'Microsoft Corporation',
            quantity: 200,
            rate: 250.0,
            status: 'Completed',
            gl: 'Gainer',
        },
        {
            id: 5,
            type: 'Buy',
            symbol: 'TSLA',
            name: 'Tesla, Inc.',
            quantity: 50,
            rate: 800.0,
            status: 'Pending',
            gl: 'Gainer',
        },
    ];
    const {customStyles} = useCustomTable();

    return (
        <div className="outstandig-card">
            <div className="mb-16">
                <span className="virtual-number">{t('My Positions')}</span>
            </div>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={fakedata}
                pagination
                highlightOnHover={true}
                striped={true}
                responsive
            />
        </div>
    );
};

export default MyPositions;