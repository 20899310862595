import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { apiUrlServer } from "../../apiConfig";
import useCustomTranslation from "../../Hooks/useTranslation";
import { useLanguage } from "../../Context/LanguageContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Reports.css';
import report_default from '../../assets/images/bannerreport.png';
import { ShimmerCard } from "react-shimmer-effects";

const SingleReportsDetails = () => {
    const { reportCategoryId } = useParams();
    const { t } = useCustomTranslation();
    const { language } = useLanguage();
    const [cookies] = useCookies(['token']);

    const fetchReportDetail = async (reportCategoryId, language, token) => {
        try {
            const response = await axios.get(`${apiUrlServer}report_page`, {
                params: {
                    'filter[report_category_id]': reportCategoryId
                },
                headers: {
                    'Accept-Language': language,
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.data.status) {
                return response.data.data;
            } else {
                throw new Error('Failed to fetch report detail');
            }
        } catch (error) {
            console.error('Error details:', error.response);
            if (error.response && error.response.status === 401) {
                throw new Error('Unauthorized: Please log in again');
            }
            throw error;
        }
    };

    const { data: reports, error, isLoading } = useQuery({
        queryKey: ['reportDetail', reportCategoryId, language],
        queryFn: () => fetchReportDetail(reportCategoryId, language, cookies.token),
        staleTime: 15 * 60 * 1000,
        refetchInterval: 15 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    const LoadingShimmer = () => (
        <div className="container">
            <div className="row">
                {[...Array(3)].map((_, index) => (
                    <div key={index} className="col-md-12 mb-4">
                        <ShimmerCard height={400}>
                            <div style={{ height: '200px' }}></div>
                            <div style={{ padding: '20px' }}>
                                <ShimmerCard height={30} className="mb-3" />
                                <ShimmerCard height={100} className="mb-3" />
                                <ShimmerCard width={120} height={40} />
                            </div>
                        </ShimmerCard>
                    </div>
                ))}
            </div>
        </div>
    );

    if (isLoading) {
        return (
            <div className={`mt-5 ${language === 'ar' ? 'rtl' : 'ltr'}`}>
                <LoadingShimmer />
            </div>
        );
    }

    if (error) {
        return <div className="text-center mt-5">{t('Error fetching report detail')}: {error.message}</div>;
    }

    return (
        <div className={`mt-5 ${language === 'ar' ? 'rtl' : 'ltr'}`}>
            <div className="container">
                <div className="row">
                    {reports && reports.map(report => (
                        <div key={report.id} className="col-md-12 mb-4">
                            <div className="card card-single-report">
                                <img src={report_default} className="card-img-top" alt={report.name}/>
                                <div className="card-body">
                                    <h2 className="card-title">{report.name}</h2>
                                    <div className="card-text" dangerouslySetInnerHTML={{__html: report.description}}/>
                                    {report.media && report.media.length > 0 && (
                                        <a href={report.media[0].original_url} target="_blank" rel="noopener noreferrer"
                                           className="btn btn-read-more mt-3">
                                            {t('View PDF')}
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SingleReportsDetails;