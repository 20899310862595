import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Button, Modal, Spinner } from "react-bootstrap";
import './AiGenrations.css';
import { apiUrlServer } from "../../../apiConfig";
import useCustomTranslation from "../../../Hooks/useTranslation";
import { useLanguage } from "../../../Context/LanguageContext";
import keyIcon from "../../../assets/images/key.webp";
import { useCookies } from "react-cookie";

const TecAi = () => {
    const [tags, setTags] = useState([]);
    const [modalData, setModalData] = useState('');
    const [show, setShow] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(false);
    const { language } = useLanguage();
    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);

    const handleClose = () => setShow(false);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrlServer}stocks/chat/tags`, {
                    headers: {
                        'Accept-Language': language,
                        'Authorization': `Bearer ${cookies.token}`,
                    }
                });
                setTags(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTags();
    }, [language, cookies.token]);

    const handleClick = (tags, slug, option) => {
        if (tags && tags.length > 0) {
            setLoading(true);
            const token = cookies.token;

            // Create FormData
            const formData = new FormData();
            formData.append('query', slug);
            tags.forEach((tag, index) => {
                formData.append(`tags[${index}]`, tag);
            });

            axios.post(`${apiUrlServer}stocks/chat/message`, formData, {
                headers: {
                    'Accept-Language': language,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    if (response.data.data.message) {
                        setModalData(response.data.data.message);
                        setSelectedOption(option);
                    } else {
                        setModalData('');
                    }
                })
                .catch(error => {
                    console.error(error);
                    setModalData('');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setModalData('');
        setSelectedOption(null);
        setShow(true);
    };

    return (
        <>
            <div className="row" >
                <h3 className="card-ai-header">{t('Technical')}</h3>
                {tags.map((category, index) => (
                    <div key={category.id} className="col-md-6">
                        <h3
                            className="btn head-ai-card d-flex align-items-center mb-0 gap-2"
                            onClick={() => handleCategoryClick(category)}
                            style={{ cursor: 'pointer' }}
                        >
                            <img src={keyIcon} width="35" height="35" alt="Key Icon" />
                            {category.name}
                        </h3>
                        {index % 2 !== 0 && <div className="w-100"></div>}
                    </div>
                ))}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="ai-modal-header">
                    <Modal.Title>{selectedCategory?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedCategory && (
                        <div className="card-body">
                            <div className="mb-0" style={{ flexWrap: 'wrap' }}>
                                {selectedCategory.options.map(option => (
                                    <div key={option.id} className="d-flex align-items-center">
                                        <div
                                            className={`d-flex align-items-center justify-content-start option-link ${
                                                selectedOption === option ? 'active' : ''
                                            }`}
                                            onClick={() => handleClick(option.tags, option.slug, option)}
                                        >
                                            <div>
                                                <p
                                                    className="mb-0 fw-semibold ai-name"
                                                    style={{
                                                        color: selectedOption === option ? '#F99F01' : 'inherit',
                                                    }}
                                                >
                                                    {option.name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {loading ? (
                        <div className="d-flex justify-content-center mt-4">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : modalData ? (
                        <div className='table-responsive' dangerouslySetInnerHTML={{ __html: modalData }}></div>
                    ) : (
                        <p className="dummy-text">{t('Please select an option from above to view the data.')}</p>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TecAi;