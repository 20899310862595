// components/OpenEarningsCharts.js
import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const OpenFinancialChart = ({ path, dataKey,  data, buttonText }) => {
    const handleClick = () => {
        sessionStorage.setItem(dataKey, JSON.stringify(data));
        window.open(path, '_blank');
    };

    return (
        <Button className="btn visual-chart" onClick={handleClick}>
            {buttonText}
        </Button>
    );
};

OpenFinancialChart.propTypes = {
    path: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
};

export default OpenFinancialChart;