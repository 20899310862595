import React from 'react';
import DataTable from 'react-data-table-component';
import { BulletList } from 'react-content-loader';
import './simulation.css';
import useStocks from "../../Hooks/useStocks";
import useCustomTable from "../../Hooks/useCustomTable";

const SimulationSummary = () => {
    const {
        stocks,
        loading,
        columns,
        totalRows,
        perPage,
        currentPage,
        handlePageChange,
        handlePerRowsChange,
        paginationComponentOptions
    } = useStocks();
    const {customStyles} = useCustomTable();

  return (
      <>
        <div className="order-entry">
            <DataTable
                columns={columns}
                customStyles={customStyles}
                data={stocks}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                progressComponent={<BulletList />}
            />
        </div>
      </>
  );
};

export default SimulationSummary;