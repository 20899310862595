import { createContext, useEffect, useState, useCallback } from "react";
import { useCookies } from 'react-cookie';

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  useEffect(() => {
    setIsUserLoggedIn(!!cookies.token);
  }, [cookies.token]);

  const isAuthenticated = useCallback(() => {
    return isUserLoggedIn || !!cookies.token;
  }, [isUserLoggedIn, cookies.token]);

  const logout = useCallback(() => {
    removeCookie('token', { path: '/' });
    setIsUserLoggedIn(false);
    localStorage.removeItem('userData'); // Clear any user data from local storage
  }, [removeCookie]);

  return (
      <AuthContext.Provider value={{ isUserLoggedIn, setIsUserLoggedIn, isAuthenticated, logout }}>
        {children}
      </AuthContext.Provider>
  );
}