import React from 'react';
import { Badge, Dropdown, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Notification.css';
import useCustomTranslation from "../../Hooks/useTranslation";
import { useNotifications } from '../../Hooks/useNotification';
import {useTheme} from "@mui/material";


const Notification = () => {
    const { notifications, markAsRead, loading } = useNotifications();
    const { theme } = useTheme();

    const iconColor = theme === 'default' ? '#451952' : '#008c45';

    const getBadgeVariant = (type) => {
        switch (type) {
            case 'info':
                return 'info';
            case 'warning':
                return 'warning';
            case 'danger':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    const getIcon = (type) => {
        switch (type) {
            case 'success':
                return 'fas fa-check-circle';
            case 'warning':
                return 'fas fa-exclamation-triangle';
            case 'info':
                return (
                `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_2929_77308)">
                <path d="M9.06917 11.2717C8.9056 11.2869 8.74139 11.25 8.60007 11.1662C8.49682 11.0602 8.44732 10.913 8.46554 10.7662C8.46935 10.6439 8.48395 10.5222 8.50917 10.4026C8.53357 10.2652 8.5651 10.1293 8.6037 9.99528L9.03279 8.5189C9.07704 8.37318 9.10629 8.22331 9.12007 8.07162C9.12007 7.908 9.14188 7.79525 9.14188 7.72981C9.15101 7.43815 9.02648 7.15828 8.8037 6.96981C8.52967 6.75943 8.18842 6.65603 7.8437 6.6789C7.59667 6.68262 7.35154 6.72306 7.11642 6.7989C6.85945 6.8789 6.58914 6.97465 6.30551 7.08618L6.18188 7.56618C6.26551 7.53709 6.36735 7.50437 6.4837 7.468C6.5947 7.43512 6.70976 7.41797 6.82551 7.41709C6.98788 7.3995 7.15132 7.43937 7.28732 7.52981C7.3797 7.64003 7.42304 7.78322 7.40732 7.92618C7.40692 8.04847 7.39351 8.17037 7.36732 8.28981C7.34185 8.41709 7.30913 8.55162 7.26913 8.69343L6.83642 10.1771C6.80154 10.315 6.77364 10.4545 6.75279 10.5952C6.73579 10.7157 6.72729 10.8372 6.72732 10.9589C6.72554 11.2525 6.85982 11.5305 7.09095 11.7116C7.3692 11.9252 7.71532 12.0312 8.06548 12.0098C8.31204 12.0148 8.55776 11.9792 8.79276 11.9043C8.99882 11.834 9.27398 11.7334 9.61823 11.6025L9.7346 11.1443C9.64135 11.183 9.54532 11.2146 9.44732 11.2388C9.32339 11.2672 9.19614 11.2782 9.06917 11.2717Z" fill="#1573FE"/>
                <path d="M9.5237 4.27305C9.32579 4.0913 9.06504 3.99352 8.79642 4.00033C8.52795 3.99427 8.26745 4.09196 8.06913 4.27305C7.70563 4.58649 7.66504 5.13527 7.97851 5.4988C8.00645 5.53121 8.03673 5.56149 8.06913 5.58943C8.48326 5.95983 9.10957 5.95983 9.52367 5.58943C9.88717 5.2729 9.92526 4.72165 9.60873 4.35815C9.58242 4.32786 9.55398 4.29943 9.5237 4.27305Z" fill="#1573FE"/>
                <path d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 15.2727C3.98338 15.2727 0.727281 12.0166 0.727281 8C0.727281 3.98338 3.98338 0.727281 8 0.727281C12.0166 0.727281 15.2727 3.98338 15.2727 8C15.2727 12.0166 12.0166 15.2727 8 15.2727Z" fill="#1573FE"/>
                </g>
                <defs>
                <clipPath id="clip0_2929_77308">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
                </svg>`
            );
            case 'alert':
                return 'fas fa-exclamation-circle';
            case 'danger':
                return 'fas fa-radiation';
            default:
                return 'fas fa-bell';
        }
    };

    // Style the button based on read status
    const notificationDotStyle = {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        marginRight: '5px', // Add some space between the dot and title
        display: 'inline-block',
        cursor:'pointer'
    };
    const {t} = useCustomTranslation();

    const truncateText = (text, maxLength) => {
        const words = text.split(' ');
        if (words.length > maxLength) {
            return words.slice(0, maxLength).join(' ') + '...';
        }
        return text;
    };

    // !loading ? (
    //     <Dropdown.Item as="div" disabled>
    //         <Spinner animation="border" size="sm" />
    //     </Dropdown.Item>
    // ) 

    return (
        <>

            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-notification">
                    <i class="fa-regular fa-bell"></i>
                    {notifications?.filter(n => n.is_read === 0)?.length > 0 &&
                        <div id="notification-badge-number">
                            <Badge bg="danger" >
                                {notifications?.filter(n => n.is_read === 0)?.length}
                            </Badge>
                        </div>
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-dropdown-menu shadow mt-2">
                    { notifications?.length > 0 ? (
                        <>
                            {notifications?.slice(0, 10)?.map((notification, index) => (      
                                <Dropdown.Item key={index} as="div" className={`notification-item ${notification.is_read ? '' : 'notification-item-unread'} position-relative overflow-hidden`}>
                                        <div
                                            className='w-100'
                                            variant="link w-100" // Make it look like a link
                                            size="sm"
                                            onClick={() => markAsRead(notification.id)}
                                        >
                                            <div className="d-flex gap-2">
                                                <span className={`notification-icon`} dangerouslySetInnerHTML={{ __html: getIcon(notification.type) }}></span>
                                                <div className='flex-grow-1'>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="notification-title">{notification.title}</span>
                                                    </div>
                                                    <div className="notification-content">
                                                        {notification.description && <span>{truncateText(notification.description,10)}</span>}
                                                         <small>{notification.date}</small>
                                                    </div>
                                                </div>
                                            </div>

                                           
                                        </div>
                                </Dropdown.Item>
                            ))}
                            <Link className="notification-seemore" to="/all-notifications">
                            <Dropdown.Item as="div" className='see-all-notifications'>
                               {t('see more')}
                            </Dropdown.Item>
                            </Link>
                        </>
                    ) : (
                        <>
                            <span className="empty_notification_icon">
                                <i class="fa-regular fa-bell-slash"></i>
                                <span>{t('No Notifications Found')}</span>
                            </span>
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default Notification;