import React from 'react';
import { usePermissions } from "../../Context/PermissionContext";

const ProtectedButton = ({ permission, className, onClick, children }) => {
    const { hasPermission, permissions, usage } = usePermissions();

    if (hasPermission(permission)) {
        const permissionDetails = permissions[permission];
        let usageInfo = '';
        if (permissionDetails && permissionDetails.type === 'limited') {
            const currentUsage = usage[permission] || 0;
            usageInfo = ` (${currentUsage}/${permissionDetails.limit})`;
        }
        return (
            <button className={className} onClick={onClick}>
                {children}{usageInfo}
            </button>
        );
    } else {
        return (
            <button className={`${className} disabled-button`} disabled style={{ cursor: 'not-allowed' }}>
                {children} (Unavailable)
            </button>
        );
    }
};

export default ProtectedButton;