import React, { useState } from 'react'
import FilterSidebar from './FilterSidebar'
import styles from '../watchlist.module.css'
import filterarrow from '../../../assets/icons/filterarrow.svg'
import WatchStockTab from './WatchListStockTab/WatchStockTab'
import useCustomTranslation from '../../../Hooks/useTranslation';
import {useWatchListAddStocks} from "../../../Context/WatchListAddStocksContext";

const StocksListWatch = ({fetchData}) => {
    const { watchListStocks } = useWatchListAddStocks();
    const {t} = useCustomTranslation();
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisibility(!isSidebarVisible);
  };
  return (
    <div className={`${styles['watchlist_card']}`}>
      <div className='row'>
        <div className={`col-lg-${isSidebarVisible ? '12' : '3'} mb-24`}>
          {/*<button className={`${styles['filter-button']}`} onClick={toggleSidebar}>*/}
          {/*  <span>{t('Stock List')}</span>*/}
          {/*  <img src={filterarrow} alt="Filter Arrow" />*/}
          {/*</button>*/}
        </div>
        {isSidebarVisible ? (
          <div className="col-lg-3">
            <FilterSidebar />
          </div>
        ) : null}
         <div className={`col-lg-${isSidebarVisible ? '9' : '12'}`}>
            <WatchStockTab/>
          </div>
      </div>
    </div>
  );
};

export default StocksListWatch