// EditUserPhone.jsx

import React, { useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import API_URL, {apiUrlServer, userChangePhone} from '../../../apiConfig';
import { useFormik } from 'formik';
import toast from 'react-hot-toast'
import useCustomTranslation from "../../../Hooks/useTranslation";

const EditUserPhone = ({ userProfile, onEditPhoneSuccess }) => {
  const [cookies] = useCookies(['token']);
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  async function editProfilePhone(values) {
    try {
      setIsLoading(true);

      const token = cookies.token;
      const formData = new FormData();
      formData.append('phone', values.phone);
      formData.append('password', values.password);
      formData.append('new_phone', values.new_phone);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        `${apiUrlServer}${userChangePhone}`,
        formData,
        config
      );

      if (data.status) {
        // Success
        // Save phone number to session storage
        sessionStorage.setItem('phone', values.new_phone);
        onEditPhoneSuccess(data.data);
        toast.success(`Your OTP is: ${data.data.otp}`, {
          position: 'top-right',
          duration: 3000,
          className: 'text-success px-5 fw-bolder my-3',
        });
        setIsLoading(false);
      } else {
        // Validation errors
        console.error('Validation Errors:', data.errors);
        // You can handle the validation errors here
        // For example, you can update formik.errors with the validation errors
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error updating user profile:', error.response.data);
      toast.error(`Your OTP is Wrong}`, {
        position: 'top-right',
        duration: 3000,
        className: 'text-success px-5 fw-bolder my-3',
      });
      // Handle other errors as needed
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      phone: userProfile.phone,
      new_phone: '',
      password: userProfile.password,
    },
    onSubmit: editProfilePhone,
  });
  const {t}= useCustomTranslation();

  return (
    <>

            <div className="form-wrapper">
              <form onSubmit={formik.handleSubmit}>
                <div className="head-form">
                  <h2>{t('Change Phone Number')}</h2>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="phone">{t('Phone')}:</label>
                      <input
                          className="form-control"
                          type="text"
                          id="phone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-group mb-3">
                      <label htmlFor="new_phone">{t('New Phone')}:</label>
                      <input
                          className="form-control"
                          type="text"
                          id="new_phone"
                          name="new_phone"
                          value={formik.values.new_phone}
                          onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-group mb-3">
                      <label htmlFor="password">{t('Password')}:</label>
                      <input
                          className="form-control"
                          type="password"
                          id="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <button className="btn btn-save-profile" type="submit">
                      {t('Save All Changes')}
                    </button>
                  </div>
                </div>
              </form>
            </div>

    </>
  );
};

export default EditUserPhone;
