import React from "react";
import "./KeyState.css";
import useCustomTranslation from "../../../Hooks/useTranslation";
import {
  formatNumber,
  useFormattedPercentage,
  useHighNumberFormatter,
  useLowNumberFormatter
} from "../../../Hooks/useNumberFormatting";

const KeyState = (props) => {
    const { t } = useCustomTranslation();
  const getChangeColor = (value) => {
    return value > 0 ? 'green' : 'red';
  };

  return (
      <>
          <div className="container staets-fundmentls my-3">
              <div className="row">
                  <div className="col-lg-12">
                      <h2 className="key-state">{t('Key Stats')}</h2>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Open')}</span>
                              <span className="key-span-number">{formatNumber(props.openDay)}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Prev Close')}</span>
                              <span className="key-span-number">{formatNumber(props.close)}</span>
                          </div>


                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Day High')}</span>
                              <span
                                  className="key-span-number">{formatNumber(props.highDay)}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Day Low')}</span>
                              <span className="key-span-number">{formatNumber(props.lowDay)}</span>
                          </div>


                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                      <div className="d-flex flex-column align-items-start div-state">

                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('52 Week Low')}</span>
                              <span className="key-span-number">{formatNumber(props.WeekLow)}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('52 Week High')}</span>
                              <span className="key-span-number">{formatNumber(props.WeekHigh)}</span>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('10 Day Average Volume')}</span>
                              <span className="key-span-number">{formatNumber(props.volume.toFixed(0))}</span>
                          </div>

                      </div>
                  </div>
                  {/* Add another column here if you have more details to display */}
              </div>
          </div>

          <div className="container staets-fundmentls my-3">
              <div className="row">
                  <div className="col-lg-12">
                      <h2 className="key-state">{t('Fundamentals')}</h2>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Beta')}</span>
                              <span className="key-span-number">{formatNumber(props.Beta)}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Market Cap')}</span>
                              <span className="key-span-number">{formatNumber(props.MarketCapitalizationMln)}</span>
                          </div>


                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                      <div className="d-flex flex-column align-items-start div-state">

                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Shares Out')}</span>
                              <span className="key-span-number">{formatNumber(props.EarningsShare)}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Dividend')}</span>
                              <span className="key-span-number">{formatNumber(props.DividendShare)}</span>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Dividend Yield')}</span>
                              <span className="key-span-number">{formatNumber(props.DividendYield)}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('YTD % Change')}</span>
                              <span className="key-span-number">-</span>
                          </div>


                      </div>
                  </div>
                  {/* Add another column here if you have more details to display */}
              </div>
          </div>

          <div className="container staets-fundmentls my-3">
              <div className="row">
                  <div className="col-lg-12">
                      <h2 className="key-state">{t('Stock Information')}</h2>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Country Name')}</span>
                              <span className="key-span-number">{props.CountryName}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Industry')}</span>
                              <span className="key-span-number">{props.Industry}</span>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                      <div className="d-flex flex-column align-items-start div-state">

                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Primary Ticker')}</span>
                              <span className="key-span-number">{props.PrimaryTicker}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Phone')}</span>
                              <span className="key-span-number">{props.Phone}</span>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('WebURL')}</span>
                              <a href={props.WebURL} className="key-span-number">{props.WebURL}</a>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Full Time Employees')}</span>
                              <span className="key-span-number">{props.FullTimeEmployees}</span>
                          </div>


                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="d-flex flex-column align-items-start div-state">
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Address')}</span>
                              <span className="key-span-number key-addres">{props.Address}</span>

                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <span className="key-span">{t('Full Time Employees')}</span>
                              <span className="key-span-number">{props.FullTimeEmployees}</span>
                          </div>
                      </div>
                  </div>
                  {/* Add another column here if you have more details to display */}
              </div>
          </div>



      </>
  );
};

export default KeyState;
