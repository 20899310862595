import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import { addSubscription, apiUrlServer } from '../../apiConfig';
import useCustomTranslation from '../../Hooks/useTranslation';

const AddSubscription = ({ plan_id }) => {
  const { t } = useCustomTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['token']);

  const handleAddSubscription = async (values) => {
    setIsLoading(true);

    try {
      const token = cookies.token;
      const response = await axios.post(
          `${apiUrlServer}${addSubscription}`,
          { plan_id: values.plan_id, method: 'Tap' },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
      );

      if (response.data.status) {
        const redirectUrl = response.data.data.payment.redirect_url;
        if (redirectUrl) {
          Swal.fire({
            title: 'Payment Subscrition',
            html: `<iframe src="${redirectUrl}" width="100%" height="600px" frameborder="0"></iframe>`,
            width: '80%',
            showCloseButton: true,
            showConfirmButton: false,
          });
        }
      }
    } catch (error) {
      console.error('Error adding subscription:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while processing your request.',
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      plan_id: plan_id,
      method: 'Tap',
    },
    onSubmit: handleAddSubscription,
  });

  return (
      <>
        <button type="button" onClick={formik.handleSubmit} className="btn-subscribe" disabled={isLoading}>
          {isLoading ? t('Processing...') : t('Subscribe')}
        </button>
      </>
  );
};

export default AddSubscription;