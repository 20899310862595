import React, { useContext, useEffect, useState } from 'react'
import {Outlet, Navigate, useLocation} from 'react-router-dom';
import styles from '../Layout/layout.module.css';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { AuthContext } from '../../Context/AuthContext';
import Footer from "../Footer/Footer";
import ChatBotIcon from "../ChatBot/ChatBotIcon";

const Layout = () => {
  const { isUserLoggedIn } = useContext(AuthContext);
  const [authStatusKnown, setAuthStatusKnown] = useState(false);
    const location = useLocation(); // Add this line

    const isChatRoute = location.pathname.includes('chatbot'); // Adjust the path accordingly


  return (
            <>
                <div id='root-container'>
                    <div className={`${styles["layout-container"]}`}>
                        <div className={`${styles["content-width"]}`}>
                            <Header />
                            <Outlet />
                            <ChatBotIcon/>
                        </div>
                    </div>
                    {!isChatRoute && <Footer />} {/* Conditionally render the footer */}
                </div>
            </>
  );
};

export default Layout;
