import React, { useEffect, useState } from 'react';
import styles from "./StockTabDetails.module.css";
import StocksUpdates from '../StocksTab/StocksUpdates/StocksUpdates';
import Dividends from '../StocksTab/Dividends/Dividends';
import Officers from '../StocksTab/Officers/Officers';
import axios from 'axios';
import { fundamentals } from '../../../apiConfig';
import { useParams } from 'react-router-dom';
import Highlights from '../StocksTab/Highlights/Highlights';
import Valuation from '../StocksTab/Valuation/Valuation';
import Technicals from '../StocksTab/Technicals/Technicals';
import OutstandingShares from '../StocksTab/OutstandingShares/OutstandingShares';
import EarningsHistory from '../StocksTab/EarningsHistory/EarningsHistory';
import Trends from '../StocksTab/Trends/Trends';
import EarningsAnnual from '../StocksTab/EarningsAnnual/EarningsAnnual';
import IncomeStatement from '../StocksTab/IncomeStatement/IncomeStatement';
import useCustomTranslation from '../../../Hooks/useTranslation';
import Fundamentals from "../StocksTab/Fundamentals/Fundamentals";
import { useCookies } from "react-cookie";
import { useLanguage } from "../../../Context/LanguageContext";
import tabImage from "../../../assets/images/K-Wealth Logo-11 .webp";
import tabImagegreen from "../../../assets/images/chhosetabgreen.webp";

import { ShimmerCard } from "react-shimmer-effects";
import FinancialStatement from '../StocksTab/FinancialStatement';
import FinancialDataGrid from '../StocksTab/FinancialDataGrid';
import EarningsDisplay from '../StocksTab/EarningsDisplay';
import {useTheme} from "../../../Context/ThemeContext";
import chatimg from "../../../assets/icons/chatboticon.webp";
import nationalDayLogoChat from "../../../assets/icons/gifday.webp";


const StockTabDetails = ({ stockDataFundamentals, companyLiveDate, isDataLoading, id }) => {
  const { t } = useCustomTranslation();
  const [activeTab, setActiveTab] = useState("");
  const { code } = useParams();
  const [stockData, setStockData] = useState({});
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  const { language } = useLanguage();
  const [error, setError] = useState(null);
  const { theme } = useTheme();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = cookies.token;
        const response = await axios.get(`${fundamentals}${code}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept-Language': language,
          },
        });
        console.log('API Response:', response.data.data);

        setStockData(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [code, cookies.token, language]);

  if (loading) {
    return <ShimmerCard />;
  }

  const tabs = [
    { key: "fundamentals", label: t('Fundamentals'), component: <Fundamentals stockDataFundamentals={stockDataFundamentals} companyLiveDate={companyLiveDate} loading={isDataLoading} /> },
    { key: "updates", label: t('Updates'), component: <StocksUpdates /> },
    { key: "dividends", label: t('Dividends'), component: <Dividends data={stockData.splits_dividends} /> },
    { key: "officers", label: t('Officers'), component: <Officers loading={loading} officers={stockData?.general?.Officers || []} /> },
    { key: "highlights", label: t('Highlights'), component: <Highlights loading={loading} {...stockData.highlights} /> },
    { key: "valuation", label: t('Valuation'), component: <Valuation {...stockData.valuation} /> },
    { key: "technicals", label: t('Technicals'), component: <Technicals {...stockData.technicals} /> },
    { key: "trends", label: t('Earnings Trends'), component: <EarningsDisplay data={stockData} displayType="earnings_trend" id={id} code={code}  />},
    {
      key: "annual",
      label: t('Earnings Annual'),
      component: <EarningsDisplay data={stockData} displayType="annual" id={id} code={code} />
    },
    {
      key: "earningsHistory",
      label: t('Earnings History'),
      component: <EarningsDisplay data={stockData} displayType="history" id={id} code={code} />
    },
    { key: "balanceSheet", label: t('Balance Sheet'), component: <FinancialStatement id={id} code={code} showChart={false} highlightRow={false} data={stockData} statementType="balancesheet" /> },
    { key: "cashFlow", label: t('Cash Flow'), component: <FinancialStatement id={id} code={code} showChart={false} highlightRow={false} data={stockData} statementType="cashdflow" /> },
    { key: "invest", label: t('Income Statement'), component: <FinancialStatement id={id} code={code} showChart={false} highlightRow={false} data={stockData} statementType="incomestatement" /> },

  ];

  return (
      <section className="my-24">
        <div className="row">
          <div className="col-lg-3">
            <nav className={styles.dailyTabsNav}>
              <ul className={styles.dailyTabsList}>
                {tabs.map(({ key, label }) => (
                    <li
                        key={key}
                        className={activeTab === key ? styles.active : undefined}
                        onClick={() => handleTabClick(key)}
                    >
                      {label}
                    </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="col-lg-9">
            <div className={styles.tabContent}>
              {!activeTab && (
                  <div className={styles.defaultContent}>
                    <img
                        src={theme === 'default' ? tabImage : tabImagegreen}
                        className="w-100" alt="Default"
                    />
                  </div>
              )}
              {activeTab && tabs.find(tab => tab.key === activeTab)?.component}
            </div>
          </div>
        </div>
      </section>
  );
};

export default StockTabDetails;