import React from 'react';
import {useCookies} from "react-cookie";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import Swal from "sweetalert2";
import iconDelete from "../../../../assets/icons/deleteIcon.png";
import axios from "axios";
import {apiUrlServer, deleteStockAlertTags} from "../../../../apiConfig";
import styles from "../../../WatchList/watchlist.module.css";
import {FaRegTrashAlt} from "react-icons/fa";

const DeleteTagsPrice = ({ groupId, onDeleteGroupSuccess, fetchData}) => {
    const [cookies] = useCookies(['token']);
    const { t } = useCustomTranslation();


    const handleDeleteClick = async () => {
        const result = await Swal.fire({
            title: t('Delete Your Stock'),
            text: t('Are you sure you want to Delete Stock ?'),
            iconHtml: `<img src="${iconDelete}" alt="Success Icon" class="" />`,
            showCancelButton: true,
            confirmButtonText: t('Yes, delete it!'),
            cancelButtonText: t('Back to Stock List'),
            customClass: {
                confirmButton: 'swal-btn-delete',
                cancelButton: 'swal-btn-cancelButton',
            },
        });

        if (result.isConfirmed) {
            try {
                const token = cookies.token;

                const response = await axios.delete(`${apiUrlServer}${deleteStockAlertTags}/${groupId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.data.status) {
                    Swal.fire({
                        title: t('Stock Deleted Successfully'),
                        icon: 'success',
                        confirmButtonText: t('OK'),
                    });
                    onDeleteGroupSuccess();
                    fetchData();
                } else {
                    Swal.fire({
                        title: t('Error'),
                        text: t('Failed to delete stock. Please try again.'),
                        icon: 'error',
                        confirmButtonText: t('OK'),
                    });
                    console.error('Delete failed with message:', response.data.message);
                }
            } catch (error) {
                console.error('Error deleting stock:', error.message);
                Swal.fire({
                    title: t('Error'),
                    text: t('Failed to delete group. Please try again.'),
                    icon: 'error',
                    confirmButtonText: t('OK'),
                });
                console.error('Error deleting group:', error);
            }
        }
    };
    return (
        <>
            <button className={`btn ${styles['delete-price-btn']}`} onClick={handleDeleteClick}>
                <div className="d-flex justify-content-center align-items-center actions-ar">
                    <div className={`${styles['icon-delete-box']}  `}>
                        <FaRegTrashAlt className={`${styles['icon-delete']}`}/>
                    </div>
                    {/*<span>{t('Delete')}</span>*/}
                </div>
            </button>
        </>
    );
};

export default DeleteTagsPrice;
