import React from 'react'
import {useSearchParams} from "react-router-dom";

function useIndicator() {
    const [searchParams] = useSearchParams();

    const indicator = searchParams.get('indicators');
    const indicatorArr = indicator?.split(',');

    const indicatorAdapter = {
        "is_SMA10_SMA20": [
            {"indecator": "MA Cross", "values": {in_0: 10, in_1: 20}},
        ],
        "is_SMA7_SMA21": [
            {"indecator": "MA Cross", "values": {in_0: 7, in_1: 21}},
        ],
        "is_MACD_Daily": [
            {"indecator": "MACD", "values": {in_0: 12, in_1: 26, in_3: 'close', in_2: 9}}
        ],
        "positive": [
            {"indecator": "moving average", "values": {length: 55}}
        ],
        "mcad": [
            {"indecator": "MACD", "values": {in_0: 12, in_1: 26, in_3: 'close', in_2: 9}}
        ],
        "up_trend": [
            {"indecator": "moving average", "values": {length: 55}}
        ],
        "up_stream": [
            {"indecator": "zig zag", "values": {in_0: 2, in_1: 3,}}
        ],
        "down_stream": [
            {"indecator": "zig zag", "values": {in_0: 2, in_1: 3,}}
        ],
        "side_way": [
            {"indecator": "zig zag", "values": {in_0: 2, in_1: 3,}}
        ]
    };

    const validIndecators = [];

    indicatorArr?.forEach(indicator => {
        if (indicatorAdapter[indicator]) {
            validIndecators.push(indicatorAdapter[indicator]);
        }
    });

    return ({validIndecators, indicator});
}

export default useIndicator