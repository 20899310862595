import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { useCookies } from "react-cookie";
import axios from "axios";
import {apiUrlServer, getsimulation} from "../../apiConfig";
import { AiOutlineFilter } from "react-icons/ai";
import {BulletList} from "react-content-loader";
import useCustomTable from "../../Hooks/useCustomTable";
import useCustomTranslation from "../../Hooks/useTranslation";

const OutStandingOrders = ({ shouldUpdateOrders, setShouldUpdateOrders }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [data, setData] = useState([]); // State to hold simulation data
    const [cookies] = useCookies(['token']);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterType, setFilterType] = useState('');
    const [showFilterButtons, setShowFilterButtons] = useState(false);
    const { t } = useCustomTranslation();

    const toggleFilterButtons = () => {
        setShowFilterButtons(!showFilterButtons);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = cookies.token;
                const params = filterType ? { 'search[type]': filterType, page: currentPage, limit: perPage } : { page: currentPage, limit: perPage };
                const response = await axios.get(`${apiUrlServer}${getsimulation}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params,
                });

                setData(response.data.data);
                console.log()
                setTotalRows(response.data.meta.total);
                // console.log("tesssst", response.data.data);
            } catch (error) {
                console.error('Error fetching simulation data:', error.message);
            }
            setLoading(false);
        };

        fetchData();
    }, [cookies.token, filterType, currentPage, perPage, shouldUpdateOrders]);

    useEffect(() => {
        if (shouldUpdateOrders) {
            setShouldUpdateOrders(false);
        }
    }, [shouldUpdateOrders, setShouldUpdateOrders]);
    // Handle select all checkbox
    const handleSelectAll = () => {
        setSelectedRows(selectedRows.length === data.length ? [] : data);
        setToggleCleared(!toggleCleared);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    // Define the columns for the table
    const columns = [
        {
            name: t('Type'),
            selector: row => row.type,
            sortable: true,
        },
        {
            name: t('Expiration Count'),
            selector: row => row.expiration_count,
            sortable: true,
        },
        {
            name: t('Quantity'),
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: t('Cost'),
            selector: row => row.rate,
            sortable: true,
        },
        {
            name: t('Value'),
            selector: row => row.value,
            sortable: true,
        },
        {
            name: t('Status'),
            selector: row => row.status,
            sortable: true,
        }
        // Add more columns as needed
    ];

    // Handle row selection
    const handleRowSelect = (row) => {
        const selectedIndex = selectedRows.findIndex((selected) => selected.id === row.id);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, row];
        } else {
            newSelectedRows = selectedRows.filter((selected) => selected.id !== row.id);
        }

        setSelectedRows(newSelectedRows);
        setToggleCleared(!toggleCleared);
    };

    const handleFilterClick = (type) => {
        setFilterType(type === '' ? null : type);
    };
    const {customStyles} = useCustomTable();

    return (
        <>
            <div className="outstandig-card">
                <div className="">
                    <div className="d-flex align-items-center justify-content-between mb-16">
                        <div>
                            <span className="virtual-number">{t('Outstanding Orders')}</span>
                        </div>
                        <div className="filter-icon-container" onClick={toggleFilterButtons}>
                            <AiOutlineFilter className="filter-icon" />
                        </div>
                    </div>
                    {showFilterButtons && (
                        <div className="filter-buttons-container">
                            <div className="d-flex align-items-center justify-content-end gap-3">
                                <button
                                    className={`btn ${filterType === '' ? 'btn-link' : 'btn'}`}
                                    onClick={() => handleFilterClick('')}
                                >
                                    {t('All')}
                                </button>
                                <button
                                    className={`btn ${filterType === 'buy' ? 'btn-link' : 'btn'}`}
                                    onClick={() => handleFilterClick('buy')}
                                >
                                    {t('Buy')}
                                </button>
                                <button
                                    className={`btn ${filterType === 'sell' ? 'btn-link' : 'btn'}`}
                                    onClick={() => handleFilterClick('sell')}
                                >
                                    {t('Sell')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {loading ? (
                    <BulletList />
                ) : (
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        selectableRows
                        onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
                        clearSelectedRows={toggleCleared}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressPending={loading}
                    />
                )}
            </div>
        </>
    );
};

export default OutStandingOrders;