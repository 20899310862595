import React, { createContext, useState, useEffect, useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import arTranslation from "../languages/ar.json";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    // Get the language preference from session storage, default to 'en' if not available
    const storedLanguage = sessionStorage.getItem('language') || 'en';
    const [language, setLanguage] = useState(storedLanguage);

    const toggleLanguage = () => {
        const newLanguage = language === 'en' ? 'ar' : 'en';
        setLanguage(newLanguage);
        // Store the new language preference in session storage
        sessionStorage.setItem('language', newLanguage);
    };

    useEffect(() => {
        i18n
            .use(initReactI18next)
            .init({
                resources: {
                    en: { translation: {} }, // Empty translations for English
                    ar: { translation: arTranslation }
                },
                lng: language, // Set language based on state
                fallbackLng: 'en', // Fallback language
                interpolation: {
                    escapeValue: false // React already safes from xss
                }
            });
        document.documentElement.lang = language; // Set lang attribute
        document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr'; // Set dir attribute
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
