import React, {useState,useEffect} from 'react';
import useCustomTranslation from '../../Hooks/useTranslation';
import gainersIcon from '../../assets/icons/Gainers.svg'
import losersIcon from '../../assets/icons/Losers.svg'
import {Link} from "react-router-dom";
import { useLanguage } from '../../Context/LanguageContext';
import {
    apiUrlServer,
} from '../../apiConfig'
import axios from "axios";
import {abbreviateNumber} from "../../Hooks/useNumberFormatting";
import DelayedPriceMessage from "../DisplayMessages/DelayedPriceMessage";
const MarketTabs = () => {
    const { t } = useCustomTranslation();
    const [activeTab, setActiveTab] = useState('Tab1');
    const [stockData, setstockData] = useState([]);
    const { language } = useLanguage();
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const fetchStockData = async () => {
        try {
            const response = await axios.get(`${apiUrlServer}stock/positiveAndNegative`,{headers: {
                'Accept-Language': language,
            }});
            setstockData(response.data.data);
        } catch (error) {
            console.error('Error fetching stock data:', error);
        }
    };

    useEffect(() => {
        fetchStockData();
    }, [language]); // Empty dependency array means this effect will only run once after the component mounts.

    if (!stockData) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <DelayedPriceMessage messageKey="The stock prices displayed are not real-time and reflect market data from 15 minutes ago."/>
            <div className="smryTabDtlRgt mt-2">
                <div className="graphTabDtl select">
                    <h3 className=" tasi-avg mb-3"><img style={{width:'24px',height:"24px"}} src={gainersIcon}/>{t("Ganiers")}</h3>
                    <ul>
                        {stockData.positive?.map((item,index)=>(

                            <li key={index}>

                                <div className="graphTabLft">

                                    <div className="name fw-bold d-flex align-items-center">
                                        <Link to={`/stocks/${item.id}/${item.code}`}>({item.code}) {item.name}</Link>
                                    </div>
                                </div>
                                <div className="graphTabRgt">
                                    <div className="price">{abbreviateNumber(item.api.close)}</div>
                                <div className="priceUp">{abbreviateNumber(item.api.change_p)}</div>
                            </div>

                            </li>
                        ))}
                       {/* <span className="soon">{t('comming soon')}</span> */}


                    </ul>
                    <h3 className="tasi-avg mb-3"><img style={{width: '24px', height: "24px"}} src={losersIcon}/>{t('Losers')}</h3>
                    <ul className="losers">
                        {stockData.negative?.map((item,index)=>(
                            <li key={index}>
                            <div className="graphTabLft">
                                <div className="name fw-bold d-flex align-items-center">
                                    <Link to={`/stocks/${item.id}/${item.code}`}>({item.code}) {item.name}</Link>
                                </div>
                            </div>
                                <div className="graphTabRgt">
                                <div className="price">{abbreviateNumber(item.api.close)}</div>
                                <div className="priceUp">{abbreviateNumber(item.api.change_p)}</div>
                            </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

        </>
    )
        ;
};

export default MarketTabs;