import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {ShimmerPostDetails} from "react-shimmer-effects";
import axios from "axios";
import useCustomTranslation from "../../../Hooks/useTranslation";
import {apiUrlServer} from "../../../apiConfig";
import {useLanguage} from "../../../Context/LanguageContext";
import {Modal} from "react-bootstrap";
import {Button} from "@mui/material";

const WorldNews = () => {
    const [news, setNews] = useState([]);
    const [noData, setNoData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const { language } = useLanguage();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrlServer}news?limit=5`, {
                    headers: {
                        'Accept-Language': language,
                    }
                });
                if (response.data.data.length === 0) {
                    setNoData(true);
                } else {
                    setNews(response.data.data);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 403) {
                    setShowErrorModal(true);
                }
                setLoading(false);
            }
        };

        fetchData();
    }, [language]);

    const handleUpgrade = () => {
        // Add your upgrade logic here
        console.log('Upgrade clicked');
        setShowErrorModal(false);
    };

    const truncateText = (text, maxLength) => {
        const words = text.split(' ');
        if (words.length > maxLength) {
            return words.slice(0, maxLength).join(' ') + '...';
        }
        return text;
    };

    const truncateSymbols = (symbols) => {
        if (!symbols || symbols.length === 0) {
            return '-';
        }
        if (symbols.length > 5) {
            return symbols.slice(0, 5).join(', ') + '...';
        }
        return symbols.join(', ');
    };

    const { t } = useCustomTranslation();


    return (
        <>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h3 className="tasi_summery">{t('Latest World News')}</h3>
                    <Link to={'/news'} className="pageLink">{t('View all')}</Link>
                </div>
                {loading && <ShimmerPostDetails card cta variant="SIMPLE" />}
                {!loading && noData && <p>{t('Data not available')}</p>}
            {!loading && !noData && (
                <>
                            <ul className='p-0'>
                                {news.map((item, index) => (
                                    <li className="news-list card mb-3  border-0" key={index}>
                                        <div className=''>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h2 className="title-head"> {truncateText(item.title, 30)}</h2>

                                            </div>
                                            <div className="d-flex align-items-center justify-content-start">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                                     viewBox="0 0 16 17" fill="none">
                                                    <g clip-path="url(#clip0_3390_76579)">
                                                        <path
                                                            d="M14.125 1.97656H13.375V0.726562H12.125V1.97656H3.875V0.726562H2.625V1.97656H1.875C0.841125 1.97656 0 2.81769 0 3.85156V14.8516C0 15.8854 0.841125 16.7266 1.875 16.7266H14.125C15.1589 16.7266 16 15.8854 16 14.8516V3.85156C16 2.81769 15.1589 1.97656 14.125 1.97656ZM14.75 14.8516C14.75 15.1962 14.4696 15.4766 14.125 15.4766H1.875C1.53038 15.4766 1.25 15.1962 1.25 14.8516V6.60156H14.75V14.8516ZM14.75 5.35156H1.25V3.85156C1.25 3.50694 1.53038 3.22656 1.875 3.22656H2.625V4.47656H3.875V3.22656H12.125V4.47656H13.375V3.22656H14.125C14.4696 3.22656 14.75 3.50694 14.75 3.85156V5.35156Z"
                                                            fill="#1D1A39"/>
                                                        <path
                                                            d="M2.375 7.91406H3.625V9.16406H2.375V7.91406ZM4.875 7.91406H6.125V9.16406H4.875V7.91406ZM7.375 7.91406H8.625V9.16406H7.375V7.91406ZM9.875 7.91406H11.125V9.16406H9.875V7.91406ZM12.375 7.91406H13.625V9.16406H12.375V7.91406ZM2.375 10.4141H3.625V11.6641H2.375V10.4141ZM4.875 10.4141H6.125V11.6641H4.875V10.4141ZM7.375 10.4141H8.625V11.6641H7.375V10.4141ZM9.875 10.4141H11.125V11.6641H9.875V10.4141ZM2.375 12.9141H3.625V14.1641H2.375V12.9141ZM4.875 12.9141H6.125V14.1641H4.875V12.9141ZM7.375 12.9141H8.625V14.1641H7.375V12.9141ZM9.875 12.9141H11.125V14.1641H9.875V12.9141ZM12.375 10.4141H13.625V11.6641H12.375V10.4141Z"
                                                            fill="#1D1A39"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_3390_76579">
                                                            <rect width="16" height="16" fill="white"
                                                                  transform="translate(0 0.726562)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="date-news">{item.date}</span>
                                            </div>

                                            <p className="text-news"
                                               style={{margin: "16px 0"}}>{truncateText(item.content, 50)}</p>
                                            <div className="card-insights-new">
                                                {((item.symbols && item.symbols.length > 0) || (item.tags && item.tags.length > 0) || (item.sentiment)) && (
                                                    <div
                                                        className="d-flex flex-column justify-content-between my-3 mb-0 gap-2">
                                                        {item.symbols && item.symbols.length > 0 && (
                                                            <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">{t('Symbols')} :
                                                                <div className='d-flex gap-2 flex-wrap'>
                                                                    {item.symbols.map((symbol, index) => (
                                                                        <span key={index}
                                                                              className="symbol-item">{symbol}</span>
                                                                    ))}
                                                                </div>
                                                            </p>
                                                        )}

                                                        {item.tags && item.tags.length > 0 && (
                                                            <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">{t('Tags')}:
                                                                <div className='d-flex gap-2 flex-wrap'>
                                                                    {item.tags.map((tag, index) => (
                                                                        <span key={index}
                                                                              className="tags-item">{tag}</span>
                                                                    ))}
                                                                </div>
                                                            </p>
                                                        )}

                                                        {item.sentiment && (
                                                            <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">{t('Sentiment')}:
                                                                <div className='d-flex gap-2 flex-wrap'>
                                                                <span
                                                                    className="sentiment-item">Neg: {item.sentiment.neg ?? 0}</span>
                                                                    <span
                                                                        className="sentiment-item">Neu: {item.sentiment.neu ?? 0}</span>
                                                                    <span
                                                                        className="sentiment-item">Pos: {item.sentiment.pos ?? 0}</span>
                                                                    <span
                                                                        className="sentiment-item">Polarity: {item.sentiment.polarity ?? 0}</span>
                                                                </div>
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="d-flex align-items-center justify-content-end gap-3 mt-2">
                                                <a className=" btn-open-link btn-view-link" target="_blank"
                                                   href={item.link}>{t('View Link')}</a>
                                                <Link to={`/news/${item.id}`}
                                                      className="btn-open-link ">{t('Read More')}</Link>
                                            </div>

                                        </div>
                                    </li>
                                ))}
                            </ul>
                </>
            )}
                <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Subscription Required</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You need to upgrade your subscription to access this content.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleUpgrade}>
                            Upgrade Now
                        </Button>
                    </Modal.Footer>
                </Modal>
        </>
    );
};

export default WorldNews;