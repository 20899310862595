import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import stocksicon from "../../assets/icons/stocksp.png";
import Select from "react-select";
import styles from "../MyPortfolio/EntryandUpload/ManualAdd.module.css";
import {useCookies} from "react-cookie";
import axios from "axios";
import {addSimulation, addSimulationAllowed, apiUrlServer} from "../../apiConfig";
import Swal from "sweetalert2";
import iconSuccess from "../../assets/icons/suceessAdd.png";
import iconError from "../../assets/icons/errorCancel.png";
import {useFormik} from "formik";
import useCustomTranslation from '../../Hooks/useTranslation';

const SimulationSell = ({ wallets, fetchWalletData,setShouldUpdateOrders}) => {
    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);
    const [stocks, setStocks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [limit, setLimit] = useState(10);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState(null);


    const handeAddClick = () => {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false);
    }


    async function handleAddStock(values) {
        // console.log(values);

        // Calculate value based on rate and quantity
        const value = parseFloat(values.rate) * parseFloat(values.quantity);

        // Update the values object with the calculated value
        const updatedValues = {
            ...values,
            value: isNaN(value) ? '' : value.toFixed(2),
        };

        setIsLoading(true);
        try {
            const token = cookies.token;
            const response = await axios.post(`${apiUrlServer}${addSimulation}`, values, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            // console.log('simulation updated successfully:', response.data.data);

            // Show SweetAlert success notification
            Swal.fire({
                title: 'Your Stock Sell successfully',
                iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
                text: 'Start Buying Stocks from your Wallet',
                confirmButtonText: 'Back to Simulation',
                cancelButtonText: 'Add Another',
                customClass: {
                    confirmButton: 'swal-btn-confirmButton',
                    cancelButton: 'swal-btn-cancelButton',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    setShowModal(false);
                    formik.resetForm();
                    fetchWalletData();
                    setShouldUpdateOrders(true); // Add this line
                } else {
                    setShowModal(true);
                    formik.resetForm();
                }
            });
        } catch (error) {
            console.error('Server error:', error.response?.data);

            // Log the specific error from the server
            console.error('Server error:', error.response?.data);

            // Show SweetAlert error notification with the API error message
            const errorMessage = error.response?.data?.message || 'Something went wrong.';
            Swal.fire({
                title: 'Error',
                text: errorMessage,
                iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
                confirmButtonText: 'OK',
                customClass: {
                    confirmButtonText: 'swal-btn-cancelButton',
                },
            });
        } finally {
            setIsLoading(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            parent_id:'',
            stock_id: '',
            quantity: '',
            rate: '',
            value: '',
            type: 'sell',
            expiration_count: '',
            simulation_wallet_id: '3',
        },
        onSubmit: handleAddStock,
    });
    useEffect(() => {
        const fetchStocksAllowed = async () => {
            const token = cookies.token;
            try {
                const response = await axios.get(`${apiUrlServer}${addSimulationAllowed}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                const result = response.data;
                // console.log("Response data:", result);

                if (result.status) {
                    setStocks(result.data);
                } else {
                    console.error('Error fetching data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        if (selectedWallet) {
            fetchStocksAllowed();
        }
    }, [selectedWallet, cookies.token]);

    const uniqueStockOptions = stocks
        .filter((item) => item.simulationWallet.id === selectedWallet?.value)
        .map((item) => ({
            value: item.stock.id,
            label: `${item.stock.code} - ${item.stock.name}`,
            parentId: item.id,
        }));
    useEffect(() => {
        const calculateValue = () => {
            const rate = parseFloat(formik.values.rate) || 0;
            const quantity = parseFloat(formik.values.quantity) || 0;
            const value = rate * quantity;
            // console.log('rate:', rate);
            // console.log('quantity:', quantity);
            // console.log('calculated value:', value);
            formik.setFieldValue('value', isNaN(value) ? '' : value.toFixed(0));
        };


        calculateValue();
        // console.log('useEffect executed'); // Add this line
    }, [formik.values.rate, formik.values.quantity]);


    const walletOptions = wallets.map((wallet) => ({
        value: wallet.id,
        label: `Wallet ${wallet.id} - Balance: ${wallet.value.toLocaleString()}`,
    }));

    // const uniqueStockOptions = stocks.map((item) => ({
    //     value: item.stock.id,
    //     label: `${item.stock.code} - ${item.stock.name}`,
    // }));

    return (
        <>
            <button className="btn btn-sell w-100" onClick={handeAddClick}>{t('Sell')}</button>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="d-flex justify-content-center align-items-center mb-16">
                            <img src={stocksicon} alt="stocks"/>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="d-flex flex-column position-relative">
                                <div className="form-group mt-16 edit-form w-100">
                                    <label htmlFor="simulation_wallet_id" className="form-label">
                                        {t('Select Wallet')}
                                    </label>
                                    <Select
                                        options={walletOptions}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue('simulation_wallet_id', selectedOption?.value || '');
                                            setSelectedWallet(selectedOption);
                                            setSelectedOption(null); // Clear the selected stock option
                                            formik.setFieldValue('stock_id', ''); // Clear the stock_id field in the form
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={walletOptions.find((option) => option.value === formik.values.simulation_wallet_id)}
                                        placeholder="Select a wallet"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column position-relative">
                                <div className="form-group mt-16 edit-form">
                                    <label htmlFor="stock_id" className="form-label">
                                        {t('Select Stock')}
                                    </label>
                                    <Select
                                        options={uniqueStockOptions}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue('stock_id', selectedOption?.value || '');
                                            formik.setFieldValue('parent_id', selectedOption?.parentId || '');
                                            setSelectedOption(selectedOption);
                                        }}
                                        onFocus={() => setIsInputFocused(true)}
                                        onBlur={formik.handleBlur}
                                        value={selectedOption}
                                        placeholder={selectedOption?.label || 'Select a stock'}
                                        isDisabled={!selectedWallet}
                                    />
                                    {!selectedWallet && (
                                        <div className="text-danger mt-2">
                                            {t('Please select a wallet before choosing a stock.')}
                                        </div>
                                    )}
                                    {selectedOption && (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <div className={`${styles['selcet-stock-box']}`}>
                                                {selectedOption.label}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center gap-3 mt-16">
                            <div className="form-group mt-16 edit-form">
                                    <label htmlFor="quantity" className="form-label">
                                        {t('Stock Quantity')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputQuantity"
                                        name="quantity"
                                        placeholder="Quantity"
                                        value={formik.values.quantity}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="form-group mt-16 edit-form">
                                    <label htmlFor="rate" className="form-label">
                                        {t('Stock Rate')}
                                    </label>
                                    <input
                                        type="text"
                                        name="rate"
                                        value={formik.values.rate}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        className="form-control"
                                        id="rate"
                                        placeholder="Rate"
                                    />
                                </div>
                                <div className="form-group mt-16 edit-form">
                                    <label htmlFor="value" className="form-label">
                                        {t('Stock Value')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="value"
                                        name="value"
                                        value={formik.values.value}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        placeholder="Value"
                                        readOnly
                                    />
                                </div>

                            </div>
                            <div className="d-flex  align-items-center gap-3 mt-16">
                                <div className="form-group mt-16 edit-form w-100">
                                    <label htmlFor="expiration_count" className="form-label">
                                        {t('Expiration Count')}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="expiration_count"
                                        name="expiration_count"
                                        placeholder="Expiration Count"
                                        value={formik.values.expiration_count}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </div>

                            </div>
                            <Modal.Footer>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <button className="btn btn-save mt-16 w-100" type="submit">
                                        {t('Sell')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </form>
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SimulationSell;