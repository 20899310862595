import React from 'react'
import '../Officers/Officers.css'
import { BulletList } from 'react-content-loader'
import useCustomTranslation from "../../../../Hooks/useTranslation";
import {abbreviateNumber} from "../../../../Hooks/useNumberFormatting";
const Highlights = (props) => {
  const { t } = useCustomTranslation();

  return (
    <>
      {props.loading && <BulletList />}
      {!props.loading && (
          <div className="row">
            <div className="col-lg-12 my-24">
              <div className="card card-highlighter h-100">
                <div className="card-body">
                  <h5 className="card-title text-center">{t('Financial Highlights')}</h5>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex align-items-center justify-content-between ">
                      <span className="fw-bold">{t('Market Capitalization')}: </span>
                      <span className="text-secondary ms-2 ">
                      {abbreviateNumber(props.MarketCapitalization)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Market Capitalization Mln')}: </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.MarketCapitalizationMln)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('EBITDA')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.EBITDA)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('PE Ratio')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.PERatio)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('PEG Ratio')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.PEGRatio)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Wall Street Target Price')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.WallStreetTargetPrice)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Book Value')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.BookValue)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Dividend Share')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.DividendShare)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Dividend Yield')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.DividendYield)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Earnings Share')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.EarningsShare)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('EPS Estimate Current Year')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.EPSEstimateCurrentYear)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('EPS Estimate Next Year')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.EPSEstimateNextYear)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('EPS Estimate Next Quarter')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.EPSEstimateNextQuarter)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('EPS Estimate Current Quarter')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.EPSEstimateCurrentQuarter)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Most Recent Quarter')}: </span>
                      <span className="text-secondary ms-2">{props.MostRecentQuarter}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Profit Margin')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.ProfitMargin)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Operating Margin TTM')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.OperatingMarginTTM)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Return On Assets TTM')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.ReturnOnAssetsTTM)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Return On Equity TTM')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.ReturnOnEquityTTM)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Revenue TTM')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.RevenueTTM)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Revenue Per Share TTM')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.RevenuePerShareTTM)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Quarterly Revenue Growth YOY')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.QuarterlyRevenueGrowthYOY)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Gross Profit TTM')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.GrossProfitTTM)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Diluted EPS TTM')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.DilutedEpsTTM)}</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t('Quarterly Earnings Growth YOY')}: </span>
                      <span className="text-secondary ms-2">{abbreviateNumber(props.QuarterlyEarningsGrowthYOY)}</span>
                    </li>
                  </ul>

              </div>
            </div>
          </div>
      </div>
      )}
    </>
  )
}

export default Highlights