// components/OpenEarningsCharts.js
import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const OpenEarningsCharts = ({ statementType, id, code, data, buttonText }) => {
    const handleClick = () => {
        const dataKey = `${statementType}Data`;
        sessionStorage.setItem(dataKey, JSON.stringify(data));
        window.open(`/test/${statementType}/${id}/${code}`, '_blank');
    };

    return (
        <Button className="btn visual-chart" onClick={handleClick}>
            {buttonText}
        </Button>
    );
};

OpenEarningsCharts.propTypes = {
    statementType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
};

export default OpenEarningsCharts;