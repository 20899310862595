import React, { useEffect, useState } from 'react';
import styles from '../Price.module.css';
import { Modal } from 'react-bootstrap';
import priceicon from '../../../assets/icons/addprice.svg';
import Select from 'react-select';
import axios from 'axios';
import { addStockAlert, apiUrlServer, getAllStocks } from '../../../apiConfig';
import Swal from 'sweetalert2';
import iconSuccess from '../../../assets/icons/suceessAdd.png';
import iconError from '../../../assets/icons/errorCancel.png';
import { useFormik } from 'formik';
import { useCookies } from 'react-cookie';
import useCustomTranslation from "../../../Hooks/useTranslation";
import { useLanguage } from "../../../Context/LanguageContext";

const AddPrice = ({ addPriceSuccess }) => {
  const [showModal, setShowModal] = useState(false);
  const [cookies] = useCookies(['token']);
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [limit, setLimit] = useState(10);
  const [previousClose, setPreviousClose] = useState('');
  const { language } = useLanguage();
  const { t } = useCustomTranslation();

  async function handleAddStock(values) {
    setIsLoading(true);
    try {
      const token = cookies.token;

      // Create a new FormData object
      const formData = new FormData();

      // Add the fields to the FormData object
      formData.append('stock_id', values.stock_id);
      formData.append('operator', values.operator);
      formData.append('value', values.value);
      formData.append('providers', JSON.stringify(['email', 'sms', 'fcm-api']));

      const response = await axios.post(`${apiUrlServer}${addStockAlert}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          'Accept-Language': language,
        },
      });

      if (response.data && response.data.status) {
        Swal.fire({
          title: t('Your Stock Added successfully'),
          iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
          text: t('Start Adding Stocks to your Alerts'),
          confirmButtonText: t('Back to Alerts'),
          cancelButtonText: t('Add Another'),
          showCancelButton: true,
          customClass: {
            confirmButton: 'swal-btn-confirmButton',
            cancelButton: 'swal-btn-cancelButton',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            addPriceSuccess(prev => [...prev, response.data.data]);
            setShowModal(false);
            formik.resetForm();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            addPriceSuccess(prev => [...prev, response.data.data]);
            formik.resetForm();
          }
        });
      } else {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error adding stock alert:', error);

      let errorMessage = 'An unknown error occurred. Please try again.';

      if (error.response) {
        console.error('Error response:', error.response.data);
        errorMessage = error.response.data.message || 'Server error. Please try again.';
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMessage = 'No response from server. Please check your internet connection.';
      } else {
        console.error('Error message:', error.message);
        errorMessage = error.message;
      }

      Swal.fire({
        title: 'Something went Wrong',
        text: errorMessage,
        iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'swal-btn-cancelButton',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      stock_id: '',
      operator: '==',
      value: '',
      providers: "[]",
    },
    onSubmit: handleAddStock,
  });

  useEffect(() => {
    const fetchStocksData = async () => {
      const token = cookies.token;
      try {
        const response = await axios.get(`${apiUrlServer}${getAllStocks}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept-Language': language,
          },
          params: {
            limit: limit,
          },
        });
        const result = response.data;

        if (result.status) {
          setStocks((prevStocks) => [...prevStocks, ...result.data]);
        } else {
          console.error('Error fetching data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchStocksData();
  }, [limit, cookies.token, language]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleMenuScroll = async (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setLimit((prevLimit) => prevLimit + 10);
    }
  };

  const uniqueStockIds = new Set();
  const uniqueStockOptions = stocks.filter((stock) => {
    if (!uniqueStockIds.has(stock.id)) {
      uniqueStockIds.add(stock.id);
      return true;
    }
    return false;
  }).map((stock) => ({ value: stock.id, label: `${stock.name} - ${stock.code}` }));

  const operatorOptions = [
    { value: '=', label: 'equals' },
    { value: '<', label: 'less than' },
    { value: '>', label: 'greater than' },
  ];

  const providerOptions = [
    { value: 'email', label: t('Email') },
    { value: 'sms', label: t('SMS') },
    { value: 'fcm-api', label: t('Notification') },
  ];

  return (
      <>
        <div className={`${styles['add-price']}`}>
          <h2>{t('Stock Price Alert')}</h2>
          <button type="button" className={`btn ${styles['btn-add-price']}`} onClick={handleOpenModal}>{t('Add Alert')}</button>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="text-center"><img src={priceicon} alt="stocks" className="mb-24"/></div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="d-flex flex-column position-relative">
                        <Select
                            options={uniqueStockOptions}
                            onMenuScrollToBottom={handleMenuScroll}
                            onChange={(selectedOption) => {
                              const selectedStock = stocks.find((stock) => stock.id === selectedOption?.value);
                              setPreviousClose(selectedStock?.api?.previousClose || '');
                              setSelectedOption(selectedOption);
                              formik.setFieldValue('stock_id', selectedOption?.value || '');
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={formik.handleBlur}
                            value={selectedOption}
                            placeholder={t("select")}
                        />
                        {selectedOption && (
                            <div className="d-flex align-items-center justify-content-start">
                              <div className={`${styles['selcet-stock-box']}`}>
                                {selectedOption.label}
                              </div>
                            </div>
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-3 mt-16">
                        <div className="form-group mt-16 edit-form w-100">
                          <label htmlFor="operator" className="form-label">
                            {t('Operator')}
                          </label>
                          <Select
                              id="operator"
                              name="operator"
                              options={operatorOptions}
                              defaultValue={operatorOptions[0]}
                              onChange={(selectedOption) => {
                                formik.setFieldValue('operator', selectedOption.value);
                              }}
                          />
                        </div>
                        <div className="form-group mt-16 edit-form w-100">
                          <label htmlFor="currentPrice" className="form-label">
                            {t('Current Price')}
                          </label>
                          <input
                              type="text"
                              className="form-control form-price"
                              id="currentPrice"
                              name="currentPrice"
                              value={previousClose}
                              readOnly
                          />
                        </div>
                        <div className="form-group mt-16 edit-form w-100">
                          <label htmlFor="value" className="form-label">
                            {t('Target Price')}
                          </label>
                          <input
                              type="number"
                              name="value"
                              value={formik.values.value}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className="form-control"
                              id="value"
                              placeholder={t("target")}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-3 mt-16">
                        <div className="form-group mt-16 edit-form w-100">
                          <label htmlFor="providers" className="form-label">
                            {t('Providers')}
                          </label>
                          <Select
                              id="providers"
                              name="providers"
                              options={providerOptions}
                              isMulti
                              value={providerOptions.filter(option => formik.values.providers.includes(option.value))}
                              onChange={(selectedOptions) => {
                                formik.setFieldValue('providers', selectedOptions.map(option => option.value));
                              }}
                              placeholder={t("Select Providers")}
                          />
                        </div>
                      </div>
                      <Modal.Footer>
                        <div className="d-flex justify-content-between align-items-center w-100 mt-24">
                          <button type="submit" className="btn btn-save mt-24 w-100" disabled={isLoading}>
                            {isLoading ? t('Adding...') : t('Add')}
                          </button>
                        </div>
                      </Modal.Footer>
                    </form>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
  );
};

export default AddPrice;