import React from 'react';
import { usePermissions } from "../../Context/PermissionContext";
import { FaLock } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ProtectedComponent = ({
                                permission,
                                children,
                                buttonText = "Upgrade to Access",
                                textHeadLine,
                                headlineStyle = {}
                            }) => {
    const { hasPermission, permissions, usage } = usePermissions();
    const navigate = useNavigate();

    const permissionDetails = permissions[permission];

    if (!permissionDetails || hasPermission(permission)) {
        return children;
    }

    const isLimitReached = permissionDetails.type === 'limited' &&
        (usage[permission] || 0) >= (permissionDetails.limit || 0);

    const showUpgradeAlert = () => {
        Swal.fire({
            title: 'Upgrade Your Plan',
            text: 'To access this feature, you need to upgrade your subscription plan.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Upgrade Now',
            cancelButtonText: 'Maybe Later',
            customClass: {
                confirmButton: 'swal-btn-confirmButton',
                cancelButton: 'swal-btn-cancelButton',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/subscription');
            }
        });
    };

    const defaultHeadlineStyle = {
        fontSize: '20px',
        color: 'var(--primary-color)',
        fontWeight: 700,
        margin: '10px 0'
    };

    const mergedHeadlineStyle = { ...defaultHeadlineStyle, ...headlineStyle };

    return (
        <>
            {textHeadLine && <h3 style={mergedHeadlineStyle}>{textHeadLine}</h3>}
            <button
                onClick={showUpgradeAlert}
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '10px 15px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    color: '#333',
                    margin: "8px 0"
                }}
            >
                <FaLock style={{ marginRight: '8px' }} />
                {buttonText}
                {permissionDetails.type === 'limited' && (
                    <span style={{ marginLeft: '8px', fontSize: '12px', color: isLimitReached ? 'red' : 'gray' }}>
                        ({usage[permission] || 0}/{permissionDetails.limit || 0})
                    </span>
                )}
            </button>
        </>
    );
};

export default ProtectedComponent;