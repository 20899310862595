import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from 'react-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../src/assets/style/Modals.css';
import '../src/assets/style/swl-custome.css';
import './index.css';
import '../src/assets/style/mediaQuery.css';
import './i18n';
import { QueryClientProvider, QueryClient} from '@tanstack/react-query';
import { LanguageProvider } from "./Context/LanguageContext";
import {FCMTokenProvider} from "./Context/FCMTokenContext";
// const queryClient = new QueryClient()
import './axios-setup'
import {DataProvider} from "./Context/FundamentalsContext";
// import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { PersistQueryClientProvider,persistQueryClient } from '@tanstack/react-query-persist-client'
import {ThemeProvider} from "./Context/ThemeContext";
import {PermissionProvider} from "./Context/PermissionContext";

// 1. the query client
const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: Infinity, // 24 hours
      },
    },
  })

  // 2. the persister
export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persister,
  maxAge: Infinity, // 24 hours
});

// Render the root component using createRoot
createRoot(document.getElementById('root')).render(
    // <React.StrictMode>
    <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >
        <FCMTokenProvider>
            <ThemeProvider>
        <LanguageProvider>


            <DataProvider>
            <App />
        </DataProvider>


        </LanguageProvider>
            </ThemeProvider>
        </FCMTokenProvider>
         {/*<ReactQueryDevtools  initialIsOpen={false} /> */}

        </PersistQueryClientProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
