import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import EditPortfolio from './EditPortfolio'
import DeletePortfolio from './DeletePortfolio'
import axios from 'axios'
import { apiUrlServer } from '../../../../apiConfig'
import { useCookies } from 'react-cookie'
import styles from '../../../WatchList/watchlist.module.css'
import { Link } from 'react-router-dom'
import { BulletList } from 'react-content-loader'
import useCustomTable from "../../../../Hooks/useCustomTable";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import {useLanguage} from "../../../../Context/LanguageContext";
import {abbreviateNumber} from "../../../../Hooks/useNumberFormatting";

const PortfolioList = () => {
  const [portfolio, setPortfolio] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['token']);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {customStyles} = useCustomTable();
  const {t} = useCustomTranslation();
  const {language} = useLanguage()

  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      const token = cookies.token;

      const response = await axios.get(`${apiUrlServer}portfolio`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          'Accept-Language': language,
        },
        params: {
          page: page,
          // pageSize: 10,
          // Add other params if needed
        },
      });

      const result = response.data;

      if (result.status) {
        setPortfolio(result.data);
        setTotalPages(result.totalPages);
        setCurrentPage(page);
      } else {
        console.error('Error fetching data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage); // Fetch data for the initial page
  }, [cookies.token, currentPage]);

  const handleEditPortfolioSuccess = () => {
    fetchData(currentPage);
  };
const handleDeletePortfolioSuccess = () => {
  fetchData(currentPage);
}
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const columns = [
    {
      name: t('Name'),
      selector: row => row.name,
      sortable: false,
      cell: row => (
        <Link to={`/portfolio/${row.id}`}>
          {row.name}
        </Link>
      ),
    },
    {
      name: t('Value'),
      selector: row => row.value_api, // Change this to 'value_api'
      sortable: true,
      cell: (row) => (
        <span>
        {abbreviateNumber(row?.value_api)}
      </span>
      ),
    },
    {
      name: t('Change'),
      selector: row=> row.change,
      sortable: true,
      cell: row => (
        <span style={{ color: row?.change < 0 ? 'red' : 'green' }}>
        {abbreviateNumber(row?.change)}
      </span>
      ),
    },
    {
      name: t('Change (%)'),
      selector: row=> row.changeP,
      sortable: true,
      cell: row => (
        <span style={{ color: row?.change < 0 ? 'red' : 'green' }}>
        {abbreviateNumber(row?.changeP)}
      </span>
      ),
    },
    {
      name: t('Actions'),
      // selector: 'actions',
      sortable: false,
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <EditPortfolio portfolioId={row.id} onEditPortfolioSuccess={handleEditPortfolioSuccess} />
          <DeletePortfolio portfolioId={row.id} onDeletePortfolioSuccess={handleDeletePortfolioSuccess} />
        </div>
      ),
    },
  ];


  const paginationOptions = {
    totalPages: totalPages,
    currentPage: currentPage,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    showTotal: true,
    total: totalPages,
    firstBtnText: 'First',
    lastBtnText: 'Last',
    prevBtnText: 'Previous',
    nextBtnText: 'Next',
    showTotalPages: false,
  };
  // Additional CSS to hide the Last button
  const hideLastButtonStyle = `
    #pagination-last-page {
      display: none;
    }
    .iNfAEA{
    display: none;
  `;

  return (
    <>
      <style>{hideLastButtonStyle}</style>
      {isLoading ? (
        <BulletList/>
      ) : (
        <DataTable
          columns={columns}
          pagination
          data={portfolio}
          customStyles={customStyles}
          className={`${styles['table-watchList']}`}
          paginationPerPage={currentPage - 1}
          paginationServer
          paginationTotalRows={totalPages }
          paginationDefaultPage={currentPage }
          onChangePage={handlePageChange}
          paginationServerOptions={paginationOptions}
          highlightOnHover={true}
          striped={true}
          responsive
        />
      )}
    </>
  );
};

export default PortfolioList;
