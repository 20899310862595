import React, {useCallback, useEffect, useRef, useState,useMemo} from "react";
import {Link, useParams, useSearchParams,} from "react-router-dom";

import axios from "axios";
import DeatialsBanner from './DeatialsBanner/DeatialsBanner'
import IndicesDetailsTab from './IndicesDetailsTab/IndicesDetailsTab'
import { Helmet } from 'react-helmet'
import {
    apiToken,
    apiUrlServer,
    eodhdChartApi,
    fundamentals, getAllGroups,
    getStockDetailsFundamentals,
    liveDate, WEB_SOCKET_URL
} from '../../apiConfig'
import {BulletList} from "react-content-loader";
import NewsChart from "../../News/NewsChart";
import useCustomTranslation from "../../Hooks/useTranslation";

import {useCookies} from "react-cookie";
import Datafeed from "../../datafeed";
import BannerIndicesDetails from "./DeatialsBanner/BannerIndicesDetails";
import {useLanguage} from "../../Context/LanguageContext";
import StocksList from "../Stocks/StocksList/StocksList";
import DataTable from "react-data-table-component";
import useStocks from "../../Hooks/useStocks";
import useCustomTable from "../../Hooks/useCustomTable";
import useCustomLoader from "../../Hooks/useCustomeLoader";
import CommonBanner from "../CommonBanner/CommonBanner";
import BannerStocks from "../Stocks/BannerStocks/BannerStocks";
import {ShimmerThumbnail} from "react-shimmer-effects";
import useWebSocket from "../../Hooks/useWebSocket";
import {usePermissions} from "../../Context/PermissionContext";
import ProtectedComponent from "../ProtectedLink/ProtectedComponent";
const STOCK_ID = ["1188203_503"];

const IndicesDetails = () => {
    const { code } = useParams();
    const chartDivRef = useRef(null);
    const rootRef = useRef(null);
    const [companyData, setCompanyData] = useState([]);
    const [companyLiveDate, setCompanyLiveDate] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useCustomTranslation();
    const [companyDataLoading, setCompanyDataLoading] = useState(true);
    const [companyLiveDateLoading, setCompanyLiveDateLoading] = useState(true);
    const {language} = useLanguage();
    const [searchParams] = useSearchParams();
    const indicator = searchParams.get('indicator');
    const indicatorArr = indicator?.split(',');

    const {responseData} = useWebSocket(WEB_SOCKET_URL, STOCK_ID);
    const [data, setData] = useState(null);
    const [loadings, setLoadings] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://scrapping001-c0189c3441d6.herokuapp.com/api/argaam');
                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    setError('Unexpected response from server');
                }
                setLoadings(false);
            } catch (error) {
                setError('An error occurred while fetching the data');
                setLoadings(false);
            }
        };

        fetchData();
    }, []);
    const {
        stocks,
        loading,
        totalRows,
        perPage,
        currentPage,
        columns,
        handlePageChange,
        handlePerRowsChange,
        paginationComponentOptions

    } = useStocks();
    const [insights, setInsights] = useState(null);

    // const paginationComponentOptions = {
    //     rowsPerPageText: language === 'ar' ? 'عدد الصفوف في الصفحة' : 'Rows per page',
    // };

    const {customStyles} = useCustomTable();
    const {customLoader} = useCustomLoader();
    useEffect(() => {
        setCompanyLiveDateLoading(true);
        axios.get(`${liveDate}/${code}.SR?api_token=${apiToken}&fmt=json`)
            .then(response => {
                setCompanyLiveDate(response.data);
                setCompanyLiveDateLoading(false);
            })
            .catch(error => {
                console.error('Error fetching live data:', error);
                setCompanyLiveDateLoading(false);
            });
    }, [code]);

    useEffect(() => {
        const fetchInsights = async () => {
            try {
                let token = cookies.token;
                const response = await axios.get(`${apiUrlServer}indicies/insights`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setInsights(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };
        fetchInsights();
    }, []);

    // const handleWebSocketMessage = useCallback((event) => {
    //     try {
    //         const data = JSON.parse(event.data);
    //         console.log('Received message:', data);
    //
    //         if (data.type === 'pong' && data.status === 'subscribed') {
    //             console.log(`Subscription status: ${data.status}`);
    //         }
    //
    //         if (data?.data?.startStream && data.data.startStream.length > 0) {
    //             const lastIndex = data.data.startStream.length - 1;
    //             const latestStockData = data.data.startStream[lastIndex];
    //             console.log('Received latest stock data:', latestStockData);
    //
    //             // Update state with the latest stock data
    //             setResponseData(prevData => ({
    //                 ...prevData,
    //                 ...latestStockData
    //             }));
    //         }
    //     } catch (error) {
    //         console.error('Error parsing WebSocket message:', error);
    //     }
    // }, []);
    // useEffect(() => {
    //     socketRef.current = new WebSocket('wss://pms-develop.nuwas.xyz');
    //
    //     socketRef.current.onopen = () => {
    //         console.log('WebSocket is connected');
    //         socketRef.current.send(JSON.stringify({ type: 'subscribe', stockId: "1188203_503" }));
    //     };
    //
    //     socketRef.current.onmessage = handleWebSocketMessage;
    //
    //     socketRef.current.onerror = (error) => {
    //         console.error('WebSocket error:', error);
    //     };
    //
    //     socketRef.current.onclose = () => {
    //         console.log('WebSocket is closed');
    //     };
    //
    //     // Ping interval to keep the connection alive
    //     const pingInterval = setInterval(() => {
    //         if (socketRef.current.readyState === WebSocket.OPEN) {
    //             socketRef.current.send(JSON.stringify({ type: 'ping' }));
    //         }
    //     }, 30000); // Send a ping every 30 seconds
    //
    //     return () => {
    //         clearInterval(pingInterval);
    //         if (socketRef.current) {
    //             socketRef.current.close();
    //         }
    //     };
    // }, [handleWebSocketMessage]);
    useEffect(() => {
        setCompanyDataLoading(true);
        axios.get(`${apiUrlServer}${getStockDetailsFundamentals}`,{
            params:{
                code:`${code}.SR`
            }
        })
            .then(response => {
                setCompanyDataLoading(false);
            })
            .catch(error => {
                console.error('Error fetching company data:', error);
                setCompanyDataLoading(false);
            });
    }, [code]);



    const chartContainerRef = useRef(null);
    const [cookies] = useCookies(['token']);
    const buttonContainerRef = useRef(null);

    const getCurrentLanguage = () => {
        return sessionStorage.getItem('language') || 'en';
    };


    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => {
                    // console.log(`Script loaded: ${src}`);
                    resolve();
                };
                script.onerror = (error) => {
                    console.error(`Error loading script: ${src}`, error);
                    reject(error);
                };
                document.body.appendChild(script);
            });
        };

        var codeSympole = "%5ETASI";
        const loadChartingLibrary = async () => {
            try {
                await loadScript('/charting_library/charting_library.standalone.js');
                await loadScript('/datafeeds/udf/dist/bundle.js');

                if (window.TradingView && chartContainerRef.current) {
                    const language = getCurrentLanguage();
                    const widget = new window.TradingView.widget({
                        container: chartContainerRef.current,
                        locale: language === 'ar' ? 'ar' : 'en',
                        library_path: '/charting_library/',
                        datafeed: Datafeed,
                        symbol: `${codeSympole}`,
                        ticker: `${codeSympole}`,
                        interval: 'D',
                        fullscreen: true,
                    });

                    const addIndicator = (indicatorName, indicatorOptions) => {
                        widget.activeChart().createStudy(indicatorName, false, false, indicatorOptions);
                    };

                    widget.onChartReady(() => {
                        widget.chart().createStudy("Volume", true, false, undefined,  {
                            // Does not include the indicator's name
                            "volume.transparency": 50,

                        })
                        // addIndicator('MACD', { in_0: 14, in_1: 30, in_3: 'close', in_2: 9 });
                        indicatorArr?.forEach((indicator) => {
                            addIndicator(indicator);
                        });
                        const button = widget.createButton();
                        button.innerHTML = `${t('Full Width')}`;
                        button.classList.add('maximize-button');
                        button.addEventListener('click', () => {
                            const newTabUrl = `/indicesmax/%5ETASI`;
                            window.open(newTabUrl, '_blank');
                        });
                        button.style.cursor = 'pointer';
                        buttonContainerRef.current.appendChild(button);
                    });
                } else {
                    console.error('TradingView or chart container not available');
                }
            } catch (error) {
                console.error('Error loading TradingView scripts', error);
            }
        };
        const setupChart = async () => {
            await loadChartingLibrary();
        };

        setupChart();

        // loadChartingLibrary();
    }, [ code, language]);
    const { hasPermission } = usePermissions();

    // Dummy data for the preview table
    const dummyData = [
        {
            id: 1,
            code: '1010',
            name: 'Riyad Bank',
            socketApi: {
                last: { value: '***.**' },
                close: { value: '***.**' },
                open: { value: '***.**' },
                high: { value: '***.**' },
                low: { value: '***.**' },
                volumeTotal: { value: '*******' },
                turnoverTotal: { value: '*********' },
                numberOfTrades: { value: '******' },
                vwap: { value: '***.**' },
                bestBid: { value: '***.**' },
                bestAsk: { value: '***.**' }
            },
            api: {
                last: '***.**',
                close: '***.**',
                change: '*.**',
                change_p: '*.**',
                open: '***.**',
                high: '***.**',
                low: '***.**',
                volumeTotal: '*******',
                turnoverTotal: '*********',
                numberOfTrades: '******',
                vwap: '***.**',
                bestBid: '***.**',
                bestAsk: '***.**',
                timestamp: new Date().getTime()
            }
        },
        {
            id: 2,
            code: '1020',
            name: 'Bank AlJazira',
            socketApi: {
                last: { value: '****.**' },
                close: { value: '****.**' },
                open: { value: '****.**' },
                high: { value: '****.**' },
                low: { value: '****.**' },
                volumeTotal: { value: '*******' },
                turnoverTotal: { value: '*********' },
                numberOfTrades: { value: '******' },
                vwap: { value: '****.**' },
                bestBid: { value: '****.**' },
                bestAsk: { value: '****.**' }
            },
            api: {
                last: '****.**',
                close: '****.**',
                change: '*.**',
                change_p: '*.**',
                open: '****.**',
                high: '****.**',
                low: '****.**',
                volumeTotal: '*******',
                turnoverTotal: '*********',
                numberOfTrades: '******',
                vwap: '****.**',
                bestBid: '****.**',
                bestAsk: '****.**',
                timestamp: new Date().getTime()
            }
        },
        {
            id: 3,
            code: '1030',
            name: 'Saudi Investment Bank',
            socketApi: {
                last: { value: '***.**' },
                close: { value: '***.**' },
                open: { value: '***.**' },
                high: { value: '***.**' },
                low: { value: '***.**' },
                volumeTotal: { value: '*******' },
                turnoverTotal: { value: '*********' },
                numberOfTrades: { value: '******' },
                vwap: { value: '***.**' },
                bestBid: { value: '***.**' },
                bestAsk: { value: '***.**' }
            },
            api: {
                last: '***.**',
                close: '***.**',
                change: '-*.**',
                change_p: '-*.**',
                open: '***.**',
                high: '***.**',
                low: '***.**',
                volumeTotal: '*******',
                turnoverTotal: '*********',
                numberOfTrades: '******',
                vwap: '***.**',
                bestBid: '***.**',
                bestAsk: '***.**',
                timestamp: new Date().getTime()
            }
        }
    ];

    const StocksTable = ({ isDummy = false }) => (
        <DataTable
            columns={columns}
            data={isDummy ? dummyData : stocks}
            pagination
            paginationServer={!isDummy}
            paginationTotalRows={isDummy ? dummyData.length : totalRows}
            onChangeRowsPerPage={isDummy ? undefined : handlePerRowsChange}
            onChangePage={isDummy ? undefined : handlePageChange}
            highlightOnHover={true}
            striped={true}
            responsive
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            customStyles={customStyles}
            progressPending={loading && !isDummy}
            progressComponent={customLoader()}
            noDataComponent={<div style={{ padding: '200px', display: "none" }}>No records to display</div>}
            paginationComponentOptions={paginationComponentOptions}
        />
    );
    return (
        <>
            <Helmet>
                <title>{t('Indices')}</title>
            </Helmet>
            <div className="container-fluid">
                <DeatialsBanner name={t('TASI')}/>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="details-info">
                            <BannerIndicesDetails
                                Code={"TASI"}
                                timestamp={companyLiveDate?.timestamp}
                                open={companyLiveDate?.open}
                                close={responseData?.last?.value ?? companyLiveDate?.close}
                                previousClose={companyLiveDate?.previousClose}
                                change_p={(() => {
                                    const close = responseData?.last?.value ?? companyLiveDate?.close;
                                    const lastClose = companyLiveDate?.previousClose;
                                    const change = close !== '-' && lastClose !== '-' ? lastClose - close : '-';
                                    const changePercent = change !== '-' && lastClose !== '-' && lastClose !== 0
                                        ? ((change / lastClose) * 100)
                                        : '-';
                                    return changePercent !== '-' ? changePercent.toFixed(2) : '-';
                                })()}
                                change={(() => {
                                    const close = responseData?.last?.value ?? companyLiveDate?.close;
                                    const lastClose = companyLiveDate?.previousClose;
                                    const change = close !== '-' && lastClose !== '-' ? lastClose - close : '-';
                                    return change !== '-' ? change.toFixed(2) : '-';
                                })()}
                                volumeTotal={responseData?.volume?.value}
                                turnoverTotal={responseData?.turnover?.value}
                                marketCapitalization={data?.marketCapitalization}
                                numberOfTransactions={data?.numberOfTransactions}
                            />
                        </div>
                        <div className="chart-details chart-indices-details "
                             style={{height: "auto", width: "100%", backgroundColor: "#f0f0f0"}}>
                            <>
                                <div id="chartContainer" ref={chartContainerRef}
                                     style={{width: '100%', height: '596px'}}></div>
                                <div className="text-muted"
                                     style={{textAlign: 'left', marginTop: '10px', fontSize: '12px',}}>
                                    {t("Powered by TradingView")}<a
                                    href="https://www.tradingview.com/economic-calendar/"
                                    className="text-muted"> {t("how to use a stock screener")}</a>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <div className="my-3">
                        <BannerStocks
                            name={t('Stocks List')}
                            actionComponent={!loading && hasPermission('stocks') && (
                                <button className="btn button-show-all">
                                    <Link to={'/stocksall'}>
                                        {t('Show All Stocks')}
                                    </Link>
                                </button>
                            )}
                        />
                    </div>

                    <ProtectedComponent
                        permission="stocks"
                        buttonText={t('Upgrade to Access Full Stocks List')}
                        // textHeadLine={t('Preview: Stocks List')}
                        // headlineStyle={{ color: '#fff', paddingBottom: '5px' }}

                    >
                        <StocksTable/>
                    </ProtectedComponent>
                    {!hasPermission('stocks') && <StocksTable isDummy={true}/>}
                </div>

            </div>
        </>
    );
};

export default IndicesDetails;
