import React from 'react';
import axios from "axios";
import {addSimulationsWallet, apiUrlServer, getSimulationsWallet} from "../../apiConfig";
import Swal from "sweetalert2";
import iconError from "../../assets/icons/errorCancel.png";
import {useCookies} from "react-cookie";
import useCustomTranslation from '../../Hooks/useTranslation';

const AddWallet = () => {

    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);

    const handelAddWallet = async () => {

        try {
            const token = cookies.token;

            const response = await axios.post(`${apiUrlServer}${addSimulationsWallet}`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            // console.log(response.data);

            // Show success alert using SweetAlert
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Wallet created successfully!',
                customClass: {
                    confirmButton: 'swal-btn-confirmButton',
                    cancelButton: 'swal-btn-cancelButton',
                },
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                // Handle the specific case when the status code is 422
                const errorMessage = error.response.data.message;
                const walletCountError = error.response.data.errors.wallets_count[0];
                console.error('Error:', errorMessage);
                console.error('Wallet Count Error:', walletCountError);

                // Show error alert using SweetAlert for the specific case
                Swal.fire({
                    title: 'Error',
                    text: errorMessage,
                    iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'swal-btn-confirmButton',
                        cancelButton: 'swal-btn-cancelButton',
                    },
                });
            } else {
                console.error('Error:', error);

                // Show generic error alert using SweetAlert for other cases
                Swal.fire({
                    title: 'Error',
                    text: error,
                    iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'swal-btn-confirmButton',
                        cancelButton: 'swal-btn-cancelButton',
                    },
                });
            }
        }
    };

    return (
        <>
            <button
                className='btn btn-all w-25'
                onClick={handelAddWallet}
            >
                {t('Add Wallet')}
            </button>
        </>
    );
};

export default AddWallet;