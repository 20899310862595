import React, {useEffect, useState} from 'react'
import CommonBanner from '../CommonBanner/CommonBanner'
import SimulationAdd from './SimulationAdd'
import SimulationSummary from './SimulationSummary'
import MarketInformation from "./MarketInformation";
import SimulationTabs from "./SimulationTabs";
import OutStandingOrders from "./OutStandingOrders";
import Wallet from "./Wallet";
import SimulationBuy from "./SimulationBuy";
import {useCookies} from "react-cookie";
import axios from "axios";
import {apiUrlServer, getSimulationsWallet} from "../../apiConfig";
import SimulationSell from "./SimulationSell";
import MyPositions from "./MyPositions";
import AddWallet from "./AddWallet";
import useCustomTranslation from '../../Hooks/useTranslation';

const Simulation = () => {
    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);
    const [wallets, setWallets] = useState([]);
    const [shouldUpdateOrders, setShouldUpdateOrders] = useState(false);

    useEffect(() => {
        fetchWalletData();
    }, []);

    const fetchWalletData = async () => {
        try {
            const token = cookies.token;
            const response = await axios.get(`${apiUrlServer}${getSimulationsWallet}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            const result = response.data;

            if (result.status) {
                setWallets(result.data);
            } else {
                console.error('Error retrieving wallets:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
  return (
    <>
            <CommonBanner title={t('Simulation')}/>
            <MarketInformation/>
            <div className="market-card">
                <div className="d-flex justify-content-between align-items-center mb-16">
                    <h2 className="head-card-market m-0">{t('My Wallet')}</h2>
                    <AddWallet/>
                </div>
                    <Wallet  wallets={wallets}/>
            </div>
            <div className='market-card mb-0'>
                <div className='d-flex justify-content-between align-items-center mb-16'>
                    <h2 className="head-card-market m-0">{t('Order Entry')}</h2>
                    <div className="d-flex justify-content-end align-items-center gap-3 w-50">
                        <SimulationBuy
                            wallets={wallets}
                            fetchWalletData={fetchWalletData}
                            setShouldUpdateOrders={setShouldUpdateOrders}
                        />
                        <SimulationSell
                            wallets={wallets}
                            fetchWalletData={fetchWalletData}
                            setShouldUpdateOrders={setShouldUpdateOrders}/>
                    </div>
                </div>

                <SimulationTabs/>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <OutStandingOrders
                            shouldUpdateOrders={shouldUpdateOrders}
                            setShouldUpdateOrders={setShouldUpdateOrders}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <MyPositions/>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Simulation