import React, { useEffect, useState,useMemo } from 'react';
import DataTable from 'react-data-table-component';
import EditGroupStocksPortfolio from './EditGroupStocksPortfolio'
import DeleteStocksPortfolio from './DeleteStocksPortfolio'
import styles from '../TimerBanner/TimerBannerStocks.module.css'
import { ReactComponent as CalendarSvg } from "../../../../../assets/icons/calendar.svg";
import { ReactComponent as SeparatorSvg } from "../../../../../assets/icons/separator.svg";
import useCustomTable from "../../../../../Hooks/useCustomTable";
import {BulletList} from "react-content-loader";
import {abbreviateNumber} from "../../../../../Hooks/useNumberFormatting";
import useWebSocketBulk from '../../../../../Hooks/useWebSocketBulk';
import { WEB_SOCKET_URL } from '../../../../../apiConfig';
import { Link } from 'react-router-dom';

let STOCK_IDs = [];

const StocksPortfolio = ({ data:stocksPortfolios, isLoading, error, fetchData, mergedData, setMergedData }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [isOverallActive, setIsOverallActive] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const {customStyles} = useCustomTable();
  const {setStockIdCode,responseData} = useWebSocketBulk(WEB_SOCKET_URL, STOCK_IDs);
  // const [mergedData, setMergedData] = useState([]);
  const [accumulatedResponseData, setAccumulatedResponseData] = useState({});

  useEffect(() => {
    if (stocksPortfolios) {
      STOCK_IDs = stocksPortfolios.map(stock => stock.stock.inis_bc);
      setStockIdCode(STOCK_IDs);
    }
  }, [stocksPortfolios]);

    useEffect(() => {
        if (responseData) {
            setAccumulatedResponseData(prev => ({
                ...prev,
                ...responseData
            }));
        }
    }, [responseData]);

    useEffect(() => {
        if (stocksPortfolios && accumulatedResponseData) {
            const updatedData = stocksPortfolios.map(stock => {
                const stockResponseData = accumulatedResponseData[stock.stock.inis_bc];
                if (stockResponseData) {
                    return {
                        ...stock,
                        socketApi: stockResponseData,
                    };
                }
                return stock;
            });
            console.log(updatedData,"updatedData");
            setMergedData(updatedData);
        }
    }, [stocksPortfolios, accumulatedResponseData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchData('stocks', { page: newPage, overall: isOverallActive });
  };

  const handleToggleOverall = () => {
    setIsOverallActive(!isOverallActive);
    fetchData('stocks', { page: 1, overall: !isOverallActive });
  };


  const columns = [
    {
      name: 'Code',
      selector: row => row.stock.code,
      sortable: true,
      cell: (params) => (
        <Link style={{
            textDecoration: 'none',
            color: '#451952'
        }}
              to={`/stocks/${params.stock.id}/${params.stock.code}`}>
            {params.stock.code}
        </Link>
      ),
    },
    {
      name: 'Name',
      selector: row => row.stock.name,
      sortable: true,
      cell: (params) => (
        <Link style={{
            textDecoration: 'none',
            color: '#451952'
        }}
              to={`/stocks/${params.stock.id}/${params.stock.code}`}>
            {params.stock.name}
        </Link>
      ),
      width: '200px',
    },
    {
      name: 'Price',
      selector: row => abbreviateNumber(row.rate),
      sortable: true,
    },
    {
      name: 'Value',
      selector: row => abbreviateNumber( row.value),
      sortable: true,
    },
    {
      name: 'Change',
      selector: row => abbreviateNumber(row.change_api),
      sortable: true,
      width: '150px',
    },
    {
      name: 'Change(%)',
      width: '150px',
      selector: row => abbreviateNumber(row.change_p_api),
      sortable: true,
    },
    {
      name: 'Last Price',
      width: '120px',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.last?.value) : '-',
      sortable: true,
    },
    {
      name: 'Open',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.open?.value) : '-',
      sortable: true,
    },
    {
      name: 'Close',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.close?.value) : '-',
      sortable: true,
    },
    {
      name: 'High',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.high?.value) : '-',
      sortable: true,
    },
    {
      name: 'Low',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.low?.value) : '-',
      sortable: true,
    },
    {
      name: 'Volume Total',
      width: '180px',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.volumeTotal?.value) : '-',
      sortable: true,
    },
    {
      name: 'Number of Trades',
      width: '180px',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.numberOfTrades?.value) : '-',
      sortable: true,
    },
    {
      name: 'Turnover Total',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.turnoverTotal?.value) : '-',
      width: '180px',
      sortable: true,
    },
    {
      name: 'VWAP',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.vwap?.value) : '-',
      sortable: true,
    },
    {
      name: 'Best Bid',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.bestBid?.value) : '-',
      sortable: true,
      width: '150px',
    },
    {
      name: 'Best Ask',
      selector: row => row.socketApi ? abbreviateNumber(row.socketApi?.bestAsk?.value) : '-',
      width: '150px',
      sortable: true,
    },
    {
      name: 'Actions',
      selector: row => row.actions,
      sortable: false,
      width: '100px',
      cell: row => (
          <div className="d-flex align-items-center justify-content-center">
            {/* <EditGroupStocksPortfolio groupId={row.id} /> */}
            <DeleteStocksPortfolio portfolioId={row.id} />
          </div>
      ),
    },
  ];



  const paginationOptions = {
    totalPages: totalPages,
    currentPage: currentPage ,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    showTotal: true,
    total: totalPages ,
    firstBtnText: 'First',
    lastBtnText: 'Last',
    prevBtnText: 'Previous',
    nextBtnText: 'Next',
    showTotalPages: false,
  };
  // Additional CSS to hide the Last button
  const hideLastButtonStyle = `
    #pagination-last-page {
      display: none;
    }
    .iNfAEA{
    display: none;
  `;

  if (isLoading || !stocksPortfolios) {
    return <BulletList />;
  }


  return (
    <>
      <style>{hideLastButtonStyle}</style>

        <>
          <div className="mb-24">
            <div className={styles.frame}>
              <div className={styles.date}>
                <div>
                  <CalendarSvg />
                </div>
                <div className={styles["text-wrapper"]}>Wed, 23 Aug 2023</div>
                <SeparatorSvg />
                <div className={styles["text-wrapper"]}>13:26</div>
              </div>
              <div className={styles['btn-container']}>
                <button  onClick={() => setIsOverallActive(false)} className={`${styles.btn} ${styles['first-btn']}`}>1 Day</button>
                <button   onClick={handleToggleOverall} className={`${styles.btn} ${styles['second-btn']} ${styles['active-btn']}`}>Over All</button>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            pagination
            customStyles={customStyles}
            data={mergedData}
            paginationPerPage={currentPage }
            paginationServer
            paginationTotalRows={totalPages }
            paginationDefaultPage={currentPage }
            onChangePage={handlePageChange}
            paginationServerOptions={paginationOptions}
            highlightOnHover={true}
            striped={true}
            responsive

          />

          {isLoading && <p><BulletList/></p>}
        </>
     
    </>
  );
};

export default StocksPortfolio;
