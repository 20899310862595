import React, { useState } from 'react'
import axios from 'axios'
import  {apiUrlServer} from '../../../apiConfig'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import style from '../Auth.module.css'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import defaultLogo from "../../../assets/images/logo_white-main.webp";
import nationalDayLogo from '../../../assets/images/K-Wealth-Saudi-national-Day Identity_Main-Logo.png';
import { Helmet } from 'react-helmet'
import {useTheme} from "../../../Context/ThemeContext";

const SendOtp = ({phoneNumber}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const navigate = useNavigate();
  const { theme } = useTheme();

  async function handleSendOtp(values) {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`${apiUrlServer}customer/sendOtp`, values);

      if (data.message === "Data updated successfully") {
        sessionStorage.setItem('phone', values.phone);

        toast.success(`Your OTP: ${data.data.otp_code}`, {
          position:"top-right",
          duration: 3000,
          className: "text-success px-5 fw-bolder my-3"
        });
        setMessageError("");
        setIsLoading(false);

        // Navigate to '/otp' on successful OTP send
        navigate('/otp');
        // console.log(data)
      } else {
        // Handle other cases if needed
        toast.error(data.message, {
          position: 'top-right',
          duration: 3000,
          className: "text-danger px-5 fw-bolder my-3"
        });
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setMessageError(err.response.data.message);
      toast.error(err.response.data.message, {
        position:"top-right",
        duration: 3000,
        className: "text-danger px-5 fw-bolder my-3"
      });
    }
  }
  let validationSchema = Yup.object({
    phone: Yup.string()
      .matches(
        /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
        'Invalid ksa phone number'
      )
      .required('Phone number is required'),

  })

  const formik = useFormik({
    initialValues:{
      phone: phoneNumber,
    },
    validationSchema,
    onSubmit: handleSendOtp,
  })
  return (
    <>
      <Helmet>
        <title>Send OTP</title>
      </Helmet>
      <div className="py-5 h-screen">
        <div className="container">
          <div className="row justify-content-center align-items-center min-h-screen-authForm">
            <div className={`col-xxl-4 col-lg-5 col-md-6 col-10 bg-white p-4 ${style.formContainer}`}>
              <div className="text-center my-16">
                                            <img
                                src={theme === 'default' ? defaultLogo : nationalDayLogo}
                                className=""
                                style={{width:"124px"}}
                                alt="k wealth logo"
                            />

              </div>
              <h2 className={`${style['head-form']}`}>Send OTP</h2>

              <form onSubmit={formik.handleSubmit}>

                <div className="mb-3">
                  <input
                    type="text"
                    className={`${style['input-custom']} form-control`}
                    placeholder="Enter Phone number"
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="mb-3">
                  {formik.errors.phone && formik.touched.phone && (
                    <small className={`${style['text-error']}`}>{formik.errors.phone}</small>
                  )}
                </div>

                <div>
                  {isLoading ? (
                    <button type="button" className="btn btn-outline-danger opacity-75 fw-bolder px-4">
                      <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                    </button>
                  ) : (
                    <button disabled={!formik.isValid} type="submit" className={`btn ${style['btn-signup']}  w-full`}>
                     Send
                    </button>
                  )
                  }
                </div>



              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default SendOtp