import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    useMediaQuery,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useTheme as useMuiTheme } from '@mui/material/styles'; // Rename this import
import useCustomTranslation from "../../../Hooks/useTranslation";
import { abbreviateNumber } from "../../../Hooks/useNumberFormatting";
import { useTheme } from "../../../Context/ThemeContext"; // Your custom useThem

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FinancialVisualization = () => {
    const { statementType, id, code } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('quarterly');
    const [selectedYears, setSelectedYears] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { t } = useCustomTranslation();
    const muiTheme = useMuiTheme(); // Use this for Material-UI theme properties
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
    const { theme } = useTheme(); // Your custom theme hook
    const buttonColor = theme === 'nationalDay' ? 'success' : 'secondary';

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const storedData = sessionStorage.getItem(`${statementType}Data`);
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                setData(parsedData);
            } else {
                throw new Error('No data found in storage');
            }
        } catch (error) {
            console.error(`Error fetching data for ${statementType}:`, error);
            setError(error.message || 'An error occurred while fetching data');
        } finally {
            setLoading(false);
        }
    }, [statementType]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleTabChange = useCallback((tab) => {
        setActiveTab(tab);
        setSelectedYears([]);
    }, []);

    const financialData = useMemo(() => {
        if (statementType === 'earnings_history') {
            return data || {};
        }
        return data?.[`financials_${statementType}_${activeTab}`] || {};
    }, [data, statementType, activeTab]);

    const rows = useMemo(() => {
        if (!financialData || typeof financialData !== 'object') {
            console.log('Invalid financialData:', financialData);
            return [];
        }
        return Object.entries(financialData).map(([date, values], index) => ({
            id: index,
            ...(typeof values === 'object' ? values : {}),
            date,
        }));
    }, [financialData]);

    const uniqueYears = useMemo(() =>
            [...new Set(rows.map((row) => row.date?.substring(0, 4) || ''))],
        [rows]);

    const handleYearChange = useCallback((event) => {
        setSelectedYears(event.target.value);
    }, []);

    const handleSelectAllYears = useCallback(() => {
        if (selectedYears.length === uniqueYears.length) {
            setSelectedYears([]);
        } else {
            setSelectedYears([...uniqueYears]);
        }
    }, [selectedYears, uniqueYears]);

    const filteredRows = useMemo(() =>
            selectedYears.length
                ? rows.filter((row) => selectedYears.includes(row.date?.substring(0, 4)))
                : rows,
        [rows, selectedYears]);

    const formatHeader = useCallback((header) =>
            header.replace(/([A-Z])/g, ' $1').toLowerCase(),
        []);

    const columns = useMemo(() => {
        if (!filteredRows.length) return [{ field: 'field', headerName: t('Field'), width: 300 }];
        return [
            { field: 'field', headerName: t('Field'), width: 300 },
            ...filteredRows.map((row, index) => ({
                field: `value${index}`,
                headerName: formatHeader(row.date || ''),
                width: 200,
                renderCell: (params) => params.row[`displayValue${index}`],
            })),
        ];
    }, [filteredRows, t, formatHeader]);

    const transposedRows = useMemo(() => {
        if (!filteredRows.length) return [];
        const firstRow = filteredRows[0] || {};
        return Object.keys(firstRow).map((key) => {
            if (key === 'id' || key === 'date') return null;
            return {
                id: key,
                field: formatHeader(key),
                ...filteredRows.reduce((acc, row, index) => {
                    const originalValue = row[key];
                    acc[`value${index}`] = originalValue;
                    acc[`displayValue${index}`] =
                        typeof originalValue === 'number' ? abbreviateNumber(originalValue) : abbreviateNumber(originalValue);
                    return acc;
                }, {}),
            };
        }).filter(Boolean);
    }, [filteredRows, formatHeader]);

    const handleRowClicked = useCallback((params) => {
        const row = params.row;
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.some((selected) => selected.id === row.id)
                ? prevSelectedRows.filter((selected) => selected.id !== row.id)
                : [...prevSelectedRows, row]
        );
    }, []);

    const chartOptions = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const value = abbreviateNumber(context.raw);
                        return `${context.dataset.label}: ${value}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return value;
                    },
                },
            },
        },
    }), []);

    const generateRandomColor = useCallback(() => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r},${g},${b},0.4)`;
    }, []);

    const generateRandomColors = useCallback((count) =>
            Array.from({ length: count }, generateRandomColor),
        [generateRandomColor]);

    const chartData = useMemo(() => {
        if (selectedRows.length === 0 || filteredRows.length === 0) return null;

        const labels = filteredRows.map(row => row.date);
        const randomColors = generateRandomColors(selectedRows.length);
        const datasets = selectedRows.map((row, index) => ({
            label: row.field,
            data: filteredRows.map((_, i) => {
                const value = row[`value${i}`];
                return typeof value === 'number' ? value : parseFloat(value) || 0;
            }),
            backgroundColor: randomColors[index],
        }));

        return { labels, datasets };
    }, [selectedRows, filteredRows, generateRandomColors]);

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
    }

    if (error) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Typography variant="body1" color="error">{error}</Typography></Box>;
    }

    if (!data || Object.keys(data).length === 0) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Typography>No data available</Typography></Box>;
    }
    const stickyHeaderStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 3,
        backgroundColor: 'var(--secondary-color-20)',
        color: 'var(--natural-color)',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        fontWeight: 'bold',
        fontSize: '12px',
    };

    const stickyColumnStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: 'var(--secondary-color-20)',
        color: 'var(--natural-color)',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        fontSize: '12px',
    };

    return (
        <>
            <Box sx={{ maxWidth: 'auto', margin: '0 auto', p: 1 }}>
                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                    <Typography className="main-color" variant="h4"  gutterBottom>
                        {t(`${statementType.replace('_', ' ')} Visualization`)} for {code}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        {statementType !== 'earnings_history' && (
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant={activeTab === 'quarterly' ? 'contained' : 'outlined'}
                                    color={buttonColor}
                                    onClick={() => handleTabChange('quarterly')}
                                    fullWidth
                                >
                                    {t('Quarterly')}
                                </Button>
                            </Grid>
                        )}
                        {statementType !== 'earnings_history' && (
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant={activeTab === 'yearly' ? 'contained' : 'outlined'}
                                    color={buttonColor}
                                    onClick={() => handleTabChange('yearly')}
                                    fullWidth
                                >
                                    {t('Yearly')}
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                <InputLabel id="year-select-label">Years</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    id="year-select"
                                    multiple
                                    value={selectedYears}
                                    onChange={handleYearChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    label="Years"
                                >
                                    {uniqueYears.map(year => (
                                        <MenuItem key={year} value={year}>
                                            <Checkbox checked={selectedYears.indexOf(year) > -1} />
                                            <ListItemText primary={year} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                variant="contained"
                                color={buttonColor}
                                onClick={handleSelectAllYears}
                                fullWidth
                            >
                                {selectedYears.length === uniqueYears.length ? t('Unselect All') : t('Select All')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper  elevation={3} sx={{ mb: 3, overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: isMobile ? 400 : 600, overflow: 'auto'}}>
                        <Table  stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={stickyHeaderStyle}>
                                        {t('Field')}
                                    </TableCell>
                                    {columns.slice(1).map((column, index) => (
                                        <TableCell key={index} sx={{
                                            backgroundColor: 'var(--natural-color)',
                                            color: 'var(--secondary-color-1)',
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                            padding:0,
                                        }}>
                                            {column.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transposedRows.map((row, rowIndex) => (
                                    <TableRow
                                        key={row.id}
                                        onClick={() => handleRowClicked({ row })}
                                        sx={{
                                            cursor: 'pointer',
                                            backgroundColor: selectedRows.some(selected => selected.id === row.id)
                                                ? muiTheme.palette.action.selected
                                                : 'inherit',
                                            '&:hover': {
                                                backgroundColor: muiTheme.palette.action.hover,
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row" sx={stickyColumnStyle}>
                                            {row.field}
                                        </TableCell>
                                        {columns.slice(1).map((column, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                {row[`displayValue${cellIndex}`]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {chartData && (
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography className="main-color" variant="h5" gutterBottom>Selected Data Visualization</Typography>
                        <Box height={isMobile ? 300 : 500}>
                            <Bar data={chartData} options={chartOptions} />
                        </Box>
                    </Paper>
                )}
            </Box>
        </>
    );
};

export default FinancialVisualization;
