import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {apiUrlServer, getAllStocks} from "../apiConfig";

const useSearchTobBar = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [stocks, setStocks] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const searchRef = useRef(null);  // Reference to the search component
    const [isActive, setIsActive] = useState(false);
    const inputSearchRef = useRef(null);
    let navigate =  useNavigate()
    const toggleSearch = () => {
        setIsActive(!isActive);
        setSearchQuery('');
        setSearchResults([]);
        setIsInputFocused(false);
    };
    const fetchStocks = async () => {
        try {
            const response = await axios.get(`${apiUrlServer}${getAllStocks}`,{
                params:{
                    limit:"300"
                }
            });
            // console.log('Stocks data:', response.data); // Add this line
            if (response.data.status) {
                setStocks(response.data.data);
            } else {
                console.error('Failed to fetch stocks:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching stocks:', error);
        }
    };
    useEffect(() => {
        // fetchStocks();
    }, []);

    useEffect(() => {
    }, [stocks]);
    const handleSearch = (e) => {
        const query = e.target.value;
        // console.log('Search query:', query); // Add this line

        // Check if the query has at least three characters
        if (query.length >= 3) {
            const filteredResults = stocks.filter((stock) => {
                const hasMatchingName =
                    typeof stock.name === 'string' && stock.name.toLowerCase().includes(query.toLowerCase());
                const hasMatchingCode =
                    typeof stock.code === 'string' &&
                    stock.code.toString().toLowerCase().includes(query.toLowerCase());

                return hasMatchingName || hasMatchingCode;
            });
            // console.log('Filtered results:', filteredResults); // Add this line

            setSearchQuery(query);
            setSearchResults(filteredResults);
        } else {
            // Clear results if the query is less than three characters
            setSearchQuery(query);
            setSearchResults([]);
        }
    };

    const handleInputBlur = () => {
        // Hide search results and error message when input loses focus
        // setSearchResults([]);
        setIsInputFocused(false);
        // Clear the error message
        setSearchQuery('');
    };
    useEffect(() => {
        function handleClickOutside(event) {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchResults([]);  // Close the search results
                setIsActive(false);    // Optionally, deactivate the search box
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchRef]);

    return { toggleSearch, fetchStocks, handleSearch, handleInputBlur, searchResults, searchQuery, isInputFocused, navigate, isActive, setSearchQuery, setIsInputFocused, setSearchResults, searchRef,inputSearchRef};

}
export default useSearchTobBar;