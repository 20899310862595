import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {apiUrlServer, getSimulationsWallet} from '../../apiConfig';
import useCustomTranslation from '../../Hooks/useTranslation';

const Wallet = () => {
    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);
    const [wallets, setWallets] = useState([]);

    useEffect(() => {
        fetchWalletData();
    }, []);

    const fetchWalletData = async () => {
        try {
            const token = cookies.token;
            const response = await axios.get(`${apiUrlServer}${getSimulationsWallet}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            const result = response.data;

            if (result.status) {
                setWallets(result.data);
            } else {
                console.error('Error retrieving wallets:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className="d-flex align-items-center gap-3 my-3">
            {wallets.map((wallet, index) => (
                <div className="add_simulation w-100" style={{width: 'fit-content'}}>
                    <span className="virtual">{t('Wallet')} {index + 1} </span>
                    <div key={wallet.id} className="d-flex align-items-center justify-content-between wallet-info">
                                <span className="virtual">{t('Wallet Code')}: <span className="virtual-number">{wallet.code}</span> </span>
                        <hr/>
                                <span className="virtual">{t('Wallet Balance')}: <span
                                    className="virtual-number">{wallet.value.toLocaleString()}</span></span>
                                {/*<span>{wallet.id}</span>*/}
                    </div>
                </div>
            ))}
            </div>
        </>

    );
};

export default Wallet;