import React, { useState } from "react";
import styles from "./WatchStockTab.module.css";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import PricesTab from './WatchListStocksTabsDetails/PricesTab/PricesTab';
import News from './WatchListStocksTabsDetails/NewsTab/News';
import Alerts from './WatchListStocksTabsDetails/Alerts/Alerts';


const WatchStockTab = () => {
  const { t } = useCustomTranslation();
  const [activeTab, setActiveTab] = useState("prices");

  const tabs = [
    { id: "prices", label: "Prices", component: PricesTab },
    { id: "news", label: "News", component: News },
    // { id: "alerts", label: "Alerts", component: Alerts },
  ];

  const renderTabButton = (tab) => (
      <li key={tab.id}>
        <button
            className={activeTab === tab.id ? styles.active : undefined}
            onClick={() => setActiveTab(tab.id)}
        >
          {t(tab.label)}
        </button>
      </li>
  );

  const renderTabContent = () => {
    const activeTabData = tabs.find(tab => tab.id === activeTab);
    if (activeTabData) {
      const TabComponent = activeTabData.component;
      return <TabComponent />;
    }
    return null;
  };

  return (
      <>
        <nav className={styles.dailyTabsNav}>
          <ul className={`${styles.dailyTabsList}  ${styles.watchlistabs}`}>
            {tabs.map(renderTabButton)}
          </ul>
        </nav>

        <div className={styles.tabContent}>
          {renderTabContent()}
        </div>
      </>
  );
};

export default WatchStockTab;