import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import API_URL, {apiUrlServer, getCustomerProfile, updateCustomerProfile} from '../../apiConfig';
import defaultImage from '../../assets/images/defimage.webp';
import { TiDelete } from 'react-icons/ti'
import { useNavigate } from 'react-router-dom'
import useCustomTranslation from "../../Hooks/useTranslation";

const EditUserProfile = ({ userProfile, onEditSuccess }) => {
  const [cookies] = useCookies(['token']);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  async  function editProfileForm(values){
    try {
      const token = cookies.token;
      const formData = new FormData();
      formData.append('name', values.name);
      // Append image to form data only if a new image is selected
      if (values.image) {
        formData.append('image', values.image);
      }


      const response = await axios.post(
        `${apiUrlServer}${updateCustomerProfile}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Handle the response data after updating the profile
      onEditSuccess(response.data.data);
      // Set the active tab to "profile"
     navigate("/home");
      // console.log('Profile updated successfully:', response.data.data);
    } catch (error) {
      // Set image to null in case of an error
      formik.setFieldValue('image', null);
      setImagePreview(null);
      console.error('Error updating user profile:', error);
      // Handle errors as needed
    }
  }

  const formik = useFormik({
    initialValues: {
      name: userProfile.name,
      lang: userProfile.lang,
      image: null ,
    },
    onSubmit:editProfileForm,
  });



  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    formik.setFieldValue('image', selectedImage);

    // Show image preview
    setImagePreview(URL.createObjectURL(selectedImage));
  };
  const handleRemoveImage = () => {
    formik.setFieldValue('image', null);
    setImagePreview(null);
  };
  const {t}= useCustomTranslation();
  return (
    <div className="form-wrapper">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="head-form mb-3">
                    <h2>{t('Edit User Profile')}:</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-3">
                    <label htmlFor="name">{t('Name')}:</label>
                    <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="form-group mb-3">
                    <div className="file-input-container">
                      <input
                          type="file"
                          id="image"
                          name="image"
                          onChange={handleImageChange}
                          className="visually-hidden"
                      />
                      <label htmlFor="image" className="file-input-label">
                        {imagePreview && (
                            <>
                              <img
                                  src={imagePreview}
                                  alt="Image Preview"
                                  className="preview-image-thumbnail profile-image-rounded"
                              />
                              <button
                                  type="button"
                                  className="btn-remove-image-edit"
                                  onClick={handleRemoveImage}
                              >
                                <TiDelete/>
                              </button>
                            </>
                        )}
                        {!imagePreview && (formik.values.image || userProfile.image) && (
                            <>
                              <img
                                  src={
                                    formik.values.image
                                        ? URL.createObjectURL(formik.values.image)
                                        : userProfile.image || defaultImage
                                  }
                                  alt={userProfile.name || "Default Image"}
                                  className="current-image-thumbnail profile-image-rounded"
                              />
                              <span className="ml-8">Choose Image</span>
                            </>
                        )}
                        {!imagePreview && !formik.values.image && !userProfile.image && (
                            <>
                              <img
                                  src={defaultImage}
                                  alt="Default Image"
                                  className="default-image-thumbnail"
                              />
                              <span className="ml-8">{t('Choose Image')}</span>
                            </>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <button className="btn btn-save-profile" type="submit">
                    {t('Save All Changes')}
                  </button>
                </div>
              </div>
            </form>
    </div>
  );
};

export default EditUserProfile;
