import React, {useEffect, useState} from 'react';
import axios from "axios";
import {apiToken, liveDate} from "../../apiConfig";
import useCustomTranslation from "../../Hooks/useTranslation";
import NewsChart from '../../News/NewsChart';
import {ShimmerFeaturedGallery} from "react-shimmer-effects";
import IndciesDetails from "./IndciesDetails";
import MarketTabs from "./MarketTabs";

const MainMarket = () => {
    const [companyData, setCompanyData] = useState([]);
    const [companyLiveDateLoading, setCompanyLiveDateLoading] = useState(true);
    const [companyLiveDate, setCompanyLiveDate] = useState("");

    const { t } = useCustomTranslation();
    let parsNewsChartData

    const fetchNewsChart = async () => {
        try {
            const response = await axios.get("https://eodhd.com/api/eod/^TASI.SR?api_token=6523dd3ad3c6c2.14907660&fmt=json");
            parsNewsChartData = response.data.map(dataItem =>({
                time: dataItem.date,  // Rename 'date' to 'time'
                open: dataItem.open,
                high: dataItem.high,
                low: dataItem.low,
                close: dataItem.close
            }));

            setCompanyData(parsNewsChartData);
        
        } catch (error) {
            console.error('Error fetching news chart:', error);
        }
    };

    useEffect(() => {
        fetchNewsChart();
    }, []);



    useEffect(() => {
        setCompanyLiveDateLoading(true);
        axios.get(`${liveDate}/^TASI.SR?api_token=${apiToken}&fmt=json`)
            .then(response => {
                setCompanyLiveDate(response.data);
                setCompanyLiveDateLoading(false);
            })
            .catch(error => {
                console.error('Error fetching live data:', error);
                setCompanyLiveDateLoading(false);
            });
    }, []);



    return (
        <section className= "mt-24 mb-24">
        <div className="">
            <div className="row">
                <>
                {companyLiveDateLoading ? (
                    <ShimmerFeaturedGallery row={2} col={2} card frameHeight={600} />
                ) : (
                    <>
                        <div className="col-lg-8 col-sm-12">
                            <div className="tasi_card tasi_card-first">
                                <h3 className="tasi_summery mb-3">{t('Todays TASI Market Summary')}</h3>
                                <div className="main_market_details">
                                    <IndciesDetails timestamp={companyLiveDate?.timestamp}
                                                    open={companyLiveDate?.open}
                                                    close={companyLiveDate?.close}
                                                    previousClose={companyLiveDate?.previousClose}
                                                    change_p={companyLiveDate?.change_p}
                                                    change={companyLiveDate.change}
                                    />
                                </div>

                                <div className="">
                                    <NewsChart data={companyData}/>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="tasi_card tasi_card-first">
                                <MarketTabs/>
                            </div>

                        </div>

                    </>
                )}
                </>
            </div>

        </div>
        </section>
    );
};

export default MainMarket;