import React, { useEffect } from 'react';
import styles from '../Price.module.css';
import { FaPen } from 'react-icons/fa';
import Swal from "sweetalert2";
import axios from "axios";
import {apiUrlServer, editStockAlert} from "../../../apiConfig";
import { useCookies } from "react-cookie";
import { useFormik } from "formik";
import useCustomTranslation from "../../../Hooks/useTranslation";

const EditPrice = ({ groupId, onEditGroupSuccess, fetchData,value,stock,operator }) => {
    const [cookies] = useCookies(['token']);
    const {t} = useCustomTranslation();// Define the formik hook with initial values
    const formik = useFormik({
        initialValues: {
            stock_id: stock,
            value: value,
            operator: operator
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const token = cookies.token;
                await axios.put(
                    `${apiUrlServer}${editStockAlert}/${groupId}`,
                    {
                        stock_id: values.stock_id.id,
                        value: values.value,
                        operator: values.operator
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                onEditGroupSuccess();
                fetchData();
                await Swal.fire(   t('Success'),  t('Stock Alert updated successfully'), 'success');
            } catch (error) {
                console.error('Error updating group:', error);
                await Swal.fire(t('Error'), t('Failed to update Stock ALert. Please try again.'), 'error');
            }
        },
    });

    // A function to fetch current group data and set formik initial values
    const fetchGroupData = async () => {
        try {
            const token = cookies.token;
            const response = await axios.get(`${apiUrlServer}stock_alert/${groupId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Assuming response.data contains the current group data
            formik.resetForm({ values: {
                    stock_id: response.data.stock_id,
                    value: response.data.value
                }});
        } catch (error) {
            console.error('Error fetching group data:', error);
        }
    };

    useEffect(() => {
        fetchGroupData();
    }, [groupId, cookies.token]);

    const handleEditClick = () => {
        const uniqueKey = new Date().getTime(); // Generate a unique key for the modal

        Swal.fire({
            title: t('Edit Your Stock Alert'),
            html: `
            <div class="d-flex align-content-center align-items-center justify-content-start gap-2 position-relative">
                <div class="form-group mt-16 edit-form w-100">
                    <label for="operator" class="form-label d-flex">
                        ${t('Operator')}
                    </label>
                    <select id="operator" class="form-control">
                        <option value="=">${t('Equal')}</option>
                        <option value=">">${t('Greater Than')}</option>
                        <option value="<">${t('Less Than')}</option>
                    </select>
                </div>
                <input 
                    type="text" 
                    id="stock_id" 
                    class=" form-control" 
                    placeholder="${t('Enter Stock ID')}"
                    disabled
                    hidden
                >
                <div class="form-group mt-16 edit-form w-100 ">
                    <label for="stock_name" class="form-label d-flex">
                        ${t('Stock Name')}
                    </label>
                    <input 
                        type="text" 
                        id="stock_name" 
                        class=" form-control" 
                        placeholder="${t('Stock Name')}"
                        disabled
                    >
                </div>
                <div class="form-group mt-16 edit-form w-100">
                    <label for="value" class="form-label d-flex">
                        ${t('Value')}
                    </label>
                    <input 
                        type="text" 
                        id="value" 
                        class=" form-control" 
                        placeholder="${t('Enter Value')}"
                    >
                </div>
            </div>
        `,
            focusConfirm: false,
            didOpen: () => {
                // Set the input values when the modal is opened
                setTimeout(() => {
                    document.getElementById('stock_id').value = formik.values.stock_id.id || '';
                    document.getElementById('stock_name').value = formik.values.stock_id.name || '';
                    document.getElementById('value').value = formik.values.value || '';
                    document.getElementById('operator').value = formik.values.operator || '';
                }, 0);
            },
            preConfirm: () => {
                // Directly access the DOM to get input values
                const stockIdValue = document.getElementById('stock_id').value;
                const valueValue = document.getElementById('value').value;
                const operatorValue = document.getElementById('operator').value;
                // Check if both values are not empty
                if (!stockIdValue || !valueValue) {
                    Swal.showValidationMessage(t('Please enter both stock ID and value.'));
                    return false;
                }

                // Return the values as an object
                return {
                    stock_id: stockIdValue,
                    value: valueValue,
                    operator: operatorValue
                };
            },
            showCancelButton: true,
            confirmButtonText: t('Save Changes'),
            cancelButtonText: t('Back to Alert Center'),
            customClass: {
                confirmButton: 'swal-btn-confirmButton', // Apply your custom class to the confirm button
                cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
            },
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                // No need to set formik values here, just use the result to submit the form
                formik.setValues({
                    stock_id: result.value.stock_id,
                    value: result.value.value,
                    operator: result.value.operator
                });
                formik.handleSubmit();
            }
        });
    };
    return (
        <>
            <button className={`btn ${styles['edit-price-btn']}`} onClick={handleEditClick}>
                <div className="d-flex justify-content-center align-items-center actions-ar">
                    <div className={`${styles['icon-edit-box']}`}>
                        <FaPen className={`${styles['icon-edit']}`}/>
                    </div>
                    <span>{t('Edit')}</span>
                </div>
            </button>
        </>
    );
}

export default EditPrice;