import React, { useState } from 'react';
import styles from './watchlist.module.css';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { apiUrlServer } from '../../apiConfig';
import iconSuccess from '../../assets/icons/suceessAdd.png';
import iconError from '../../assets/icons/errorCancel.png';
import useCustomTranslation from '../../Hooks/useTranslation';

import '../../assets/style/swl-custome.css';

const AddGroup = ({ onAddGroupSuccess, fetchData }) => {
  const {t} = useCustomTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['token']);

  async function handleAddGroup(values) {
    setIsLoading(true);
    try {
      const token = cookies.token;
      const response = await axios.post(`${apiUrlServer}groups`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      // Show SweetAlert success notification
      Swal.fire({
        title: `${t("Your Watchlist Group Added successfully")}`,
        iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
        text: `${t("Start Adding Stocks to your Group")}`,
        confirmButtonText: `${t("Go to My Group")}`,
        cancelButtonText: `${t("Back to Watchlist")}`,
        customClass: {
          confirmButton: 'swal-btn-confirmButton',
          cancelButton: 'swal-btn-cancelButton',
        },
      });

      // Call the callback function to trigger a refresh in GroupTable
      onAddGroupSuccess();

      // Call the locally defined fetchData function
      fetchData();

    } catch (error) {
      console.error('Error updating watchlist:', error);

      // Log the specific error from the server
      console.error('Server error:', error.response?.data);

      // Show SweetAlert error notification or update the UI to inform the user
      const responseMessage = 'The name has already been taken.';
      Swal.fire({
        title: `${t('Something went Wrong')}`,
        text:
          responseMessage === 'The name has already been taken.'
            ? 'Please choose a different name.'
            : 'Please, Check your Internet connection and try again',
        iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
        confirmButtonText: 'Back to Watchlist',
        customClass: {
          confirmButtonText: 'swal-btn-cancelButton',
        },
      });

    } finally {
      setIsLoading(false);
    }
  }

  let validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .max(50, 'Name must be at most 50 characters'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: handleAddGroup,
  });

  return (
    <>
      <div className={`${styles['add-group']}`}>
        <h2>{t('Add A WatchList Group')}</h2>
        <form onSubmit={formik.handleSubmit} className="add-group-form mt-16">
          <div className="d-flex gap-3 ">
            <input
              name="name"
              type="text"
              placeholder={t('Group Name')}
              className={`form-control ${styles['group-input']} `}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <button
              disabled={!formik.isValid || isLoading}
              type="submit"
              className={`btn ${styles['btn-add-group']}`}
            >
              {isLoading ? `{${t('Adding Group')}...}` : t('Add Group')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddGroup;
