import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import changes from "../../../assets/icons/changes.svg";
import {apiUrlServer} from "../../../apiConfig";
import useCustomTranslation from "../../../Hooks/useTranslation";
import {useLanguage} from "../../../Context/LanguageContext";
import {useCookies} from "react-cookie";

const Reports = () => {
    const [reportCategories, setReportCategories] = useState([]);
    const { language } = useLanguage(); // Get the language from the context
    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);

    useEffect(() => {
        const fetchReportCategories = async () => {
            try {
                // Get the token from localStorage or your auth context

                const response = await axios.get(`${apiUrlServer}report_category?filter[report_category_id]=`, {
                    headers: {
                        'Accept-Language': language,
                        'Authorization': `Bearer ${cookies.token}`,
                    }
                });
                if (response.data.status) {
                    setReportCategories(response.data.data);
                } else {
                    console.error('Failed to fetch report categories:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching report categories:', error);
                // Handle unauthorized access or token expiration
                if (error.response && error.response.status === 401) {
                    // Redirect to login page or refresh token
                    console.log('Unauthorized access. Please login again.');
                    // You might want to use a router to redirect: history.push('/login');
                }
            }
        };

        fetchReportCategories();
    }, [language]); // Added language as a dependency

    return (
        <div className="row">
            <h3 className="card-ai-header">{t("Reports")}</h3>
            {reportCategories.map((category, index) => (
                <div key={category.id} className="col-md-6">
                    <Link
                        to={`/reports/${category.id}`}
                        className="d-flex align-items-center justify-content-start insights-link"
                    >
                        <div className="me-2">
                            <span className="avatar avatar-sm">
                                <img src={changes} alt="head" />
                            </span>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">{category.name}</p>
                        </div>
                    </Link>
                    {index % 2 !== 0 && <div className="w-100"></div>}
                </div>
            ))}
        </div>
    );
};

export default Reports;