import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import styles from "../SubTab.module.css";

function UpdateTab() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <nav>
            <ul className={`${styles.subTabsList}`}>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                  to="/dailyReports/updates/news"
                >
                  News
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                  to="/dailyReports/updates/announcements"
                >
                  Announcements
                </NavLink>
              </li>
            </ul>
          </nav>

          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default UpdateTab;
