import React, { useEffect, useState } from 'react'
import styles from '../../WatchStockTab.module.css';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { apiUrlServer } from '../../../../../../apiConfig'
import { useCookies } from 'react-cookie'
import { BulletList } from 'react-content-loader'
import Slider from 'react-slick'
import useCustomTranslation from '../../../../../../Hooks/useTranslation';
import { useLanguage } from '../../../../../../Context/LanguageContext';

const News = () => {
  const {t} = useCustomTranslation();
  const {id} = useParams();
  const [cookies] = useCookies(['token']);
  const [newsUpdate, setNewsUpdate] = useState({ data: [] }); // Initialize as an object with an empty data array
   const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeStockId, setActiveStockId] = useState(null);
  const {language} = useLanguage();
  const fetchDataStocks = async () => {
    try {
      const token = cookies.token;

      const response = await axios.get(`${apiUrlServer}groups/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          'Accept-Language': language,
        },
      });
      const result = response.data;
      if (result.status) {
        setStocks(result.data.stocks);
        setIsLoading(false);
      } else {
        console.error('Error fetching prices details:', result.message);
      }
    } catch (err) {
      console.error('Error fetching watchlist details:', err.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNewsUpdate = async (selectedId) => {
    try {
      const token = cookies.token;

      // console.log('Fetching news update for stock ID:', selectedId);
      const response = await axios.get(`${apiUrlServer}news?s=${selectedId}.SR`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      // console.log('News update response:', response.data);
      setNewsUpdate(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching news data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataStocks();
  }, [id, cookies.token]);

  useEffect(() => {
    if (stocks.length > 0) {
      const firstStocksCode = stocks[0].code;
      fetchNewsUpdate(firstStocksCode);
      setActiveStockId(stocks[0].id); // Set the first stock as active
    }
  }, [stocks]); // Add stocks to the dependency array
  function truncateContent(content, maxWords) {
    const words = content.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return content;
  }
  const settings = {
    dots: false, // shows dot indicators at the bottom of the slider
    infinite: false, // enables infinite looping
    speed: 1500, // animation speed
    slidesToShow: 3, // number of slides to show at once
    slidesToScroll: 1, // number of slides to scroll at once
    // Add other settings as needed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <>
    {isLoading && <BulletList  />}
    {!isLoading &&
      <>

        <div className={`${styles.customSlickslider}`}>
          <Slider {...settings}>
            {stocks.map((stock) => (
                <div className='px-2' key={stock.id}> {/* Slider item container */}
                  <button
                      className={
                        styles[
                            activeStockId === stock.id
                                ? 'btn-news-watchlist-actives'
                                : 'btn-news-watchlist'
                            ]
                      }
                      onClick={() => {
                        // console.log('Button clicked for stock ID:', stock.code);
                        fetchNewsUpdate(stock.code);
                        setActiveStockId(stock.id);
                      }}
                  >
                    {stock.name}
                  </button>
                </div>
            ))}
          </Slider>
        </div>


        <div>

          {newsUpdate.data.length === 0 ? (
              <div className="my-16 text-center">
                <p className="no_data_on_div">{t('No data available for the selected stock.')}</p>
              </div>

          ) : (
              <>
              {newsUpdate.data.map((newsItem, index) => (
                <a href={newsItem.link} target="_blank" rel="noreferrer">
                  <div
                    key={index}
                    className={`d-flex justify-content-between gap-2 px-4 py-3 align-items-center bg-natural-color ${styles.card_news_update}`}
                  >
                    <div className="fs-16 fw-500 text-primary-color">
                      <p className="mb-2">{newsItem.title}</p>
                      <div className={`${styles["your-div"]}`}>
                        <p className="text-natural-color-3 mb-2">
                          {truncateContent(newsItem.content, 60)}
                        </p>

                      </div>
                      <div className="d-flex  ">
                        {newsItem.tags.length > 0 && (
                          <>
                          <span className="text-natural-color-3 m-0">
                            {t('Tags')}:
                          </span>

                            <div className="d-flex flex-wrap gap-2">
                              {newsItem.tags.slice(0, 4).map((tag, i) => (
                                <span
                                  key={i}
                                  className="text-natural-color-3 m-0"
                                >
                                #{tag},
                              </span>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_105_35009)">
                          <path d="M10.7031 7.89062V1.17188H30.5469L38.8281 8.20312V38.8281H10.7031V24.7656" stroke="#1D1A39" strokeWidth="2.34375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M21.5586 24.8438V29.375H18.8281L24.6875 35.2344L30.5469 29.375H27.8164V24.7656M1.17188 8.20312H30.5469V24.6875H1.17188V8.20312Z" stroke="#1D1A39" strokeWidth="2.34375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M5.9375 17.6562H8.26203C9.56703 17.6562 10.625 16.5983 10.625 15.2933C10.625 13.9729 9.54289 12.9087 8.22266 12.9306L5.9375 12.9688V20M26.3281 12.9688H22.3438V20M25.0781 17.6562H22.3438M38.8281 8.20312H30.5469V1.17188M14.1406 12.9688V20H15.3125C17.2541 20 18.8281 18.426 18.8281 16.4844C18.8281 14.5427 17.2541 12.9688 15.3125 12.9688H14.1406Z" stroke="#1D1A39" strokeWidth="2.34375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_105_35009">
                            <rect width="40" height="40" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  </div>
                </a>
                // <div key={newsItem.date} className={`your-news-item-class`}>
                //   <p className="mb-2">{newsItem.title}</p>
                //   <div className={`${styles["your-div"]}`}>
                //     <p className="text-natural-color-3 mb-2">{newsItem.content}</p>
                //     <a href={newsItem.link} target="_blank" rel="noopener noreferrer">
                //       Read more
                //     </a>
                //   </div>
                // </div>
              ))}
            </>
            )}
        </div>
      </>
    }
    </>
  )
}

export default News