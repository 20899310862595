import React from 'react';
import useCustomTranslation from "../../../Hooks/useTranslation";
const FundamentalAi = () => {
    const { t } = useCustomTranslation();

    return (
        <>
            <div className="d-flex flex-column h-100">
                    <h3 className="card-ai-header">{t('Finances')}</h3>
                    <p className="coming-soon m-0 justify-content-center h-100">{t('comming soon')}</p>
            </div>
        </>
    );
};

export default FundamentalAi;