import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdOutlineWarningAmber } from "react-icons/md";
import useCustomTranslation from "../../Hooks/useTranslation";

const DelayedPriceMessage = ({ messageKey }) => {
    const { t } = useCustomTranslation();
    return (
        <div className="container-fluid px-0">
            <div className="row justify-content-start">
                <div className="col-12">
                    <div className="alert alert-warning d-inline-flex align-items-center" role="alert" style={{
                        padding: "0.5rem 1rem",
                        fontSize: "0.875rem",
                        maxWidth: "100%",
                        width: "auto"
                    }}>
                        <MdOutlineWarningAmber className="me-2" style={{ fontSize: '1.25rem' }} />
                        <span className="text-capitalize">{t(messageKey)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DelayedPriceMessage;