import React, { useEffect, useState } from 'react';
import CommonBanner from '../../CommonBanner/CommonBanner';
import AddStocks from './AddStocks';
import StocksListWatch from './StocksListWatch';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiUrlServer, getAllGroups } from '../../../apiConfig';
import { useCookies } from 'react-cookie';
import {useLanguage} from "../../../Context/LanguageContext";
import {ShimmerThumbnail} from "react-shimmer-effects";
import {WatchListAddStocksProvider} from "../../../Context/WatchListAddStocksContext";

const GroupStocks = () => {
  const { id } = useParams();
  const [watchListDetails, setWatchListDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  const {language} = useLanguage();

  const fetchData = async () => {
    try {
      const token = cookies.token;
      const response = await axios.get(`${apiUrlServer}${getAllGroups}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          'Accept-Language': language,
        },
      });

      const result = response.data;

      if (result.status) {
        setWatchListDetails(result.data);
        console.log(result.data);
      } else {
        console.error('Error fetching watchlist details:', result.message);
      }
    } catch (error) {
      console.error('Error fetching watchlist details:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [cookies.token, id, language]);

  if (isLoading) {
    return <ShimmerThumbnail height={800} />;
  }

  return (
      <WatchListAddStocksProvider>
        <CommonBanner title={watchListDetails.name} />
        <AddStocks onStockAdded={fetchData} />
        <StocksListWatch
            watchListStocks={watchListDetails.stocks}
            fetchData={fetchData}
        />
      </WatchListAddStocksProvider>
  );
};

export default GroupStocks;