import axios from 'axios';
import {useLanguage} from "../Context/LanguageContext";

const useCustomAxios = () => {
    const { language } = useLanguage();

    const customAxios = async (url, options = {}) => {
        const config = {
            ...options,
            headers: {
                ...options.headers,
                'Accept-Language': language,
            },
        };

        return await axios(url, config);
    };

    return customAxios;
};

export default useCustomAxios;
