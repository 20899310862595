import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import {apiUrlServer, getAllGroups} from "../apiConfig";
import {useLanguage} from "../Context/LanguageContext";

const useWatchList = (page = 1) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState(0);  // State for total pages
    const [cookies] = useCookies(['token']);
    const {language} = useLanguage();
    useEffect(() => {
        const fetchData = async () => {
            try {
                let token = cookies.token;

                const response = await axios.get(`${apiUrlServer}${getAllGroups}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': language,
                    },
                    params:{
                        page:`${page}`
                    }
                });

                setData(response.data.data.data); // Access the nested data property
                setTotalPages(response.data.data.meta.total_pages); // Set the total pages from the response
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [page, cookies.token]);

    return { data, loading, error, totalPages }; // Return totalPages for pagination control
}

export default useWatchList;