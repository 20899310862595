import React from "react";
import styles from "./TimerBannerStocks.module.css";
import { ReactComponent as CalendarSvg } from "../../../../../assets/icons/calendar.svg";
import { ReactComponent as SeparatorSvg } from "../../../../../assets/icons/separator.svg";
export const TimerBannerStocks = () => {
  return (
    <>
      <div className="mb-24">
        <div className={styles.frame}>
          <div className={styles.date}>
            <div>
              <CalendarSvg />
            </div>
            <div className={styles["text-wrapper"]}>Wed, 23 Aug 2023</div>
            <SeparatorSvg />
            <div className={styles["text-wrapper"]}>13:26</div>
          </div>
          <div className={styles['btn-container']}>
            <button className={`${styles.btn} ${styles['first-btn']}`}>1 Day</button>
            <button className={`${styles.btn} ${styles['second-btn']} ${styles['active-btn']}`}>Over All</button>
          </div>
        </div>
      </div>
    </>
  );
};
