import React from 'react'
import styles from '../Header/Header.module.css'
import { Link } from 'react-router-dom'
import defImage from '../../assets/images/defimage.webp'
import useCustomTranslation from '../../Hooks/useTranslation'

const SearchWathList = ({ searchTerm, setSearchTerm }) => {
  const { t } = useCustomTranslation();
  return (
    <>
      <div className={`${styles["header-search"]}`}>
        <div className="row justify-content-between align-items-center m-0">
          {/*  <!-- Start Search Input -->*/}
          <div
            className={`${styles.search} align-items-center px-8 col-8 p-0`}
          >
            <svg
              className={`${styles.faSearch} ml-8`}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#1D1A39"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 21L16.65 16.65"
                stroke="#1D1A39"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              className={` form-control border-radius-16  ${styles['search-inputs']} pr-8 pl-48`}
              placeholder={t('Search for watchlist group')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

          </div>

        </div>
      </div>

    </>
  )
}

export default SearchWathList


