import React, { createContext, useState, useEffect } from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCSa-gYIN4fEgDDF_EpRurGsy4PriM4gDs",
    authDomain: "k-wealth-745f7.firebaseapp.com",
    projectId: "k-wealth-745f7",
    storageBucket: "k-wealth-745f7.appspot.com",
    messagingSenderId: "205578399871",
    appId: "1:205578399871:web:66608e85c421440d350f4a",
    measurementId: "G-H7ZZKW7GJ1"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const FCMTokenContext = createContext();
export const FCMTokenProvider = ({ children }) => {
    const [deviceToken, setDeviceToken] = useState('');
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const currentToken = await getToken(messaging, { vapidKey: 'BNzDAxsyrxPDxdSfPS4QeVG-Zanqp6JBXoU4HNHOPx-ndwn6zTutOZnh1LeQuRzmjE94_l1fjuQIqs1kQNENS9A' });
                if (currentToken) {
                    setDeviceToken(currentToken);
                    console.log("token notifications:", currentToken);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            } catch (err) {
                console.log('An error occurred while retrieving token. ashraf firebase ', err);
            }
        };
        const requestPermissionAndFetchToken = async () => {
            const permission = await Notification.requestPermission();

            if(permission === "granted"){
                fetchToken();
            }else{
                const generateRandomString = (length) => {
                    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    let result = '';
                
                    for(let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                    }
                    return result;
                }
                setDeviceToken(generateRandomString(60));
            }
        }
        requestPermissionAndFetchToken();
    }, []);
    return (
        <FCMTokenContext.Provider value={deviceToken} messaging = {messaging}>
            {children}
        </FCMTokenContext.Provider>
    );
};