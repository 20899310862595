import {HiMiniArrowSmallLeft, HiMiniArrowSmallRight} from "react-icons/hi2";
import SimulationSummary from "./SimulationSummary";
import {useEffect, useState} from "react";
import styles from "../WatchList/GroupStocks/WatchListStockTab/WatchStockTab.module.css";
import useWatchList from "../../Hooks/useWatchList";
import useGroupData from "../../Hooks/useGroupData";
import GroupDataDisplay from "./GroupDataDisplay";
import {BulletList} from "react-content-loader";
import useCustomTranslation from '../../Hooks/useTranslation';
const SimulationTabs = () => {
    const {t} = useCustomTranslation();
    const [page, setPage] = useState(1);
    const { data: watchListData, loading: watchListLoading, error: watchListError, totalPages } = useWatchList(page);
    const [activeTab, setActiveTab] = useState("fullMarket");

    // Use useGroupData hook to fetch group data based on the active tab
    const { groupData, loading: groupLoading, error: groupError, columns, loading } = useGroupData(activeTab === "fullMarket" ? null : activeTab);

    useEffect(() => {
        if (watchListData.length > 0) {
            setActiveTab(watchListData[0].id);
        }
    }, [watchListData]);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };


    return (
        <>
            <div className="">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className={`${styles.dailyTabsNav}`}>
                            <ul className={`${styles.dailyTabsList}`}>
                                <li>
                                    <button
                                        className={activeTab === "fullMarket" ? styles.active : undefined}
                                        onClick={() => handleTabClick("fullMarket")}
                                    >
                                        {t('Full Market')}
                                    </button>
                                </li>
                                <button
                                    className="btn pagination-btn-watchlist"
                                    onClick={handlePrevPage}
                                    disabled={page === 1}
                                >
                                    <HiMiniArrowSmallLeft/>
                                </button>
                                {watchListData.map(item => (
                                    <li key={item.id}>
                                        <button
                                            className={activeTab === item.id ? styles.active : undefined}
                                            onClick={() => handleTabClick(item.id)}
                                        >
                                            {item.name}
                                        </button>
                                    </li>
                                ))}
                                <button
                                    className="btn pagination-btn-watchlist"
                                    onClick={handleNextPage}
                                    disabled={page === totalPages}
                                >
                                    <HiMiniArrowSmallRight/>
                                </button>
                            </ul>
                        </nav>
                        <div className={styles.tabContent}>
                            {groupLoading ? (
                                <BulletList/>
                            ) : groupError ? (
                                <div>{t('Error')}: {groupError}</div>
                            ) : activeTab === "fullMarket" ? (
                                <SimulationSummary/>
                            ) : (
                                <GroupDataDisplay
                                    loading={loading}
                                    groupData={groupData}
                                    groupLoading={groupLoading}
                                    groupError={groupError}
                                    activeTab={activeTab}
                                    columns={columns}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SimulationTabs;