import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../Context/AuthContext';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {apiUrlServer} from '../../../apiConfig';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import style from '../Auth.module.css';
import defaultLogo from "../../../assets/images/logo_white-main.webp";
import nationalDayLogo from '../../../assets/images/K-Wealth-Saudi-national-Day Identity_Main-Logo.png';
import { Helmet } from 'react-helmet'
import useCountdown from '../../../Hooks/useCountdown';
import {useTheme} from "../../../Context/ThemeContext";

const CheckOtp = ( ) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const { setIsUserLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [resendTimeout, setResendTimeout] = useState(null);
  const storedSecondsRemaining = parseInt(localStorage.getItem('secondsRemaining')) || 0;
  const { secondsRemaining, setSecondsRemaining } = useCountdown(storedSecondsRemaining);
  const { theme } = useTheme();


  useEffect(() => {
    localStorage.setItem('secondsRemaining', secondsRemaining.toString());
    return () => {
      localStorage.removeItem('secondsRemaining');
    };
  }, [secondsRemaining]);

  async function handelCheckOtp(values) {
    setIsLoading(true);

    try {
      // Retrieve phone number from session storage
      const storedPhone = sessionStorage.getItem('phone');

      const { data } = await axios.post(`${apiUrlServer}customer/checkOtp`, {
        ...values,
        phone: storedPhone,
      });
      if (data.message === "done") {
        // Save phone number to session storage
        sessionStorage.setItem('otp_code', values.otp_code);
        sessionStorage.setItem('phone', values.phone);
        toast.success('Verify', {
          position: 'top-right',
          duration: 3000,
          className: 'text-success px-5 fw-bolder my-3',
        });

        setMessageError('');
        setIsLoading(false);
        // console.log(data);

        navigate('/resetPassword');
      }


    } catch (err) {
      setIsLoading(false);
      setMessageError(err.response.data.message);
      toast.error(err.response.data.message, {
        position: 'top-right',
        duration: 3000,
        className: 'text-danger px-5 fw-bolder my-3',
      });
    }
  }

  let validationSchema = Yup.object({
    otp_code: Yup.string().required('OTP code is required'),
    phone: Yup.string()
      .matches(
        /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
        'Invalid ksa phone number'
      )
      .required('Phone number is required'),
  });
  let storedPhone = sessionStorage.getItem('phone');


  const formik = useFormik({
    initialValues: {
      otp_code: '',
      phone: storedPhone,
    },
    validationSchema,
    onSubmit: handelCheckOtp,
  });

  async function handleResendOtp() {
    try {
      // Retrieve phone number from session storage
      const storedPhone = sessionStorage.getItem('phone');
      const { data } = await axios.post(`${API_URL}customer/sendOtp`, { phone: storedPhone });

      if (data.message === 'Data updated successfully') {
        toast.success(`Your OTP is: ${data.data.otp_code} `, {
          position: 'top-right',
          duration: 3000,
          className: 'text-success px-5 fw-bolder my-3',
        });
        // Set a timeout to enable the Resend OTP button after 60 seconds

        setMessageError('');
        setSecondsRemaining(60);      
      }
    } catch (err) {
      if (err.response && err.response.data) {
        const errorMessage = err.response.data.message;
        if (errorMessage === 'The selected phone is invalid.') {
          toast.error("This phone number doesn’t have an account yet", {
            position: 'top-right',
            duration: 3000,
            className: "text-danger px-5 fw-bolder my-3",
          });
        }
      }
    }
  }


  return (
    <>
      <Helmet>
        <title>Check OTP</title>
      </Helmet>
      <div className="py-5 h-screen">
        <div className="container ">
          <div className="row justify-content-center align-items-center min-h-screen-authForm">
            <div className={`col-xxl-4 col-lg-5 col-md-6 col-10 bg-white p-4 ${style.formContainer}`}>
              <div className="text-center my-16">
                <img className="max-w" src={logo} alt="logo" />
              </div>
              <h2 className={`${style['head-form']}`}>Check OTP</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className={`${style['input-custom']} form-control`}
                    placeholder="Enter OTP code"
                    id="otp_code"
                    name="otp_code"
                    value={formik.values.otp_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="mb-3">
                  {formik.errors.otp_code && formik.touched.otp_code && (
                    <small className={`${style['text-error']}`}>{formik.errors.otp_code}</small>
                  )}
                </div>

                <div>
                  {isLoading ? (
                    <button type="button"  className={`${style['btn-signup']} btn opacity-75 fw-bolder px-4 w-100`}>
                      <span  className="spinner-border spinner-border-sm " role="status" aria-hidden="false"></span>
                    </button>
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        {/* Submit OTP button */}
                        <button disabled={!formik.isValid} type="submit" className={`btn ${style['btn-signup']}`}>
                          Submit OTP
                        </button>

                        {/* Resend OTP button */}
                        <button
                          type="button"
                          className={`btn ${style['btn-signup']} ml-2`}
                          onClick={handleResendOtp}
                          disabled={secondsRemaining > 0}
                        >
                          {secondsRemaining > 0
                            ? `Resend OTP in ${secondsRemaining}s`
                            : 'Resend OTP'}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default CheckOtp;