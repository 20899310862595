import React from "react";
import "./Portfolio.css";
import { TimerBanner } from './TimerBanner/TimerBanner'
import PortfolioList from './PortfolioTable/PortfolioList'
import { AllSummary } from '../AllSummary'
import  useCustomTranslation  from '../../../Hooks/useTranslation';

const PortfoliotabHome = () => {
  const { t } = useCustomTranslation();
  return (
    <>
      <section className="delivery-scans tasi_card ">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="portfolio-tab-home">
              <div className="row">
                <div className="col-lg-12">
                  <ul
                    className="home-tabs nav nav-tabs mb-24"
                    id="myTabs"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="tab1-tab"
                        data-bs-toggle="tab"
                        href="src/components/Portfolio#tab1"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        {t('Portfolios Summary')}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="tab2-tab"
                        data-bs-toggle="tab"
                        href="src/components/Portfolio#tab2"
                        role="tab"
                        aria-controls="tab2"
                        aria-selected="false"
                      >
                        {t('Portfoliotab List')}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabsContent">
                    <div
                      className="tab-pane fade show active"
                      id="tab1"
                      role="tabpanel"
                      aria-labelledby="tab1-tab"
                    >
                      <AllSummary/>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab2"
                      role="tabpanel"
                      aria-labelledby="tab2-tab"
                    >
                      <TimerBanner />
                      <PortfolioList/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfoliotabHome;
