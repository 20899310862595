import React from 'react'
import CommonBanner from '../CommonBanner/CommonBanner'
import GroupTable from './GroupTable'
import useCustomTranslation from '../../Hooks/useTranslation'

const WatchList = () => {
  const { t } = useCustomTranslation();

  return (
    <>
        <div className="">
            <CommonBanner title={t('My Watchlist')}/>
            <GroupTable/>
        </div>
    </>
  )
}

export default WatchList