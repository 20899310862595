import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import axios from "axios";
import { apiUrlServer, WEB_SOCKET_URL } from "../apiConfig";
import { Link } from "react-router-dom";
import { abbreviateNumber, formatNumber } from "./useNumberFormatting";
import useCustomTranslation from './useTranslation';
import { useLanguage } from "../Context/LanguageContext";
import { useCookies } from "react-cookie";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useWebSocketBulk from './useWebSocketBulk';

const useStocks = () => {
    const { t } = useCustomTranslation();
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { language } = useLanguage();
    const [cookies] = useCookies(['token']);
    const [stockIds, setStockIds] = useState([]);
    const [updatedCells, setUpdatedCells] = useState({});
    const [lastUpdateTime, setLastUpdateTime] = useState({});
    const lastUpdateRef = useRef({});
    const queryClient = useQueryClient();
    const [accumulatedResponseData, setAccumulatedResponseData] = useState({});
    const [mergedData, setMergedData] = useState([]);
    const fetchStocks = useCallback(async ({ queryKey }) => {
        const [_, page, limit] = queryKey;
        const response = await axios.get(`${apiUrlServer}stocks`, {
            headers: {
                'Accept-Language': language,
                'Authorization': `Bearer ${cookies.token}`
            },
            params: {
                with_api: true,
                page: page,
                limit: limit,
            },
        });
        return response.data;
    }, [language, cookies.token]);

    const {
        data: stocks,
        isLoading: loading,
        refetch
    } = useQuery({
        queryKey: ['stocks', currentPage, perPage, language],
        queryFn: fetchStocks,
        refetchOnWindowFocus: false,
        staleTime: 60000,
        retry: 2,
    });

    // const updateStocksData = useCallback((streamData) => {
    //     console.log("Updating stocks data with:", streamData);
    //     queryClient.setQueryData(['stocks', currentPage, perPage, language], (oldData) => {
    //         if (!oldData) return oldData;
    //
    //         const updatedStocks = [...oldData.data];
    //         const newUpdatedCells = {};
    //         const newLastUpdateTime = {};
    //
    //         streamData.forEach(item => {
    //             const stockIndex = updatedStocks.findIndex(s => `${s.inis_bc}` === item.requestedId);
    //             if (stockIndex !== -1) {
    //                 console.log(`Updating stock data for ${item.requestedId}`);
    //                 const stock = updatedStocks[stockIndex];
    //                 const timestamp = item.timestamp ? new Date(item.timestamp).getTime() : undefined;
    //                 newLastUpdateTime[stock.code] = timestamp;
    //
    //                 const updateField = (field) => {
    //                     if (item[field]?.value !== undefined) {
    //                         const newValue = item[field].value;
    //                         if (newValue !== stock.api?.[field] && item.type === "UPDATE") {
    //                             const direction = newValue > stock.api?.[field] ? 'increase' : 'decrease';
    //                             newUpdatedCells[`${stock.code}-${field}`] = { timestamp, direction };
    //                         }
    //                         return newValue;
    //                     }
    //                     return stock.api?.[field] ?? '-';
    //                 };
    //
    //                 const newApi = {
    //                     last: updateField('last'),
    //                     timestamp: timestamp ?? stock.api?.timestamp,
    //                     numberOfTrades: updateField('numberOfTrades'),
    //                     volumeTotal: updateField('volumeTotal'),
    //                     turnoverTotal: updateField('turnoverTotal'),
    //                     open: updateField('open'),
    //                     close: updateField('close'),
    //                     high: updateField('high'),
    //                     low: updateField('low'),
    //                     vwap: updateField('vwap'),
    //                     bestBid: updateField('bestBid'),
    //                     bestAsk: updateField('bestAsk'),
    //                 };
    //
    //                 const change = newApi.close !== '-' && newApi.last !== '-' ? newApi.last - newApi.close : '-';
    //                 const changePercent = change !== '-' && newApi.last !== '-' && newApi.last !== 0
    //                     ? ((change / newApi.last) * 100).toFixed(2)
    //                     : '-';
    //
    //                 updatedStocks[stockIndex] = {
    //                     ...stock,
    //                     api: newApi,
    //                     change: change,
    //                     changePercent: changePercent
    //                 };
    //             } else {
    //                 console.log(`No matching stock found for ${item.requestedId}`);
    //             }
    //         });
    //
    //         setUpdatedCells(prev => ({...prev, ...newUpdatedCells}));
    //         setLastUpdateTime(prev => ({...prev, ...newLastUpdateTime}));
    //
    //         return {...oldData, data: updatedStocks};
    //     });
    // }, [queryClient, language, currentPage, perPage]);
    const updateStocksData = useCallback((streamData) => {
        console.log("Updating stocks data with:", streamData);
        queryClient.setQueryData(['stocks', currentPage, perPage, language], (oldData) => {
            if (!oldData) return oldData;

            const updatedStocks = [...oldData.data];
            const newUpdatedCells = {};
            const newLastUpdateTime = {};

            streamData.forEach(item => {
                const stockIndex = updatedStocks.findIndex(s => `${s.inis_bc}` === item.requestedId);
                if (stockIndex !== -1) {
                    console.log(`Updating stock data for ${item.requestedId}`);
                    const stock = updatedStocks[stockIndex];
                    const timestamp = item.last?.timestamp ? new Date(item.last.timestamp).getTime() : undefined;
                    newLastUpdateTime[stock.code] = timestamp;

                    const updateField = (field) => {
                        if (item[field]?.value !== undefined) {
                            const newValue = item[field].value;
                            if (newValue !== stock.api?.[field] && item.type === "UPDATE") {
                                const direction = newValue > stock.api?.[field] ? 'increase' : 'decrease';
                                newUpdatedCells[`${stock.code}-${field}`] = { timestamp, direction };
                            }
                            return newValue;
                        }
                        return stock.api?.[field] ?? '-';
                    };

                    const newApi = {
                        last: updateField('last'),
                        timestamp: timestamp,
                        numberOfTrades: updateField('numberOfTrades'),
                        volumeTotal: updateField('volumeTotal'),
                        turnoverTotal: updateField('turnoverTotal'),
                        open: updateField('open'),
                        close: updateField('close'),
                        high: updateField('high'),
                        low: updateField('low'),
                        vwap: updateField('vwap'),
                        bestBid: updateField('bestBid'),
                        bestAsk: updateField('bestAsk'),
                    };

                    const change = newApi.close !== '-' && newApi.last !== '-' ? newApi.last - newApi.close : '-';
                    const changePercent = change !== '-' && newApi.last !== '-' && newApi.last !== 0
                        ? ((change / newApi.last) * 100).toFixed(2)
                        : '-';

                    updatedStocks[stockIndex] = {
                        ...stock,
                        api: newApi,
                        change: change,
                        changePercent: changePercent
                    };
                } else {
                    console.log(`No matching stock found for ${item.requestedId}`);
                }
            });

            setUpdatedCells(prev => ({...prev, ...newUpdatedCells}));
            setLastUpdateTime(prev => ({...prev, ...newLastUpdateTime}));

            return {...oldData, data: updatedStocks};
        });
    }, [queryClient, language, currentPage, perPage]);
    const { responseData, setStockIdCode } = useWebSocketBulk(WEB_SOCKET_URL, stockIds);

    useEffect(() => {
        if (stocks?.data) {
            const newStockIds = stocks.data.map(stock => `${stock.inis_bc}`);
            console.log("Setting new stock IDs:", newStockIds);
            setStockIdCode(newStockIds);
        }
    }, [stocks, setStockIdCode]);

    useEffect(() => {
        if (responseData) {
            setAccumulatedResponseData(prev => ({
                ...prev,
                ...responseData
            }));
        }
    }, [responseData]);

    useEffect(() => {
        if (stocks?.data && accumulatedResponseData) {
            const updatedData = stocks.data.map(stock => {
                const stockResponseData = accumulatedResponseData[stock.inis_bc];
                if (stockResponseData) {
                    return {
                        ...stock,
                        socketApi: stockResponseData,
                    };
                }
                return stock;
            });
            // console.log("Updated data:", updatedData);
            setMergedData(updatedData);
        }
    }, [stocks, accumulatedResponseData]);

    // useEffect(() => {
    //     if (responseData) {
    //         updateStocksData([responseData]);
    //     }
    // }, [responseData, updateStocksData]);

    const columns = useMemo(() => [
        {
            name: t('Code'),
            selector: row => row.code,
            sortable: true,
            cell: row => <Link to={`/stocks/${row.id}/${row.code}`} className="text-blue-600 hover:text-blue-800">{row.code}</Link>,
        },
        {
            name: t('Name'),
            selector: row => row.name,
            sortable: true,
            cell: row => <div className="truncate" style={{maxWidth: "200px"}}>{row.name}</div>,
        },
        {
            name: t('Last Price'),
            selector: row => row.socketApi?.last?.value ?? row.api?.last,
            sortable: true,
            width: '120px',
            cell: row => (
                <div>
                    {formatNumber(row.socketApi?.last?.value ?? row.api?.close)}
                </div>
            ),
        },
        {
            name: t('Change'),
            selector: row => row.api?.change,
            sortable: true,
            width: '150px',
            cell: row => {
                if(row.socketApi?.last?.value && row.socketApi?.close?.value){
                    const change = row.socketApi?.last?.value - row.socketApi?.close?.value;
                    return (
                        <div style={{color: change >= 0 ? 'green' : 'red'}}>
                            {formatNumber(change)}
                        </div>
                    )
                }
                return (
                    <div style={{color: row.api?.change >= 0 ? 'green' : 'red'}}>
                        {row.api?.change}
                    </div>
                )
            },
        },
        {
            name: t('Change(%)'),
            selector: row => row.api?.change_p,
            width: '150px',
            sortable: true,
            cell: row => {
                if(row.socketApi?.last?.value && row.socketApi?.close?.value){
                    const change = row.socketApi?.last?.value - row.socketApi?.close?.value;
                    return (
                        <div style={{color: change >= 0 ? 'green' : 'red'}}>
                            {formatNumber((change/row.socketApi?.last?.value) * 100)}
                        </div>
                    )
                }
                return (
                    <div style={{color: row.api?.change_p >= 0 ? 'green' : 'red'}}>
                        {row.api?.change_p}
                    </div>
                )
            },
        },
        {
            name: t('Open'),
            selector: row => row.socketApi?.open?.value ?? row.api?.open,
            sortable: true,
            cell: row => <div className="text-right">{formatNumber(row.socketApi?.open?.value ?? row.api?.open)}</div>,
        },
        {
            name: t('High'),
            selector: row => row.socketApi?.high?.value ?? row.api?.high,
            sortable: true,
            cell: row => <div className="text-right">{formatNumber(row.socketApi?.high?.value ?? row.api?.high)}</div>,
        },
        {
            name: t('Low'),
            selector: row => row.socketApi?.low?.value ?? row.api?.low,
            sortable: true,
            cell: row => <div className="text-right">{formatNumber(row.socketApi?.low?.value ?? row.api?.low)}</div>,
        },
        {
            name: t('Volume Total'),
            selector: row => row.socketApi?.volumeTotal?.value ?? row.api?.volumeTotal,
            width: '180px',
            sortable: true,
            cell: row => <div className="text-right">{abbreviateNumber(row.socketApi?.volumeTotal?.value ?? row.api?.volumeTotal)}</div>,
        },
        {
            name: t('Turnover Total'),
            selector: row => row.socketApi?.turnoverTotal?.value ?? row.api?.turnoverTotal,
            width: '180px',
            sortable: true,
            cell: row => <div className="text-right">{abbreviateNumber(row.socketApi?.turnoverTotal?.value ?? row.api?.turnoverTotal)}</div>,
        },
        {
            name: t('Number of Trades'),
            selector: row => row.socketApi?.numberOfTrades?.value ?? row.api?.numberOfTrades,
            width: '180px',
            sortable: true,
            cell: row => <div className="text-right">{abbreviateNumber(row.socketApi?.numberOfTrades?.value ?? row.api?.numberOfTrades)}</div>,
        },
        {
            name: 'VWAP',
            selector: row => row.socketApi?.vwap?.value ?? row.api?.vwap,
            cell: row => <div className="text-right">{formatNumber(row.socketApi?.vwap?.value ?? row.api?.vwap)}</div>,
            sortable: true,
        },
        {
            name: 'bestBid',
            selector: row => row.socketApi?.bestBid?.value ?? row.api?.bestBid,
            width: '150px',
            cell: row => <div className="text-right">{formatNumber(row.socketApi?.bestBid?.value ?? row.api?.bestBid)}</div>,
            sortable: true,
        },
        {
            name: 'besAsk',
            selector: row => row.socketApi?.bestAsk?.value ?? row.api?.bestAsk,
            width: '150px',
            cell: row => <div className="text-right">{formatNumber(row.socketApi?.bestAsk?.value ?? row.api?.bestAsk)}</div>,
            sortable: true,
        },
        {
            name: t('Last Update'),
            width: '180px',
            selector: row => row.api?.timestamp || 0,
            cell: row => (
                <div className="text-right">
                    {row.api?.timestamp
                        ? new Date(row.api.timestamp).toLocaleString()
                        : '-'}
                </div>
            ),
            sortable: true,
        },
    ], [t, language, updatedCells, lastUpdateTime]);

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const handlePerRowsChange = useCallback(async (newPerPage, page) => {
        setPerPage(newPerPage);
    }, []);

    const paginationComponentOptions = {
        rowsPerPageText: t('Rows per page:'),
        rangeSeparatorText: t('of'),
        selectAllRowsItem: true,
        selectAllRowsItemText: t('All'),
    };

    return {
        stocks: mergedData || [],
        loading,
        columns,
        totalRows: stocks?.meta?.total || 0,
        perPage,
        currentPage,
        handlePageChange,
        handlePerRowsChange,
        paginationComponentOptions
    };
};

export default useStocks;