// useWebSocket.js
import { useState, useEffect, useRef, useCallback,useMemo } from 'react';

const useWebSocketBulk = (url, stockId) => {
    const [responseData, setResponseData] = useState(null);
    const socketRef = useRef(null);
    const [stockIdCode, setStockIdCode] = useState(stockId);
    const stockIdCodeArray = useMemo(() => stockIdCode.join(','), [stockIdCode]);

    const handleWebSocketMessage = useCallback((event) => {
        try {
            const data = JSON.parse(event.data);
            // console.log('Received message:', data,stockId);
            if (data.type === 'pong' && data.status === 'subscribed') {
                // console.log(`Subscription status: ${data.status}`);
            }

            const isInArray = stockIdCode.some((id) => id === data?.data?.startStream[0]?.requestedId);
            if (data?.data?.startStream && data.data.startStream.length > 0 && isInArray) {
                console.log("startStream");
                setResponseData(prevData => {
                    // console.log(prevData, "prevData");
                    const updatedStocks = data.data.startStream.reduce((acc, stockData) => {
                        const existingStockData = prevData?.[stockData.requestedId] || {};
                        acc[stockData.requestedId] = {
                            ...existingStockData,
                            ...stockData
                        };
                        return acc;
                    }, {});

                    return {
                        ...prevData,
                        ...updatedStocks
                    };
                });
            }
        } catch (error) {
            console.error('Error parsing WebSocket message:', error);
        }
    }, [stockIdCode]);

    useEffect(() => {
        if(stockIdCode.length > 0){
            socketRef.current = new WebSocket(url);

            socketRef.current.onopen = () => {
                console.log('WebSocket is connected',stockIdCode);
                socketRef.current.send(JSON.stringify({ type: 'subscribe', stockId: stockIdCodeArray}));
            };
            socketRef.current.onmessage = handleWebSocketMessage;
            socketRef.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
    
            socketRef.current.onclose = () => {
                console.log('WebSocket is closed');
            };
            const pingInterval = setInterval(() => {
                if (socketRef.current.readyState === WebSocket.OPEN) {
                    socketRef.current.send(JSON.stringify({ type: 'ping' }));
                }
            }, 30000); // Send a ping every 30 seconds
            // Ping interval to keep the connection alive
            return () => {
                clearInterval(pingInterval);
                if (socketRef.current) {
                    socketRef.current.close();
                }
            };
        }




    }, [url, stockId, handleWebSocketMessage,stockIdCode]);

    return {
        responseData,
        setStockIdCode

    };
};

export default useWebSocketBulk;