import { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {apiUrlServer} from "../apiConfig";

const useStockFundamentals = (code) => {
    const [stockDataFundamentals, setStockDataFundamentals] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [cookies] = useCookies(['token']);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = cookies.token;
                const response = await axios.get(`${apiUrlServer}stock-details/fundamentals?code=${code}.SR`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                const result = response.data;
                if (result.status) {
                    setStockDataFundamentals(result.data);
                } else {
                    console.error('Error fetching stock data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching stock data:', error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [code, cookies.token]);

    return { stockDataFundamentals, isLoading };
};

export default useStockFundamentals;