import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { apiUrlServer, getAllStocks } from "../../apiConfig";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import useSearchTobBar from "../../Hooks/useSearchTobBar";
import useCustomTranslation from '../../Hooks/useTranslation';

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

const searchStocks = async (query, setSearchResults, cookies, cancelToken) => {
    const token = cookies.token;
    try {
        const response = await axios.get(`${apiUrlServer}${getAllStocks}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
            params: {
                overall: false,
                [`filter[name]`]: query
            },
            cancelToken: cancelToken.token
        });
        if (response.data.status) {
            setSearchResults(response.data.data);
        } else {
            console.error('Failed to fetch search results:', response.data.message);
        }
    } catch (error) {
        console.error('Error fetching search results:', error);
    }
};

const StockSearchComponent = () => {
    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);
    const inputRef = useRef(null);
    const {
        toggleSearch,
        fetchStocks,
        handleInputBlur,
        searchResults,
        searchQuery,
        isInputFocused,
        navigate,
        isActive,
        setSearchQuery,
        setIsInputFocused,
        setSearchResults,
        searchRef
    } = useSearchTobBar();

    const [activeIndex, setActiveIndex] = useState(-1);
    const debouncedQuery = useDebounce(searchQuery, 500);
    const cancelTokenSource = useRef(null);


    useEffect(() => {
        if (debouncedQuery.length >= 2) {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel('Operation canceled due to new request.');
            }
            cancelTokenSource.current = axios.CancelToken.source();
            searchStocks(debouncedQuery, setSearchResults, cookies, cancelTokenSource.current);
        } else {
            setSearchResults([]);
        }

        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel();
            }
        };
    }, [debouncedQuery, cookies]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (searchResults.length > 0) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setActiveIndex((prevIndex) => (prevIndex + 1) % searchResults.length);
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setActiveIndex((prevIndex) => (prevIndex - 1 + searchResults.length) % searchResults.length);
            } else if (e.key === 'Enter' && activeIndex !== -1) {
                const selectedResult = searchResults[activeIndex];
                navigate(`/stocks/${selectedResult.id}/${selectedResult.code}`);
                setSearchResults([]);
                setSearchQuery('');
                setActiveIndex(-1);
                window.scrollTo(0, 0);
            } else if (e.key === 'Escape') {
                setSearchResults([]);
                toggleSearch();
                setActiveIndex(-1);
            }
        }
    };

    useEffect(() => {
        if (isActive) {
            inputRef.current && inputRef.current.focus();
        }

        document.addEventListener('keydown', handleKeyDown);

        if (activeIndex !== -1) {
            const results = document.querySelectorAll('.stocks-result a');
            const activeElement = results[activeIndex];
            if (activeElement) {
                activeElement.focus();
            }
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isActive, searchResults, activeIndex]);

    return (
        <div>
            <div className="nav-item" style={{ zIndex: '9' }} ref={searchRef}>
                <div className="container-search">
                    <div className="search">
                        {isActive && (
                            <div className={`search-input-container ${isActive ? "active" : ""}`}>
                                <input
                                    type="text"
                                    placeholder={t("Search stocks")}
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    ref={inputRef}
                                    className={isActive ? "active" : ""}
                                />
                                <span className="close-search" onClick={toggleSearch}>
                                    <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2133_62607)">
                                            <path
                                                d="M14.1225 11.9998L23.5602 2.56205C24.1466 1.97619 24.1466 1.02562 23.5602 0.439766C22.9739 -0.146589 22.0243 -0.146589 21.438 0.439766L12.0002 9.87747L2.56205 0.439766C1.97569 -0.146589 1.02612 -0.146589 0.439766 0.439766C-0.146589 1.02562 -0.146589 1.97619 0.439766 2.56205L9.87797 11.9998L0.439766 21.4375C-0.146589 22.0233 -0.146589 22.9739 0.439766 23.5597C0.732943 23.8524 1.11717 23.999 1.50091 23.999C1.88464 23.999 2.26887 23.8524 2.56205 23.5592L12.0002 14.1215L21.438 23.5592C21.7311 23.8524 22.1154 23.999 22.4991 23.999C22.8828 23.999 23.2671 23.8524 23.5602 23.5592C24.1466 22.9734 24.1466 22.0228 23.5602 21.437L14.1225 11.9998Z"
                                                fill="black"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2133_62607">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>

                                {searchResults.length === 0 && isInputFocused && (
                                    <div className='search-error'>
                                        <p>{t('No results found')}</p>
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className={`search-icon ${isActive ? "" : "show-input-search"}`}
                            id="icon-show-search"
                            onClick={toggleSearch}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-search"
                            >
                                <circle cx="11" cy="11" r="8"/>
                                <path d="M21 21l-4.3-4.3"/>
                            </svg>
                        </div>
                        {searchResults.length > 0 && (
                            <div className='search-place smooth-scroll'>
                                <ul className='stock-ul'>
                                    {searchResults.map((result, index) => (
                                        <li
                                            className={`stocks-result ${index === activeIndex ? 'active-result' : ''}`}
                                            key={result.id}
                                        >
                                            <Link className="search-list"
                                                  to={`/stocks/${result.id}/${result.code}`}
                                                  onClick={() => {
                                                      setSearchResults([]);
                                                      setSearchQuery('');
                                                      setActiveIndex(-1);
                                                      window.scrollTo(0, 0);
                                                      toggleSearch();
                                                  }}
                                            >
                                                {result.code} - {result.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockSearchComponent;