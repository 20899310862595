import React, { useEffect, useState } from 'react';
import {ShimmerPostDetails, ShimmerSectionHeader, ShimmerText, ShimmerTitle} from "react-shimmer-effects";
import useCustomTranslation from "../../Hooks/useTranslation";
import StocksHome from "./StocksHome";
import TecAi from "./AIGentation/TecAi";
import Reports from "./AIGentation/Reports";
import FundamentalAi from "./AIGentation/FundamentalAi";
import WorldNews from "./AIGentation/WorldNews";
import ProtectedComponent from "../ProtectedLink/ProtectedComponent";

const LatestNews = () => {
    const [news, setNews] = useState([]);
    const [noData, setNoData] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const { t } = useCustomTranslation();

    return (
        <>
        <div className="latest-news">
            <div className="">
                <div className="row">

                    <div className="col-lg-12 col-sm-12 m-auto">
                        {loading ? (
                            <ShimmerSectionHeader/>
                        ) : (
                            <div className="tasi_card ai-genration-card">
                                <h3 className="tasi_summery">{t('AI Genration')}</h3>
                                <div className="row">

                                    <div className="col-lg-4">

                                        <div className="tasi_card">
                                            <ProtectedComponent  permission="stocks/chat/message/techs"
                                                                 buttonText="Unlock Advanced Features"
                                                                 textHeadLine="Technical"
                                            >
                                            <TecAi/>
                                            </ProtectedComponent>
                                        </div>

                                    </div>

                                        <div className="col-lg-4">
                                        <div className="tasi_card">
                                            <Reports/>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="tasi_card">
                                            <FundamentalAi/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-lg-4 col-sm-12 mt-24">
                        <div className="tasi_card news-card">
                            <WorldNews/>
                        </div>
                    </div>
                    <div className="col-lg-8 col-sm-12 mt-24">
                        <StocksHome/>
                    </div>


                </div>
            </div>
        </div>

        </>
    );
};

export default LatestNews;
