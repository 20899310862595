import React, { useState, useEffect } from 'react';
import './Officers.css';
import { BulletList } from 'react-content-loader';

const Officers = ({ officers }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (officers && Object.keys(officers).length > 0) {
      // If officers data exists, set loading to false
      setLoading(false);
    }
  }, [officers]);

  if (!officers || Object.keys(officers).length === 0) {
    return <p className="no_data_on_tab">No officer data available</p>;
  }

  return (
    <>
      {loading && <BulletList />}
      {!loading && (
        <div className="row">
          {Object.values(officers).map((officer, index) => (
            <div className="col-lg-4 my-24" key={index}>
              <div className="employee-card h-100">
                <h3>{officer.Name}</h3>
                <p>{officer.Title}</p>
                <p>Year Born: {officer.YearBorn}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Officers;
