import React from 'react';

import {useParams} from "react-router-dom";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import useCompanyLiveData from "../../../../Hooks/useCompanyLiveData";
import useStockFundamentals from "../../../../Hooks/useStockFundamentals";
import './Fundmaentals.css';
import {abbreviateNumber, formatNumber, formatNumbers} from "../../../../Hooks/useNumberFormatting";
import Shareholding from "../Shareholding/Shareholding";
import {ShimmerThumbnail} from "react-shimmer-effects";
const Fundamentals = ({ stockDataFundamentals, companyLiveDate, loading }) => {
    const { t } = useCustomTranslation();

    if (loading) {
        return <ShimmerThumbnail height={785} rounded />;
    }

    if (!stockDataFundamentals) {
        return <div>No data available</div>;
    }

    const { General, Technicals, Highlights, SharesStats } = stockDataFundamentals;
    const stockData = companyLiveDate && companyLiveDate.length > 0 ? companyLiveDate[0] : {};
    const api = stockData.api || {};

    const HighlightsMarketCapitalizationMln = abbreviateNumber(Highlights.MarketCapitalizationMln);
    const SharesStatsSharesOutstanding = abbreviateNumber(SharesStats.SharesOutstanding);

    return (
        <section className="fundamentals">
            <div className="container mt-4">
                <div className="row">
                    <div className="col-12">
                        <h3 className="tasi_summery mb-3">{t("Fundamentals")}</h3>

                        <ul className="nav nav-tabs mt-3 d-flex align-items-center" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="details-tab" data-bs-toggle="tab" href="#details"
                                   role="tab" aria-controls="details" aria-selected="true">{t("Stock Informations and Details")}</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="stats-tab" data-bs-toggle="tab" href="#stats" role="tab"
                                   aria-controls="stats" aria-selected="false">{t("Key Stats")}</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="fundamentals-tab" data-bs-toggle="tab" href="#fundamentals"
                                   role="tab" aria-controls="fundamentals" aria-selected="false">{t("Fundamentals")}</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="share-states-tab" data-bs-toggle="tab" href="#share-states"
                                   role="tab" aria-controls="share-states" aria-selected="false">{t("Share States")}</a>
                            </li>
                        </ul>

                        <div className="tab-content mt-3" id="myTabContent">
                            <div className="tab-pane fade show active" id="details" role="tabpanel"
                                 aria-labelledby="details-tab">
                                <div className="card card-fundmentals">
                                    <div className="card-header">
                                        {t("Details and Informations")}
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row g-0">
                                            <div className="col-lg-6 info-row-border">
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Code')}</div>
                                                    <div
                                                        className="info-cell info-cell-data">{General.Code}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Type')}</div>
                                                    <div className="info-cell">{General.Type}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('CurrencyCode')}</div>
                                                    <div className="info-cell">{General.CurrencyCode}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Name')}</div>
                                                    <div className="info-cell">{General.Name}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('CurrencyName')}</div>
                                                    <div className="info-cell">{General.CurrencyName}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('CurrencySymbol')}</div>
                                                    <div className="info-cell">{General.CurrencySymbol}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('ISIN')}</div>
                                                    <div className="info-cell">{General.ISIN}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Exchange')}</div>
                                                    <div className="info-cell">{General.Exchange}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Country Name')}</div>
                                                    <div className="info-cell">{t('Saudi Arabia')}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="info-row">
                                                    <div className="info-cell">{t('CountryISO')}</div>
                                                    <div className="info-cell">{General.CountryISO}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Industry')}</div>
                                                    <div className="info-cell">{General.Industry}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Address')}</div>
                                                    <div className="info-cell">
                                                        {General.Address?.split(' ').slice(0, 3).join(' ') || ''}
                                                    </div>
                                                </div>
                                                <div className="info-row">
                                                <div className="info-cell">{t('Phone')}</div>
                                                    <div className="info-cell">{General.Phone}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('WebURL')}</div>
                                                    <div className="info-cell">{General.WebURL}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Full Time Employees')}</div>
                                                    <div className="info-cell">{General.FullTimeEmployees}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Updated At')}</div>
                                                    <div className="info-cell">{General.UpdatedAt}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Primary Ticker')}</div>
                                                    <div className="info-cell">{General.PrimaryTicker}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Fiscal Year End')}</div>
                                                    <div className="info-cell">{General.FiscalYearEnd}</div>
                                                </div>
                                                {/* Add any other details here as needed */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show " id="stats" role="tabpanel"
                                 aria-labelledby="details-tab">
                                <div className="card card-fundmentals">
                                    <div className="card-header">
                                        {t('Details and Informations')}
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row g-0">
                                            <div className="col-lg-6 info-row-border">
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Open Day')}</div>
                                                    <div className="info-cell info-cell-data">{api.open}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Close Day')}</div>
                                                    <div className="info-cell">{api.close}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('High Day')}</div>
                                                    <div className="info-cell">{api.high}</div>
                                                </div>

                                                <div className="info-row">
                                                    <div className="info-cell">{t('Change Percentage')}</div>
                                                    <div className="info-cell"   style={{color: api.change_p > 0 ? '#049C6B' : '#FD2523'}}>{api.change_p}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Low Day')}</div>
                                                    <div className="info-cell">{api.low}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Volume')}</div>
                                                    <div className="info-cell">{api.volume}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Change')}</div>
                                                    <div className="info-cell"   style={{color: api.change > 0 ? '#049C6B' : '#FD2523'}}>{api.change}</div>
                                                </div>
                                                {/* Add any other details here as needed */}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="tab-pane fade show " id="fundamentals" role="tabpanel"
                                 aria-labelledby="details-tab">
                                <div className="card card-fundmentals">
                                    <div className="card-header">
                                        {t('Details and Informations')}
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row g-0">
                                            <div className="col-lg-6 info-row-border">
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Beta')}</div>
                                                    <div className="info-cell info-cell-data">{Technicals.Beta}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Dividend Yield')}</div>
                                                    <div className="info-cell">{Highlights.DividendYield}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Market Capitalization Mln')}</div>
                                                    <div
                                                        className="info-cell">{HighlightsMarketCapitalizationMln}</div>
                                                </div>

                                                <div className="info-row">
                                                    <div className="info-cell">{t('Change Percentage')}</div>
                                                    <div className="info-cell"   style={{color: api.change_p > 0 ? '#049C6B' : '#FD2523'}}>{api.change_p}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="info-row">
                                                    <div className="info-cell">{t('YTD % Change')}</div>
                                                    <div className="info-cell">-</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Shares Outstanding')}</div>
                                                    <div className="info-cell">{SharesStatsSharesOutstanding}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Change')}</div>
                                                    <div className="info-cell"   style={{color: api.change > 0 ? '#049C6B' : '#FD2523'}}>{api.change}</div>
                                                </div>
                                                {/* Add any other details here as needed */}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="tab-pane fade show " id="share-states" role="tabpanel"
                                 aria-labelledby="details-tab">
                                <div className="card card-fundmentals">
                                    <div className="card-header">
                                        {t('Details and Informations')}
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row g-0">
                                            <div className="col-lg-6 info-row-border">
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Shares Outstanding')}</div>
                                                    <div className="info-cell info-cell-data">{SharesStatsSharesOutstanding}</div>
                                                </div>
                                                <div className="info-row">
                                                    <div className="info-cell">{t('Shares Float')}</div>
                                                    <div className="info-cell">{SharesStats.PercentInsiders}</div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                {/*<div className="info-row">*/}
                                                {/*    <div className="info-cell">{t('Shares Outstanding')}</div>*/}
                                                {/*    <div className="info-cell">{SharesStats.SharesOutstanding}</div>*/}
                                                {/*</div>*/}
                                                {/* Add any other details here as needed */}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Fundamentals;