import React from 'react'
import Swal from 'sweetalert2'
import iconDelete from '../../../../assets/icons/deleteIcon.png'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import styles from '../../Portfolio.module.css';
import { FaRegTrashAlt } from 'react-icons/fa'
import { apiUrlServer } from '../../../../apiConfig'
import useCustomTranslation from "../../../../Hooks/useTranslation";

const DeletePortfolio = ({ portfolioId, onDeletePortfolioSuccess, fetchData }) => {
  const [cookies] = useCookies(['token']);
  const {t} = useCustomTranslation();
  const handleDeleteClick = async () => {
    // Display a confirmation dialog using SweetAlert2
    const result = await Swal.fire({
      title: t('Delete Your Portfolio'),
      text: t('Are you sure you want to Delete Portfolio Name?'),
      iconHtml: `<img src="${iconDelete}" alt="Success Icon" class="" />`,
      showCancelButton: true,
      confirmButtonText: t('Yes, delete it!'),
      cancelButtonText: t('Back to Portfolio List'),
      customClass: {
        confirmButton: 'swal-btn-delete',
        cancelButtonText: 'swal-btn-cancelButton',
      },
    });

    if (result.isConfirmed) {
      try {
        const token = cookies.token;

        const response = await axios.delete(`${apiUrlServer}portfolio/${portfolioId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data.status) {
          await Swal.fire({
            title: t('Group Deleted Successfully'),
            icon: 'success',
            confirmButtonText: t('OK'),
          });
          onDeletePortfolioSuccess();
          fetchData();
        } else {
          await Swal.fire({
            title: t('Error'),
            text: t('Failed to delete group. Please try again.'),
            icon: 'error',
            confirmButtonText: t('OK'),
          });
        }
      } catch (error) {
        console.error('Error deleting group:', error); // Log the error
        // You might want to display a generic error message to the user here as well.
      }
    }
  };
  return (
    <>
      <button className={`btn ${styles['delete-group-btn']}`} onClick={handleDeleteClick}>
        <div className="d-flex justify-content-center align-items-center">
          <div className={`${styles['icon-delete-box']}  `}>
            <FaRegTrashAlt className={`${styles['icon-delete']}`} />
          </div>
          <span>{t('Delete')}</span>
        </div>
      </button>
    </>
  );
};

export default DeletePortfolio;