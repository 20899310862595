import React from 'react';
import MainMarket from "./MainMarket";
import './new_home.css'
import LatestNews from "./LatestNews";
import TopStocks from "./TopStocks";
const Home = () => {
    return (
        <>
        <TopStocks/>
        <MainMarket/>
        <LatestNews/>
        </>
    );
};

export default Home;