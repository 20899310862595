import React from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import API_URL, {apiUrlServer, updateCustomerPassword} from '../../apiConfig';
import { useFormik } from 'formik';
import useCustomTranslation from "../../Hooks/useTranslation";

const EditUserPassword = ({ userProfile, onEditPasswordSuccess }) => {
  const [cookies] = useCookies(['token']);

  async function editProfilePassword(values) {
    try {
      const token = cookies.token;
      const formData = new FormData();
      formData.append('old_password', values.old_password);
      formData.append('password', values.password);
      formData.append('password_confirmation', values.password_confirmation);

      const response = await axios.post(
        `${apiUrlServer}${updateCustomerPassword}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      // Handle the response data after updating the profile
      onEditPasswordSuccess(response.data.data);

      // console.log('Profile updated successfully:', response.data.data);
    } catch (error) {
      console.error('Error updating user profile:', error.response.data);
      // Handle errors as needed
    }
  }

  const formik = useFormik({
    initialValues: {
      old_password: userProfile?.old_password ?? '', // Use '' as a default value if userProfile or old_password is undefined
      password: userProfile?.password ?? '',
      password_confirmation: userProfile?.password_confirmation ?? '',
    },
    onSubmit: editProfilePassword,
  });
  const {t}= useCustomTranslation();

  return (
    <>
      <div className="form-wrapper">
        <form onSubmit={formik.handleSubmit}>

          <div className="row">
            <div className="col-lg-12">
              <div className="head-form mb-3">
                <h2>{t('Change Password')}</h2>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group mb-3">
                <label htmlFor="old_password">{t('Old Password')}</label>
                <input
                    className="form-control"
                    type="password"
                    id="old_password"
                    name="old_password"
                    value={formik.values.old_password}
                    onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group mb-3">
                <label htmlFor="password">{t('New Password')}</label>
                <input
                    className="form-control"
                    type="password"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group mb-3">
                <label htmlFor="password_confirmation">{t('Retype Password')}</label>
                <input
                    className="form-control"
                    type="password"
                    id="password_confirmation"
                    name="password_confirmation"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <button className="btn btn-save-profile" type="submit">
                {t('Save All Changes')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditUserPassword;
