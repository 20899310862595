import React from 'react'
import Swal from 'sweetalert2'
import iconDelete from '../../../assets/icons/deleteIcon.png'
import axios from 'axios'
import { apiUrlServer } from '../../../apiConfig'
import { useCookies } from 'react-cookie'
import styles from '../watchlist.module.css'
import { FaRegTrashAlt } from 'react-icons/fa'
import useCustomTranslation from '../../../Hooks/useTranslation';
import {useLanguage} from "../../../Context/LanguageContext";

const DeleteGroup = ({ groupId, onDeleteGroupSuccess, fetchData }) => {
  const [cookies] = useCookies(['token']);
  const { t } = useCustomTranslation();
  const {language} = useLanguage();
  const handleDeleteClick = async () => {
    // Display a confirmation dialog using SweetAlert2
    const result = await Swal.fire({
      title: t('Delete Your Group'), // Translate title
      text: t('Are you sure you want to Delete Group?'), // Translate text
      iconHtml: `<img src="${iconDelete}" alt="Success Icon" class="" />`,
      showCancelButton: false,
      confirmButtonText: t('Yes, delete it!'), // Translate confirm button
      cancelButtonText: t('Back to Group List'), // Translate cancel button
      customClass: {
        confirmButton: 'swal-btn-delete', // Apply your custom class to the confirm button
        cancelButtonText: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
      },
    });

    if (result.isConfirmed) {
      try {
        const token = cookies.token;

        const response = await axios.delete(`${apiUrlServer}groups/${groupId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept-Language': language,
          },
        });

        if (response.data.status) {
          Swal.fire({
            title: `${t('Group Deleted Successfully')}`,
            icon: 'success',
            confirmButtonText: t('OK'), // Translate OK button
          });
          onDeleteGroupSuccess();
          fetchData();
        } else {
          Swal.fire({
            title: t('Error'), // Translate Error
            text: t('Failed to delete group. Please try again.'), // Translate error message
            icon: 'error',
            confirmButtonText: t('OK'), // Translate OK button
          });
        }
      } catch (error) {
        console.error('Error deleting group:', error.message);
        Swal.fire({
          title: t('Error'), // Translate Error
          text: t('Failed to delete group. Please try again.'), // Translate error message
          icon: 'error',
          confirmButtonText: t('OK'), // Translate OK button
        });
      }
    }
  };

  return (
    <>
      <button className={`btn ${styles['delete-group-btn']}`} onClick={handleDeleteClick}>
        <div className="d-flex justify-content-center align-items-center gap-2 actions-ar">
          <div className={`${styles['icon-delete-box']}  `}>
            <FaRegTrashAlt className={`${styles['icon-delete']}`} />
          </div>
          <span className='m-0'>{t('Delete')}</span>
        </div>
      </button>
    </>
  );
};

export default DeleteGroup;