  import React from 'react'
  import Swal from 'sweetalert2'
  import iconDelete from '../../../../../assets/icons/deleteIcon.png'
  import axios from 'axios'
  import {apiUrlServer, deletePortfolioStock} from '../../../../../apiConfig'
  import { useCookies } from 'react-cookie'
  import styles from '../../../Portfolios.module.css';
  import { FaRegTrashAlt } from 'react-icons/fa'
  import iconSuccess from '../../../../../assets/icons/suceessAdd.png'

  const DeleteStocksPortfolio = ({portfolioId, onDeletePortfolioSuccess, fetchData }) => {
    const [cookies] = useCookies(['token']);

    const handleDeleteClick = async () => {
      // Display a confirmation dialog using SweetAlert2
      const result = await Swal.fire({
        title: 'Portfolio Stock Delete',
        text: 'Are you sure you want to Delete Stock Name ?',
        iconHtml: `<img src="${iconDelete}" alt="Success Icon" class="" />`,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Back to Portfolio List',
        customClass: {
          confirmButton: 'swal-btn-delete', // Apply your custom class to the confirm button
          cancelButtonText: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
        },
      });

      if (result.isConfirmed) {
        try {
          const token = cookies.token;

          const response = await axios.delete(`${apiUrlServer}${deletePortfolioStock}/${portfolioId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.data.status) {
            Swal.fire({
              title: 'Stock Deleted Successfully',
              iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
              confirmButtonText: 'OK',
            });
            onDeletePortfolioSuccess();
            fetchData();
          } else {
            Swal.fire({
              title: 'Error',
              text: 'Failed to delete group. Please try again.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error deleting group:', error.message);
          // Swal.fire({
          //   title: 'Error',
          //   text: 'Failed to delete group. Please try again.',
          //   icon: 'error',
          //   confirmButtonText: 'OK',
          // });
        }
      }
    };

    return (
      <>
        <button className={`btn ${styles['delete-group-btn']}`} onClick={handleDeleteClick}>
          <div className="d-flex justify-content-center align-items-center">
            <div className={`${styles['icon-delete-box']}  `}>
              <FaRegTrashAlt className={`${styles['icon-delete']}`} />
            </div>
            {/*<span>Delete</span>*/}
          </div>
        </button>
      </>
    );
  };

  export default DeleteStocksPortfolio;