// WatchListAddStocksContext.js
import React, { createContext, useContext, useState } from 'react';

const WatchListAddStocksContext = createContext();

export const WatchListAddStocksProvider = ({ children }) => {
    const [watchListStocks, setWatchListStocks] = useState([]);

    return (
        <WatchListAddStocksContext.Provider value={{ watchListStocks, setWatchListStocks }}>
            {children}
        </WatchListAddStocksContext.Provider>
    );
};

export const useWatchListAddStocks = () => useContext(WatchListAddStocksContext);