import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, CloseButton } from 'react-bootstrap';
import CommonBanner from '../CommonBanner/CommonBanner';
import styles from './StockScreener.module.css';
import FilterModal from './FilterModal';
import { DataGrid ,gridClasses,GridToolbar} from '@mui/x-data-grid';
import {Paper} from "@mui/material";
import { Link } from 'react-router-dom';
import useCustomTranslation from '../../Hooks/useTranslation';
import { abbreviateNumber } from '../../Hooks/useNumberFormatting';
import {ShimmerTable} from "react-shimmer-effects";
import SaveCriteriaModal from './SaveCriteriaModal';
import toast from 'react-hot-toast';
import { useUserProfile } from '../../Context/UserContext';



function StockScreener() {
  const [stockScreenerData, setStockScreenerData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveCriteriaModalOpen, setIsSaveCriteriaModalOpen] = useState(false);
  const {t} = useCustomTranslation();
  const [savedScreeners, setSavedScreeners] = useState([]);
  const [selectedSavedScreener, setSelectedSavedScreener] = useState(null);
  const { userProfile } = useUserProfile();

  const handleSaveCriteria = async (saveData) => {
    const criteriaData = selectedFilters.map(filter => ({
      section: filter.section,
      key: filter.slug,
      operator: filter.operator,
      mathematical: filter.mathematical,
      values: filter.values.filter(v => v !== '')
    }));

    const dataToSave = {
      ...saveData,
      user_id: userProfile.id,
      criteria: criteriaData
    };

    try {
      const response = await axios.post('https://k-wealth-scanner-f75248ff38b6.herokuapp.com/screener/saved-screener/add', dataToSave);
      setSavedScreeners(prev => [...prev, response.data]);

      toast.success('Your Filters Saved successfully');
      // You might want to show a success message here
    } catch (error) {
      toast.error('Failed to save filters');
      // You might want to show an error message here
    }
  };

  async function fetchStockScreenerData() {
    const response = await axios.get(`https://k-wealth-scanner-f75248ff38b6.herokuapp.com/filters-by-sections`);
    setStockScreenerData(response.data);
  }

  async function fetchSavedScreeners() {
    try {
      const response = await axios.get('https://k-wealth-scanner-f75248ff38b6.herokuapp.com/screener/saved-screener',{
        params:{
          user_id:userProfile.id
        }
      });
      setSavedScreeners(response.data);
    } catch (error) {
      console.error('Error fetching saved screeners:', error);
    }
  }

  const handleSavedScreenerSelect = (event) => {
    setSelectedFilters([]);
    const selectedId = parseInt(event.target.value);
    const selected = savedScreeners.find(screener => screener.id === selectedId);
    setSelectedSavedScreener(selected);

    if (selected) {
      const parsedCriteria = JSON.parse(selected.criteria);
      const newSelectedFilters = parsedCriteria.map((criterion,index) => {
        return {
          id:index,
          name:criterion.key,
          operator: criterion.operator,
          values: criterion.values.length > 0 ? criterion.values : (criterion.operator === 'between' ? ['',''] : ['']),
          section: criterion.section,
          mathematical: criterion.mathematical,
          slug: criterion.key,
        };
      });
      console.log(newSelectedFilters);
      setSelectedFilters(newSelectedFilters);
    }
  };

  useEffect(() => {
    fetchStockScreenerData();
    fetchSavedScreeners();
  }, []);

  const handleFilterSelect = (filter) => {
    setSelectedFilters(prev => {
      const filterIndex = prev.findIndex(f => f.slug === filter.slug);
      if (filterIndex !== -1) {
        // Remove the filter if it's already selected
        return prev.filter(f => f.slug !== filter.slug);
      } else {
        // Add the filter if it's not already selected
        return [...prev, { ...filter, operator: '=', values: [''] }];
      }
    });
  };

  const handleFilterRemove = (filter) => {
    setSelectedFilters(prev => prev.filter(f => f.slug !== filter.slug));
  };

  const handleOperatorChange = (filterSlug, operator) => {
    setSelectedFilters(prev => prev.map(f => 
      f.slug === filterSlug ? { ...f, operator, values: operator === 'between' ? ['', ''] : [''] } : f
    ));
  };

  const handleValueChange = (filterSlug, index, value) => {
    setSelectedFilters(prev => prev.map(f => 
      f.slug === filterSlug ? { ...f, values: f.values.map((v, i) => i === index ? value : v) } : f
    ));
  };

  function convertJsonInObject(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'string') {
          try {
            // Attempt to parse the string as JSON
            const parsed = JSON.parse(obj[key]);
            // If successful, replace the string with the parsed object
            obj[key] = parsed;
          } catch (e) {
            // If parsing fails, it's not valid JSON, so we leave it as is
          }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          // If it's an object (but not null), recursively process it
          convertJsonInObject(obj[key]);
        }
      }
    }
    return obj;
  }

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const filterData = selectedFilters.map(filter => ({
      section: filter.section,
      key: filter.slug,
      operator: filter.operator,
      values: filter.values.filter(v => v !== '')
    }));

    try {
      const response = await axios.post('https://k-wealth-scanner-f75248ff38b6.herokuapp.com/screener/search', filterData);
      let adjustRespone = convertJsonInObject(response.data)
      setIsLoading(false);
      setSearchResults(adjustRespone);
    } catch (error) {
      console.error('Error submitting search:', error);
    }
  };


  const columns = [
    {           
      field: 'code',
      headerName: t('Code'),
      width: 80,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
          <Link style={{
              textDecoration: 'none',
              color: '#451952'
          }}
                to={`/stocks/${params.row.id}/${params.row.code}`}>
              {params.value}
          </Link>
      ),
      renderHeader: () => <strong>{t('Code')}</strong>
    },
    {
      field: 'name',
      headerName: t('Name'),
      width: 200,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
          <Link style={{
              textDecoration: 'none',
              color: '#451952'
          }}
                to={`/stocks/${params.row.id}/${params.row.code}`}>
              {params.value.en}
          </Link>
      ),
      renderHeader: () => <strong>{t('Name')}</strong>
    },
    {
      field: 'lastPrice',
      headerName: t('Last Trade Price'),
      width: 150,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{t('Last Trade Price')}</strong>,
      renderCell: (params) => (
        <div>
          {abbreviateNumber(params.value)}
        </div>
      )
    },
    {
      field: 'change',
      headerName: t('Change'),
      width: 80,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{t('Change')}</strong>,
      renderCell: (params) => {
          const value = params.value;
          let color = 'black';
          if (value > 0) color = 'green';
          if (value < 0) color = 'red';
          return (
              <div style={{color}}>
                  {value && Number(value)?.toFixed(2)}
              </div>
          );
      }
    },
    {
      field: 'changePercent',
      headerName: t('Change %'),
      width: 80,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{t('Change %')}</strong>,
      renderCell: (params) => {
          const value = params.value;
          let color = 'black';
          let displayValue = '-';

          if (value !== '-') {
              const numericValue = parseFloat(value);
              if (numericValue < 0) color = 'red';
              else if (numericValue > 0) color = 'green';
              // If numericValue is exactly 0, color remains black

              // Format the value with 2 decimal places and add the % symbol
              displayValue = `${numericValue.toFixed(2)}%`;
          }

          return (
              <div style={{color}}>
                  {displayValue}
              </div>
          );
      }
    },
    {
      field: 'open',
      headerName: t('Open'),
      width: 80,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{t('Open')}</strong>
    },
    {
        field: 'close',
        headerName: t('Close'),
        width: 80,
        // resizable: true,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => <strong>{t('Close')}</strong>
    },
    {
      field: 'high',
      headerName: t('High'),
      width: 80,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{t('High')}</strong>
    },
    {
        field: 'low',
        headerName: t('Low'),
        width: 80,
        // resizable: true,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => <strong>{t('Low')}</strong>
    },
    {
      field: 'volumeTotal',
      headerName: t('Volume Total'),
      width: 150,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div>
          {abbreviateNumber(params.value)}
        </div>
      ),
      renderHeader: () => <strong>{t('Volume Total')}</strong>
    },
    {
      field: 'timestamp',
      headerName: t('Last Update'),
      width: 200,
      // resizable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{t('Last Update')}</strong>
    }
  ];


  const prepareRowData = (data) => {
    return data.map(item => ({
      ...item,
      id: item.id, // Ensure each row has a unique id
      lastPrice: item.api?.close || 'N/A',
      change: item.api?.change || 'N/A',
      changePercent: item.api?.change_p || 'N/A',
      volumeTotal: item.api?.volume || 'N/A',
      open: item.api?.open || 'N/A',
      close: item.api?.close || 'N/A',
      high: item.api?.high || 'N/A',
      low: item.api?.low || 'N/A',
      timestamp:item.api?.timestamp ? new Date(Number(item.api.timestamp)).toLocaleString() : '-'
    }));
  };

  return (
    <div>
      <CommonBanner title="Stock Screener" />
      <div className={`${styles['stockScreener--container']}`}>
        <Form className={styles.filterForm} onSubmit={handleSubmit}>
        {savedScreeners.length > 0 && (
            <Form.Group className="mb-3">
              <Form.Label>Load Saved Screener</Form.Label>
              <Form.Select onChange={handleSavedScreenerSelect}>
                <option value="">Select a saved screener</option>
                {savedScreeners.map(screener => (
                  <option key={screener.id} value={screener.id}>{screener.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          <div className='d-flex gap-1 flex-column w-full'>
            {selectedFilters.map(filter => (
              <div key={filter.id} className={styles.formGroupContainer}>
                <div className={styles.formGroup}>
                  <Form.Label>
                    {filter.name}
                  </Form.Label>
                  <div className={styles.inputWrapper}>
                    {filter.mathematical == 1? (
                        <Form.Select 
                          className={styles.operatorSelect}
                          value={filter.operator}
                          onChange={(e) => handleOperatorChange(filter.slug, e.target.value)}
                        >
                        <option value="=">equals</option>
                        <option value="between">between</option>
                        <option value="<">less than</option>
                        <option value="<=">less than or equal to</option>
                        <option value=">">greater than</option>
                        <option value=">=">greater than or equal to</option>
                      </Form.Select>
                    ):''}
                    <Form.Control 
                      type="text" 
                      value={filter.values[0]}
                      onChange={(e) => handleValueChange(filter.slug, 0, e.target.value)}
                    />
                    {filter.operator === 'between' && (
                      <Form.Control 
                        type="text" 
                        value={filter.values[1]}
                        onChange={(e) => handleValueChange(filter.slug, 1, e.target.value)}
                      />
                    )}
                  </div>
                  <CloseButton 
                    className={styles.removeButton} 
                    onClick={() => handleFilterRemove(filter)}
                  />
                </div>
              </div>
            ))}
          </div>
          {selectedFilters.length > 0 ? (
            <>
              <Button 
                className={`${styles.selectFiltersButton}`} 
                onClick={() => setIsModalOpen(true)}
                type="button"
              >
                <svg className="" width="18" height="18" viewBox="0 0 48 48" data-icon="new">
                  <path d="M41.253 22.784h-14v-14c0-1.104-.896-2-2-2s-2 .896-2 2v14h-14c-1.104 0-2 .896-2 2s.896 2 2 2h14v14c0 1.104.896 2 2 2s2-.896 2-2v-14h14c1.104 0 2-.896 2-2s-.896-2-2-2z"></path>
                </svg>
                Add another filter
              </Button>

              <div className={styles.buttonContainer}>
                <Button 
                  type="submit" 
                  className={styles.searchButton}
                >
                  Find Stocks
                </Button>
                {selectedFilters.length > 0 && (
                <Button 
                  className={`${styles.saveCriteriaButton}`} 
                  onClick={() => setIsSaveCriteriaModalOpen(true)}
                  type="button"
                >
                  Save Filters
                </Button>
              )}
              </div>
            </>
          ) : (
            <Button 
              className={`${styles.noFiltersButton}`} 
              onClick={() => setIsModalOpen(true)}
              type="button"
            >
              No filters selected. Add a few filters to get results
            </Button>
          )}
        </Form>

        <FilterModal 
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          filters={stockScreenerData}
          selectedFilters={selectedFilters}
          onFilterSelect={handleFilterSelect}
        />
        <SaveCriteriaModal
          isOpen={isSaveCriteriaModalOpen}
          onClose={() => setIsSaveCriteriaModalOpen(false)}
          onSave={handleSaveCriteria}
          criteria={selectedFilters}
        />

        {selectedFilters.length > 0 && (
          <div className={styles.searchResults}>
            <h3>Search Results</h3>
            {isLoading ? (
                <ShimmerTable/>
            ) : (
              searchResults && (
                <Paper style={{ width: '100%'}}>
                  <DataGrid
                    sx={{
                      [`.${gridClasses.columnHeaders}`]: {
                          position: 'sticky',
                          top: 0,
                          zIndex: 1,
                          backgroundColor: 'var(--natural-color)',
                          color: 'var(--secondary-color-1)',
                      },
                      [`.${gridClasses.virtualScroller}`]: {
                          height: 'calc(100vh - 200px)',
                          overflowY: 'auto !important',
                      },
                    }}
                    rows={searchResults && prepareRowData(searchResults)}
                    columns={columns}
                    paginationMode={false}
                    // autoPageSize={true}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    columnBuffer={2}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    hideFooterPagination
                    autoHeight={false}
                    hideFooter
                    rowHeight={52}
                  />
                </Paper>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default StockScreener;