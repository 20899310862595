import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { apiUrlServer } from "../../apiConfig";
import useCustomTranslation from "../../Hooks/useTranslation";
import { useLanguage } from "../../Context/LanguageContext";
import './Reports.css';
import report_default from '../../assets/images/report_default.png';
import {ShimmerCard} from "react-shimmer-effects";

const fetchReportPages = async (id, language, token) => {
    try {
        const response = await axios.get(`${apiUrlServer}report_page?filter[report_category_id]=${id}`, {
            headers: {
                'Accept-Language': language,
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.data.status) {
            return response.data.data;
        } else {
            throw new Error('Failed to fetch report pages');
        }
    } catch (error) {
        console.error('Error fetching report pages:', error);
        throw error;
    }
};

const SingleReport = () => {
    const { id } = useParams();
    const { t } = useCustomTranslation();
    const { language } = useLanguage();
    const [cookies] = useCookies(['token']);

    const { data: reportPages = [], error, isLoading } = useQuery({
        queryKey: ['reportPages', id, language],
        queryFn: () => fetchReportPages(id, language, cookies.token),
        staleTime: 15 * 60 * 1000,
        refetchInterval: 15 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    const reportCategory = reportPages.length > 0 ? reportPages[0].report_category : null;

    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    const LoadingShimmer = () => (
        <div className="container">
            <div className="row">
                {[...Array(3)].map((_, index) => (
                    <div key={index} className="col-md-12 mb-4">
                        <ShimmerCard height={400}>
                            <div style={{ height: '200px' }}></div>
                            <div style={{ padding: '20px' }}>
                                <ShimmerCard height={30} className="mb-3" />
                                <ShimmerCard height={100} className="mb-3" />
                                <ShimmerCard width={120} height={40} />
                            </div>
                        </ShimmerCard>
                    </div>
                ))}
            </div>
        </div>
    );

    if (isLoading) {
        return <div><LoadingShimmer /></div>;
    }

    if (error) {
        return <div>{t('Error fetching report pages')}: {error.message}</div>;
    }

    return (
        <div className={`row ${language === 'ar' ? 'rtl' : 'ltr'}`}>
            <div className="col-lg-12 col-sm-12 col-md-12">
                {reportCategory && (
                    <div className="report-category-info mt-3">
                        <h3 className="tasi_summery my-3 head-report">{reportCategory.name}</h3>
                    </div>
                )}
            </div>
            {reportPages.map((report) => (
                <div className="col-lg-3 col-md-6 col-sm-12" key={report.id}>
                    <div className="card card-report">
                        <img src={report_default} className="card-img-top" alt="logo" />
                        <div className="card-body">
                            <h2 className="card-title">{truncateText(report.name, 10)}</h2>

                            <p className="card-text"
                               dangerouslySetInnerHTML={{__html: truncateText(report.description, 30)}}>
                            </p>
                            <div className="d-flex align-items-center justify-content-between">
                                <Link to={`/report/${report.id}`} className="btn btn-read-more">{t('Read More')}</Link>
                                {report.media && report.media.length > 0 && (
                                    <a href={report.media[0].original_url} target="_blank"
                                       rel="noopener noreferrer" className="btn btn-view-pdf">{t('View PDF')}</a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SingleReport;