import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {apiUrlServer} from "../apiConfig";
import {useLanguage} from "../Context/LanguageContext";
import useCustomTranslation from "../Hooks/useTranslation";
import CommonBanner from "../components/CommonBanner/CommonBanner";
import kwhiteLogo from '../assets/images/logo_white main copy.webp'

const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
};

const NewsDetails = () => {
    const {id} = useParams();
    const {language} = useLanguage();
    const { t } = useCustomTranslation();
    const [news, setNews] = useState(null);

    const fetchSingleNews = async () => {
        try {
            const response = await axios.get(`${apiUrlServer}news/${id}`, {
                headers: {
                    'Accept-Language': language,
                }
            });
            setNews(response.data.data);
            console.log("testnews", response);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchSingleNews();
    }, []);

    if (!news) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="row">

                <div className="col-lg-6 col-sm-12">
                    <div className=''>
                        <CommonBanner
                            title={news.title}
                        />
                        <div className="news-list card mb-3  border-0">
                            <div className='card-body'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="title-head">{news.title}</h2>
                                </div>
                                <div className="d-flex align-items-center justify-content-start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                         viewBox="0 0 16 17" fill="none">
                                        <g clip-path="url(#clip0_3390_76579)">
                                            <path
                                                d="M14.125 1.97656H13.375V0.726562H12.125V1.97656H3.875V0.726562H2.625V1.97656H1.875C0.841125 1.97656 0 2.81769 0 3.85156V14.8516C0 15.8854 0.841125 16.7266 1.875 16.7266H14.125C15.1589 16.7266 16 15.8854 16 14.8516V3.85156C16 2.81769 15.1589 1.97656 14.125 1.97656ZM14.75 14.8516C14.75 15.1962 14.4696 15.4766 14.125 15.4766H1.875C1.53038 15.4766 1.25 15.1962 1.25 14.8516V6.60156H14.75V14.8516ZM14.75 5.35156H1.25V3.85156C1.25 3.50694 1.53038 3.22656 1.875 3.22656H2.625V4.47656H3.875V3.22656H12.125V4.47656H13.375V3.22656H14.125C14.4696 3.22656 14.75 3.50694 14.75 3.85156V5.35156Z"
                                                fill="#1D1A39"/>
                                            <path
                                                d="M2.375 7.91406H3.625V9.16406H2.375V7.91406ZM4.875 7.91406H6.125V9.16406H4.875V7.91406ZM7.375 7.91406H8.625V9.16406H7.375V7.91406ZM9.875 7.91406H11.125V9.16406H9.875V7.91406ZM12.375 7.91406H13.625V9.16406H12.375V7.91406ZM2.375 10.4141H3.625V11.6641H2.375V10.4141ZM4.875 10.4141H6.125V11.6641H4.875V10.4141ZM7.375 10.4141H8.625V11.6641H7.375V10.4141ZM9.875 10.4141H11.125V11.6641H9.875V10.4141ZM2.375 12.9141H3.625V14.1641H2.375V12.9141ZM4.875 12.9141H6.125V14.1641H4.875V12.9141ZM7.375 12.9141H8.625V14.1641H7.375V12.9141ZM9.875 12.9141H11.125V14.1641H9.875V12.9141ZM12.375 10.4141H13.625V11.6641H12.375V10.4141Z"
                                                fill="#1D1A39"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3390_76579">
                                                <rect width="16" height="16" fill="white"
                                                      transform="translate(0 0.726562)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span className="date-news">{news.date}</span>
                                </div>
                                <p className="text-news" style={{margin: "16px 0"}}>
                                    {news.content}
                                </p>
                                <div className="card-insights-new">
                                    {((news.symbols && news.symbols.length > 0) || (news.tags && news.tags.length > 0) || (news.sentiment)) && (
                                        <div className="d-flex flex-column justify-content-between my-3 mb-0 gap-2">
                                            {news.symbols && news.symbols.length > 0 && (
                                                <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">
                                                    {t('Symbols')}:
                                                    <div className='d-flex gap-2 flex-wrap'>
                                                        {news.symbols.map((symbol, index) => (
                                                            <span key={index} className="symbol-item">{symbol}</span>
                                                        ))}
                                                    </div>
                                                </p>
                                            )}

                                            {news.tags && news.tags.length > 0 && (
                                                <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">
                                                    {t('Tags')}:
                                                    <div className='d-flex gap-2 flex-wrap'>
                                                        {news.tags.map((tag, index) => (
                                                            <span key={index} className="tags-item">{tag}</span>
                                                        ))}
                                                    </div>
                                                </p>
                                            )}

                                            {news.sentiment && (
                                                <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">
                                                    {t('Sentiment')}:
                                                    <div className='d-flex gap-2 flex-wrap'>
                                                    <span
                                                        className="sentiment-item">Neg: {news.sentiment.neg ?? 0}</span>
                                                        <span
                                                            className="sentiment-item">Neu: {news.sentiment.neu ?? 0}</span>
                                                        <span
                                                            className="sentiment-item">Pos: {news.sentiment.pos ?? 0}</span>
                                                        <span
                                                            className="sentiment-item">Polarity: {news.sentiment.polarity ?? 0}</span>
                                                    </div>
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>


                                <div className="d-flex align-items-center gap-3 mt-4 justify-content-end">
                                    <a className="btn-open-link btn-view-link" target="_blank" rel="noopener noreferrer"
                                       href={news.link}>
                                        {t('View Link')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-center justify-content-center">
                   <img src={kwhiteLogo} className="w-50"/>
                </div>
            </div>
        </>
    );
};

export default NewsDetails;