import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLanguage} from "../../Context/LanguageContext";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import {Helmet} from 'react-helmet';
import DeatialsBanner from '../Indices/DeatialsBanner/DeatialsBanner';
import StockTabDetails from "./StockTabDetails/StockTabDetails";
import useStockFundamentals from "../../Hooks/useStockFundamentals";
import useCompanyLiveData from "../../Hooks/useCompanyLiveData";
import useCustomTranslation from "../../Hooks/useTranslation";
import {apiUrlServer, getAllStocks, WEB_SOCKET_URL} from '../../apiConfig';
import {ShimmerFeaturedGallery, ShimmerSeparator} from "react-shimmer-effects";
import StockInformation from "./GenralInformation/StockInformation";
import './StockDetails.css';
import {useCookies} from "react-cookie";
import Datafeed from "../../datafeed";
import BannerStocksDetails from "./BannerStocks/BannerStocksDetails";
import useIndicator from "../../Hooks/useIndicator";
import {abbreviateNumber} from "../../Hooks/useNumberFormatting";
import DelayedPriceMessage from "../DisplayMessages/DelayedPriceMessage";
import useWebSocket from "../../Hooks/useWebSocket";
import KeyState from "./KeyState/KeyState";

let STOCK_ID = [];

const StockDetails = () => {
    const { t } = useCustomTranslation();
    const { id, code } = useParams();
    const [searchParams] = useSearchParams();
    const { stockDataFundamentals, isLoading: isLoadingFundamentals } = useStockFundamentals(code);
    const { companyLiveDate, isLoading: isLoadingCompanyData } = useCompanyLiveData(id);
    const [stockDataFundamentalsSingle, setStockDataFundamentalsSingle] = useState(null);
    const [loading, setLoading] = useState(true);
    const { language } = useLanguage();
    const { validIndecators, indicator } = useIndicator();
    const chartContainerRef = useRef(null);
    const [cookies] = useCookies(['token']);
    const buttonContainerRef = useRef(null);
    const [resolution, setResolution] = useState('1D');
    const [error, setError] = useState(null);
    // const [stockDataTest, setStockDataTest] = useState(null);
    const {setStockIdCode,responseData} = useWebSocket(WEB_SOCKET_URL, STOCK_ID);

    const fetchStockINCCode = async () => {
        try {
            const limit = 300;
            const response = await axios.get(`${apiUrlServer}limitedStock?limit=${limit}`);
            console.log('Full API response:', response);

            if (response.data && Array.isArray(response.data.data)) {
                return response.data.data;
            } else {
                console.error('Unexpected API response structure:', response.data);
                throw new Error('Unexpected API response structure');
            }
        } catch (error) {
            console.error('Error fetching stock data:', error);
            throw error;
        }
    };

    const fetchStockData = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrlServer}${getAllStocks}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`
                }
            });
            const apiData = response.data.data.api;
            console.log(apiData, 'oo');
            setStockDataFundamentalsSingle(apiData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching stock data:', error);
            setLoading(false);
        }
    }, [id, cookies.token]);
    useEffect(() => {
        const loadStockData = async () => {
            try {
                const allStocks = await fetchStockINCCode();
                console.log('Fetched stocks:', allStocks);

                if (!Array.isArray(allStocks)) {
                    console.error('Fetched data is not an array:', allStocks);
                    setError('Invalid data format received from server');
                    return;
                }
                const matchedStock = allStocks.find(stock =>
                    stock.id.toString() === id && stock.code === code
                );

                if (matchedStock) {
                    setStockIdCode([matchedStock.inis_bc]);
                    STOCK_ID = [matchedStock.inis_bc];
                    // setStockDataTest(matchedStock);
                } else {
                    console.log('No matching stock found for id:', id, 'and code:', code);
                    setError('No matching stock found');
                }
            } catch (error) {
                console.error('Failed to load stock data:', error);
                setError('Failed to load stock data: ' + error.message);
            }
        };
        fetchStockData();
        loadStockData();
    }, [id, code]);
    const navigate = useNavigate();

    const loadChartingLibrary = useCallback(async () => {
        try {
            const loadScript = (src) => {
                return new Promise((resolve, reject) => {
                    const script = document.createElement('script');
                    script.src = src;
                    script.onload = resolve;
                    script.onerror = reject;
                    document.body.appendChild(script);
                });
            };

            await loadScript('/charting_library/charting_library.standalone.js');
            await loadScript('/datafeeds/udf/dist/bundle.js');
            await loadScript('https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.5.4/socket.io.js');

            if (window.TradingView && chartContainerRef.current) {
                const language = sessionStorage.getItem('language') || 'en';
                const widget = new window.TradingView.widget({
                    container: chartContainerRef.current,
                    locale: language === 'ar' ? 'ar' : 'en',
                    library_path: '/charting_library/',
                    datafeed: Datafeed,
                    symbol: code,
                    ticker: code,
                    interval: resolution,
                    fullscreen: true,
                    load_last_chart: true,
                    time_frames: [
                        { text: "1D", resolution: "5", description: "1D", title: "1D" },
                        { text: "5D", resolution: "15", description: "5D", title: "5D" },
                        { text: "1M", resolution: "30", description: "1M", title: "1M" },
                        { text: "3M", resolution: "60", description: "3M", title: "3M" },
                        { text: "6M", resolution: "1D", description: "6M", title: "6M" },
                        { text: "1y", resolution: "1D", description: "1Y", title: "1Y" },
                        { text: "5y", resolution: "1D", description: "5Y", title: "5Y" },
                        { text: "1M", resolution: "1D", description: "All", title: "All" },
                    ],
                });

                const addIndicator = (indicatorName, indicatorOptions) => {
                    widget.activeChart().createStudy(indicatorName, false, false, indicatorOptions);
                };

                widget.onChartReady(() => {
                    widget.chart().onIntervalChanged().subscribe(null, (newInterval) => {
                        setResolution(newInterval);
                    });

                    // Add symbol change listener
                    widget.chart().onSymbolChanged().subscribe(null, (symbolInfo) => {
                        console.log('Symbol changed:', symbolInfo);
                        if (symbolInfo && symbolInfo.name) {
                            const stockCode = symbolInfo.name;
                            const stockId = symbolInfo.id
                            const newUrl = `/stocks/${stockId}/${stockCode}`;
                            navigate(newUrl);
                        }
                    });

                    validIndecators?.forEach((indicator) => {
                        indicator.forEach(ind => {
                            addIndicator(ind.indecator, ind.values);
                        });
                    });


                    const button = widget.createButton();
                    button.innerHTML = `${t('Full Width')}`;
                    button.classList.add('maximize-button');
                    button.addEventListener('click', () => {
                        const newTabUrl = `/stocksmax/${id}/${code}?indicators=${indicator}`;
                        window.open(newTabUrl, '_blank');
                    });
                    button.style.cursor = 'pointer';
                    buttonContainerRef.current.appendChild(button);
                });
            } else {
                console.error('TradingView or chart container not available');
            }
        } catch (error) {
            console.error('Error loading TradingView scripts', error);
        }
    }, [code, resolution, indicator, t, id]);

    useEffect(() => {
        loadChartingLibrary();
    }, [loadChartingLibrary]);

    const renderDetailsBanner = useMemo(() => {
        if (isLoadingCompanyData) return <ShimmerSeparator />;
        if (companyLiveDate.length === 0) return <div className="no_data_on_div">{t('No data available.')}</div>;

        return companyLiveDate.map((stock) => (
            <DeatialsBanner
                key={stock.id}
                name={stock?.name}
                AddStockToWatchList={() => {}}
                watchlisted={stock?.watchlisted}
            />
        ));
    }, [isLoadingCompanyData, companyLiveDate, t]);

    const renderKeyState = useMemo(() => {
        if (isLoadingCompanyData) return <ShimmerFeaturedGallery />;
        if (companyLiveDate.length === 0) return <div className="no_data_on_div">{t('No data available.')}</div>;

        return companyLiveDate.map((stock) => {
            const highDayStyle = stock.api?.high > 0 ? { color: 'green' } : { color: 'red' };

            return (
                <KeyState
                    key={stock.id}
                    openDay={stock.api?.open}
                    highDay={stock.api?.high}
                    lowDay={stock.api?.low}
                    close={stock.api?.close}
                    volume={stock.api?.volume}
                    WeekHigh={stockDataFundamentals.Technicals?.['52WeekHigh']}
                    WeekLow={stockDataFundamentals.Technicals?.['52WeekLow']}
                    Beta={stockDataFundamentals.Technicals?.['Beta']}
                    MarketCapitalizationMln={stockDataFundamentals.Highlights?.['MarketCapitalizationMln']}
                    DividendShare={stockDataFundamentals.Highlights?.['DividendShare']}
                    DividendYield={stockDataFundamentals.Highlights?.['DividendYield']}
                    EarningsShare={stockDataFundamentals.Highlights?.['EarningsShare']}
                    highDayStyle={highDayStyle}
                    CountryISO={stockDataFundamentals.General?.CountryISO}
                    CurrencyName={stockDataFundamentals.General?.CurrencyName}
                    CountryName={stockDataFundamentals.General?.CountryName}
                    Industry={stockDataFundamentals.General?.Industry}
                    Address={stockDataFundamentals.General?.Address}
                    Phone={stockDataFundamentals.General?.Phone}
                    WebURL={stockDataFundamentals.General?.WebURL}
                    FullTimeEmployees={stockDataFundamentals.General?.FullTimeEmployees}
                    UpdatedAt={stockDataFundamentals.General?.UpdatedAt}
                    PrimaryTicker={stockDataFundamentals.General?.PrimaryTicker}
                    FiscalYearEnd={stockDataFundamentals.General?.FiscalYearEnd}
                />
            );
        });
    }, [isLoadingCompanyData, companyLiveDate, stockDataFundamentals, t]);


    const renderBannerStocksDetails = useMemo(() => {
        if (isLoadingCompanyData) return <ShimmerSeparator />;
        if (companyLiveDate.length === 0) return <div className="no_data_on_div">{t('No data available.')}</div>;

        return companyLiveDate.map((stock) => {
            const close = responseData?.last?.value ?? stockDataFundamentalsSingle?.close;
            const lastClose = stockDataFundamentalsSingle?.previousClose;

            // Calculate change and changePercent
            const change = close !== '-' && lastClose !== '-' ? lastClose - close : '-';
            const changePercent = change !== '-' && lastClose !== '-' && lastClose !== 0
                ? ((change / lastClose) * 100)
                : '-';

            return (
                <BannerStocksDetails
                    key={stock.id}
                    timestamp={stockDataFundamentalsSingle?.timestamp}
                    open={stockDataFundamentalsSingle?.open}
                    close={close}
                    previousClose={lastClose}
                    change_p={changePercent !== '-' ? changePercent.toFixed(2) : '-'} // Use calculated changePercent
                    low={stockDataFundamentalsSingle?.low}
                    high={stockDataFundamentalsSingle?.high}
                    change={change !== '-' ? change.toFixed(2) : '-'} // Use calculated change
                    volume={stockDataFundamentalsSingle?.volume}
                    Code={stockDataFundamentals.General?.Code}
                    Type={stockDataFundamentals.General?.Type}
                    Names={stockDataFundamentals.General?.Name}
                    Exchange={stockDataFundamentals.General?.Exchange}
                    Currency={stockDataFundamentals.General?.Currency}
                    CurrencyCode={stockDataFundamentals.General?.CurrencyCode}
                    CurrencySymbol={stockDataFundamentals.General?.CurrencySymbol}
                    CountryISO={stockDataFundamentals.General?.CountryISO}
                    CurrencyName={stockDataFundamentals.General?.CurrencyName}
                    CountryName={stockDataFundamentals.General?.CountryName}
                    Industry={stockDataFundamentals.General?.Industry}
                    Address={stockDataFundamentals.General?.Address}
                    Phone={stockDataFundamentals.General?.Phone}
                    WebURL={stockDataFundamentals.General?.WebURL}
                    FullTimeEmployees={stockDataFundamentals.General?.FullTimeEmployees}
                    UpdatedAt={stockDataFundamentals.General?.UpdatedAt}
                    PrimaryTicker={stockDataFundamentals.General?.PrimaryTicker}
                    FiscalYearEnd={stockDataFundamentals.General?.FiscalYearEnd}
                    ISIN="-"
                    volumeTotal={abbreviateNumber(responseData?.volumeTotal?.value)}
                    turnoverTotal={abbreviateNumber(responseData?.turnoverTotal.value)}
                    marketCapitalisation={abbreviateNumber(responseData?.marketCapitalisation?.value)}
                    numberOfTrades={abbreviateNumber(responseData?.numberOfTrades?.value)}
                    closingAsk={abbreviateNumber(responseData?.bestBid?.value)}
                    closingBid={abbreviateNumber(responseData?.bestAsk?.value)}
                />
            );
        });
    }, [isLoadingCompanyData, companyLiveDate, stockDataFundamentalsSingle, stockDataFundamentals, responseData, t]);

    return (
        <>
            <Helmet>
                <title>{t('Stocks')}</title>
            </Helmet>
            {renderDetailsBanner}

            <div className="details-info">
                {renderBannerStocksDetails}
            </div>
            <div className="" style={{height: 'max-content'}}>
                <div className="row">
                    {/* <div className="col-lg-12">
                        <DelayedPriceMessage
                            messageKey="The stock prices displayed are not real-time and reflect market data from 15 minutes ago on 1D."/>

                    </div> */}
                    <div className="col-lg-9">
                        <div className="chart-details position-relative">
                            <div id="chartContainer" ref={chartContainerRef}
                                 style={{width: '100%', height: '100%'}}></div>
                            <div className="text-muted position-absolute"
                                 style={{textAlign: 'left', bottom: '0px', fontSize: '12px',}}>
                                {t("Powered by TradingView")}
                                <a href="https://www.tradingview.com/economic-calendar/"
                                   className="text-muted"> {t("how to use a stock screener")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 h-100">
                        <StockInformation Beta={stockDataFundamentals.Technicals?.['Beta']}
                                          price={stockDataFundamentalsSingle?.close}
                                          DividendShare={stockDataFundamentals.Highlights?.['DividendShare']}
                                          SharesFloat={stockDataFundamentals.SharesStats?.SharesFloat}
                                          MarketCapitalizationMln={stockDataFundamentals.Highlights?.['MarketCapitalizationMln']}
                                          WeekHigh={stockDataFundamentals.Technicals?.['52WeekHigh']}
                                          WeekLow={stockDataFundamentals.Technicals?.['52WeekLow']}
                                          DilutedEpsTTM={stockDataFundamentals.Highlights?.['DilutedEpsTTM']}
                                          EPSEstimateCurrentYear={stockDataFundamentals.Highlights?.['EPSEstimateCurrentYear']}
                        />
                    </div>
                </div>
            </div>
            <StockTabDetails
                stockDataFundamentals={stockDataFundamentals}
                companyLiveDate={companyLiveDate}
                isDataLoading={isLoadingFundamentals || isLoadingCompanyData}
                id={id}
            />
        </>
    );
};
export default StockDetails;
