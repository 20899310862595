import React from 'react'
import { Link } from 'react-router-dom'
import chatimg from '../../assets/icons/chatboticon.webp';
import nationalDayLogoChat from '../../assets/icons/gifday.webp';
import {useTheme} from "../../Context/ThemeContext";
const ChatBotIcon = ()=> {
    const {theme } = useTheme();
  return (
    <div className="chatboticon">
      <Link to={"/chatbot"} className={``}>
          <img className={`h-100 w-100 `} src={theme === 'default' ? chatimg : nationalDayLogoChat }
               alt="k wealth logo"
          />
      </Link>
    </div>
  )
}
export default ChatBotIcon

