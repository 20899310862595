// Card.js
import React from 'react';
import Slider from "react-slick";
import './new_home.css';
import styles from "../Indices/IndexLevel/IndexLevel.module.css";
import positiveChangeArrow from "../../assets/icons/FrameGreen.png";
import negativeChangeArrow from "../../assets/icons/FrameRed.png";
import useCustomTranslation from "../../Hooks/useTranslation";
import {Link} from "react-router-dom";
import {formatNumber, useFormattedPercentage} from "../../Hooks/useNumberFormatting";
const CardsStock = ({ data }) => {
    const { low, code, high, open, close, change, volume, change_p, gmtoffset, timestamp, previousClose } = data.api;
    const getChangeArrow = () => {
        if (change_p > 0) {
            return positiveChangeArrow; // Image for positive change
        } else {
            return negativeChangeArrow; // Image for non-positive change
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Riyadh', // Set your desired time zone
        };
        const timeString = date.toLocaleString('en-US', options);
        return `Last | ${timeString} CTT`;
    };
    const { t } = useCustomTranslation();

    const formattedChangeP = useFormattedPercentage(data.api.change_p);
    // Round the number to two decimal places
    const formattedClose = formatNumber(data.api.close);
    // const formattedOpen = formatNumber(props.open);
    const formattedChange = formatNumber(data.api.change);

        const truncateText = (text, maxLength) => {
            const words = text.split(' ');
            if (words.length > maxLength) {
                return words.slice(0, maxLength).join(' ') + '...';
            }
            return text;
        };

    return (
        <>
            <Link to={`/stocks/${data.id}/${data.code}`}>
            <div className="card-top-stocks">
                <div className="d-flex align-items-center justify-content-between my-2">
                    <span className="header-name-stock">{truncateText(data.name, 3)}</span>
                    <span className="header-code-stock">{data.code}.SR</span>
                </div>
                {/*<div className="d-flex align-items-center justify-content-between my-2">*/}
                {/*    <span className="properties_stock">{t('Close')}: {close}</span>*/}
                {/*    <span className="properties_stock">{t('Open')}: {open}</span>*/}
                {/*    <span className="properties_stock">{t('Low')}: <span className="low_stocks">{low}</span></span>*/}
                {/*    <span className="properties_stock">{t('High')}: <span className="high_stocks">{high}</span></span>*/}
                {/*</div>*/}
                <div className="d-flex align-items-center justify-content-between my-2">
                     <span className="d-flex align-items-center chnge_p_stock"
                           style={{color: change_p > 0 ? '#049C6B' : '#FD2523'}}>
                        <img className={`${styles['img-change']}`} src={getChangeArrow()} alt="Change Image"/>
                         {formattedChangeP}
                  </span>
                    <span className="header-name-stock" style={{color: change_p > 0 ? '#049C6B' : '#FD2523'}}>{formattedChange}</span>
                    <span className="header-name-stock">{formattedClose}</span>
                    {/*<span className="properties_stock"> {formatTimestamp(timestamp)}</span>*/}
                </div>
            </div>
            </Link>
        </>
    );
};

export default CardsStock;
