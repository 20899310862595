import React, {useEffect, useRef} from 'react';
import Datafeed from "../../datafeed";
import {useParams,useSearchParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import useCustomTranslation from "../../Hooks/useTranslation";
import CommonBanner from "../CommonBanner/CommonBanner";
import {useLanguage} from "../../Context/LanguageContext";
import useIndicator from '../../Hooks/useIndicator';

const StockMaxScreen = () => {
    const { t } = useCustomTranslation();

    const chartContainerRef = useRef(null);
    const [cookies] = useCookies(['token']);
    const { id, code } = useParams();
    const [searchParams] = useSearchParams();
    const indicator = searchParams.get('indicator');
    const indicatorArr = indicator?.split(',');
    const {language} = useLanguage();

    const {validIndecators} = useIndicator();

    const getCurrentLanguage = () => {
        return sessionStorage.getItem('language') || 'en';
    };
    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => {
                    // console.log(`Script loaded: ${src}`);
                    resolve();
                };
                script.onerror = (error) => {
                    console.error(`Error loading script: ${src}`, error);
                    reject(error);
                };
                document.body.appendChild(script);
            });
        };


        const loadChartingLibrary = async () => {
            try {
                await loadScript('/charting_library/charting_library.standalone.js');
                await loadScript('/datafeeds/udf/dist/bundle.js');

                if (window.TradingView && chartContainerRef.current) {
                    const language = getCurrentLanguage();
                    const widget = new window.TradingView.widget({
                        container: chartContainerRef.current,
                        locale: language === 'ar' ? 'ar' : 'en',
                        library_path: '/charting_library/',
                        datafeed: Datafeed,
                        symbol: `${code}`,
                        ticker: `${code}`,
                        interval: 'D',
                        fullscreen: true,
                    });

                    const addIndicator = (indicatorName, indicatorOptions) => {
                        widget.activeChart().createStudy(indicatorName, false, false, indicatorOptions);
                    };

                    widget.onChartReady(() => {
                        widget.chart().createStudy("Volume", true, false, undefined,  {
                            // Does not include the indicator's name
                            "volume.transparency": 50,

                        })
                        validIndecators?.forEach((indicator) => {
                            indicator.forEach(ind => {
                                addIndicator(ind.indecator,ind.values);
                            });
                        });
                    });


                } else {
                    console.error('TradingView or chart container not available');
                }
            } catch (error) {
                console.error('Error loading TradingView scripts', error);
            }
        };
        const setupChart = async () => {
            await loadChartingLibrary();
        };

        setupChart();

        // loadChartingLibrary();
    }, [ code, language]);
    return (
        <>
            <div id="chartMaxScreen" className="chart-details position-relative p-0">


                <div id="chartContainer" ref={chartContainerRef}
                     style={{width: '100%', height: '100vh'}}></div>
                <div className="text-muted "
                     style={{textAlign: 'left', bottom: '0px', fontSize: '12px',}}>
                    {t("Powered by TradingView")}<a href="https://www.tradingview.com/economic-calendar/"
                                                    className="text-muted"> {t("how to use a stock screener")}</a>
                </div>
            </div>
        </>
    );
};

export default StockMaxScreen;