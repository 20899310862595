import React, { useEffect, useState } from 'react';
import deleteIcon from '../../../assets/icons/delete.png';
import filterIcon from '../../../assets/icons/filter.png';

import styles from '../watchlist.module.css';
import axios from 'axios';
import {apiUrlServer, deleteGroupStock, getAllGroups} from '../../../apiConfig';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { BulletList } from 'react-content-loader';
import Swal from 'sweetalert2'
import iconSuccess from '../../../assets/icons/suceessAdd.png'
import useCustomTranslation from '../../../Hooks/useTranslation';
import {useLanguage} from "../../../Context/LanguageContext";

const FilterSidebar = () => {
  const {t} = useCustomTranslation();
  const { id } = useParams();
  const [cookies] = useCookies(['token']);
  const [isLoading, setIsLoading] = useState(true);
  const [stocks, setStocks] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [deleteStock, setDeleteStock] = useState({});
  const {language} = useLanguage();
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const token = cookies.token;

        const response = await axios.get(`${apiUrlServer}${getAllGroups}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept-Language': language,
          },
        });
        const result = response.data;
        if (result.status) {
          setStocks(result.data.stocks);
        } else {
          console.error('Error fetching prices details:', result.message);
        }
      } catch (err) {
        console.error('Error fetching watchlist details:', err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, cookies.token, language]);

  const handleCheckboxChange = (stockId) => {
    setSelectedStocks((prevSelectedStocks) => {
      if (prevSelectedStocks.includes(stockId)) {
        return prevSelectedStocks.filter((id) => id !== stockId);
      } else {
        return [...prevSelectedStocks, stockId];
      }
    });
  };

  const deleteSelectedStocks = async () => {
    try {
      const token = cookies.token;

      if (selectedStocks.length === 0) {
        // console.log('No stocks selected for deletion');
        return;
      }

      // Map the selected stock IDs to their corresponding reference_ids
      const referenceIds = stocks
        .filter((stock) => selectedStocks.includes(stock.id))
        .map((selectedStock) => selectedStock.reference_id);

      const response = await axios.delete(`${apiUrlServer}${deleteGroupStock}/${referenceIds}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept-Language': language,
        },
      });

      // Update the state (stocks) after successful deletion
      setStocks((prevStocks) => prevStocks.filter((stock) => !selectedStocks.includes(stock.id)));

      setDeleteStock(response.data);
      setIsLoading(false);
      // Show a Swal alert
      Swal.fire({
        iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
        title: `${t('Stocks Deleted')}`,
        text: `${t('Selected stocks have been successfully deleted.')}`,
        customClass: {
          confirmButton: 'swal-btn-confirmButton', // Apply your custom class to the confirm button
          cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
        },
      }).then(() => {
        // Refresh the page after the Swal alert is closed
        window.location.reload();
      });
    } catch (error) {
      console.error('Error deleting stocks:', error);
      setIsLoading(false);
    }
  };


  return (
    <>
      <div className={`${styles['filter-sidebar']}`}>
        <div className={`${styles['filter-sidebar-header']}  d-flex align-items-center justify-content-between`}>
          <span className={`${styles['filter-head']}`}>{t('Stock List')}</span>
          <div className="filter-head-action d-flex gap-2 ">
            <div className={`${styles['action-filter']} ${styles['delete-action']}`}>
              <button onClick={deleteSelectedStocks}>
                <img src={deleteIcon} alt="filter" />
              </button>
            </div>
            {/*<div className={`${styles['action-filter']} ${styles['filter-action']}`}>*/}
            {/*  <a href="#">*/}
            {/*    <img src={filterIcon} alt="filter" />*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="stocks-filter-list">
          {isLoading && <BulletList />}
          {!isLoading && (
            <ul className={`${styles['ul-stocks']}`}>
              {stocks.map((stock) => (
                <li key={stock.id}>
                  <div className="d-flex justify-content-between align-items-center">
                      <span className={`${styles['stock-filter-name']}`}>{stock.name}</span>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`flexCheckDefault_${stock.id}`}
                          onChange={() => handleCheckboxChange(stock.id)}
                        />
                      </div>
                    </div>
                    {stock.api ? (
                      <>

                      {/*  <div className="form-check">*/}
                      {/*  <input*/}
                      {/*    className="form-check-input"*/}
                      {/*    type="checkbox"*/}
                      {/*    value=""*/}
                      {/*    id={`flexCheckDefault_${stock.id}`}*/}
                      {/*    onChange={() => handleCheckboxChange(stock.id)}*/}
                      {/*  />*/}
                      {/*</div>*/}
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="text-secondary">{stock.api.volume}</span>
                          <span>
                                                      <span
                                                        className={`${styles['stock-filter-change']}`}
                                                        style={{ color: stock.api.change > 0 ? '#049C6B' : '#FD2523' }}
                                                      >
              ({stock.api.change})
            </span>
                          <span
                            className={`${styles['stock-filter-change']}`}
                            style={{ color: stock.api.change_p > 0 ? '#049C6B' : '#FD2523' }}
                          >
              ({stock.api.change_p})
            </span>
                          </span>

                        </div>

                      </>
                    ) : (
                      <span className="text-secondary">{t('API Data Unavailable')}</span>
                    )}

                </li>
              ))}
            </ul>

          )}
        </div>
      </div>
    </>
  );
};

export default FilterSidebar;
