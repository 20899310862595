import React, {useContext, useState} from 'react'
import registerImage from '../../../assets/images/Register-photo.png'
import { BsEnvelopeCheck, BsFillTelephoneFill, BsGlobe, BsLockFill, BsPersonFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import style from '../Auth.module.css'
import { useFormik } from 'formik'
import * as Yup from "yup";
import axios from 'axios'
import toast from 'react-hot-toast'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import defaultLogo from "../../../assets/images/logo_white-main.webp";
import nationalDayLogo from '../../../assets/images/K-Wealth-Saudi-national-Day Identity_Main-Logo.png';import {apiUrlServer} from '../../../apiConfig';
import OtpConfirmation from '../OtpConfirmation/OtpConfirmation'
import { Helmet } from 'react-helmet'
import Swal from "sweetalert2";
import useCustomTranslation from "../../../Hooks/useTranslation";
import {useLanguage} from "../../../Context/LanguageContext";
import {useTheme} from "../../../Context/ThemeContext"; // Import the API URL
const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpConfirmed, setIsOtpConfirmed] = useState(false);
  const navigate = useNavigate();
  const {language} = useLanguage();
  const { theme } = useTheme();



  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for(let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  //-----------------> handelRegister
  async function handelRegister(values) {
    setIsLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json', // Include this line for accepting JSON response
        'Accept-Language': language,
        'Content-Security-Policy': 'upgrade-insecure-requests',
      },
    };

    try {
      const { data } = await axios.post(`${apiUrlServer}customer/register`, values, config);

      if (data.message === "Data Retrieved Successfully") {
        sessionStorage.setItem('phone', values.phone);
        toast.success(`Your OTP is: ${data.data.otp} `, {
          position: "top-right",
          duration: 10000,
          className: "text-success px-5 fw-bolder my-3"
        });

        setMessageError("");
        setIsLoading(false);
        setIsOtpConfirmed(true);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Registration Error',
          text: data.message,
          confirmButtonText: 'OK'
        });
        setIsLoading(false);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'An error occurred. Please try again.';
      Swal.fire({
        icon: 'error',
        title: 'Welcom on K-wealth Website',
        text: errorMessage,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'swal-btn-confirmButton w-100', // Apply your custom class to the confirm button
          cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
        },
      });

      setIsLoading(false);
      setMessageError(errorMessage);
      // toast.error(errorMessage, {
      //   position: 'top-right',
      //   duration: 3000,
      //   className: "text-danger px-5 fw-bolder my-3"
      // });
    }
  }

  let validationSchema = Yup.object({
    name: Yup.string().required('Name Is Required').min(3,'Min Length must be greater than 3 letters').max(20,'Max Length must be less than 20 letters'),
    email: Yup.string().required('Email Is Required').matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Enter valid email'),
    password: Yup.string().min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]+$/,
        'Password must contain at least one letter, one number, and one special character'
      )
      .required('Password is required'),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null],'password not match').required('confirm Password is required'),
    phone: Yup.string()
      .matches(
        /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
        'Invalid ksa phone number'
      )
      .required('Phone number is required'),
    card_number: Yup.string().required('Card Number is required'),
    device_token: Yup.string().required('Device Token is required'),
  })
  const formik = useFormik({
    initialValues:{
      name:'',
      email:'',
      password:'',
      password_confirmation:'',
      phone:'',
      card_number:generateRandomString(16),
      device_token:generateRandomString(32),
    },
    validationSchema,
    onSubmit: handelRegister,

  })
const {t} = useCustomTranslation();

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>

              {isOtpConfirmed ? (
                <OtpConfirmation phoneNumber={formik.values.phone} />
              ) : (
                <div className="py-5 h-screen">
                  <div className="container">
                    <div className="row justify-content-center align-items-center  min-h-screen-authForm">
                      <div className={`col-xxl-4 col-lg-5 col-md-6 col-10 bg-white p-4 ${style.formContainer}`}>
                        <div className="text-center my-16">
                                                      <img
                                src={theme === 'default' ? defaultLogo : nationalDayLogo}
                                className=""
                                style={{width:"124px"}}
                                alt="k wealth logo"
                            />

                        </div>
                        <h2 className={`${style['head-form']}`}>{t('Sign Up')}</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <BsPersonFill className={`${style[ 'icon-input']} me-3`} />
                  <input
                    type="text"
                    className={`${style['input-custom']} form-control`}
                    placeholder={t('Your Name')}
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-3">
                  {formik.errors.name && formik.touched.name &&
                    <small className={`${style['text-error']}`}>
                      {formik.errors.name}
                    </small>}
                </div>

                <div className="d-flex align-items-center justify-content-center mb-3">
                  <BsEnvelopeCheck className={`${style[ 'icon-input']} me-3`} />
                  <input
                    type="email"
                    className={`${style['input-custom']} form-control`}
                    placeholder={t('Email')}
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-3">
                  {formik.errors.email && formik.touched.email &&
                    <small className={`${style['text-error']}`}>
                      {formik.errors.email}
                    </small>
                  }
                </div>

                <div className="d-flex align-items-center justify-content-center mb-3">
                  <BsLockFill className={`${style[ 'icon-input']} me-3`} />
                  <div className="w-100 position-relative">
                    <input
                      type={`${showPassword ? "text" : "password"}`}
                       className={`${style['input-custom']} form-control`}
                      placeholder={t('password')}
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div
                      className={`${style['show-password']} position-absolute top-50 end-0 translate-middle-y`}

                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEye data-bs-toggle="tooltip" data-bs-html="true" title="Hide password" />
                      ) : (
                        <AiOutlineEyeInvisible data-bs-toggle="tooltip" data-bs-html="true" title="Show password" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {formik.errors.password && formik.touched.password && (
                    <small className={`${style['text-error']}`}>
                      {formik.errors.password}
                    </small>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-center mb-3">
                  <BsLockFill className={`${style[ 'icon-input']} me-3`} />

                  <div className="w-100 position-relative">
                    <input
                      type={`${showPassword ? "text" : "password"}`}
                      className={`${style['input-custom']} form-control`}
                      placeholder={t('re password')}
                      id='password_confirmation'
                      name='password_confirmation'
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div
                      className={`${style['show-password']} position-absolute top-50 end-0 translate-middle-y `}

                      onClick={() => setShowPassword(!showPassword)}
                    >


                      {showPassword ? (
                        <AiOutlineEye data-bs-toggle="tooltip" data-bs-html="true" title="Hide password" />
                      ) : (
                        <AiOutlineEyeInvisible data-bs-toggle="tooltip" data-bs-html="true" title="Show password" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {formik.errors.password_confirmation && formik.touched.password_confirmation && (
                    <small className={`${style['text-error']}`}>
                      {formik.errors.password_confirmation}
                    </small>
                  )}
                </div>



                <div className="d-flex align-items-center justify-content-center mb-3">
                  <BsFillTelephoneFill className={`${style[ 'icon-input']} me-3`} />
                  <input
                    type="text"
                     className={`${style['input-custom']} form-control`}
                    placeholder={t('Phone')}
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="mb-3">
                  {formik.errors.phone && formik.touched.phone &&
                    <small className={`${style['text-error']}`}>
                      {formik.errors.phone}
                    </small>
                  }
                </div>



                <div className={`${style['btn-container']}`}>
                  {isLoading ? (
                    <button type="button"  className={`${style['btn-signup']} btn opacity-75 fw-bolder px-4 w-100`}>
                      <span  className="spinner-border spinner-border-sm " role="status" aria-hidden="false"></span>
                    </button>
                  ) : (
                    <button disabled={!formik.isValid} type="submit" className={`btn ${style['btn-signup']} `}>
                      {t('Sign Up')}
                    </button>
                  )}
                </div>



                <div className="d-flex mt-3 align-items-center justify-content-center gap-1">
                  <p className={`${style[ 'icon-input']} m-0 fw-600 text-natural-color-3`}>{t('Have an Account')}?</p>
                  <Link className={`${style[ 'icon-input']} m-0  fw-800`} to="/login">{t('Sign In')}</Link>
                </div>
              </form>
                </div>
                </div>
                </div>
                </div>
              )}
    </>
  )
}

export default Register