import React from 'react';
import styles from '../../Portfolio.module.css';
import { FaPen } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { apiUrlServer } from '../../../../apiConfig'
import iconEdit from '../../../../assets/icons/editIcon.png'
import iconSuccess from '../../../../assets/icons/suceessAdd.png'
import useCustomTranslation from "../../../../Hooks/useTranslation";
const EditPortfolio = ({ portfolioId, onEditPortfolioSuccess, fetchData }) => {
  const [cookies] = useCookies(['token']);
  const {t} = useCustomTranslation();
  const handleEditClick = async () => {
    const { value: name } = await Swal.fire({
      iconHtml: `<img src="${iconEdit}" alt="Success Icon" class="" />`,
      title: t("Edit Your Portfolio"),
      input: 'text',
      inputValue: '', // You can pre-fill the input field if needed
      showCancelButton: true,
      confirmButtonText: t('Save Changes'), // Translate button text
      cancelButtonText: t('Back to Portfolio List'), // Translate button text
      customClass: {
        confirmButton: 'swal-btn-confirmButton', // Apply your custom class to the confirm button
        cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
      },
      inputValidator: (value) => {
        if (!value) {
          return t('You need to enter a new name!'); // Translate validation message
        }
      },
      inputAttributes: {
        class: 'form-control',
        placeholder: t('New Portfolio Name'), // Translate placeholder
      },
    });

    if (name) {
      try {
        const token = cookies.token;
        const response = await axios.put(
          `${apiUrlServer}portfolio/${portfolioId}?name=${name}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.status) {
          await Swal.fire({
            title: t('Group Updated Successfully'), // Translate success title
            iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
            confirmButtonText: t('OK'), // Translate button text
          });
          onEditPortfolioSuccess();
          fetchData();
        } else {
          await Swal.fire({
            title: t('Error'), // Translate error title
            text: t('Failed to update group. Please try again.'), // Translate error text
            icon: 'error',
            confirmButtonText: t('OK'), // Translate button text
          });
        }
      } catch (error) {
        console.error('Error updating group:', error.message);
      }
    }
  };



  return (
    <>
      <button className={`btn ${styles['edit-group-btn']}`} onClick={handleEditClick}>
        <div className="d-flex justify-content-between align-items-center actions-ar">
          <div className={`${styles['icon-edit-box']}  `}>
            <FaPen className={`${styles['icon-edit']}`} />
          </div>
          <span>{t('Edit')}</span>
        </div>
      </button>

    </>
  );
};

export default EditPortfolio;
