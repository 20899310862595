import React, {useCallback, useEffect, useState} from 'react';
import {addStockAlert, addStockAlertTags, apiUrlServer, getAllStocks, getTags} from "../../../../apiConfig";
import axios from "axios";
import styles from "../../Price.module.css";
import { Modal } from "react-bootstrap";
import priceicon from "../../../../assets/icons/addprice.svg";
import Select from "react-select";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import { useFormik } from 'formik';
import { useCookies } from "react-cookie";
import { useLanguage } from "../../../../Context/LanguageContext";
import Swal from "sweetalert2";
import iconSuccess from "../../../../assets/icons/suceessAdd.png";
import iconError from "../../../../assets/icons/errorCancel.png";

const AddTagsPrice = ({ addPriceSuccess }) => {
    const [showModal, setShowModal] = useState(false);
    const [cookies] = useCookies(['token']);
    const [stocks, setStocks] = useState([]);
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [limit, setLimit] = useState(10);
    const [previousClose, setPreviousClose] = useState('');
    const { language } = useLanguage();
    const { t } = useCustomTranslation();

    const formik = useFormik({
        initialValues: {
            stock_id: '',
            tags: '',
            providers: [],
            is_sent: '1',
        },
        onSubmit: handleAddStock,
    });

    async function handleAddStock(values) {
        setIsLoading(true);
        try {
            const token = cookies.token;
            const requestData = {
                ...values,
                tags: JSON.stringify(values.tags.split(',')),
                providers: JSON.stringify(values.providers),
            };
            const response = await axios.post(`${apiUrlServer}${addStockAlertTags}`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept-Language': language,
                },
            });

            Swal.fire({
                title: t('Your Stock Added successfully'),
                iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
                text: t('Start Adding Stocks to your Alerts'),
                confirmButtonText: t('Back to Alerts'),
                cancelButtonText: t('Add Another'),
                showCancelButton: true,
                customClass: {
                    confirmButton: 'swal-btn-confirmButton',
                    cancelButton: 'swal-btn-cancelButton',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    addPriceSuccess(prev => [...prev, response.data.data]);
                    setShowModal(false);
                    resetForm();
                } else {
                    addPriceSuccess(prev => [...prev, response.data.data]);
                    setShowModal(true);
                    resetForm();
                }
            });
        } catch (error) {
            console.error('Server error:', error.response?.data);

            const responseMessage = error.response?.data?.message || "An error occurred";
            Swal.fire({
                title: 'Something went Wrong',
                text: responseMessage === "The stock id has already been taken." ? 'Please choose a different stock.' : 'Please, Check your Internet connection and try again',
                iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
                confirmButtonText: 'Back to Watchlist',
                customClass: {
                    confirmButton: 'swal-btn-cancelButton',
                },
            });
        } finally {
            setIsLoading(false);
        }
    }

    const resetForm = () => {
        formik.resetForm();
        setSelectedOption(null);
        setPreviousClose('');
    };

    const providerOptions = [
        { value: 'email', label: t('Email') },
        { value: 'sms', label: t('SMS') },
        { value: 'fcm-api', label: t('Notificatin') },
    ];

    useEffect(() => {
        const fetchStocksData = async () => {
            const token = cookies.token;
            try {
                const response = await axios.get(`${apiUrlServer}${getAllStocks}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Accept-Language': language,
                    },
                    params: {
                        limit: limit,
                    },
                });
                const result = response.data;

                if (result.status) {
                    setStocks((prevStocks) => [...prevStocks, ...result.data]);
                } else {
                    console.error('Error fetching data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };
        fetchStocksData();
    }, [limit, cookies.token, language]);

    const fetchTags = useCallback(async () => {
        const token = cookies.token;
        try {
            const response = await axios.get(`${apiUrlServer}stocks/chat/tags`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': language,
                },
            });
            const result = response.data;

            if (result.status) {
                setTags(result.data);
            } else {
                console.error('Error fetching tags:', result.message);
            }
        } catch (error) {
            console.error('Error fetching tags:', error.message);
        }
    }, [cookies.token, language]);

    const handleMenuScroll = (e) => {
        const target = e.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setLimit((prevLimit) => prevLimit + 10);
        }
    };

    const uniqueStockOptions = stocks
        .filter((stock, index, self) => index === self.findIndex((t) => t.id === stock.id))
        .map((stock) => ({ value: stock.id, label: `${stock.name} - ${stock.code}` }));

    const tagOptions = tags.flatMap(category =>
        category.options.map(option => ({
            value: option.tags.join(','),
            label: option.name
        }))
    );

    return (
        <>
            <div className={`${styles['add-price']}`}>
                <h2>{t('Stock Price Alert')}</h2>
                <button type="button" className={`btn ${styles['btn-add-price']}`}
                        onClick={() => setShowModal(true)}>{t('Add Alert')}</button>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    onShow={fetchTags}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center"><img src={priceicon} alt="stocks" className="mb-24"/>
                                    </div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="d-flex flex-column position-relative">
                                            <Select
                                                options={uniqueStockOptions}
                                                onMenuScrollToBottom={handleMenuScroll}
                                                onChange={(selectedOption) => {
                                                    const selectedStock = stocks.find((stock) => stock.id === selectedOption?.value);
                                                    setPreviousClose(selectedStock?.api?.previousClose || '');
                                                    setSelectedOption(selectedOption);
                                                    formik.setFieldValue('stock_id', selectedOption?.value || '');
                                                }}
                                                value={selectedOption}
                                                placeholder={t("Select Stock")}
                                            />
                                            {selectedOption && (
                                                <div className="d-flex align-items-center justify-content-start mt-2">
                                                    <div className={`${styles['selcet-stock-box']}`}>
                                                        {selectedOption.label}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-16">
                                            <div className="form-group mt-16 edit-form w-100">
                                                <label htmlFor="tags" className="form-label">
                                                    {t('Tags')}
                                                </label>
                                                <Select
                                                    id="tags"
                                                    name="tags"
                                                    options={tagOptions}
                                                    value={tagOptions.find(option => option.value === formik.values.tags)}
                                                    onChange={(selectedOption) => {
                                                        formik.setFieldValue('tags', selectedOption.value);
                                                    }}
                                                    placeholder={t("Select Tag")}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-16">
                                            <div className="form-group mt-16 edit-form w-100">
                                                <label htmlFor="providers" className="form-label">
                                                    {t('Providers')}
                                                </label>
                                                <Select
                                                    id="providers"
                                                    name="providers"
                                                    options={providerOptions}
                                                    isMulti
                                                    value={providerOptions.filter(option => formik.values.providers.includes(option.value))}
                                                    onChange={(selectedOptions) => {
                                                        formik.setFieldValue('providers', selectedOptions.map(option => option.value));
                                                    }}
                                                    placeholder={t("Select Providers")}
                                                />
                                            </div>
                                        </div>
                                        <Modal.Footer>
                                            <div
                                                className="d-flex justify-content-between align-items-center w-100 mt-24">
                                                <button type="submit" className="btn btn-save mt-24 w-100"
                                                        disabled={isLoading}>
                                                    {isLoading ? t('Adding...') : t('Add')}
                                                </button>
                                            </div>
                                        </Modal.Footer>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default AddTagsPrice;