import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { apiUrlServer, getAllGroups, deleteGroupStock, WEB_SOCKET_URL } from '../../../../../../apiConfig';
import axios from 'axios';
import useCustomTranslation from '../../../../../../Hooks/useTranslation';
import { useLanguage } from "../../../../../../Context/LanguageContext";
import useCustomLoader from "../../../../../../Hooks/useCustomeLoader";
import useStocks from "../../../../../../Hooks/useStocks";
import { useWatchListAddStocks } from "../../../../../../Context/WatchListAddStocksContext";
import Swal from 'sweetalert2';
import iconSuccess from '../../../../../../assets/icons/suceessAdd.png';
import iconError from '../../../../../../assets/icons/errorCancel.png';
import styles from "../../../../watchlist.module.css";
import {FaRegTrashAlt} from "react-icons/fa";
import useWebSocketBulk from '../../../../../../Hooks/useWebSocketBulk';

const PricesTab = () => {
  const { t } = useCustomTranslation();
  const { id } = useParams();
  const [cookies] = useCookies(['token']);
  const [isLoading, setIsLoading] = useState(true);
  const { language } = useLanguage();
  const { watchListStocks, setWatchListStocks } = useWatchListAddStocks();
  const [stockAdded, setStockAdded] = useState(false);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [accumulatedResponseData, setAccumulatedResponseData] = useState({});
  // const [mergedData, setMergedData] = useState([]);
  // const { responseData, setStockIdCode } = useWebSocketBulk(WEB_SOCKET_URL, []);

  // useEffect(() => {
  //   if (watchListStocks) {
  //       const newStockIds = watchListStocks.map(stock => `${stock.inis_bc}`);
  //       console.log("Setting new stock IDs:", newStockIds);
  //       setStockIdCode(newStockIds);
  //   }
  // }, [watchListStocks, setStockIdCode]);

  // useEffect(() => {
  //     if (responseData) {
  //         setAccumulatedResponseData(prev => ({
  //             ...prev,
  //             ...responseData
  //         }));
  //     }
  // }, [responseData]);

  // useEffect(() => {
  //     if (watchListStocks && accumulatedResponseData) {
  //         const updatedData = watchListStocks.map(stock => {
  //             const stockResponseData = accumulatedResponseData[stock.inis_bc];
  //             if (stockResponseData) {
  //                 return {
  //                     ...stock,
  //                     socketApi: stockResponseData,
  //                 };
  //             }
  //             return stock;
  //         });
  //         setMergedData(updatedData);
  //     }
  // }, [watchListStocks, accumulatedResponseData]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = cookies.token;
      const response = await axios.get(`${apiUrlServer}${getAllGroups}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          'Accept-Language': language,
        },
      });
      const result = response.data;
      if (result.status) {
        console.log('Fetched stocks:', result.data.stocks);
        setWatchListStocks(result.data.stocks);
      } else {
        console.error('Error fetching prices details:', result.message);
      }
    } catch (err) {
      console.error('Error fetching watchlist details:', err.message);
    } finally {
      setIsLoading(false);
    }
  }, [id, cookies.token, language, setWatchListStocks]);

  useEffect(() => {
    fetchData();
  }, [fetchData, stockAdded]);

  const refreshData = useCallback(() => {
    setStockAdded(prev => !prev);
  }, []);

  const handleCheckboxChange = (stockId) => {
    setSelectedStocks((prevSelectedStocks) => {
      if (prevSelectedStocks.includes(stockId)) {
        return prevSelectedStocks.filter((id) => id !== stockId);
      } else {
        return [...prevSelectedStocks, stockId];
      }
    });
  };

  const deleteStocks = async (stockIds) => {
    try {
      const token = cookies.token;

      if (stockIds.length === 0) {
        return;
      }

      const referenceIds = watchListStocks
          .filter((stock) => stockIds.includes(stock.id))
          .map((selectedStock) => selectedStock.reference_id);

      await axios.delete(`${apiUrlServer}${deleteGroupStock}/${referenceIds}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept-Language': language,
        },
      });

      // Update the watchListStocks state directly
      setWatchListStocks((prevStocks) => prevStocks.filter((stock) => !stockIds.includes(stock.id)));
      setSelectedStocks([]);

      Swal.fire({
        iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
        title: `${t('Stocks Deleted')}`,
        text: `${t('Selected stocks have been successfully deleted.')}`,
        customClass: {
          confirmButton: 'swal-btn-confirmButton',
          cancelButton: 'swal-btn-cancelButton',
        },
      });
    } catch (error) {
      console.error('Error deleting stocks:', error);
      Swal.fire({
        iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
        title: `${t('Error')}`,
        text: `${t('An error occurred while deleting stocks. Please try again.')}`,
        customClass: {
          confirmButton: 'swal-btn-cancelButton',
        },
      });
    }
  };

  const handleRowDelete = (stockId) => {
    Swal.fire({
      title: t('Are you sure?'),
      text: t('You won\'t be able to revert this!'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Yes, delete it!'),
      cancelButtonText: t('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStocks([stockId]);
      }
    });
  };

  const handleMultiDelete = () => {
    if (selectedStocks.length === 0) {
      Swal.fire({
        iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
        title: t('No stocks selected'),
        text: t('Please select at least one stock to delete.'),
        customClass: {
          confirmButton: 'swal-btn-cancelButton',
        },
      });
      return;
    }

    Swal.fire({
      title: t('Are you sure?'),
      text: t('You won\'t be able to revert this!'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Yes, delete them!'),
      cancelButtonText: t('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStocks(selectedStocks);
      }
    });
  };
  const handleSelectAllChange = useCallback((selected) => {
    if (selected) {
      const allStockIds = watchListStocks.map(stock => stock.id);
      setSelectedStocks(allStockIds);
    } else {
      setSelectedStocks([]);
    }
  }, [watchListStocks]);

  const columns = useMemo(() => [
    {
      name: t('St. Symbol'),
      selector: row => row.code,
      sortable: true,
      cell: (row) => <span>{row?.code}</span>,
    },
    {
      name: t('Name'),
      selector: row => row.name,
      sortable: false,
      cell: row => (
          <span style={{ color: '#662549', transition: 'color 0.3s', textAlign: language === "ar" ? "right" : "left" }} className="hoverable-cell">
          {row?.name}
        </span>
      ),
    },
    {
      name: t('Price'),
      selector: row => row.api?.close,
      sortable: true,
      cell: (row) => <span>{row?.api?.close}</span>,
    },
    {
      name: t('Change'),
      selector: row => row.api?.change,
      sortable: true,
      cell: row => (
          <span style={{ color: row?.api?.change < 0 ? 'red' : 'green' }}>
          {row?.api?.change}
        </span>
      ),
    },
    {
      name: `${t('Change')} (%)`,
      selector: row => row.api?.change_p,
      sortable: true,
      cell: row => (
          <span style={{ color: row?.api?.change < 0 ? 'red' : 'green' }}>
          {row?.api?.change_p}
        </span>
      ),
    },
    {
      name: t('Prev. close'),
      selector: row => row.api?.previousClose,
      sortable: true,
      cell: row => <span>{row?.api?.previousClose}</span>,
    },
    {
      name: t('Volume'),
      selector: row => row.api?.volume,
      sortable: true,
      cell: row => <span>{row?.api?.volume}</span>,
    },
    {
      name: t('Action'),
      cell: (row) => (
          <button onClick={() => handleRowDelete(row.id)}  className={`${styles['icon-delete-box']}  `}>
            <FaRegTrashAlt className={`${styles['icon-delete']}`} />
          </button>
      ),
      sortable: false,
      width: '100px',
    },
  ], [t, language]);

  const customStyles = {
    rows: {
      style: {
        minHeight: '64px',
        color: '#6F6F6F',
        textAlign: 'center',
      },
    },
    headCells: {
      style: {
        color: '#1D1A39',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'left',
      },
    },
    cells: {
      style: {
        textAlign: 'left',
      },
    },
  };

  const { customLoader } = useCustomLoader();
  const { paginationComponentOptions } = useStocks();

  return (
      <>
        <div className="prices-tab">
        {/*<button onClick={handleMultiDelete} className="btn btn-danger my-3">*/}
        {/*  {t('Delete Selected Stocks')}*/}
        {/*</button>*/}
        <DataTable
            className="table-watch"
            columns={columns}
            data={watchListStocks}
            pagination
            customStyles={customStyles}
            highlightOnHover={true}
            striped={true}
            responsive
            progressPending={isLoading}
            progressComponent={customLoader()}
            noDataComponent={<div style={{ padding: '24px' }}>No records to display</div>}
            paginationComponentOptions={paginationComponentOptions}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) => setSelectedStocks(selectedRows.map(row => row.id))}
        />
        </div>
      </>
  );
};

export default PricesTab;