// apiConfig.js
// API_AuthConfig
const API_URL = process.env.REACT_APP_API_URL;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const apiUrlServer = API_URL;
// EODHD API
// NEWS URL API
const apiNewsUrl = "https://eodhd.com/api/news";
// EXCHANGE SYMBOL LIST URL API
const exchangeSymbolList = "https://eodhd.com/api/exchange-symbol-list/SR";

const eodhdChartApi = "https://eodhd.com/api/eod/";

const fundamentals = API_URL + "fundamental_eod/";
const tasiCandles = "https://eodhd.com/api/eod/^TASI.SR?api_token=6523dd3ad3c6c2.14907660&fmt=json";


const liveDate = "https://eodhd.com/api/real-time/";
const apiToken = "6523dd3ad3c6c2.14907660";

// API Stocks and Indcies Variables
const getAllStocks = "stocks";
const getAllIndexes = "indexes"
const getIndexesCandles = "index_candle"
const getIndicesChartData = "index-details/eod"
const getStockDetailsFundamentals = "stock-details/fundamentals"

// API WatchList Groups
const getAllGroups = "groups";
const addGroupStock = "group_stock";
const deleteGroupStock = "group_stock";
// API Portfolio
const getPortfolio = "portfolio"
const portfolioStocksSummary = "portfolio_stocks/summary"
const getPortfolioStock = "portfolio_stock"
const addPortfolioStock = "portfolio_stock"
const deletePortfolioStock = "portfolio_stock"
// Simiulation
const getsimulation = "simulation"
const addSimulationsWallet = "simulations_wallet"
const getSimulationsWallet = "simulations_wallet"
const addSimulation = "simulation"
const addSimulationAllowed = "simulations/allowed"
// API Alerts
const getStockAlert = "stock_alert"
const addStockAlert = "stock_alert"
const editStockAlert = "stock_alert"
const deleteStockAlert = "stock_alert"
//API Tags
const getTag = "tag"
// API Alerts Tags
const getStockAlertTags = "stock_alert_tag"
const addStockAlertTags = "stock_alert_tag"
const editStockAlertTags = "stock_alert_tag"
const deleteStockAlertTags = "stock_alert_tag"
// API Subscription
const getPlan = "plan"
const addSubscription = "subscription"
const getMySubscription = "subscription"
// API Profile
const getCustomerProfile = "customer/profile"
const updateCustomerProfile = "customer/profile/update"
const updateCustomerPassword = "customer/changePassword"
const deleteProfileImage = "customer/delete-image"
const checkOtpAndActivate = "customer/checkOtpAndActivate"
const sendOtp = "customer/sendOtp"
const userChangePhone = "customer/profile/changePhone"
// API Common pages
const contactUs = "contact_us"
// Notifications
const getNotificationsList = "notifications"
// socket url
const WEB_SOCKET_URL = 'wss://scrapping-python-0bb8b4d8e9d7.herokuapp.com';

export {
    apiUrlServer,
    apiNewsUrl,
    exchangeSymbolList,
    fundamentals,
    liveDate,
    eodhdChartApi,
    apiToken,
    getAllStocks,
    getAllIndexes,
    getIndicesChartData,
    getStockDetailsFundamentals,
    getAllGroups,
    addGroupStock,
    deleteGroupStock,
    getPortfolio,
    portfolioStocksSummary,
    getPortfolioStock,
    addPortfolioStock,
    deletePortfolioStock,
    addSimulationsWallet,
    getSimulationsWallet,
    getsimulation,
    addSimulation,
    addSimulationAllowed,
    getTag,
    addStockAlert,
    getStockAlert,
    deleteStockAlert,
    editStockAlert,
    getStockAlertTags,
    addStockAlertTags,
    editStockAlertTags,
    deleteStockAlertTags,
    getPlan,
    addSubscription,
    getMySubscription,
    getCustomerProfile,
    updateCustomerProfile,
    updateCustomerPassword,
    deleteProfileImage,
    checkOtpAndActivate,
    sendOtp,
    userChangePhone,
    contactUs,
    getNotificationsList,
    tasiCandles,
    WEB_SOCKET_URL,
    getIndexesCandles
};

