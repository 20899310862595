// import {createContext, useContext, useState} from "react";
//
// const ChatContext = createContext(null);
//
// export const useChat = () => useContext(ChatContext);
//
// export const ChatProvider = ({ children }) => {
//     const [chatMessages, setChatMessages] = useState([]);
//     const [inputValue, setInputValue] = useState('');
//     const [formSubmitTriggered, setFormSubmitTriggered] = useState(false);
//
//     const addMessage = (text) => {
//         const newMessage = { sender: 'user', text };
//         setChatMessages(prevMessages => [...prevMessages, newMessage]);
//     };
//
//     const updateInputValue = (value) => {
//         setInputValue(value);
//     };
//
//     const triggerFormSubmit = () => {
//         setFormSubmitTriggered(true);
//     };
//
//     const resetFormSubmitTriggered = () => {
//         setFormSubmitTriggered(false);
//     };
//
//     return (
//         <ChatContext.Provider value={{ chatMessages, addMessage, setChatMessages, inputValue, updateInputValue, triggerFormSubmit, formSubmitTriggered, resetFormSubmitTriggered }}>
//             {children}
//         </ChatContext.Provider>
//     );
// };

import React, { createContext, useContext, useState } from "react";

const ChatContext = createContext(null);

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [chatMessages, setChatMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [formSubmitTriggered, setFormSubmitTriggered] = useState(false);

    const addMessage = (text) => {
        const newMessage = { sender: 'user', text };
        setChatMessages(prevMessages => [...prevMessages, newMessage]);
    };

    const updateInputValue = (value) => {
        setInputValue(value);
    };

    const triggerFormSubmit = () => {
        setFormSubmitTriggered(true);
    };

    const resetFormSubmitTriggered = () => {
        setFormSubmitTriggered(false);
    };

    return (
        <ChatContext.Provider
            value={{
                chatMessages,
                addMessage,
                setChatMessages,
                inputValue,
                updateInputValue,
                triggerFormSubmit,
                formSubmitTriggered,
                resetFormSubmitTriggered
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};
