// ChangeUtils.js

import positiveChangeImage from './assets/icons/greenflag.png';
import negativeChangeImage from './assets/icons/redFlag.png';
import positiveChangeArrow from './assets/icons/FrameGreen.png';
import negativeChangeArrow from './assets/icons/FrameRed.png';

export const getChangeFlag = (change_p) => {
  return change_p > 0 ? positiveChangeImage : negativeChangeImage;
};

export const getChangeArrow = (change_p) => {
  return change_p > 0 ? positiveChangeArrow : negativeChangeArrow;
};

export const getTextColor = (value) => {
  return value > 0 ? '#049C6B' : '#FD2523';
};