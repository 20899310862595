import React from 'react';
import useCustomTranslation from "../../../Hooks/useTranslation";
import {abbreviateNumber, formatNumber, formatNumbers} from "../../../Hooks/useNumberFormatting";

const StockInformation = (props) => {
    const { t } = useCustomTranslation();
    const formattedNumberWeekHigh = formatNumber(props.WeekHigh);
    const formattedSharesFloat = abbreviateNumber(props.SharesFloat);
    const formattedMarketCapitalizationMln = abbreviateNumber(props?.MarketCapitalizationMln)
    const formatEPSEstimateCurrentYear = props.EPSEstimateCurrentYear && formatNumber((props.EPSEstimateCurrentYear/props.price)*100)

    return (
        <>
            <div className="col-lg-12 col-sm-12 h-100">
                <div className="state-card stete-card-information">
                    <h2 className="key-state">{t('Stock Information')}</h2>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Beta')}</span>
                        <span className="key-span-number">{props.Beta || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Eps')}</span>
                        <span className="key-span-number">{props.DilutedEpsTTM  || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Eps')} %</span>
                        <span className="key-span-number">{ formatEPSEstimateCurrentYear || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Dividend')}</span>
                        <span className="key-span-number">{props.DividendShare || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Dividend')} %</span>
                        <span className="key-span-number">{props.DividendShare || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Shares Float')}</span>
                        <span className="key-span-number">{formattedSharesFloat || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Market Cap')}</span>
                        <span className="key-span-number">{formattedMarketCapitalizationMln || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('52 Week High')}</span>
                        <a href={props.WebURL} className="key-span-number">{formattedNumberWeekHigh}</a>
                    </div>
                    <div className="d-flex align-items-center justify-content-between div-state">
                        <span className="key-span">{t('52 Week Low')}</span>
                        <span className="key-span-number">{props.WeekLow || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between div-state">
                        <span className="key-span">{t('Income last 12 month')}</span>
                        <span className="key-span-number">{props.last12month || '-'}</span>
                    </div>
                    {/* <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Updated At')}</span>
                        <span className="key-span-number">{props.UpdatedAt}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Updated At')}</span>
                        <span className="key-span-number">{props.UpdatedAt}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Primary Ticker')}</span>
                        <span className="key-span-number">{props.PrimaryTicker}</span>
                    </div>
                    <div className="d-flex align-items-center     justify-content-between div-state">
                        <span className="key-span">{t('Fiscal Year End')}</span>
                        <span className="key-span-number">{props.FiscalYearEnd}</span>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default StockInformation;