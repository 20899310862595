import React, {useEffect, useState} from 'react';
import './PrivacyPolicy.css';
import axios from "axios";
import {apiUrlServer} from "../../apiConfig";
const PrivacyPolicy = () => {
    const [privacyPage, setPrivacyPage] = useState("");
    const fetchPrivacy = async () => {
        try {
            const response = await axios.get(`${apiUrlServer}cms_page?=policy`, {
                // params: {
                //     'policy'
                // },
            });
            const result = response.data;
            console.log(result);
        } catch (e) {
            console.error('Error fetching data:', e.message);
        }
    };

    useEffect(() => {
        fetchPrivacy();
    }, []);

    return (
        <>
            <section className="privacyBox width80">
                <h1>Acceptable Use Policy</h1>
                <p dir="ltr">Saudi Exchange’s Acceptable Use Policy applies the best practices to ensure the quality and
                    timeliness of the information shared on this website. This policy applies to all Saudi Exchange
                    website users. This policy applies to all information, in whatever form, relating to Saudi
                    Exchange’s business activities and to all information handled by Saudi Exchange relating to other
                    entities with whom it deals.</p>


                <h3>Disclaimer</h3>
                <p dir="ltr">Saudi Exchange is not liable for any inaccurate, incomplete or out dated information or any
                    actions taken in regard to the given information. Saudi Exchange disclaims its responsibility to
                    provide any legal or implied warranties or acknowledgments. Saudi Exchange does not assume any
                    responsibility and will not be subject to any claims for any failure in performance, error,
                    interruption, defect, delay, transmission of viruses to your computer, or temporary unavailability
                    of the website because of technical problems beyond our control.</p>


                <h3>Limitations of liability</h3>
                <p dir="ltr">By continuing to use this website, the user agrees that all actions including browsing,
                    downloading materials, and submitting information falls under that user’s responsibility and no
                    unauthorized activity will be performing such but not limited to vulnerability scan or penetration
                    test to gain unauthorized resources. Saudi Exchange is not liable under any circumstances for any
                    damages, losses, or any obligations related to, but not limited to, any damages, losses, direct or
                    indirect, special, incidental, or any obligations related to your use of the website or special
                    damages of any kind arising out of the use of any websites, materials, or links accessed through
                    Saudi Exchange’s website.</p>

            </section>
        </>
    );
};

export default PrivacyPolicy;