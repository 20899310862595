import { useState, useEffect } from 'react';
import axios from 'axios';

const useNews = (apiNewsUrl, apiToken, initialVisibleItems = 10) => {
    const [newsUpdate, setNewsUpdate] = useState([]);
    const [visibleItems, setVisibleItems] = useState(initialVisibleItems);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`${apiNewsUrl}?api_token=${apiToken}`)
            .then((response) => {
                setNewsUpdate(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching news data:', error);
                setLoading(false);
            });
    }, [apiNewsUrl, apiToken]);

    const newsByDate = newsUpdate.reduce((acc, item) => {
        const date = item.date.split('T')[0];
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(item);
        return acc;
    }, {});

    const truncatedContent = (content, maxWords) => {
        const words = content.split(' ');
        if (words.length > maxWords) {
            return words.slice(0, maxWords).join(' ') + '...';
        }
        return content;
    };

    const loadMoreItems = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
    };

    return { newsUpdate, visibleItems, loading, newsByDate, truncatedContent, loadMoreItems };
};

export default useNews;