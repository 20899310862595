import axios from "axios";
import {apiUrlServer, getIndexesCandles, getAllStocks, tasiCandles} from "./apiConfig";

// DatafeedConfiguration implementation
const configurationData = {
    exchanges: [
        {value: '%5ETASI', name: 'Taduwal', desc: 'Taduwal'},
    ],
    symbols_types: [
        {name: 'tadawul all share', value: 'tadawul all share'},
    ],

};

async function getAllSymbols() {
    const limit = 300;
    const response = await fetch(`${apiUrlServer}limitedStock?limit=${limit}`);
    const data = await response.json();
    let symbols = [
        {
            symbol: "%5ETASI",
            ticker: "%5ETASI",
            description: "%5ETASI",
            exchange: "%5ETASI",
            type: 'tadawul all share',
        },
    ];

    if (data.status) {
        data.data.forEach(stock => (
            symbols.push({
                symbol: stock.code,
                ticker: `${stock.name.toLowerCase()} ${stock.code}`,
                description: `${stock.name.toLowerCase()} ${stock.code}`,
                exchange: "%5ETASI",
                type: 'tadawul all share',
                id: stock.id,
                inis_bc: stock.inis_bc
            })
        ));
        return symbols;
    }

    return [];
}

const lastBarsCache = new Map();
const channelToSubscription = new Map();
let socket;

export default {
    onReady: (callback) => {
        setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
        const symbols = await getAllSymbols();
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.ticker
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
        const symbols = await getAllSymbols();
        const symbolItem = symbols.find(({ticker}) => ticker.search(symbolName) !== -1);

        if (!symbolItem) {
            onResolveErrorCallback('Cannot resolve symbol');
            return;
        }

        const symbolInfo = {
            ticker: symbolItem.ticker,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 100,
            visible_plots_set: 'ohlc',
            has_weekly_and_monthly: true,
            has_intraday: true,
            supported_resolutions: ['1D', '60', '30', "15", "5", "10"],
            volume_precision: 2,
            id: symbolItem.id,
            inis_bc: symbolItem.inis_bc,

        };

        onSymbolResolvedCallback(symbolInfo);
    },


    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const {from, to, firstDataRequest} = periodParams;
        console.log("get bars from", from);
        console.log("get bars to", to);
        let defaultValues = {
            "5": "5M",
            "60": "1h",
            "30": "30M",
            "10": "10M",
            "15": "15M",
            "1D": "1D",
        };
        const convertedResolutions = defaultValues[resolution];
        try {
            let bars = [];
            let response = null;
            let responseData = [];
            let url;

            if (symbolInfo.ticker === "%5ETASI") {
                url = `${apiUrlServer}${getIndexesCandles}` + `?search[interval]=${convertedResolutions}`;

                response = await axios.get(url);
                responseData = response.data;

                if (!responseData || !responseData.data || !Array.isArray(responseData.data)) {
                    console.error('Invalid TASI data format:', responseData);
                    onErrorCallback('Invalid data format for TASI');
                    return;
                }
            } else {

                url = `${apiUrlServer}candle?search[stock_id]=${symbolInfo.id}&search[interval]=${convertedResolutions}`;

                response = await axios.get(url);
                responseData = response.data;

                if (!responseData || !responseData.data || !Array.isArray(responseData.data)) {
                    console.error('Invalid data format:', responseData);
                    onErrorCallback('Invalid data format');
                    return;
                }
            }
            const data = responseData.data;

            if (convertedResolutions === "1D") {
                data.forEach(bar => {
                    if (bar && bar.sessionDate) {
                        bars.push({
                            time: Date.parse(bar.sessionDate),
                            low: Number(bar.low),
                            high: Number(bar.high),
                            open: Number(bar.open),
                            close: Number(bar.close),
                            volume: Number(bar.volumeTotal)
                        });
                    }
                });
            } else {
                data.forEach(bar => {
                    if (bar && bar.timestamp) {
                        bars.push({
                            time: Number(bar.timestamp) * 1000,
                            low: Number(bar.low),
                            high: Number(bar.high),
                            open: Number(bar.open),
                            close: Number(bar.close),
                            volume: Number(bar.volume)
                        });
                    }
                });
            }
            if (convertedResolutions === "1h") {
                bars = bars.filter(bar =>
                    bar.time >= from * 1000 && bar.time <= to * 1000
                );
            }
            // Sort the bars by time to ensure chronological order
            bars.sort((a, b) => a.time - b.time);
            console.log(bars);
            if (firstDataRequest) {
                lastBarsCache.set(`${symbolInfo.inis_bc}`, {...bars[bars.length - 1]});
            }
            if (bars.length) {
                onHistoryCallback(bars, {noData: false});
            } else {
                onHistoryCallback([], {noData: true});
            }
        } catch (error) {
            console.log('[getBars]: Get error', error);

            onErrorCallback(error);
        }
    },


    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback
    ) => {

        if (symbolInfo.ticker === "%5ETASI") {
            console.log('[subscribeBars]: TASI does not support real-time updates');
            return;
        }
        let defaultValues = {
            "5": "5M",
            "60": "1h",
            "30": "30M",
            "10": "10M",
            "15": "15M",
            "1D": "1D",

        };
        const convertedResolutions = defaultValues[resolution];
        if (convertedResolutions != "1D") {
            console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
            if (socket != null) {
                socket.close();
            }
            socket = new WebSocket('ws://185.202.239.86:8081');


            let channelString;
            channelString = symbolInfo.inis_bc;

// Subscribe to the stream
            socket.onopen = function (event) {
                console.log('[socket] Connected to WebSocket server');
                // Send the stock ID to subscribe
                socket.send(JSON.stringify({type: 'subscribe', stockId: symbolInfo.inis_bc}));
            };

            socket.onmessage = function (event) {
                const messageObj = JSON.parse(event.data);
                console.log('Received data:', messageObj);

                if (messageObj.data && messageObj.data.startStream) {
                    messageObj.data.startStream.forEach(update => {
                        const stockId = update.requestedId;

                        if (stockId === symbolInfo.inis_bc) {
                            if (update.last) {
                                const {value, timestamp} = update.last;
                                let lastDailyBar = lastBarsCache.get(stockId);
                                let newTimestamp = Date.parse(timestamp);

                                if (lastDailyBar) {
                                    let bar = {
                                        ...lastDailyBar,
                                        time: newTimestamp,
                                        high: Math.max(lastDailyBar.high, value),
                                        low: Math.min(lastDailyBar.low, value),
                                        close: value,
                                    };
                                    lastBarsCache.set(stockId, bar);
                                    console.log('[socket] Update the latest bar by price', bar);
                                    onRealtimeCallback(bar);
                                } else {
                                    console.log('[socket] No last daily bar found for', stockId);
                                    // You might want to fetch the last known bar here if it's not in the cache
                                }
                            }
                        }
                    });
                }
            };
            socket.onclose = function (event) {
            };


            console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
            console.log('[socket]: status:', socket);
        } else {
            console.log('[subscribeBars]: you can\'t subscribe  because you use 1D resolution',);

        }

    },

    unsubscribeBars:
        (subscriberUID) => {
            console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
            if (socket != null) {
                socket.close();
            }
        },
};


function widenDateRange(from, to) {
    const fromDate = new Date(from * 1000);
    const toDate = new Date(to * 1000);

    fromDate.setDate(fromDate.getDate() - 7);  // Go back one more week
    toDate.setDate(toDate.getDate() + 7);  // Go forward one more week

    return {
        from: Math.floor(fromDate.getTime() / 1000),
        to: Math.floor(toDate.getTime() / 1000)
    };
}

function adjustForWeekend(from, to) {
    const fromDate = new Date(from * 1000);
    const toDate = new Date(to * 1000);

    // Adjust 'from' date to previous Thursday if it's Friday or Saturday
    fromDate.setDate(fromDate.getDate() - 7);

    // Adjust 'to' date to next Sunday if it's Friday or Saturday
    fromDate.setDate(fromDate.getDate() + 7);
    // Set the time for 'to' to 23:59:59
    toDate.setHours(23, 59, 59, 999);

    return {
        from: Math.floor(fromDate.getTime() / 1000),
        to: Math.floor(toDate.getTime() / 1000)
    };
}