import React, { useEffect, useState } from 'react'
import { BulletList } from 'react-content-loader'
import Slider from 'react-slick'
import AddSubscription from './AddSubscription'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import {apiUrlServer, getPlan} from '../../apiConfig'
import './subscription.css';
import useCustomTranslation from '../../Hooks/useTranslation';

const PlansTable = ({tabId}) => {
  const { t } = useCustomTranslation();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

  useEffect(() => {
    const fetchPlansData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiUrlServer}${getPlan}`, {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
            'Content-Type': 'application/json', // Changed from 'multipart/form-data'
          },
          params: {
          "filter[interval_id]": tabId
          }
        });
        const result = response.data;
        if (result.status) {
          setPlans(result.data);
        } else {
          console.error('Failed to fetch plans:', result.message);
          setPlans([]);
        }
      } catch (err) {
        console.error('Error fetching plans:', err);
        setPlans([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (tabId) {
      fetchPlansData();
    }
  }, [tabId, cookies.token]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.7,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  if (isLoading) {
    return <BulletList />;
  }

  if (plans.length === 0) {
    return <div>No plans available for this tab.</div>;
  }

  return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 slider-subscription">
            <Slider {...settings}>
              {plans.map((plan, index) => (
                  <div key={plan.id} className="plan-card">
                    <div className="card-body w-100 h-100" >
                      <div className="card-title">
                        <span className="plan-name">{plan.name}</span>
                        <span className="prices">
                      <span className="current-price"><del>{plan.price} / </del></span>  {plan.discount_price}{' '}
                          <span>{t('SAR/Month')}</span>
                    </span>
                      </div>
                      <div className="card-description">{truncateText(plan.description, 10)}</div>
                      <div className="card-details"></div>
                      <ul className="p-0 plans-list">
                        {plan.features.map((feature, index) => (
                            <li key={index} className="plans-benefits">
                              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.398438" width="20" height="20" rx="10" fill="#E8FFEB" />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.247 6.55682L8.28036 12.3152L6.69703 10.6235C6.40536 10.3485 5.94703 10.3318 5.61369 10.5652C5.28869 10.8068 5.19703 11.2318 5.39703 11.5735L7.27202 14.6235C7.45536 14.9068 7.77202 15.0818 8.13036 15.0818C8.47202 15.0818 8.79702 14.9068 8.98036 14.6235C9.28036 14.2318 15.0054 7.40682 15.0054 7.40682C15.7554 6.64016 14.847 5.96516 14.247 6.54849V6.55682Z"
                                    fill="#049C6B"
                                />
                              </svg>
                              {feature.description}
                            </li>
                        ))}
                      </ul>
                      <AddSubscription plan_id={plan.id} />
                    </div>
                  </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
  )
}

export default React.memo(PlansTable);