import React, {useEffect} from 'react';
import positiveChangeImage from "../../../assets/icons/greenflag.png";
import negativeChangeImage from "../../../assets/icons/redFlag.png";
import positiveChangeArrow from "../../../assets/icons/FrameGreen.png";
import negativeChangeArrow from "../../../assets/icons/FrameRed.png";
import useCustomTranslation from "../../../Hooks/useTranslation";
import {abbreviateNumber, formatNumber, useFormattedPercentage} from "../../../Hooks/useNumberFormatting";
import styles from './BannerIndicesDetails.module.css';

const BannerIndicesDetails = (props) => {

    const getChangeFlag = () => {
        if (props.change_p > 0) {
            return positiveChangeImage; // Image for positive change
        } else {
            return negativeChangeImage; // Image for non-positive change
        }
    };
    const getChangeArrow = () => {
        if (props.change_p > 0) {
            return positiveChangeArrow; // Image for positive change
        } else {
            return negativeChangeArrow; // Image for non-positive change
        }
    };
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Riyadh', // Set your desired time zone
        };
        const timeString = date.toLocaleString('en-US', options);
        return `Last | ${timeString} CTT`;
    };
    const { t } = useCustomTranslation();


    const formattedChangeP = useFormattedPercentage(props.change_p);
    // Round the number to two decimal places
    const formattedClose = abbreviateNumber(props.close);
    const formattedOpen = abbreviateNumber(props.open);
    const formattedChange = abbreviateNumber(props.change);


    const formattedPreviousClose = abbreviateNumber(props.previousClose);
    return (
      <>
        <div className=" card-banner">
          <div className="d-flex justify-content-between align-items-center w-100 gap-4 flex-wrap">
            <div className="box-1 d-flex">
              <div className="stock-box-code">{props.Code}</div>
              <div className="d-block ">
                <div className="d-block">
                  <span className="date-indcies">
                    {formatTimestamp(props.timestamp)}
                  </span>
                </div>
                <div className={`d-flex justify-content-start align-items-center gap-5 flex-wrap ${styles['gap-row-10px']}`}>
                  <div className="d-flex align-items-center ">
                    <div className="mr-24 d-flex justify-content-center align-items-center">
                      {/*<img className="flag-size mx-2" src={getChangeFlag()} alt="Change Image"/>*/}
                      <span className="close-indcies">{formattedClose}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <span
                        className="change_p_indcies"
                        style={{
                          color: props.change_p > 0 ? "#049C6B" : "#FD2523",
                        }}
                      >
                        <img
                          className="arrow-indcies"
                          src={getChangeArrow()}
                          alt="Change_p"
                        />
                        {formattedChange}({formattedChangeP})
                      </span>
                    </div>
                  </div>
                  <div className={`d-flex gap-5 align-items-center flex-wrap ${styles['gap-row-10px']}`}>
                    <div className="indices-text-details">
                      <span className="mx-1">{t("open")}</span>
                      <span className="indices-text-number">
                        {formattedOpen}
                      </span>
                    </div>
                    <div className="indices-text-details">
                      <span className="mx-1">{t("Prev.close")}</span>
                      <span className="indices-text-number">
                        {formattedPreviousClose}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-2">
              <div className={`d-flex gap-5 align-items-center flex-wrap ${styles['gap-row-10px']}`}>
                <div className="stodck-details-text">
                  <span className="mx-1">{t("Volume Total")}:</span>
                  <span className="stodck-details-number">
                    {abbreviateNumber(props.volumeTotal)}
                  </span>
                </div>
                <div className="stodck-details-text">
                  <span className="mx-1">{t("Turnover Total")}:</span>
                  <span className="stodck-details-number">
                    {abbreviateNumber(props.turnoverTotal)}
                  </span>
                </div>
                <div className="stodck-details-text">
                  <span className="mx-1">{t("Market Capitalisation")}:</span>
                  <span className="stodck-details-number">
                    {props.marketCapitalization}
                  </span>
                </div>
              </div>
              <div className="d-flex gap-5 align-items-center">
                <div className="stodck-details-text">
                  <span className="mx-1">{t("Number Of Trades")}:</span>
                  <span className="stodck-details-number">
                    {abbreviateNumber(props.numberOfTransactions)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default BannerIndicesDetails;