import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from './SaveCriteriaModal.module.css';

function SaveCriteriaModal({ isOpen, onClose, onSave, criteria }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      name,
      description,
      criteria
    });
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose} dialogClassName={styles['stock-screener-modal']}>
      <Modal.Header closeButton>
        <Modal.Title>Save Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter criteria name" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3} 
              placeholder="Enter description (optional)"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className={styles.saveCriteriaButton}>
            Save Filters
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SaveCriteriaModal;