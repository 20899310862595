import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../Context/UserContext';
import { AuthContext } from '../../Context/AuthContext';
import EditUserProfile from './EditUserProfile';
import EditUserPhone from './ChangePhoneNumber/EditUserPhone';
import DeleteProfileImage from './DeleteProfileImage';
import EditUserPassword from './EditUserPassword';
import DeleteAccount from './DeleteAccount';
import './profile.css';
import useCustomTranslation from "../../Hooks/useTranslation";
import {apiUrlServer, getCustomerProfile} from "../../apiConfig";
import { AvatarComponent } from 'avatar-initials';
import {useTheme} from "../../Context/ThemeContext";

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [cookies, removeCookie] = useCookies(['token']);
  const { userProfile, setProfile } = useUserProfile();
  const navigate = useNavigate();
  const { setIsUserLoggedIn } = useContext(AuthContext);
  const { theme } = useTheme();
  const backgroundColor = theme === 'default' ? '#451952' : '#008c45';

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (cookies.token) {
          const response = await axios.get(`${apiUrlServer}${getCustomerProfile}`, {
            headers: {
              Authorization: `Bearer ${cookies.token}`,
            },
          });
          setProfile(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    if (cookies.token) {
      fetchUserProfile();
    } else {
      console.error('User not logged in');
    }
  }, [cookies.token]);

  const handleEditSuccess = (updatedProfile) => {
    setProfile(updatedProfile);
    setActiveTab('profile');
  };

  const handleEditPhoneSuccess = (updatedProfile) => {
    setProfile(updatedProfile);
    removeCookie('token');
    setIsUserLoggedIn(false);
    navigate('/OtpChangePhone');
  };

  const handleEditPasswordSuccess = () => {
    setIsUserLoggedIn(false);
    setActiveTab('profile');
  };

  const handleDeletePhotoSuccess = () => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      image: null,
    }));
  };

  const { t } = useCustomTranslation();

  const navItems = [
    { eventKey: 'profile', label: t('Profile') },
    { eventKey: 'subscription', label: t('My Subscription') },
    { eventKey: 'edit', label: t('Edit Profile') },
    { eventKey: 'editPhone', label: t('Edit Phone') },
    { eventKey: 'editPassword', label: t('Edit Password') },
    { eventKey: 'deleteAccount', label: t('Delete Account') },
  ];

  const renderFeatures = () => {
    if (!userProfile?.subscription?.plan?.features) return null;

    return (
        <div className="card mt-3">
          <div className="card-body">
            <h5 className="card-title">{t('Subscription Features')}</h5>
            <ul className="list-group list-group-flush">
              {userProfile.subscription.plan.features.map((feature, index) => (
                  <li key={index} className="list-group-item">
                    <div className="d-flex align-items-center gap-2">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.398438" width="20" height="20" rx="10" fill="#E8FFEB"/>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.247 6.55682L8.28036 12.3152L6.69703 10.6235C6.40536 10.3485 5.94703 10.3318 5.61369 10.5652C5.28869 10.8068 5.19703 11.2318 5.39703 11.5735L7.27202 14.6235C7.45536 14.9068 7.77202 15.0818 8.13036 15.0818C8.47202 15.0818 8.79702 14.9068 8.98036 14.6235C9.28036 14.2318 15.0054 7.40682 15.0054 7.40682C15.7554 6.64016 14.847 5.96516 14.247 6.54849V6.55682Z"
                            fill="#049C6B"
                        />
                      </svg>
                      <strong>{feature.name}</strong>:
                    </div>
                    {feature.key === 'PermissionAndValue' ? (
                        <span> {feature.value} {t('uses')}</span>
                    ) : (
                        <span> {t('Unlimited')}</span>
                    )}
                    {feature.description && <p className="text-muted small">{feature.description}</p>}
                  </li>
              ))}
            </ul>
          </div>
        </div>
    );
  };

  const renderSubscriptionDetails = () => {
    if (!userProfile?.subscription) return null;

    return (
        <div className="card profile-content">
          <div className="card-body">
            <h5 className="card-title text-center">{t('Subscription Details')}</h5>
            <div className="d-flex flex-column">
              <span className="text-muted mx">Plan Name: <span
                  className="mx-1">{userProfile.subscription.plan.name}</span></span>
              <span className="text-muted">Plan Description: <span
                  className="mx-1">{userProfile.subscription.plan.description}</span></span>
              <span className="text-muted">Interval: <span
                  className="mx-1">{userProfile.subscription.plan.interval.name}</span></span>
              <span className="text-muted">Price: <span className="mx-1">{userProfile.subscription.plan.price}</span></span>
              <span className="text-muted">Discount Price: <span className="mx-1">{userProfile.subscription.plan.discount_price}</span></span>
              <span className="text-muted">Status: <span className="mx-1">{userProfile.subscription.status}</span></span>
              <span className="text-muted">Expires At: <span className="mx-1">{userProfile.subscription.expire_at}</span></span>
            </div>
            {renderFeatures()}
          </div>
        </div>
    );
  };

  return (
      <>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        <section className="user-profile-module my-3">
          <div className="container-fluid">
            <div className="row align-items-start g-0">
              <div className="col-lg-2 col-sm-12">
                <div className="tab-profile-container">
                  <ul className="ul-profile-settings">
                    {navItems.map((item) => (
                        <li
                            key={item.eventKey}
                            className={`nav-profile-link ${activeTab === item.eventKey ? 'active' : ''}`}
                            onClick={() => setActiveTab(item.eventKey)}
                        >
                          {item.label}
                        </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-10 col-sm-12">
                <div className="tab-content tab-profile-contenet overflow-scroll" >
                  {activeTab === 'profile' && (
                      <div className="profile-content user-profile-content">
                        <div className="d-flex justify-content-between align-items-start gap-3">
                          <div className="profile-data card w-100">
                            <div className="card-body">
                              <div className="head-form">
                                {userProfile?.image ? (
                                    <>
                                      <img src={userProfile?.image} alt={userProfile?.name} className="profile-image-rounded"/>
                                      <DeleteProfileImage onDeleteSuccess={handleDeletePhotoSuccess} userProfile={userProfile}/>
                                    </>
                                ) : (
                                    <AvatarComponent
                                        classes="rounded-full"
                                        useGravatar={false}
                                        size={100}
                                        primarySource={userProfile?.image}
                                        color="#fff"
                                        background={backgroundColor}
                                        fontSize={35}
                                        fontWeight={500}
                                        offsetY={50}
                                        initials={`${userProfile?.name[0]}${userProfile?.name[1]}`}
                                    />
                                )}
                              </div>
                              <span className="text-muted">
                            {t('Name')}: <span>{userProfile?.name}</span>
                          </span>
                              <span className="text-muted">
                            {t('Phone')}: <span>{userProfile?.phone}</span>
                          </span>
                              <span className="text-muted">
                            {t('Language')}: <span>{userProfile?.lang}</span>
                          </span>
                            </div>
                          </div>
                        </div>
                      </div>
                  )}
                  {activeTab === 'subscription' && renderSubscriptionDetails()}
                  {activeTab === 'edit' && <EditUserProfile userProfile={userProfile} onEditSuccess={handleEditSuccess}/>}
                  {activeTab === 'editPhone' && <EditUserPhone userProfile={userProfile} onEditPhoneSuccess={handleEditPhoneSuccess}/>}
                  {activeTab === 'editPassword' && <EditUserPassword userProfile={userProfile} onEditPasswordSuccess={handleEditPasswordSuccess}/>}
                  {activeTab === 'deleteAccount' && <DeleteAccount/>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default UserProfile;