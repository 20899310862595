import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { FaLock, FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { usePermissions } from "../../Context/PermissionContext";
import Swal from 'sweetalert2';

const ProtectedLink = ({ permission, to, className, children }) => {
    const navigate = useNavigate();
    const { hasPermission, permissions, usage } = usePermissions();
    const [showTooltip, setShowTooltip] = useState(false);

    const permissionDetails = permissions[permission];

    // If permission is not found, treat it as a public route
    if (!permissionDetails) {
        return (
            <Link to={to} className={className}>
                {children}
            </Link>
        );
    }

    const isLocked = !hasPermission(permission);
    const isInPlan = permissionDetails.type === 'unlimited' || permissionDetails.type === 'limited';
    const isLimitReached = permissionDetails.type === 'limited' &&
        (usage[permission] || 0) >= permissionDetails.limit;

    const getSubscriptionInfo = () => {
        if (isInPlan) {
            if (isLimitReached) {
                return "Usage limit reached. Upgrade for more.";
            }
            return "In your plan";
        } else {
            return "Upgrade your subscription to access this feature.";
        }
    };

    const showUpgradeAlert = () => {
        Swal.fire({
            title: 'Upgrade Your Plan',
            text: 'To access this feature, you need to upgrade your subscription plan.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Upgrade Now',
            cancelButtonText: 'Maybe Later',
            customClass: {
                confirmButton: 'swal-btn-confirmButton', // Apply your custom class to the confirm button
                cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
            },
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/subscription');

            }
        });
    };

    const renderIcon = () => {
        if (isInPlan && !isLimitReached) {
            return <FaCheckCircle style={{ color: 'green', marginLeft: '5px' }} />;
        } else {
            return <FaLock style={{ marginLeft: '5px' }} />;
        }
    };

    const renderTooltip = () => (
        <span
            style={{
                position: 'absolute',
                bottom: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'rgba(0,0,0,0.8)',
                color: 'white',
                padding: '5px',
                borderRadius: '4px',
                whiteSpace: 'nowrap',
                zIndex: 1000,
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
            }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showUpgradeAlert();
            }}
        >
            {getSubscriptionInfo()}
            <FaInfoCircle style={{ marginLeft: '5px' }} />
        </span>
    );

    const renderUsageInfo = () => {
        if (permissionDetails.type === 'limited') {
            const currentUsage = usage[permission] || 0;
            const limit = permissionDetails.limit;
            return (
                <span style={{
                    marginLeft: '5px',
                    fontSize: '0.8em',
                    color: isLimitReached ? 'red' : 'gray'
                }}>
                    ({currentUsage}/{limit})
                </span>
            );
        }
        return null;
    };

    const content = (
        <>
            {children}
            {renderUsageInfo()}
            <span
                style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {renderIcon()}
                {showTooltip && renderTooltip()}
            </span>
        </>
    );

    if (isLocked || isLimitReached) {
        return (
            <span
                className={`${className} disabled-link`}
                style={{ cursor: 'pointer' }}
                onClick={showUpgradeAlert}
            >
                {content}
            </span>
        );
    } else {
        return (
            <Link to={to} className={className}>
                {content}
            </Link>
        );
    }
};

export default ProtectedLink;