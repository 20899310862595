import defaultLogo from "../../assets/images/logo_white-main.webp";
import nationalDayLogo from '../../assets/images/K-Wealth-Saudi-national-Day Identity_Main-Logo.png';

import {Link, useNavigate} from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Navbar.module.css";
import './Search.css'
import { TiArrowSortedDown, TiArrowSortedUp} from "react-icons/ti";
import defImage from "../../assets/images/defimage.webp";
import useSearchTobBar from "../../Hooks/useSearchTobBar";
import useMenueTopBar from "../../Hooks/useMenueTopBar";
import LanguageSwitch from "../../LanguageSwitch";
import useCustomTranslation from "../../Hooks/useTranslation";
import chatimg from '../../assets/icons/chatboticon.webp';
import nationalDayLogoChat from '../../assets/icons/gifday.webp';
import StockSearchComponent from "./StockSearchComponent";
import Notification from "../Notification/Notification";
import { AvatarComponent } from 'avatar-initials';
import ThemeToggle from "../../ThemeToggle";
import {useTheme} from "../../Context/ThemeContext";
import ProtectedLink from "../ProtectedLink/ProtectedLink";
import {useAuth} from "../../Hooks/useAuth";

const Navbar = () => {
    const { theme } = useTheme();
    const backgroundColor = theme === 'default' ? '#451952' : '#008c45';


    const { t } = useCustomTranslation();

    // eslint-disable-next-line react-hooks/rules-of-hooks

    const { navigate, isActive,inputSearchRef} = useSearchTobBar();
    // eslint-disable-next-line react-hooks/rules-of-hooks

    const {
     isCollapsedAlerts,
     isCollapsedDashbored,
     isCollapsedSupport,
     isCollapsedPolicies,
     isCollapsedMyAccount,
     isCollapsedProfile,
     supportCollapseRef,
     policiesCollapseRef,
     dashboredCollapseRef,
     myAccountCollapseRef,
     alertCollapseRef,
     profileCollapseRef,
     isUserLoggedIn,
     setIsUserLoggedIn,
     userProfile,
     cookies,
     setCookie,
     removeCookie,
     dropdownOpen,
     setDropdownOpen,
     handleCollapseSupportToggle,
     handleCollapseProfileToggle,
     handleCollapseDashboredToggle,
     handleCollapseMyAccountToggle,
     handleCollapsePoliciesToggle,
     handleCollapseAlertsToggle} = useMenueTopBar()




    const { logout } = useAuth();

    useEffect(() => {
        if (isActive) {
            inputSearchRef.current && inputSearchRef.current.focus();
        }
    }, [isActive]);

    return (
        <>
            <nav className="navbar navbar-expand-lg p-0">
                <div className="container-fluid">
                    <div>
                        <Link className="navbar-brand" to={"/"}>
                            <img
                                src={theme === 'default' ? defaultLogo : nationalDayLogo}
                                className=""
                                style={{width:"124px"}}
                                alt="k wealth logo"
                            />
                        </Link>
                    </div>
                    {/* Toggle button */}
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                   <StockSearchComponent/>
                        <ul className={`navbar-nav mb-2 mb-lg-0 align-items-center ${styles.nav_container}`}>
                            <li className="nav-item">
                                <div className="d-flex align-items-center">
                                    <a
                                        className={`${styles.sideLink} `}
                                        aria-expanded={isCollapsedSupport}
                                        onClick={handleCollapseSupportToggle}
                                    >
                                        {t('Support')}{" "}
                                        {isCollapsedSupport ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}
                                    </a>
                                </div>
                                <div
                                    className={`collapse ${isCollapsedSupport ? "show" : ""}`}
                                    ref={supportCollapseRef}
                                >
                                    <ul
                                        className={`${styles.collapsedropdown} btn-toggle-nav list-unstyled fw-normal pb-1 small pt-16 d-flex flex-column gap-16`}
                                    >
                                        <li>
                                            <ProtectedLink to={'generatreports'} permission="generatreports" className={`${styles.linkSupport}`}>
                                                {t('Generating Report')}
                                            </ProtectedLink>
                                        </li>
                                        <li>
                                            <ProtectedLink to={'faqs'} permission="faqs" className={`${styles.linkSupport}`}>
                                                {t('FAQ')}
                                            </ProtectedLink>
                                        </li>
                                        <li>
                                            <ProtectedLink to={'contactus'} permission="contactus" className={`${styles.linkSupport}`}>
                                                {t('Contact Us')}
                                            </ProtectedLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="d-flex align-items-center">
                                    <ProtectedLink to={"/stocks"} permission="stocks" className={`${styles.sideLink}`}>
                                        {t('Stocks')}
                                    </ProtectedLink>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="d-flex align-items-center">
                                    <ProtectedLink to={"/indices/%5ETASI"} permission="indices" className={`${styles.sideLink}`}>
                                        {t('Indices')}
                                    </ProtectedLink>
                                </div>
                            </li>

                            {isUserLoggedIn ? (
                                    <>
                                        <li className="nav-item">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex align-items-center position-relative">
                                                    <a
                                                        className={`${styles.sideLink} `}
                                                        aria-expanded={isCollapsedMyAccount}
                                                        onClick={handleCollapseMyAccountToggle}
                                                    >
                                                        {t("My Account")}{" "}
                                                        {isCollapsedMyAccount ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className={`collapse ${isCollapsedMyAccount ? "show" : ""}`}
                                                ref={myAccountCollapseRef}
                                            >
                                                <ul className={`${styles.collapsedropdown} btn-toggle-nav list-unstyled fw-normal pb-1 small pt-16 d-flex flex-column gap-16`}>
                                                    <li>
                                                        <ProtectedLink permission="watchlist" to="/watchlist" className={`${styles.linkSupport}`}>
                                                            {t('My Watchlist')}
                                                        </ProtectedLink>
                                                    </li>
                                                    <li>
                                                        <ProtectedLink   permission="portfolio_stock"
                                                                         to="portfolio"
                                                                         className={styles.linkSupport}
                                                        >
                                                            {t('My Portfolio')}
                                                        </ProtectedLink>
                                                    </li>
                                                    <li>
                                                        <ProtectedLink to="/simulation" permission="simulation" className={styles.linkSupport}>
                                                            {t('Simulation')}
                                                        </ProtectedLink>
                                                    </li>
                                                    <li>
                                                        <ProtectedLink permission="mySubscription" to={"/mySubscription"} className={`${styles.linkSupport}`}>
                                                            {t('My Subscription')}
                                                        </ProtectedLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex align-items-center position-relative">
                                                    <a
                                                        className={`${styles.sideLink}`}
                                                        aria-expanded={isCollapsedAlerts}
                                                        onClick={handleCollapseAlertsToggle}
                                                    >
                                                        {t("Alert Center")}{" "}
                                                        {isCollapsedAlerts ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className={`collapse ${isCollapsedAlerts ? "show" : ""}`}
                                                ref={alertCollapseRef}
                                            >
                                                <ul className={`${styles.collapsedropdown} btn-toggle-nav list-unstyled fw-normal pb-1 small pt-16 d-flex flex-column gap-16`}>
                                                    <li>
                                                        <ProtectedLink permission="stock_alert"
                                                                       to={"/alert-center/price"}
                                                                       className={`${styles.linkSupport}`}>
                                                            {t('Price')}
                                                        </ProtectedLink>
                                                    </li>
                                                    <li>
                                                        <ProtectedLink permission="stock_alert_tag"
                                                                       to={"/alert-center/tags_prices"}
                                                                       className={`${styles.linkSupport}`}>
                                                            {t('Tags Price')}
                                                        </ProtectedLink>
                                                    </li>
                                                    <li>
                                                        <ProtectedLink to={"/news"} permission="news"
                                                                       className={`${styles.linkSupport}`}>
                                                            {t('News')}
                                                        </ProtectedLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="d-flex align-items-center">
                                                <ProtectedLink to={"/subscription"} permission="subscription"
                                                               className={`${styles.sideLink}`}>
                                                {t('Subscription')}
                                                </ProtectedLink>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="d-flex align-items-center">
                                                <ProtectedLink to={"/stockScreener"} permission="stockScreener" className={`${styles.sideLink}`}>
                                                    {t('Screener')}
                                                </ProtectedLink>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="d-flex align-items-center">
                                                <a
                                                    className={`${styles.sideLink} `}
                                                    aria-expanded={isCollapsedProfile}
                                                    onClick={handleCollapseProfileToggle}
                                                >
                                                    {userProfile && (
                                                        <>
                                                            <div
                                                                className="d-flex align-items-center justify-content-center">
                                                                {userProfile?.image ? (
                                                                    <>
                                                                        <img src={userProfile?.image}
                                                                             alt={userProfile?.name}
                                                                             className="profile-image-rounded"/>
                                                                    </>
                                                                ) : (
                                                                    // <img src={defImage} alt="Default" className="profile-image-rounded" />
                                                                    <AvatarComponent
                                                                        classes="rounded-full"
                                                                        useGravatar={false}
                                                                        size={30}
                                                                        primarySource={userProfile?.image}
                                                                        color="#fff"
                                                                        background={backgroundColor}
                                                                        fontSize={14}
                                                                        fontWeight={500}
                                                                        offsetY={15}
                                                                        initials={`${userProfile?.name[0]}${userProfile?.name[1]}`}
                                                                    />
                                                                )}
                                                                <span className={`ml-8 mr-8 ${styles.userProfileButton}`}>
                                                              <p className="m-0">{userProfile.name}</p>
                                                            </span>
                                                                {isCollapsedProfile ? <TiArrowSortedUp/> :
                                                                    <TiArrowSortedDown/>}
                                                            </div>
                                                        </>
                                                    )}

                                                </a>
                                            </div>
                                            <div
                                                className={`collapse ${isCollapsedProfile ? "show" : ""}`}
                                                ref={profileCollapseRef}
                                            >
                                                <ul
                                                    className={`${styles.collapsedropdown} btn-toggle-nav list-unstyled fw-normal pb-1 small pt-16 d-flex flex-column gap-16`}
                                                >
                                                    <li>
                                                        <ProtectedLink to="/userprofile" permission="userprofile"
                                                              className={`${styles.linkSupport}`}>
                                                            {t('User Profile')}
                                                        </ProtectedLink>
                                                    </li>
                                                    <li>
                                                        <Link onClick={logout}
                                                              className={`${styles.linkSupport} text-danger`}>
                                                            {t('Logout')}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                    </>
                                ) :
                                <Link to="/login" className={`${styles.sideLink} `}>
                                    {t('Login')}
                                </Link>
                            }

                            <li className="nav-item">
                                <LanguageSwitch/>
                            </li>
                            <li className="nav-item">
                            <ThemeToggle/>

                            </li>
                            <li className="nav-item"><Notification/></li>
                            <li className="nav-item">
                                <div className="d-flex align-items-center">
                                    <ProtectedLink permission="stocks/chat/message" to={"/chatbot"} className={`${styles.sideLink} `}>
                                        <img
                                            src={theme === 'default' ? chatimg : nationalDayLogoChat}
                                            className={`${styles.chatIconNavbar}`}
                                            alt="k wealth logo"
                                        />
                                        {/*<img className={`${styles.chatIconNavbar}`} src={chatimg}/>*/}
                                    </ProtectedLink>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;