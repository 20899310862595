import React from 'react'
import { TiDelete } from 'react-icons/ti'
import defaultImage from '../../assets/images/defimage.webp'
import useCustomTranslation from "../../Hooks/useTranslation";

const DeleteAccount = () => {
  const {t}= useCustomTranslation();
  return (
    <div className="form-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 my-5">
            <div className="delete-account">
              <h2>{t('Delete Account')}</h2>
              <span>{t('Are you sure you want to Delete Your Account?')}</span>
              <p>{t('Please, Note that In case you delete your account you can not restore all saved data')}</p>
              <div className="d-flex align-items-center justify-content-center gap-24">
                <button className="delete-profile-btn">{t('Yes, Delete')}</button>
                <button className="cancel-profile-btn">{t('Cancel')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteAccount