import {Spinner} from "react-bootstrap";
import styled, {keyframes} from "styled-components";

const useCustomLoader = () => {
    const rotate360 = keyframes`
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    `;
    const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 1px solid grey;
	border-right: 1px solid grey;
	border-bottom: 1px solid grey;
	border-left: 4px solid var(--secondary-color-1);
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;
    const customLoader = () => (
        <div style={{ padding: '24px' }}>
            <Spinner />
        </div>
    );
    return {
        customLoader
    };
}

export default useCustomLoader;
