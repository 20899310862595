import React, { useEffect, useState } from 'react';
import styles from '../../PortfolioTab.module.css';
import { BulletList } from 'react-content-loader';
import Slider from 'react-slick';

const News = ({ data:stocks, newsUpdate, isLoading, error, fetchNewsUpdate }) => {
  const [activeStockId, setActiveStockId] = useState( newsUpdate.selectedId ?? stocks?.[0].stock.code);

  useEffect(() => {
    if (stocks && stocks.length > 0) {
      const firstStockCode = newsUpdate.selectedId ?? stocks[0].stock.code;
      const firstStockId = newsUpdate.selectedId ?? stocks[0].stock.code;
      
      // Only fetch news update if it hasn't been fetched yet
      if (!newsUpdate.data.length) {
        fetchNewsUpdate(firstStockCode);
      }
      
      setActiveStockId(firstStockId);
    }
  }, [stocks, newsUpdate.data.length, newsUpdate.selectedId]);


  function truncateContent(content, maxWords) {
    const words = content.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return content;
  }

  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    // autoplay: true,
    // speed: 20000,
  };

  if (isLoading || !stocks) {
    return <BulletList />;
  }

  return (
    <>
        <div>
          {stocks.length > 0 && (
            <>
              <Slider {...settings}>
                {stocks.map((stock) => (
                  <div className="px-2">
                    <button
                    key={stock?.id}
                    className={`${
                      styles[
                        activeStockId === stock?.stock?.code
                          ? 'btn-news-watchlist-actives'
                          : 'btn-news-watchlist'
                        ]
                    }`}
                    onClick={() => {
                      fetchNewsUpdate(stock?.stock?.code);
                      setActiveStockId(stock?.stock?.code);
                    }}
                  >
                    {stock?.stock?.name}
                  </button>
                  </div>
                ))}
              </Slider>

              <div>
                {newsUpdate.data.length === 0 ? (
                    <BulletList />
                ) : (
                  <>
                    {newsUpdate.data.map((newsItem, index) => (
                      <a key={index} href={newsItem.link} target="_blank" rel="noreferrer">
                        <div
                          className={`d-flex justify-content-between gap-2 px-4 py-3 align-items-center bg-natural-color ${styles.card_news_update}`}
                        >
                          <div className="fs-16 fw-500 text-primary-color">
                            <p className="mb-2">{newsItem.title}</p>
                            <div className={`${styles['your-div']}`}>
                              <p className="text-natural-color-3 mb-2">
                                {truncateContent(newsItem.content, 60)}
                              </p>
                            </div>
                            <div className="d-flex">
                              {newsItem.tags.length > 0 && (
                                <>
                                  <span className="text-natural-color-3 m-0">Tags:</span>
                                  <div className="d-flex flex-wrap gap-2">
                                    {newsItem.tags.slice(0, 4).map((tag, i) => (
                                      <span key={i} className="text-natural-color-3 m-0">
                                        #{tag},
                                      </span>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div>
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              {/* ... (existing SVG code) */}
                            </svg>
                          </div>
                        </div>
                      </a>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
    </>
  );
};

export default News;
