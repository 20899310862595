import React from "react";
import "../Officers/Officers.css";
import {BulletList} from "react-content-loader";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import {abbreviateNumber} from "../../../../Hooks/useNumberFormatting";
const Valuation = (props) => {
  const { t } = useCustomTranslation();
  return (
    <>
      {props.loading && <BulletList />}
      {!props.loading && (
          <div className="row">
            <div className="col-lg-12 my-24">
              <div className="card card-highlighter h-100">
                <div className="card-body">
                  <h5 className="card-title text-center">{t("Valuation")}</h5>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t("TrailingPE")}: </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.TrailingPE)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t("ForwardPE")}: </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.ForwardPE)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t("PriceSalesTTM")}: </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.PriceSalesTTM)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t("PriceBookMRQ")}: </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.PriceBookMRQ)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                      <span className="fw-bold">{t("EnterpriseValue")}: </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.EnterpriseValue)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                    <span className="fw-bold">
                      {t("EnterpriseValueRevenue")}:{" "}
                    </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.EnterpriseValueRevenue)}
                    </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                    <span className="fw-bold">
                      {t("EnterpriseValueEbitda")}:{" "}
                    </span>
                      <span className="text-secondary ms-2">
                      {abbreviateNumber(props.EnterpriseValueEbitda)}
                    </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      )}
    </>
  );
};

export default Valuation;
