import React, { useEffect, useState } from 'react'
import CommonBanner from '../CommonBanner/CommonBanner'
import styles from './Portfolios.module.css';
import PortfolioTab from './PortfolioTab/PortfolioTab';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import {apiUrlServer, getPortfolio} from '../../apiConfig'
import useCustomTranslation from "../../Hooks/useTranslation";

const MyPortfolio = () => {
  const { id } = useParams(); // Extract the id parameter from the URL
  const [myPortfolio, setMyPortfolio] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  // console.log('Fetching watchlist details for id:', id);

  useEffect(()=>{
    const token = cookies.token;
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrlServer}${getPortfolio}/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          },
        });
        const result = response.data;

        if(result.status){
          setMyPortfolio(result.data);
          // console.log(result.data);
        } else{
          // console.log('Error fetching My Portfolio details:',result.message)
        }
      }catch (err){
        console.error('Error fetching My Portfolio details', err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

  },[cookies.token, id])
  const {t} = useCustomTranslation();
  return (
    <>
      {/*<CommonBanner title={myPortfolio?.name}/>*/}
      <PortfolioTab/>
    </>
  )
}
export default MyPortfolio;