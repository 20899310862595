import React, { useContext, useEffect, useState } from 'react'
import styles from "./Header.module.css";
import defImage from '../../assets/images/defimage.webp';
import { AuthContext } from '../../Context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import './Search.css'
import { useUserProfile } from '../../Context/UserContext'
import Navbar from "./Navbar";
import {apiUrlServer, getAllStocks} from "../../apiConfig";
import StockSearchComponent from "./StockSearchComponent";

const Header = () => {
  return (
    <>
    <div className={`${styles["header-nav-container"]}`}>
      <div className={`${styles["header-nav"]}`}>
        <Navbar/>
      </div>
    </div>
    </>
  );
};

export default Header;
