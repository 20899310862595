import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../IndicesDetails";
import styles from "./IndicesList.module.css";

import BannerIndeices from "../Banner/BannerIndeices";
import { Helmet } from "react-helmet";
import { BulletList } from "react-content-loader";
import { apiToken, exchangeSymbolList, liveDate } from "../../../apiConfig";
import { Line } from "react-chartjs-2";
import NewsChart from "../../../News/NewsChart";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import {formatNumber} from "../../../Hooks/useNumberFormatting";
import useCustomTranslation from "../../../Hooks/useTranslation";
import IndciesDetails from "../../Home/IndciesDetails";

const IndicesList = () => {
  const [data, setData] = useState([]);
  const [indicesData, setIndicesData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const [companyLiveDateLoading, setCompanyLiveDateLoading] = useState(true);
  const [companyLiveDate, setCompanyLiveDate] = useState("");

  const changeValue = indicesData.change;
  const isPositive = changeValue >= 0;
  const colorStyle = {
    color: isPositive ? "green" : "red",
  };

  let parsNewsChartData;

  const fetchNewsChart = async () => {
    try {
      const response = await axios.get(
          "https://eodhd.com/api/eod/^TASI.SR?api_token=6523dd3ad3c6c2.14907660&fmt=json"
      );
      parsNewsChartData = response.data.map((dataItem) => ({
        time: dataItem.date, // Rename 'date' to 'time'
        open: dataItem.open,
        high: dataItem.high,
        low: dataItem.low,
        close: dataItem.close,
      }));

      setIndicesData(parsNewsChartData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching news chart:", error);
    }
  };

  useEffect(() => {
    fetchNewsChart();
  }, []);

  useEffect(() => {
    setCompanyLiveDateLoading(true);
    axios
        .get(`${liveDate}/^TASI.SR?api_token=${apiToken}&fmt=json`)
        .then((response) => {
          setCompanyLiveDate(response.data);
          setCompanyLiveDateLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching live data:", error);
          setCompanyLiveDateLoading(false);
        });
  }, []);

  useEffect(() => {
    setCompanyLiveDateLoading(true);
    axios
        .get(`${liveDate}/^Tasi.SR?api_token=${apiToken}&fmt=json`)
        .then((response) => {
          setCompanyLiveDate(response.data);
          setCompanyLiveDateLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching live data:", error);
          setCompanyLiveDateLoading(false);
        });
  }, []);
  const formattedPreviousClose = formatNumber(companyLiveDate.previousClose);
  const formattedOpen = formatNumber(companyLiveDate.open);
  const formattedChange = formatNumber(companyLiveDate.change);
  const formattedVolume = formatNumber(companyLiveDate.volume);
  const {t}= useCustomTranslation();
  return (
      <>
        <Helmet>
          <title>{t('Indices')}</title>
        </Helmet>
        <div className="">
          <BannerIndeices name={t('Indices')} />
          {loading && <BulletList />}
          {!loading && (
              <section className="tasi_card tasi_card-first mt-24">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="d-flex flex-column justify-content-between h-100">

                        <IndciesDetails timestamp={companyLiveDate?.timestamp}
                                        open={companyLiveDate?.open}
                                        close={companyLiveDate?.close}
                                        previousClose={companyLiveDate?.previousClose}
                                        change_p={companyLiveDate?.change_p}
                                        change={companyLiveDate.change}
                        />
                        <div className="my-3 ">
                          <div className="">
                            <NewsChart  data={indicesData}/>
                          </div>
                          <div className="d-flex justify-content-start mt-3">
                            <Link to={`/indices/${companyLiveDate.code.split('.')[0]}`}>
                              <button className="btn btn-primary">{t('View Indices Details')}</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          )}
        </div>
      </>
  );
};

export default IndicesList;
