import React, { useEffect, useState } from "react";
import styles from "./AnnouncementTab.module.css";
import axios from "axios";
import { BulletList } from 'react-content-loader'
import MyLoader from '../../../../MyLoader'
import { apiToken, apiNewsUrl } from '../../../../../apiConfig'
import useNews from "../../../../../Hooks/useNews";

function AnnouncementTabContent() {
  const { newsUpdate, visibleItems, loading, newsByDate, truncatedContent, loadMoreItems } = useNews(apiNewsUrl, apiToken);

  return (
      <>
        {/* Conditionally render loader while data is loading */}
        {loading && <BulletList  />}

        {/* Render content when data is available */}
        {!loading &&
            Object.keys(newsByDate).map((date) => (
                <div key={date}>
                  <div className="d-flex gap-2 px-4 py-3 align-items-center m-0">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                      <g clipPath="url(#clip0_105_43667)">
                        <path
                            d="M14.125 1.25H13.375V0H12.125V1.25H3.875V0H2.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V14.125C0 15.1589 0.841125 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM14.75 14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.53038 14.75 1.25 14.4696 1.25 14.125V5.875H14.75V14.125ZM14.75 4.625H1.25V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H2.625V3.75H3.875V2.5H12.125V3.75H13.375V2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V4.625Z"
                            fill="#1D1A39"
                        />
                        <path
                            d="M2.375 7.1875H3.625V8.4375H2.375V7.1875ZM4.875 7.1875H6.125V8.4375H4.875V7.1875ZM7.375 7.1875H8.625V8.4375H7.375V7.1875ZM9.875 7.1875H11.125V8.4375H9.875V7.1875ZM12.375 7.1875H13.625V8.4375H12.375V7.1875ZM2.375 9.6875H3.625V10.9375H2.375V9.6875ZM4.875 9.6875H6.125V10.9375H4.875V9.6875ZM7.375 9.6875H8.625V10.9375H7.375V9.6875ZM9.875 9.6875H11.125V10.9375H9.875V9.6875ZM2.375 12.1875H3.625V13.4375H2.375V12.1875ZM4.875 12.1875H6.125V13.4375H4.875V12.1875ZM7.375 12.1875H8.625V13.4375H7.375V12.1875ZM9.875 12.1875H11.125V13.4375H9.875V12.1875ZM12.375 9.6875H13.625V10.9375H12.375V9.6875Z"
                            fill="#1D1A39"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_105_43667">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="fs-14 fw-600 text-primary-color ">{date}</span>
                  </div>
                  {newsByDate[date].slice(0, visibleItems).map((item, index) => (
                      <>
                        <a key={index} href={item.link} target="_blank">
                          {loading ? (
                              <MyLoader key={index} />
                          ) : (
                              <div
                                  key={index}
                                  className={`d-flex justify-content-between gap-2 px-4 py-5 align-items-center bg-natural-color ${styles.card_news_update}`}
                              >
                                <div className="fs-16 fw-500 text-primary-color">
                                  {item.title}

                                  <div className={`${styles["your-div"]}`}>
                                    <p className="text-natural-color-3 mb-2">
                                      {truncatedContent(item.content, 60)}
                                    </p>

                                  </div>
                                  <div className="d-flex  ">
                                    {item.tags.length > 0 && (
                                        <>
                          <span className="text-natural-color-3 m-0">
                            Tags:
                          </span>

                                          <div className="d-flex flex-wrap gap-2">
                                            {item.tags.slice(0, 4).map((tag, i) => (
                                                <span
                                                    key={i}
                                                    className="text-natural-color-3 m-0"
                                                >
                                #{tag},
                              </span>
                                            ))}
                                          </div>
                                        </>
                                    )}
                                  </div>
                                </div>


                              </div>
                          )}
                        </a>
                      </>
                  ))}
                </div>
            ))}

        {visibleItems < newsUpdate.length && (
            <div className="d-flex justify-content-center mt-3">
              <button className={`${styles["btn-load-more"]} btn`} onClick={loadMoreItems}>
                Load More
              </button>
            </div>
        )}
      </>
  );
}

export default AnnouncementTabContent;
