import React, { useEffect, useState } from 'react'
import styles from '../watchlist.module.css'
import { useCookies } from 'react-cookie'
import {addGroupStock, apiUrlServer, getAllGroups} from '../../../apiConfig'
import axios from 'axios'

import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import iconSuccess from '../../../assets/icons/suceessAdd.png'
import iconError from '../../../assets/icons/errorCancel.png'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import useCustomTranslation from '../../../Hooks/useTranslation';
import { selectStyle } from '../../../Hooks/SelectElStyle';
import {useLanguage} from "../../../Context/LanguageContext";
import {useWatchListAddStocks} from "../../../Context/WatchListAddStocksContext";

const AddStocks = () => {
  const {t} = useCustomTranslation();
  const { id } = useParams(); // Extract the id parameter from the URL

  const [indexes, setIndexes] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [cookies] = useCookies(['token']);
  const [limit, setLimit] = useState(400);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionIndex, setSelectedOptionIndex] = useState('');
  const {language} = useLanguage();
  const { addWatchListStock } = useWatchListAddStocks();
  const { setWatchListStocks } = useWatchListAddStocks(); // Correctly destructure setWatchListStocks


  const handleMenuScroll = async (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      // User has scrolled to the bottom
      setLimit((prevLimit) => prevLimit + 10);
    }
  };

  useEffect(() => {
    const fetchIndexesData = async () => {
      const token = cookies.token;
      try {
        const response = await axios.get(`${apiUrlServer}indexes`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept-Language': language,
          },
          // params: {
          //   limit: limit,
          // },
        });
        const result = response.data;
        // console.log(result);

        if (result.status) {
          setIndexes(result.data);
          if (result.data && result.data.length > 0) {
            const firstIndex = result.data[0];
            setSelectedOptionIndex({ value: firstIndex.id, label: firstIndex.name });
            formik.setFieldValue('index_id', firstIndex.id);
          }
        } else {
          console.error('Error fetching data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchIndexesData(); // Call the fetchData function here
    const fetchStocksData = async () => {
      const token = cookies.token;
      try {
        const response = await axios.get(`${apiUrlServer}stocks`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept-Language': language,
          },
          params: {
            limit: limit,
          }
        });
        const result = response.data;
        // console.log(result);

        if (result.status) {
          setStocks((prevStocks) => [...prevStocks, ...result.data]);

          // setStocks(result.data);
        } else {
          console.error('Error fetching data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchStocksData(); // Call the fetchData function here
  },[cookies.token, limit]); // Add cookies.token to the dependency array
  // async function handleAddStock(values) {
  //   // console.log(values);
  //   setIsLoading(true);
  //   try {
  //     const token = cookies.token;
  //     const response = await axios.post(`${apiUrlServer}${addGroupStock}`, values, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data',
  //         'Accept-Language': language,
  //       },
  //     });
  //     // console.log('watchlist updated successfully:', response.data.data);
  //
  //     // Show SweetAlert success notification
  //     Swal.fire({
  //       title: t('Your Watchlist Group Added successfully'),
  //       iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
  //       text: t('Start Adding Stocks to your Group'),
  //       confirmButtonText: t('Go to My Group'),
  //       cancelButtonText: t('Back to Watchlist'),
  //       customClass: {
  //         confirmButton: 'swal-btn-confirmButton',
  //         cancelButton: 'swal-btn-cancelButton',
  //       },
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         window.location.reload();
  //       }
  //     });
  //   } catch (error) {
  //     console.error('Server error:', error.response?.data);
  //     console.error('Error updating watchlist:', error);
  //
  //     // Show SweetAlert error notification
  //     const responseMessage = error.response?.data?.message || 'Unknown error'; // Get error message from response
  //     Swal.fire({
  //       title: t('Something went Wrong'),
  //       text: responseMessage === 'The name has already been taken.'
  //           ? t('Please choose a different name.')
  //           : t('Please, Check your Internet connection and try again'),
  //       iconHtml: `<img src="${iconError}" alt="Success Icon" class="custom-icon" />`,
  //       confirmButtonText: t('Back to Watchlist'),
  //       customClass: {
  //         confirmButtonText: 'swal-btn-cancelButton',
  //       },
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  // async function handleAddStock(values) {
  //   setIsLoading(true);
  //   try {
  //     const token = cookies.token;
  //     const response = await axios.post(`${apiUrlServer}${addGroupStock}`, values, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data',
  //         'Accept-Language': language,
  //       },
  //     });
  //
  //     // Fetch the updated list of stocks
  //     const updatedGroupResponse = await axios.get(`${apiUrlServer}${getAllGroups}/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data',
  //         'Accept-Language': language,
  //       },
  //     });
  //
  //     if (updatedGroupResponse.data.status) {
  //       setWatchListStocks(updatedGroupResponse.data.data.stocks);
  //     }
  //
  //     // Show success notification
  //     Swal.fire({
  //       title: t('Your Stock Added successfully'),
  //       iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
  //       text: t('The stock has been added to your watchlist group'),
  //       confirmButtonText: t('OK'),
  //       customClass: {
  //         confirmButton: 'swal-btn-confirmButton',
  //       },
  //     });
  //
  //     // Reset the form
  //     formik.resetForm();
  //     setSelectedOption('');
  //     setSelectedOptionIndex('');
  //
  //     // Call the onStockAdded callback
  //     if (onStockAdded) {
  //       onStockAdded();
  //     }
  //   } catch (error) {
  //     console.error('Server error:', error.response?.data);
  //     console.error('Error updating watchlist:', error);
  //
  //     // Show SweetAlert error notification
  //     const responseMessage = error.response?.data?.message || 'Unknown error';
  //     Swal.fire({
  //       title: t('Something went Wrong'),
  //       text: responseMessage,
  //       iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
  //       confirmButtonText: t('OK'),
  //       customClass: {
  //         confirmButton: 'swal-btn-cancelButton',
  //       },
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  async function handleAddStock(values) {
    setIsLoading(true);
    try {
      const token = cookies.token;
      const response = await axios.post(`${apiUrlServer}${addGroupStock}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          'Accept-Language': language,
        },
      });

      // Check if the response indicates success
      if (response.data.status) {
        // Fetch the updated list of stocks
        const updatedGroupResponse = await axios.get(`${apiUrlServer}${getAllGroups}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept-Language': language,
          },
        });

        if (updatedGroupResponse.data.status) {
          setWatchListStocks(updatedGroupResponse.data.data.stocks);
        }

        // Show success notification
        Swal.fire({
          title: t('Your Stock Added successfully'),
          iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
          text: t('The stock has been added to your watchlist group'),
          confirmButtonText: t('OK'),
          customClass: {
            confirmButton: 'swal-btn-confirmButton',
          },
        });

        // Reset the form
        formik.resetForm();
        setSelectedOption('');
        setSelectedOptionIndex('');

        // Removed the onStockAdded callback
      } else {
        // If the response status is false, throw an error to be caught by the catch block
        throw new Error(response.data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Server error:', error.response?.data);
      console.error('Error updating watchlist:', error);

      // Show SweetAlert error notification
      const responseMessage = error.response?.data?.message || error.message || 'Unknown error';
      Swal.fire({
        title: t('Something went Wrong'),
        text: responseMessage,
        iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
        confirmButtonText: t('OK'),
        customClass: {
          confirmButton: 'swal-btn-cancelButton',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }
  const formik = useFormik({
    initialValues: {
      stock_id: '', // The selected option value will be assigned here
      group_id: id,
    }, // <-- Add a comma here
    onSubmit: handleAddStock,
  });
  // Filter Search with code and name
  const uniqueStockIds = new Set();
  const uniqueStockOptions = stocks.filter((stock) => {
    if (!uniqueStockIds.has(stock.id)) {
      uniqueStockIds.add(stock.id);
      return true;
    }
    return false;
  }).map((stock) => ({ value: stock.id, label: `${stock.name} - ${stock.code}` }));

  return (
    <>
      <div className={`${styles['add-group']} ${styles['watchlist_card']} position-relative z-2 `}>
        <h2>{t('Add Stock to watchlist group')}</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-9">
              <div className="d-flex align-items-start justify-content-start gap-3 ">
                <div className="d-flex flex-column w-100 position-relative mb-16">
                <label htmlFor="market" className="form-label">{t('Select Market')}</label>
                  <Select
                      options={indexes.map((index) => ({value: index.id, label: index.name}))}
                      onMenuScrollToBottom={handleMenuScroll}
                      onChange={(selectedOptionIndex) => {
                        formik.setFieldValue('index_id', selectedOptionIndex?.value || '');
                        setSelectedOptionIndex(selectedOptionIndex);
                      }}
                      onFocus={() => setIsInputFocused(true)}
                      onBlur={formik.handleBlur}
                      value={selectedOptionIndex}
                      placeholder={selectedOptionIndex.label}
                      styles={selectStyle}
                  />
                  {/* {selectedOptionIndex && (
                      <>
                        <div className="d-flex align-items-center justify-content-start">
                          <div className={`${styles['selcet-stock-label']}`}> Selected Option:</div>
                          <div className={`${styles['selcet-stock-box']}`}>
                            {selectedOptionIndex.label}
                          </div>
                        </div>
                      </>
                  )} */}
                </div>
                <div className="d-flex flex-column w-100 position-relative">
                <label htmlFor="market" className="form-label">{t('Select Stock')}</label>
                  <Select
                      options={uniqueStockOptions}
                      onMenuScrollToBottom={handleMenuScroll}
                      onChange={(selectedOption) => {
                        formik.setFieldValue('stock_id', selectedOption?.value || '');
                        setSelectedOption(selectedOption);
                      }}
                      onFocus={() => setIsInputFocused(true)}
                      onBlur={formik.handleBlur}
                      // value={stocks.find((stock) => stock.id === formik.values.stock_id)}
                      value={selectedOption}
                      placeholder={selectedOption.label || `${t("select")}`}
                      styles={selectStyle}
                  />
                  {/* {selectedOption && (
                      <>
                        <div className="d-flex align-items-center justify-content-start">
                          <div className={`${styles['selcet-stock-label']}`}> Selected Option:</div>
                          <div className={`${styles['selcet-stock-box']}`}>
                            {selectedOption.label}
                          </div>
                        </div>
                      </>
                  )} */}
                </div>
              </div>

            </div>
            <div className="col-3  text-center">
              <button className={` btn text-center w-100 mt-32 ${styles['btn-add-group']}`}>{t('Add Stock')}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddStocks