import React, { useEffect, useState } from 'react';
import axios from "axios";
import positiveChangeArrow from '../../assets/icons/FrameGreen.png';
import negativeChangeArrow from '../../assets/icons/FrameRed.png'
import useCustomTranslation from "../../Hooks/useTranslation";
import {abbreviateNumber, formatNumber, useFormattedPercentage} from "../../Hooks/useNumberFormatting";
import { apiUrlServer } from '../../apiConfig';

const IndciesDetails = (props) => {
    const [gainersAndLosers, setGainersAndLosers] = useState([]);
    const { t } = useCustomTranslation();

    const getChangeArrow = () => {
        return props.change_p > 0 ? positiveChangeArrow : negativeChangeArrow;
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Riyadh',
        };
        const timeString = date.toLocaleString('en-US', options);
        return `${t('last time')} | ${timeString} `;
    };

    useEffect(() => {
        axios.get(`${apiUrlServer}stock/getCountGainerAndLosers`)
            .then(response => {
                setGainersAndLosers(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching live data:', error);
            });
    }, []);

    const [data, setData] = useState(null);
    const [loadings, setLoadings] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://scrapping001-c0189c3441d6.herokuapp.com/api/argaam');
                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    setError('Unexpected response from server');
                }
                setLoadings(false);
            } catch (error) {
                setError('An error occurred while fetching the data');
                setLoadings(false);
            }
        };

        fetchData();
    }, []);

    const formattedChangeP = useFormattedPercentage(props.change_p);
    const formattedClose = formatNumber(props.close);
    const formattedOpen = formatNumber(props.open);
    const formattedChange = formatNumber(props.change);
    const formattedPreviousClose = formatNumber(props.previousClose);

    return (
        <div className="container-fluid mt-3">
            <div className="row mb-2">
                <div className="col-12">
                    <small className="date-indcies">
                        {formatTimestamp(props.timestamp)}
                    </small>
                </div>
            </div>
            <div className="row align-items-center">

                <div className="col-12 col-lg-12">
                    <div className="d-flex flex-wrap justify-content-between">
                        <div className="me-3 mb-2">
                            <span className="indices-text-number">{formattedClose}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <span className={`fs-14 d-flex ${props.change_p > 0 ? 'text-success' : 'text-danger'}`}>
                        <img src={getChangeArrow()} alt="Change Arrow" className="me-1"
                             style={{width: '20px', height: '20px'}}/>
                                {formattedChange} ({formattedChangeP})
                    </span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("open")}:</small>
                            <span className="indices-text-number">{formattedOpen}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Prev.close")}:</small>
                            <span className="indices-text-number">{formattedPreviousClose}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Gainers")}:</small>
                            <span className="indices-text-number">{gainersAndLosers.positive}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Losers")}:</small>
                            <span className="indices-text-number">{gainersAndLosers.negative}</span>
                        </div>
                        <div className="mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Unchanged")}:</small>
                            <span className="indices-text-number">{gainersAndLosers.unchanged}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-12 col-lg-12">
                    <div className="d-flex flex-wrap justify-content-between">
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Number Of Trades")}:</small>
                            <span className="indices-text-number">{data && data.numberOfTransactions}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Value Traded (SAR)")}:</small>
                            <span className="indices-text-number">{data && data.tradingValue}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Volume Traded")}:</small>
                            <span className="indices-text-number">{data && data.tradingVolume}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Market Cap (SAR MN)")} :</small>
                            <span className="indices-text-number">{data && data.marketCapitalization}</span>
                        </div>
                        <div className="me-3 mb-2">
                            <small className="indices-text-details text-muted me-1">{t("Symbols Listed")}:</small>
                            <span className="indices-text-number">234</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndciesDetails;