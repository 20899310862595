import React, { useState } from 'react';
import styles from './Portfolio.module.css';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { apiUrlServer } from '../../apiConfig';
import iconSuccess from '../../assets/icons/suceessAdd.png';
import iconError from '../../assets/icons/errorCancel.png';

import '../../assets/style/swl-custome.css';
import useCustomTranslation from "../../Hooks/useTranslation";

const AddPortfolio = ({ onAddPortfolioSuccess, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['token']);

  const handleAddGroup = async (values) => {
    setIsLoading(true);

    try {
      const token = cookies.token;
      const response = await axios.post(`${apiUrlServer}portfolio`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status) {
        await Swal.fire({
          title: `${t("Your Portfolio Added successfully")}`,
          iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
          text: `${t("Start Adding Investment to your Portfolio")}`,
          confirmButtonText: `${t("Go to My Portfolio")}`,
          cancelButtonText: `${t('Back')}`,
          customClass: {
            confirmButton: 'swal-btn-confirmButton',
            cancelButton: 'swal-btn-cancelButton',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } catch (error) {
      console.error('Error adding portfolio:', error);

      await Swal.fire({
        title: 'Something went Wrong',
        text: error.message || 'Please, Check your Internet connection and try again',
        iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
        confirmButtonText: 'Back to Watchlist',
        customClass: {
          confirmButtonText: 'swal-btn-cancelButton',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Name must be at least 3 characters')
        .max(50, 'Name must be at most 50 characters'),
    }),
    onSubmit: handleAddGroup,
  });
  const {t} = useCustomTranslation();

  return (
    <>
      <div className={`${styles['add-group']}`}>
        <h2>{t('Add Portfolio')}</h2>
        <form onSubmit={formik.handleSubmit} className="add-group-form mt-16">
          <div className="d-flex ">
            <input
              name="name"
              type="text"
              placeholder={t("Portfolio Name")}
              className={`form-control ${styles['group-input']} `}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <button
              disabled={!formik.isValid || isLoading}
              type="submit"
              className={`btn ${styles['btn-add-group']}`}
            >
              {isLoading ? 'Adding Portfolio  ...' : `${t("Add Portfolio")}`}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddPortfolio;
