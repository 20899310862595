import React from "react";
import errorImage from '../../../../../assets/images/K-wealth Coming soon screen_.webp'

function LosersTab() {

  return(
  <>
    <img src={errorImage} alt="under constrictions" className="w-100 h-100" />
    {/*<StockIndicesTable />*/}
  </>
  )
}

export default LosersTab;
