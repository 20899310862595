import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../Context/AuthContext";
import { useUserProfile } from "../Context/UserContext";
import { useCookies } from "react-cookie";

const useSearchTobBar = () => {
    // State declarations
    const [isCollapsedAlerts, setIsCollapsedAlerts] = useState(false);
    const [isCollapsedDashbored, setIsCollapsedDashbored] = useState(false);
    const [isCollapsedSupport, setIsCollapsedSupport] = useState(false);
    const [isCollapsedPolicies, setIsCollapsedPolicies] = useState(false);
    const [isCollapsedMyAccount, setIsisCollapsedMyAccount] = useState(false);
    const [isCollapsedProfile, setIsisCollapsedProfile] = useState(false);

    // Refs for DOM nodes
    const supportCollapseRef = useRef(null);
    const policiesCollapseRef = useRef(null);
    const dashboredCollapseRef = useRef(null);
    const myAccountCollapseRef = useRef(null);
    const alertCollapseRef = useRef(null);
    const profileCollapseRef = useRef(null);

    // Contexts
    const { isUserLoggedIn, setIsUserLoggedIn } = useContext(AuthContext);
    const { userProfile } = useUserProfile();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // Handler functions for toggling collapse states
    const handleCollapseSupportToggle = () => setIsCollapsedSupport(!isCollapsedSupport);
    const handleCollapseProfileToggle = () => setIsisCollapsedProfile(!isCollapsedProfile);
    const handleCollapseDashboredToggle = () => setIsCollapsedDashbored(!isCollapsedDashbored);
    const handleCollapseMyAccountToggle = () => setIsisCollapsedMyAccount(!isCollapsedMyAccount);
    const handleCollapsePoliciesToggle = () => setIsCollapsedPolicies(!isCollapsedPolicies);
    const handleCollapseAlertsToggle = () => setIsCollapsedAlerts(!isCollapsedAlerts);

    // Function to handle click outside collapsible areas
    const handleClickOutside = (event) => {
        // Array of all collapsible elements
        const refs = [
            { ref: dashboredCollapseRef, setter: setIsCollapsedDashbored },
            { ref: profileCollapseRef, setter: setIsisCollapsedProfile },
            { ref: myAccountCollapseRef, setter: setIsisCollapsedMyAccount },
            { ref: supportCollapseRef, setter: setIsCollapsedSupport },
            { ref: policiesCollapseRef, setter: setIsCollapsedPolicies },
            { ref: alertCollapseRef, setter: setIsCollapsedAlerts },
        ];

        // Automatically handle outside clicks for all collapsible elements
        refs.forEach(({ ref, setter }) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setter(false);
            }
        });
    };

    // Dropdown toggle function
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    // Effect for handling outside clicks
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // Return all states, refs, and methods that components using this hook might need
    return {
        isCollapsedAlerts,
        isCollapsedDashbored,
        isCollapsedSupport,
        isCollapsedPolicies,
        isCollapsedMyAccount,
        isCollapsedProfile,
        supportCollapseRef,
        policiesCollapseRef,
        dashboredCollapseRef,
        myAccountCollapseRef,
        alertCollapseRef,
        profileCollapseRef,
        isUserLoggedIn,
        setIsUserLoggedIn,
        userProfile,
        cookies,
        setCookie,
        removeCookie,
        dropdownOpen,
        setDropdownOpen,
        handleCollapseSupportToggle,
        handleCollapseProfileToggle,
        handleCollapseDashboredToggle,
        handleCollapseMyAccountToggle,
        handleCollapsePoliciesToggle,
        handleCollapseAlertsToggle
    };
};

export default useSearchTobBar;