import React, { useState, useReducer, useEffect } from "react";
import styles from "./PortfolioTab.module.css";
import News from './PortfolioTabsDetails/NewsTab/News'

import { Summary } from "./PortfolioTabsDetails/Summary/Summary";
import StocksPortfolio from './PortfolioTabsDetails/Stocks/StocksPortfolio'
import MutualFundPortfolio from './PortfolioTabsDetails/MutualFund/MutualFundPortfolio'
import useCustomTranslation from "../../../Hooks/useTranslation";
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { apiUrlServer, portfolioStocksSummary, getPortfolioStock } from '../../../apiConfig';
import ManualEntry from "../EntryandUpload/ManualEntry";
import axios from 'axios';

// Action types
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const SET_TAB_DATA = 'SET_TAB_DATA';
const SET_LOADING = 'SET_LOADING';
const SET_ERROR = 'SET_ERROR';
const SET_NEWS_UPDATE = 'SET_NEWS_UPDATE';
const SET_SNIGEL_DATA='SET_SNIGEL_DATA'

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case SET_TAB_DATA:
      return { ...state, tabData: { ...state.tabData, [action.payload.tab]: action.payload.data } };
    case SET_SNIGEL_DATA:
      return {...state, tabData: { ...state.tabData, stocks: state.tabData.stocks ? [...state.tabData.stocks,action.payload.data] : [action.payload.data] } };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_NEWS_UPDATE:
      return { ...state, newsUpdate: action.payload };
    default:
      return state;
  }
};

// Initial state
const initialState = {
  activeTab: "summary",
  tabData: {
    summary: null,
    news: null,
    stocks: null,
    mutualFund: null
  },
  newsUpdate: { data: [] },
  isLoading: false,
  error: null
};

const PortfolioTab = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {t} = useCustomTranslation();
  const [cookies] = useCookies(['token']);
  const { id } = useParams();
  const [mergedData, setMergedData] = useState([]);

  const handleTabClick = (tab) => {
    dispatch({ type: SET_ACTIVE_TAB, payload: tab });
  };

  useEffect(() => {
    if (!state.tabData[state.activeTab]) {
      fetchDataForTab(state.activeTab);
    }
  }, [state.activeTab]);

  const fetchDataForTab = async (tab,otherParams,data=[]) => {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: SET_ERROR, payload: null });
    const token = cookies.token;

    try {
      let endpoint;
      let params = { [`search[portfolio_id]`]: id };
      
      switch (tab) {
        case 'summary':
          endpoint = `${apiUrlServer}${portfolioStocksSummary}`;
          break;
        case 'stocks':
          endpoint = `${apiUrlServer}${getPortfolioStock}/`;
          params = { ...params, page: otherParams?.page || 1, pageSize: 10, overall: otherParams?.overall ?? true };
          break;
        case 'news':
          endpoint = `${apiUrlServer}portfolio_stock`;
          break;
        case 'addStockData':
          dispatch({ type: SET_SNIGEL_DATA, payload: { tab, data: data } })
          return;
        // Add cases for other tabs here
        default:
          throw new Error('Invalid tab');
      }

      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        params: params
      });
      const result = response.data;
      if (result.status) {
        dispatch({ type: SET_TAB_DATA, payload: { tab, data: result.data } });
      } else {
        throw new Error(result.message || 'Failed to fetch data');
      }
    } catch (err) {
      console.error(`Error fetching data for ${tab}:`, err.message);
      dispatch({ type: SET_ERROR, payload: err.message });
    } finally {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  const fetchNewsUpdate = async (selectedId, token) => {
    try {
      const response = await axios.get(`${apiUrlServer}news?s=${selectedId}.SR`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: SET_NEWS_UPDATE, payload: {...response.data, selectedId} });
    } catch (error) {
      console.error('Error fetching news data:', error);
      dispatch({ type: SET_ERROR, payload: 'Failed to fetch news update' });
    }
  };
  return (
    <>
      <div className={`${styles['add-investment']}`}>
        <h2>{t('Add Investment')}</h2>
        <div className={`${styles['add-investment-btn-container']}`}>
          <ManualEntry fetchStockData={fetchDataForTab}/>
          {/* <button className={`${styles['upload-csv-btn']}`}>{t('Upload CSV')}</button> */}
        </div>
      </div>
      <section className="tasi_card">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <nav className={`${styles.dailyTabsNav}`}>
                <ul className={`${styles.dailyTabsList}`}>
                  <li>
                    <button
                        className={
                          state.activeTab === "summary" ? styles.active : undefined
                        }
                        onClick={() => handleTabClick("summary")}
                    >
                      {t('Summary')}
                    </button>
                  </li>
                  <li>
                    <button
                        className={
                          state.activeTab === "stocks"
                              ? styles.active
                              : undefined
                        }
                        onClick={() =>
                            handleTabClick("stocks")
                        }
                    >
                      {t('Stocks')}
                    </button>
                  </li>
                  {/* <li>
                    <button
                        className={
                          state.activeTab === "mutualFund" ? styles.active : undefined
                        }
                        onClick={() => handleTabClick("mutualFund")}
                    >
                      {t('Mutual Funds')}
                    </button>
                  </li> */}
                  <li>
                    <button
                        className={
                          state.activeTab === "news" ? styles.active : undefined
                        }
                        onClick={() => handleTabClick("news")}
                    >
                      {t('News')}
                    </button>
                  </li>
                </ul>
              </nav>

              <div className={styles.tabContent}>
                {state.activeTab === "summary" && <Summary data={state.tabData.summary} isLoading={state.isLoading} error={state.error} />}
                {state.activeTab === "news" && <News data={state.tabData.news} newsUpdate={state.newsUpdate} isLoading={state.isLoading} error={state.error} fetchNewsUpdate={(selectedId) => fetchNewsUpdate(selectedId, cookies.token)} />}
                {state.activeTab === "stocks" && <StocksPortfolio data={state.tabData.stocks} isLoading={state.isLoading} error={state.error} fetchData={fetchDataForTab} mergedData={mergedData} setMergedData={setMergedData} />}
                {/* {state.activeTab === "mutualFund" && <MutualFundPortfolio data={state.tabData.mutualFund} isLoading={state.isLoading} error={state.error} />} */}
            </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default PortfolioTab;
