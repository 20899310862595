import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SliderStocks from "./SliderStocks";
import {apiUrlServer} from "../../apiConfig";
import {Link} from "react-router-dom";
import useCustomTranslation from "../../Hooks/useTranslation";
import {ShimmerThumbnail} from "react-shimmer-effects";
import {useLanguage} from "../../Context/LanguageContext";
import {useCookies} from "react-cookie";
import useStocks from '../../Hooks/useStocks';
import Slider from "react-slick";
import CardsStock from "./CardsStocks";

const StockSlider = () => {
    const {
        stocks,
        loading,
    } = useStocks();
    const { t } = useCustomTranslation();

    return (
        <>
            <div className=" ">
                <div className="cards-stocks-banner border-radius-16 my-3">
                    <SliderStocks cards={stocks}/>
                </div>
            </div>
        </>
    );
};

export default StockSlider;
