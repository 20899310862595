import { useTranslation } from 'react-i18next';

const useCustomTranslation = () => {
    const { t, i18n } = useTranslation();

    if (!i18n.language) {
        return null; // or render a loading state
    }

    return { t, i18n };
};

export default useCustomTranslation;