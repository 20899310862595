import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { Link } from "react-router-dom";
import useCustomTranslation from '../Hooks/useTranslation';
import { useLanguage } from "../Context/LanguageContext";
import { useTheme } from "../Context/ThemeContext";

const NewsChart = ({ data }) => {
    const { t, i18n } = useCustomTranslation();
    const { language } = useLanguage();
    const { theme } = useTheme();
    const chartContainerRef = useRef();

    useEffect(() => {
        if (!chartContainerRef.current) return;

        const themeValues = theme === 'default' ? {
            primaryColor: '#1D1A39',
            secondaryColor1: '#451952',
            naturalColor: '#fff',
            naturalColor4: 'rgba(33, 123, 168, 0.5)',
            secondaryColor5: 'rgba(4, 156, 107, 0.94)',
            secondaryColor6: '#FD2523',
        } : {
            primaryColor: '#004a24',
            secondaryColor1: '#008c45',
            naturalColor: '#fff',
            naturalColor4: 'rgba(33, 123, 168, 0.5)',
            secondaryColor5: 'rgba(4, 156, 107, 0.94)',
            secondaryColor6: '#FD2523',
        };

        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 500,
            layout: {
                backgroundColor: themeValues.primaryColor,
                textColor: themeValues.naturalColor,
            },
            grid: {
                vertLines: {
                    color: themeValues.naturalColor4,
                },
                horzLines: {
                    color: themeValues.naturalColor4,
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: themeValues.primaryColor,
            },
            timeScale: {
                borderColor: themeValues.primaryColor,
            },
            localization: {
                locale: language
            }
        });

        const lineData = data.map(datapoint => ({
            time: datapoint.time,
            value: (datapoint.close + datapoint.open) / 2,
        }));

        const areaSeries = chart.addAreaSeries({
            lastValueVisible: false,
            crosshairMarkerVisible: false,
            lineColor: 'transparent',
            topColor: themeValues.naturalColor,
            bottomColor: themeValues.secondaryColor1,
        });

        areaSeries.setData(lineData);

        const candleSeries = chart.addCandlestickSeries({
            upColor: themeValues.secondaryColor5,
            downColor: themeValues.secondaryColor6,
            borderDownColor: themeValues.secondaryColor6,
            borderUpColor: themeValues.secondaryColor5,
            wickDownColor: themeValues.secondaryColor6,
            wickUpColor: themeValues.secondaryColor5,
        });

        candleSeries.setData(data);

        return () => {
            chart.remove();
        };
    }, [data, language, theme]);

    return (
        <>
            <Link to={'/indices/^TASI'}>
                <div ref={chartContainerRef} style={{ width: '100%', height: 'auto' }} />
            </Link>
            <div className="text-muted" style={{ textAlign: 'left', marginTop: '10px', fontSize: '12px' }}>
                {t("Powered by TradingView")} <a href="https://www.tradingview.com/economic-calendar/" className="text-muted"> {t("how to use a stock screener")}</a>
            </div>
        </>
    );
};

export default NewsChart;