import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import DailyReports from "./components/DailyReports/DailyReports";
import UpdateTab from "./components/DailyReports/DailyReportsTabs/UpdateTab/UpdateTab";
import NewsTab from "./components/DailyReports/DailyReportsTabs/UpdateTab/NewsTabContent/NewsTab";
import AnnouncementTab from "./components/DailyReports/DailyReportsTabs/UpdateTab/AnnouncementTabContent/AnnouncementTab";
import IndicesTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/IndicesTab";
import MajorTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/MajorTabContent/MajorTab";
import GainersTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/GainersTabContent/GainersTab";
import LosersTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/LosersTabContent/LosersTab";
import NotFound from "./components/NotFound/NotFound";
import React, { useEffect } from 'react'
import IndicesList from './components/Indices/IndicesList/IndicesList'
import IndicesDetails from './components/Indices/IndicesDetails'
import StocksList from './components/Stocks/StocksList/StocksList'
import StockDetails from './components/Stocks/StockDetails'
import Register from './components/Authentication/Register/Register'
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import Login from './components/Authentication/Login/Login'
import ForgetPassword from './components/Authentication/ForgetPassword/ForgetPassword'
import VerifyResetCode from './components/Authentication/VerifyResetCode/VerifyResetCode'
import ResetPassword from './components/Authentication/ForgetPassword/ResetPassword'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import AuthContextProvider from './Context/AuthContext'
import OtpConfirmation from './components/Authentication/OtpConfirmation/OtpConfirmation'
import SendOtp from './components/Authentication/SendOtp/SendOtp'
import CheckOtp from './components/Authentication/ForgetPassword/CheckOtp'
import UserProfile from './components/Profile/UserProfile'
import { UserProfileProvider } from './Context/UserContext'
import OtpConfirmChangeNumber from './components/Profile/ChangePhoneNumber/OTPConfirmChangeNumber'
import WatchList from './components/WatchList/WatchList'
import GroupStocks from './components/WatchList/GroupStocks/GroupStocks'
import Portfolio from './components/Portfolio/Portfolio'
import MyPortfolio from './components/MyPortfolio/MyPortfolio'
import Simulation from './components/Simulation/Simulation'
import SubscriptionPlan from './components/Subscription/SubscriptionPlan'
import MySubscription from './components/Subscription/MySubscription'
import PriceAlerts from './components/SpecificAlerts/PriceAlerts'
import ChatBot from "./components/ChatBot/ChatBot";
import Home from "./components/Home/Home";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Faqs from "./components/Faqs/Faqs";
import ContactForm from "./components/ContactForm/ContactForm";
import News from "./News/News";
import SingleReport from "./components/Reports/SingleReport";
import TradingViewChart from "./TradingViewChart";
import AuthenticatedRoute from './Context/AuthRoute'
import {generateToken, messaging} from "./firebase.js";
import {onMessage} from "firebase/messaging"
import StockMaxScreen from "./components/Stocks/StockMaxScreen";
import IndicesMaxScreen from "./components/Indices/IndicesMaxScreen";
import AllNotifications from "./components/Notification/AllNotifications";
import { pointer } from "d3-selection";
import AllStocks from "./components/Stocks/StocksList/AllStocks";
import NewsDetails from "./News/NewsDetails";
import GenrateReportAi from "./components/GenrateReport/GenrateReportAi";
import BalanceSheetVisualization from "./components/Stocks/StocksTab/BalanceSheet/BalanceSheetVisualization";
import SuccessPayment from "./components/Payment/SuccessPayment";
import FailPayment from "./components/Payment/FailPayment";
import FinancialVisualization from "./components/Stocks/StocksTab/FinancialVisualization";
import EarningsVisualization from "./components/Stocks/StocksTab/EarningsVisualization";
import {ThemeProvider} from "./Context/ThemeContext";
import StockScreener from "./components/StockScreener/StockScreener";
import axios from "axios";
import {PermissionProvider} from "./Context/PermissionContext";
import SingleReportsDetails from "./components/Reports/SingleReportsDetails";
import TagsAlerts from "./components/SpecificAlerts/TagsAlerts/TagsAlerts";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  let routes = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute><Layout /></ProtectedRoute>,
      children: [
        { path: "home", element: <ProtectedRoute><Home /></ProtectedRoute> },
        { path: "/", element: <ProtectedRoute><Home /></ProtectedRoute> },
        // Daily Reports and Tabs Routes
        {
          path: "dailyReports",
          element: <ProtectedRoute><DailyReports /></ProtectedRoute>,
          children: [
            {
              path: "updates",
              element: <ProtectedRoute requiredPermission="updates"><UpdateTab /></ProtectedRoute>,
              children: [
                { path: "news", element: <ProtectedRoute requiredPermission="news"><NewsTab /></ProtectedRoute> },
                { path: "announcements", element: <ProtectedRoute requiredPermission="announcements"><AnnouncementTab /></ProtectedRoute> },
                {
                  index: true,
                  element: <ProtectedRoute requiredPermission="news"><Navigate to="news" replace /></ProtectedRoute>,
                },
              ],
            },
            {
              path: "indices",
              element: <ProtectedRoute requiredPermission="indices"><IndicesTab /></ProtectedRoute>,
              children: [
                { path: "major", element: <ProtectedRoute><MajorTab /></ProtectedRoute> },
                { path: "gainers", element: <ProtectedRoute><GainersTab /></ProtectedRoute> },
                { path: "losers", element: <ProtectedRoute><LosersTab /></ProtectedRoute> },
                {
                  index: true,
                  element: <ProtectedRoute><Navigate to="major" replace /></ProtectedRoute>,
                },
              ],
            },
            {
              index: true,
              element: <ProtectedRoute><Navigate to="updates/news" replace /></ProtectedRoute>,
            },
          ],
        },
        {path: "stockScreener", element: <ProtectedRoute requiredPermission="stockScreener"><StockScreener /></ProtectedRoute>},
        { path: "indices", element: <ProtectedRoute requiredPermission="indices"><IndicesList /></ProtectedRoute> },
        { path: "indices/:code", element: <ProtectedRoute requiredPermission="indices"><IndicesDetails /></ProtectedRoute> },
        { path: "stocks", element: <ProtectedRoute requiredPermission="stocks"><AllStocks /></ProtectedRoute> },
        { path: "all-notifications", element: <ProtectedRoute requiredPermission="all-notifications"><AllNotifications /></ProtectedRoute> },
        { path: "stocks/:id/:code", element: <ProtectedRoute requiredPermission="stocks"><StockDetails /></ProtectedRoute> },
        {path: "stocksall", element: <ProtectedRoute requiredPermission="stocks"><AllStocks /></ProtectedRoute> },
        { path: "chatbot", element: <ProtectedRoute requiredPermission="stocks/chat/message"><ChatBot /></ProtectedRoute> },
        {
          path: "userprofile",
          element: <ProtectedRoute requiredPermission="userprofile"><UserProfile /></ProtectedRoute>,
        },
        {
          path: "watchlist",
          element: <ProtectedRoute requiredPermission="watchlist"><WatchList /></ProtectedRoute>,
        },
        {
          path: "/watchlist/:id",
          element: <ProtectedRoute requiredPermission="watchlist"><GroupStocks /></ProtectedRoute>,
        },
        {
          path: "alert-center/price",
          element: <ProtectedRoute ><PriceAlerts requiredPermission="stock_alert" /></ProtectedRoute>,
        },
        {
          path: "alert-center/tags_prices",
          element: <ProtectedRoute requiredPermission="stock_alert_tag"> <TagsAlerts /> </ProtectedRoute>,
        },
        {
          path: "portfolio",
          element: <ProtectedRoute requiredPermission="portfolio_stock"><Portfolio /></ProtectedRoute>,
        },
        {
          path: "portfolio/:id",
          element: <ProtectedRoute requiredPermission="portfolio_stock"><MyPortfolio /></ProtectedRoute>,
        },
        {
          path:"/simulation",
          element:<ProtectedRoute requiredPermission="simulation"><Simulation /></ProtectedRoute>,
        },
        {
          path: "subscription",
          element: <ProtectedRoute ><SubscriptionPlan /></ProtectedRoute>,
        },
        {
          path: "mySubscription",
          element: <ProtectedRoute requiredPermission="mySubscription"><MySubscription /></ProtectedRoute>,
        },
        // {
        //   path: "privacy",
        //   element: <ProtectedRoute><PrivacyPolicy /></ProtectedRoute>,
        // },
        {
          path: "privacy",
          element: <ProtectedRoute requiredPermission="privacy"><PrivacyPolicy /></ProtectedRoute>,
        },
        {
          path: "faqs",
          element: <ProtectedRoute requiredPermission="faqs"><Faqs /></ProtectedRoute>,
        },
        {
          path: "contactus",
          element: <ProtectedRoute requiredPermission="contactus"><ContactForm /></ProtectedRoute>,
        },
        {
          path: "news",
          element: <ProtectedRoute requiredPermission="news"><News /></ProtectedRoute>,
        },
        {
          path: "news/:id",
          element: <ProtectedRoute requiredPermission="news"><NewsDetails /></ProtectedRoute>,
        },
        {
          path: "reports/:id",
          element: <ProtectedRoute ><SingleReport /></ProtectedRoute>,
        },
        {
          path: "/report/:reportId",
          element: <ProtectedRoute><SingleReportsDetails /></ProtectedRoute>,
        },
        {
          path: "generatreports",
          element: <ProtectedRoute requiredPermission="generatreports"><GenrateReportAi /></ProtectedRoute>,
        },
        { path: ":statementType/:id/:code", element: <ProtectedRoute requiredPermission="statementType"><FinancialVisualization /></ProtectedRoute> },
        {path:"test/:statementType/:id/:code", element:<ProtectedRoute requiredPermission="test"><EarningsVisualization /></ProtectedRoute>},


        // {
        //   path: "omany",
        //   element: <ProtectedRoute><Omany /></ProtectedRoute>,
        // },
        // {path: "tradingviewchart",element: <ProtectedRoute><TradingViewChart /></ProtectedRoute>}
      ],
    },
    // { path: "balancesheet/:id/:code", element: <ProtectedRoute><BalanceSheetVisualization /></ProtectedRoute> },
    // { path: ":statementType/:id/:code", element: <ProtectedRoute><FinancialVisualization /></ProtectedRoute> },



    { path: "stocksmax/:id/:code", element: <ProtectedRoute><StockMaxScreen /></ProtectedRoute> },
    { path: "indicesmax/:code", element: <ProtectedRoute><IndicesMaxScreen /></ProtectedRoute> },
    { path: "indices/:code", element: <ProtectedRoute><IndicesMaxScreen /></ProtectedRoute> },
    {
      path: "success-payment",
      element: <ProtectedRoute><SuccessPayment /></ProtectedRoute>,
    },
    {
      path: "fail-payment",
      element: <ProtectedRoute><FailPayment/></ProtectedRoute>,
    },
    {path: "register", element: <Register/>},
    {path: "otp", element: <OtpConfirmation/>},
    {path: "OtpChangePhone", element: <OtpConfirmChangeNumber/>},
    {path: "sendotp", element: <SendOtp/>},
    {path: "login", element: <AuthenticatedRoute><Login/></AuthenticatedRoute>},
    {path: "forgetPassword", element: <ForgetPassword/>},
    {path: "checkotp", element: <CheckOtp/>},
    {path: "resetPassword", element: <ResetPassword/>},
    {path: "verify", element: <VerifyResetCode/>},
    {path: "*", element: <NotFound /> },
    {path: "tradingviewchart",element: <TradingViewChart />},
  ]);

  return (

      <>
        <UserProfileProvider>
          <AuthContextProvider>
            <PermissionProvider>
            <Toaster position="top-right">
              {(t) => (
                <ToastBar toast={t}>
                  {({ icon, message }) => (
                    <>
                      {icon}
                      {message}
                      {t.type !== 'loading' && (
                        <i onClick={() => toast.dismiss(t.id)} class="fa-solid fa-xmark close" style={{cursor:'pointer'}}></i>
                      )}
                    </>
                  )}
                </ToastBar>
              )}
            </Toaster>

            <RouterProvider router={routes}>
              <ScrollToTop />
            </RouterProvider>
            </PermissionProvider>
          </AuthContextProvider>
        </UserProfileProvider>
      </>
  );
}

export default App;
