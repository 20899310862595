// DeleteProfileImage.js

import React from 'react';
import axios from 'axios';
import API_URL, {apiUrlServer, deleteProfileImage} from '../../apiConfig';
import { useCookies } from 'react-cookie'
import { MdDeleteForever } from 'react-icons/md'
import { TiDelete } from 'react-icons/ti'

const DeleteProfileImage = ({ onDeleteSuccess, userProfile  }) => {
  const [cookies] = useCookies(['token']);

  const handleDelete = async () => {
    try {
      // Fetch the token from your cookies or wherever you store it
      const token = cookies.token;
      // Replace with your actual token

      // Make a POST request to delete the user's photo
      const response = await axios.post(
        `${apiUrlServer}${deleteProfileImage}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        // If deletion is successful, trigger the callback to update the user profile
        onDeleteSuccess();
      }
    } catch (error) {
      console.error('Error deleting user photo:', error);
    }
  };

  return (
    <div className="position-relative mb-24">
      {/*<h3>Delete User Photo</h3>*/}
      {userProfile.image && (
        <button className="btn-remove-image" onClick={handleDelete}><TiDelete /></button>
      )}
    </div>
  );
};

export default DeleteProfileImage;
