import React, { createContext, useContext, useState } from 'react';

const FundamentalsContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState(null);

    return (
        <FundamentalsContext.Provider value={{ data, setData }}>
            {children}
        </FundamentalsContext.Provider>
    );
};

export const useData = () => useContext(FundamentalsContext);