import React from 'react';
import CommonBanner from "../../CommonBanner/CommonBanner";
import PriceTable from "../PriceTable";
import PriceTagsTable from "./PriceTagsTable";
import useCustomTranslation from "../../../Hooks/useTranslation";

const TagsAlerts = () => {
    const {t} = useCustomTranslation();

    return (
        <>
            <CommonBanner title={t("Tags Price Alerts")} />
            <PriceTagsTable/>
        </>
    );
};

export default TagsAlerts;
