import React from 'react'
import CommonBanner from '../CommonBanner/CommonBanner'
import PortfoliotabHome from './PortfolioTab/PortfoliotabHome'
import AddPortfolio from './AddPortfolio'
import useCustomTranslation from "../../Hooks/useTranslation";
const Portfolio = () => {
const {t} = useCustomTranslation();
  return (
    <>
        <CommonBanner title={t("My Portfolios")}/>
        <AddPortfolio/>
        <PortfoliotabHome/>
    </>
  )
}

export default Portfolio