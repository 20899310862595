

const useCustomTable = () => {

    const customStyles = {
        rows: {
            style: {
                minHeight: '64px', // override the row height
                color: '#6F6F6F',
                justifyContent:'center',
            },
        },
        headCells: {
            style: {
                color: 'var(--primary-color)', // Change text color in the header cells
                fontSize: '16px', // Change font size in the header cells
                fontWeight: '700',
                justifyContent:'start',
                minHeight: '64px',
                // paddingLeft: '0',
                // paddingRight: '0',
            },
        },
        cells: {
            style: {
                justifyContent:'space-between',
                // paddingLeft: '0',
                // paddingRight: '0',

            },
        },
    };




    return {
        customStyles
    }
};

export default useCustomTable;