import React, { useState, useCallback } from 'react';
import styles from './DetailsBanner.module.css';
import fav from '../../../assets/images/Fav.svg';
import axios from 'axios';
import { apiUrlServer } from '../../../apiConfig';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import iconSuccess from '../../../assets/icons/suceessAdd.png';
import plusGreen from '../../../assets/icons/plusGreen.png';
import iconError from '../../../assets/icons/errorCancel.png';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';

const DetailsBanner = (props) => {
  const { id } = useParams();
  const [cookies] = useCookies(['token']);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = useCallback(async (page) => {
    try {
      setIsLoading(true);
      const token = cookies.token;

      const response = await axios.get(`${apiUrlServer}groups`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          page: page,
          pageSize: 10,
        },
      });

      const result = response.data;

      if (result.status) {
        setData(result.data.data);
        setTotalPages(result.data.meta.total_pages);
        setCurrentPage(page);
      } else {
        console.error('Error fetching data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setIsLoading(false);
    }
  }, [cookies.token]);

  const handlePageChange = (newPage) => {
    fetchData(newPage);
  };

  const handleModalOpen = () => {
    fetchData(1);
  };

  const handleAddStock = async (values) => {
    setIsLoading(true);
    try {
      const token = cookies.token;
      const response = await axios.post(`${apiUrlServer}group_stock`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        title: 'Your Stock Added to Group successfully',
        iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
        text: 'Thanks',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Add Stock to another Group',
        customClass: {
          confirmButton: 'swal-btn-confirmButton',
          cancelButton: 'swal-btn-cancelButton',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.error('Server error:', error.response?.data);

      const responseMessage = "The stock id has already been taken.";
      Swal.fire({
        title: 'The stock has already been added',
        text: responseMessage === responseMessage ? 'Please choose a different group.' : 'Please, Check your Internet connection and try again',
        iconHtml: `<img src="${iconError}" alt="Success Icon" class="custom-icon" />`,
        confirmButtonText: 'Back to Watchlist',
        customClass: {
          confirmButtonText: 'swal-btn-cancelButton',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      stock_id: id,
      group_id: '',
    },
    onSubmit: handleAddStock,
  });

  return (
      <>
        <section className={`${styles['block-text-two']} d-flex align-items-center justify-content-between`}>
          <div>
          <span className={`${styles['block-text']} mr-16`}>
            <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* SVG path content */}
            </svg>
            {props.name}
          </span>
          </div>
          <div>
            <a
                onClick={() => {
                  props.AddStockToWatchList();
                  handleModalOpen();
                }}
                data-bs-toggle="modal"
                data-bs-target="#quickAddModal"
            >
              <img src={fav} style={{cursor: 'pointer'}} alt="Add to Watchlist"/>
            </a>
            {/* Bootstrap Modal */}
            <div className="modal fade" id="quickAddModal" tabIndex="-1" aria-labelledby="quickAddModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className={`${styles['stocks-add-groups-modal-title']} modal-title`} id="quickAddModalLabel">WatchList Groups</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <ul className="list-unstyled">
                          {Array.isArray(data) && data.map((group) => (
                              <li className={styles['stocks-add-groups']} key={group.id}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <span>{group.name}</span>
                                  <form onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.setFieldValue('group_id', group.id);
                                    formik.handleSubmit(e);
                                  }}>
                                    <button className={`${styles['add-to-group']}`}>
                                      <div className={`${styles['img-box']}`}>
                                        <img src={plusGreen} alt="icon add group"/>
                                      </div>
                                      <div>Add to Group</div>
                                    </button>
                                  </form>
                                </div>
                              </li>
                          ))}
                        </ul>
                    )}
                  </div>
                  {/* Pagination buttons */}
                  <div className="pagination d-flex align-items-center justify-content-center">
                    <button
                        className={`btn ${styles['pagination-Previous']}`}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1 || isLoading}
                    >
                      Previous
                    </button>
                    <span className={`btn ${styles['pagination-number']} mx-2`}>Page {currentPage}</span>
                    <button
                        className={`btn ${styles['pagination-next']}`}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages || isLoading}
                    >
                      Next
                    </button>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default DetailsBanner;