import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from './FilterModal.module.css';

function FilterModal({ isOpen, onClose, filters, selectedFilters, onFilterSelect }) {
  // const groupedFilters = filters.reduce((acc, filter) => {
  //   if (!acc[filter.section]) {
  //     acc[filter.section] = [];
  //   }
  //   acc[filter.section].push(filter);
  //   return acc;
  // }, {});

  const isFilterSelected = (filter) => {
    return selectedFilters.some(f => f.slug === filter.slug);
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="xl" dialogClassName={styles['stock-screener-modal']}>
      <Modal.Header closeButton>
        <Modal.Title>Select Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.scrollableModalBody}>
        <Form>
          {Object.entries(filters).map(([section, sectionFilters]) => (
            <div key={section} className={styles.filterSection}>
              <h5>{section.charAt(0).toUpperCase() + section.slice(1)}</h5>
              <div className={styles.checkboxGrid}>
                {sectionFilters.map(filter => (
                  <div key={filter.id} className={styles.customCheckbox}>
                    <input
                      type="checkbox"
                      id={`filter-${filter.id}`}
                      checked={isFilterSelected(filter)}
                      onChange={() => onFilterSelect(filter)}
                    />
                    <label htmlFor={`filter-${filter.id}`}>{filter.name}</label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer className={`${styles.modalFooter}`}>
         <Button variant="custom" className={`${styles.closeButton} px-4`} onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FilterModal;