import React from "react";
import "./NotFound.css";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import errorImage from "../../assets/images/K-wealth Coming soon screen_.webp";
function NotFound() {
  return (
    <>
      <div className="position-relative">
        <Link className="btn-lg back-btn" to={"/"}>back to home</Link>
        <img src={errorImage} alt="under constrictions" className="w-100 h-100" />
      </div>
    </>
    // <div id="notfound">
    //   <div className="notfound">
    //     <div className="notfound-404">
    //       <h1>404</h1>
    //     </div>
    //     <h2>Oops! Nothing was found</h2>
    //     <p>
    //       The page you are looking for might have been removed had its name
    //       changed or is temporarily unavailable.{" "}
    //       <Link to={"/"}>Return to Home_old-Page</Link>
    //     </p>
    //     <div className="notfound-social">
    //       <a href="#">
    //         <FaFacebookSquare />
    //       </a>
    //       <a href="#">
    //         <FaTwitterSquare />
    //       </a>
    //       <a href="#">
    //         <FaWhatsappSquare />
    //       </a>
    //     </div>
    //   </div>
    // </div>
  );
}

export default NotFound;
