import React from 'react';
import DataTable from "react-data-table-component";
import useCustomTranslation from "../../Hooks/useTranslation";
import useStocks from "../../Hooks/useStocks";
import useCustomLoader from "../../Hooks/useCustomeLoader";
import ProtectedComponent from "../ProtectedLink/ProtectedComponent";
import {usePermissions} from "../../Context/PermissionContext";

const StocksHome = () => {
    const { t } = useCustomTranslation();
    const { customLoader } = useCustomLoader();
    const {
        stocks,
        loading,
        totalRows,
        perPage,
        currentPage,
        columns,
        handlePageChange,
        handlePerRowsChange,
        paginationComponentOptions
    } = useStocks();

    const customStyles = {
        rows: {
            style: {
                minHeight: '48px',
                color: '#6F6F6F',
                justifyContent: 'center',
            },
        },
        headCells: {
            style: {
                color: '#1D1A39',
                fontSize: '16px',
                fontWeight: '700',
                justifyContent: 'start',
                minHeight: '64px',
            },
        },
        cells: {
            style: {
                justifyContent: 'space-between',
            },
        },
    };

    const StocksTable = ({ isDummy = false }) => (
        <DataTable
            columns={columns}
            data={isDummy ? dummyData : stocks}
            pagination
            paginationServer={!isDummy}
            paginationTotalRows={isDummy ? dummyData.length : totalRows}
            onChangeRowsPerPage={isDummy ? undefined : handlePerRowsChange}
            onChangePage={isDummy ? undefined : handlePageChange}
            highlightOnHover={true}
            striped={true}
            responsive
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            customStyles={customStyles}
            progressPending={loading && !isDummy}
            progressComponent={customLoader()}
            noDataComponent={<div style={{ padding: '200px', display: "none" }}>No records to display</div>}
            paginationComponentOptions={paginationComponentOptions}
        />
    );

    // Dummy data for the preview table
    const dummyData = [
        {
            id: 1,
            code: '1010',
            name: 'Riyad Bank',
            socketApi: {
                last: { value: '***.**' },
                close: { value: '***.**' },
                open: { value: '***.**' },
                high: { value: '***.**' },
                low: { value: '***.**' },
                volumeTotal: { value: '*******' },
                turnoverTotal: { value: '*********' },
                numberOfTrades: { value: '******' },
                vwap: { value: '***.**' },
                bestBid: { value: '***.**' },
                bestAsk: { value: '***.**' }
            },
            api: {
                last: '***.**',
                close: '***.**',
                change: '*.**',
                change_p: '*.**',
                open: '***.**',
                high: '***.**',
                low: '***.**',
                volumeTotal: '*******',
                turnoverTotal: '*********',
                numberOfTrades: '******',
                vwap: '***.**',
                bestBid: '***.**',
                bestAsk: '***.**',
                timestamp: new Date().getTime()
            }
        },
        {
            id: 2,
            code: '1020',
            name: 'Bank AlJazira',
            socketApi: {
                last: { value: '****.**' },
                close: { value: '****.**' },
                open: { value: '****.**' },
                high: { value: '****.**' },
                low: { value: '****.**' },
                volumeTotal: { value: '*******' },
                turnoverTotal: { value: '*********' },
                numberOfTrades: { value: '******' },
                vwap: { value: '****.**' },
                bestBid: { value: '****.**' },
                bestAsk: { value: '****.**' }
            },
            api: {
                last: '****.**',
                close: '****.**',
                change: '*.**',
                change_p: '*.**',
                open: '****.**',
                high: '****.**',
                low: '****.**',
                volumeTotal: '*******',
                turnoverTotal: '*********',
                numberOfTrades: '******',
                vwap: '****.**',
                bestBid: '****.**',
                bestAsk: '****.**',
                timestamp: new Date().getTime()
            }
        },
        {
            id: 3,
            code: '1030',
            name: 'Saudi Investment Bank',
            socketApi: {
                last: { value: '***.**' },
                close: { value: '***.**' },
                open: { value: '***.**' },
                high: { value: '***.**' },
                low: { value: '***.**' },
                volumeTotal: { value: '*******' },
                turnoverTotal: { value: '*********' },
                numberOfTrades: { value: '******' },
                vwap: { value: '***.**' },
                bestBid: { value: '***.**' },
                bestAsk: { value: '***.**' }
            },
            api: {
                last: '***.**',
                close: '***.**',
                change: '-*.**',
                change_p: '-*.**',
                open: '***.**',
                high: '***.**',
                low: '***.**',
                volumeTotal: '*******',
                turnoverTotal: '*********',
                numberOfTrades: '******',
                vwap: '***.**',
                bestBid: '***.**',
                bestAsk: '***.**',
                timestamp: new Date().getTime()
            }
        }
    ];


    return (
        <div className="tasi_card summary-stocks-card">
            <div className="">
                <h3 className="tasi_summery mb-3">{t('TASI Stocks Summary')}</h3>
                <ProtectedComponent
                    permission="stocks"
                    buttonText={t('Upgrade to Access Full Stocks Summary')}
                    // textHeadLine={t('Preview: Stocks Summary')}
                >
                    <StocksTable />
                </ProtectedComponent>
                {!usePermissions().hasPermission('stocks') && <StocksTable isDummy={true} />}
            </div>
        </div>
    );
};

export default StocksHome;