import React from 'react';
import DataTable from "react-data-table-component";
import useCustomTable from "../../Hooks/useCustomTable";
import useCustomTranslation from "../../Hooks/useTranslation";

const GroupDataDisplay = ({ groupData, groupLoading, groupError, activeTab, columns }) => {
    const {t} = useCustomTranslation();
    const {customStyles} = useCustomTable();

    if (groupLoading) {
        return <div>{t('Loading group data...')}</div>;
    }
    if (groupError) {
        return <div>{t('Error')}: {groupError}</div>;
    }



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={groupData?.data?.stocks || []} // Access stocks from groupData.data
                pagination
                paginationServer
                highlightOnHover={true}
                striped={true}
                responsive
            />
        </div>
    );
};

export default GroupDataDisplay;