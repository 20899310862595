import React from 'react';

const SuccessPayment = () => {
    const cardStyle = {
        background: 'white',
        padding: '60px',
        borderRadius: '4px',
        boxShadow: '0 2px 3px #C8D0D8',
        display: 'inline-block',
        margin: '0 auto',
    };

    const iconContainerStyle = {
        borderRadius: '200px',
        height: '200px',
        width: '200px',
        background: '#F8FAF5',
        margin: '0 auto',
    };

    const iconStyle = {
        color: '#9ABC66',
        fontSize: '100px',
        lineHeight: '200px',
        marginLeft: '-15px',
    };

    const headerStyle = {
        color: '#88B04B',
        fontFamily: '"Nunito Sans", "Helvetica Neue", sans-serif',
        fontWeight: 900,
        fontSize: '40px',
        marginBottom: '10px',
    };

    const paragraphStyle = {
        color: '#404F5E',
        fontFamily: '"Nunito Sans", "Helvetica Neue", sans-serif',
        fontSize: '20px',
        margin: '0',
    };

    return (
        <div style={{ textAlign: 'center', padding: '40px 0', background: '#EBF0F5', minHeight: '100vh' }}>
            <div style={cardStyle}>
                <div style={iconContainerStyle}>
                    <i className="checkmark" style={iconStyle}>✓</i>
                </div>
                <h1 style={headerStyle}>Success</h1>
                <p style={paragraphStyle}>
                    We received your purchase request;<br /> we'll be in touch shortly!
                </p>
            </div>
        </div>
    );
};

export default SuccessPayment;
