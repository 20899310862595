import React from 'react'
import { TimerBannerStocks } from '../TimerBanner/TimerBannerStocks'
import styles from './Summary.module.css';
import changes from '../../../../../assets/icons/changes.svg'
import { getChangeArrow, getTextColor } from '../../../../../ChangeUtils'
import { BulletList } from 'react-content-loader'
import useCustomTranslation from "../../../../../Hooks/useTranslation";
import {abbreviateNumber} from "../../../../../Hooks/useNumberFormatting";

export const Summary = ({ data:summary, isLoading }) => {
  const { t } = useCustomTranslation();
  
  return (
    <>
      <TimerBannerStocks/>
      {isLoading ? (
       <BulletList/>
      ) : (
        <>
      <div className="row">
        {/*<div className="col-lg-6">*/}
        {/*  <div className={`${styles['card']}`}>*/}
        {/*    <div className={`${styles['text-wrapper']}`}>{t('Current Value')}</div>*/}
        {/*    <div className={`${styles['frame']}`}>*/}
        {/*      <div className={`${styles['inv']} `}>*/}
        {/*        <div className={`${styles['text-wrapper-2']}`}>{abbreviateNumber(summary?.totalValue)}</div>*/}
        {/*        <div className={`${styles['text-wrapper-3']}`} >Invested -</div>*/}
        {/*      </div>*/}
        {/*      <div className={`${styles['frame-2']}`}>*/}
        {/*          <span className={`${styles["number-flags"]}`} style={{ color: getTextColor(summary?.totalChangP) }}>*/}
        {/*            ({abbreviateNumber(summary?.totalChangP)}%)*/}
        {/*          </span>*/}
        {/*        /!*<div className={`${styles['text-wrapper-4']}`}>({summary?.totalChangP?.toFixed(1)}%)</div>*!/*/}
        {/*        <div className={`${styles['frame-3']}`} >*/}
        {/*          /!*<img className={`${styles['img']}`} alt="Frame" src={framegreen} />*!/*/}
        {/*          <img  className={`${styles['img']}`} alt="Change Flag" src={getChangeArrow(summary?.totalChangP)} />*/}
        {/*          <span className={`${styles["number-flags"]}`} style={{ color: getTextColor(summary?.totalChangP) }}>*/}
        {/*            {abbreviateNumber(summary?.totalChang)}*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className={`${styles['frame-4']}`}>*/}
        {/*      <img className={`${styles['fi']}`}  alt="Fi" src={changes} />*/}
        {/*      <div className={`${styles['text-wrapper-5']}`} >Today Changes</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="col-lg-12">
          <div className={`${styles.card}`}>
            {/*<div className={`${styles.stocks}`}>{t('Stocks')}&nbsp;&nbsp;(95.5%)</div>*/}
            <div className={`${styles.frame}`}>
                <div className={`${styles.inv}`}>
                    <div className={`${styles['text-wrapper']}`}>{t('Current Value')}</div>
                    <div className={`${styles['text-wrapper']}`}>{abbreviateNumber(summary?.totalValue)}</div>
                    {/*<div className={`${styles['text-wrapper-3']}`}>{t('Invested')} 2,234,123</div>*/}
                </div>
                <div className={`${styles['frame-2']}`}>
              <span className={`${styles["number-flags"]}`} style={{ color: getTextColor(summary?.totalChangP) }}>
                    ({abbreviateNumber(summary?.totalChangP)}%)
                  </span>
                <div className={`${styles['frame-3']}`}>
                  <img  className={`${styles['img']}`} alt="Change Flag" src={getChangeArrow(summary?.totalChangP)} />
                  <span className={`${styles["number-flags"]}`} style={{ color: getTextColor(summary?.totalChangP) }}>
                    {abbreviateNumber(summary?.totalChang)}
                  </span>
                </div>
              </div>
            </div>
            <div className={`${styles['frame-44']}`}>
              <div className={`${styles['frame-5']}`}>
                <div className={`${styles['percent-2']}`}>
                  <div className={`${styles['element-2']}`} style={{ color: '#fff' }}>
                    {summary?.negativesStocks?.length}
                  </div>
                </div>
                <div className={`${styles['text-wrapper-55']}`}>{t('Losers')}</div>
              </div>
              <div className={`${styles['frame-5']}`}>
                <div className={`${styles.percent}`}>
                  <div className={`${styles.element}`} style={{ color: '#fff' }}>
                    { summary?.positivesStocks?.length}
                  </div>
                </div>
                <div className={`${styles['text-wrapper-4']}`}>{t('Gainers')}</div>
              </div>
            </div>
            <div className={`${styles.group}`}>
              <div className={`${styles['overlap-group']}`}>
                <div className={`${styles.rectangle}`} style={{
                  background: `linear-gradient(to right, #FD2523 0%, #FD2523 ${(summary?.negativesStocks?.length / (summary?.negativesStocks?.length + summary?.positivesStocks?.length)) * 100}%, #049C6B ${(summary?.negativesStocks?.length / (summary?.negativesStocks?.length + summary?.positivesStocks?.length)) * 100}%, #049C6B 100%)`,
                  // width: `${(summary?.negativesStocks?.length / (summary?.negativesStocks?.length + summary?.positivesStocks?.length)) * 100}%`
                  width:' 100%'
                }} />
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className="row my-24">
        <div className="col-lg-6">
          <div className={`${styles['card-top']}`}>
            <div className={`${styles['head-card']}`}>
              <span>{t('Top Gainers')}</span>
              <table className="table table-responsive mt-16">
                <thead className={`${styles['thead-table']}`}>
                <tr>
                  <th scope="col">{t('Stock Name')}</th>
                  <th scope="col">{t('Change')}</th>
                  <th scope="col">{t('Change (%)')}</th>
                </tr>
                </thead>
                <tbody>

                {summary?.positivesStocks.map((stock, index) => (
                    <tr key={index} className={`${styles['thead-td']}`}>
                    <td>{stock?.stock?.name}</td>

                    <td  style={{ color: stock.value_api > 0 ? '#049C6B' : '#FD2523' }}>{abbreviateNumber(stock.value_api)}</td>
                    <td style={{ color: stock.change_p_api > 0 ? '#049C6B' : '#FD2523' }}>
                      ({typeof stock.change_p_api === 'number' ? abbreviateNumber(stock.change_p_api) : abbreviateNumber(stock.change_p_api)}%)
                    </td>

                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className={`${styles['card-top']}`}>
            <div className={`${styles['head-card-losers']}`}>
              <span>{t('Top losers')}</span>
              <table className="table table-responsive mt-16">
                <thead className={`${styles['thead-table']}`}>
                <tr>
                  <th scope="col">{t('Stock Name')}</th>
                  <th scope="col">{t('Change')}</th>
                  <th scope="col">{t('Change (%)')}</th>
                </tr>
                </thead>
                <tbody>
                {summary?.negativesStocks.map((stock, index) => (
                  <tr key={index} className={`${styles['thead-td']}`}>
                    <td>{stock?.stock?.name}</td>
                    <td  style={{ color: stock.value_api > 0 ? '#049C6B' : '#FD2523' }}>{abbreviateNumber(stock.value_api)}</td>
                    <td style={{ color: stock.change_p_api > 0 ? '#049C6B' : '#FD2523' }}>({abbreviateNumber(stock.change_p_api)}%)</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
        </>
        )}
    </>
  )
}
