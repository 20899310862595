import React, { useState, useEffect } from 'react';
import styles from './watchlist.module.css';
import SearchWathList from './SearchWathList';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {apiUrlServer, getAllGroups} from '../../apiConfig';
import '../../assets/style/swl-custome.css';
import EditGroup from './GroupStocks/EditGroup';
import DeleteGroup from './GroupStocks/DeleteGroup';
import { Link } from 'react-router-dom';
import AddGroup from './AddGroup';
import { BulletList } from 'react-content-loader';
import useCustomTable from "../../Hooks/useCustomTable";
import useCustomTranslation from '../../Hooks/useTranslation';
import useCustomLoader from "../../Hooks/useCustomeLoader";
import useStocks from "../../Hooks/useStocks";

const GroupTable = () => {
  const [cookies] = useCookies(['token']);
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const {customStyles} = useCustomTable();
  const { t } = useCustomTranslation();
  const {customLoader} = useCustomLoader();

  const fetchData = async (page, limit) => {
    try {
      let token = cookies.token;

      const response = await axios.get(`${apiUrlServer}${getAllGroups}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          page: page,
          limit: limit,
        },
      });

      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      //setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, [cookies.token, currentPage, perPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    try {
      const response = await axios.get(`${apiUrlServer}${getAllGroups}`, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          page: page,
          limit: newPerPage,
        },
      });
      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
    } catch (error) {
      console.error('Error fetching groups:', error);
    }

  };

  const handleAddGroupSuccess = () => {
    fetchData(currentPage, perPage);
  };
  const handleEditGroupSuccess = () => {
    fetchData(currentPage, perPage);
  };

  const handleDeleteGroupSuccess = () => {
    fetchData(currentPage, perPage);
  };

  // ... (columns and customStyles remain the same)
  const columns = [
    {
      name: t('Watchlist Name'),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
          <div className="d-flex align-items-center justify-content-center">
            <Link to={`/watchlist/${row.id}`}>{row.name}</Link>
          </div>
      ),
    },
    {
      name: t('Stocks Number'),
      selector: (row) => (row.stocks ? row.stocks.length : 0),
      sortable: true,
      className: 'red-column',
    },
    {
      name: t('Actions'),
      selector: () => null,
      sortable: false,
      cell: (row) => (
          <div className="d-flex align-items-center justify-content-center ">
            <EditGroup groupId={row.id} onEditGroupSuccess={handleEditGroupSuccess} fetchData={fetchData} />
            <DeleteGroup groupId={row.id} onDeleteGroupSuccess={handleDeleteGroupSuccess} fetchData={fetchData} />
          </div>
      ),
    },
  ];


  const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
      <>
        <AddGroup onAddGroupSuccess={handleAddGroupSuccess} fetchData={fetchData} />

            <>
              <div className='tasi_card'>
                <SearchWathList searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                <DataTable
                    customStyles={customStyles}
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    progressPending={isLoading}
                    progressComponent={customLoader()} // Use your custom loader
                    noDataComponent={<div style={{ padding: '200px' , display:"none"}}>No records to display</div>}
                />
              </div>
            </>
      </>
  );
};

export default GroupTable;