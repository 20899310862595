import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CommonBanner from '../CommonBanner/CommonBanner';
import './subscription.css';
import { apiUrlServer } from '../../apiConfig'
import { useCookies } from 'react-cookie'
import Slider from 'react-slick'
import PlansTable from './PlansTable'
import useCustomTranslation from '../../Hooks/useTranslation';
import { useLanguage } from '../../Context/LanguageContext';

const SubscriptionPlan = () => {
  const {t} = useCustomTranslation();
  const [tabsData, setTabsData] = useState([]);
  const [cookies] = useCookies(['token']);
  const [activeTab, setActiveTab] = useState(null);
  const {language} = useLanguage();

  useEffect(() => {
    const fetchTabsData = async () => {
      try {
        const response = await axios.get(`${apiUrlServer}interval`, {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
            'Content-Type': 'application/json',
            'Accept-Language': language,
          },

        });
        if (response.data.status) {
          setTabsData(response.data.data);
          // Set the first tab as active by default
          if (response.data.data.length > 0) {
            setActiveTab(response.data.data[0].id);
          }
        } else {
          console.error('Failed to fetch tabs data');
        }
      } catch (error) {
        console.error('Error fetching tabs data:', error);
      }
    };

    fetchTabsData();
  }, [cookies.token, language]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
      <>
        <CommonBanner title={t('Subscription Plan')} />
        <section className="delivery-scans subscription-card">
          <div className="row">
            <div className="portfolio-tab-home">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 m-auto ">
                    <Slider {...settings} className="subscription-slider-header">
                      {tabsData.map((tab) => (
                          <div key={tab.id} className="subscriptionsPlans">
                            <a
                                className={`nav-link border-radius-16 ${tab.id === activeTab ? 'active' : ''}`}
                                onClick={() => handleTabClick(tab.id)}
                            >
                              {tab.name}
                            </a>
                          </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="tab-content" id="myTabsContent">
                    {activeTab && <PlansTable tabId={activeTab} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default SubscriptionPlan;