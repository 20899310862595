import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';
import style from '../../Authentication/Auth.module.css';
import {apiUrlServer, checkOtpAndActivate, sendOtp} from '../../../apiConfig';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AuthContext } from '../../../Context/AuthContext';
import logo from '../../../assets/images/logo.png';
import { Helmet } from 'react-helmet';
import useCountdown from '../../../Hooks/useCountdown';
import useCustomTranslation from "../../../Hooks/useTranslation";

const OtpConfirmChangeNumber = ({ phoneNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const { setIsUserLoggedIn } = useContext(AuthContext);
  const [cookies, setCookie] = useCookies(['token']);
  const navigate = useNavigate();
  const [resendTimeout, setResendTimeout] = useState(null);
  const storedSecondsRemaining = parseInt(localStorage.getItem('secondsRemaining')) || 0;
  const { secondsRemaining, setSecondsRemaining } = useCountdown(storedSecondsRemaining);


  useEffect(() => {
    localStorage.setItem('secondsRemaining', secondsRemaining.toString());
    return () => {
      localStorage.removeItem('secondsRemaining');
    };
  }, [secondsRemaining]);

  useEffect(() => {
    const token = cookies.token; // Use cookies to get the token
    if(token) {
      setIsUserLoggedIn(true);
    }
  },[setIsUserLoggedIn]);
  async function handelOtp(values) {
    setIsLoading(true);

    try {
      // Retrieve New phone number from session storage
      const storedPhone = sessionStorage.getItem('new_phone');
      const { data } = await axios.post(`${apiUrlServer}${checkOtpAndActivate}`,{
        ...values,
        new_phone: storedPhone,
      });
      if (data.message === "Data Retrieved Successfully") {
        // Save New phone number to session storage
        sessionStorage.setItem('new_phone', values.new_phone);
        toast.success(`create account`, {
          position:'top-right',
          duration: 3000,
          className: "text-success px-5 fw-bolder my-3"
        });
        setCookie('token', data.data.token, {path:'/'});
        setIsUserLoggedIn(true)
        setMessageError("");
        setIsLoading(false);
        navigate("/home");
      }
    } catch (err) {
      setIsLoading(false);
      setMessageError(err.response.data.message);
      toast.error(err.response.data.message, {
        position: "top-right",
        duration: 3000,
        className: "text-danger px-5 fw-bolder my-3"
      });
    }finally {
      setIsLoading(false);
    }
  }
  let validationSchema = Yup.object({
    otp_code: Yup.string().required('OTP code is required'),
    phone: Yup.string().required('Phone number is required'), // Add this line for phone validation
  })

  let storedPhone = sessionStorage.getItem('phone');

  const formik = useFormik({
    initialValues:{
      otp_code: '',
      phone: storedPhone,
    },
    validationSchema,
    onSubmit: handelOtp,

  })
  async function handleResendOtp() {
    try {
      // Retrieve phone number from session storage
      const storedPhone = sessionStorage.getItem('phone');
      const { data } = await axios.post(`${apiUrlServer}${sendOtp}`, { phone: storedPhone });

      if (data.message === 'Data updated successfully') {

        toast.success(`Your OTP is: ${data.data.otp_code} `, {
          position: 'top-right',
          duration: 3000,
          className: 'text-success px-5 fw-bolder my-3',
        });
        // Set a timeout to enable the Resend OTP button after 60 seconds

        setMessageError('');
        setSecondsRemaining(60);

      }
    } catch (err) {
      if (err.response && err.response.data) {
        const errorMessage = err.response.data.message;
        if (errorMessage === 'The selected phone is invalid.') {
          toast.error("This phone number doesn’t have an account yet", {
            position: 'top-right',
            duration: 3000,
            className: "text-danger px-5 fw-bolder my-3",
          });
        }
      }
    }
  }
  const {t}= useCustomTranslation();

  return (
    <>
      <Helmet>
        <title>{t('OTP Confirm')}</title>
      </Helmet>
      <div className="py-5 h-screen">
        <div className="container">
          <div className="row justify-content-center align-items-center min-h-screen-authForm">
            <div className={`col-xxl-4 col-lg-5 col-md-6 col-10 bg-white p-4 ${style.formContainer}`}>
              <div className="text-center my-16">
                                            <img
                                src={theme === 'default' ? defaultLogo : nationalDayLogo}
                                className=""
                                style={{width:"124px"}}
                                alt="k wealth logo"
                            />

              </div>
              <h2 className={`${style['head-form']}`}>{t('OTP Confirmation')}</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className={`${style['input-custom']} form-control`}
                    placeholder="Enter OTP code"
                    id="otp_code"
                    name="otp_code"
                    value={formik.values.otp_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="mb-3">
                  {formik.errors.otp_code && formik.touched.otp_code && (
                    <small className={`${style['text-error']}`}>{formik.errors.otp_code}</small>
                  )}
                </div>

                <div>
                  {isLoading ? (
                    <button type="button"  className={`${style['btn-signup']} btn opacity-75 fw-bolder px-4 w-100`}>
                      <span  className="spinner-border spinner-border-sm " role="status" aria-hidden="false"></span>
                    </button>
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        {/* Submit OTP button */}
                        <button disabled={!formik.isValid} type="submit" className={`btn ${style['btn-signup']}`}>
                          Submit OTP
                        </button>

                        {/* Resend OTP button */}
                        <button
                          type="button"
                          className={`btn ${style['btn-signup']} ml-2`}
                          onClick={handleResendOtp}
                          disabled={secondsRemaining > 0}
                        >
                          {secondsRemaining > 0
                            ? `Resend OTP in ${secondsRemaining}s`
                            : 'Resend OTP'}
                        </button>
                      </div>
                    </>
                  )
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default OtpConfirmChangeNumber;
