import React from "react";
import errorImage from '../../../../../assets/images/K-wealth Coming soon screen_.webp'

function MajorTab() {
  return(
  <>
    <img src={errorImage} alt="under constrictions" className="w-100 h-100" />
    {/*<StockIndicesTable />*/}
  </>
  )
}

export default MajorTab;
