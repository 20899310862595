import React from 'react';
import { BulletList } from 'react-content-loader';
import './Dividends.css';
import useCustomTranslation from '../../../../Hooks/useTranslation';
import { abbreviateNumber } from "../../../../Hooks/useNumberFormatting";

const Dividends = ({ data }) => {
  const { t } = useCustomTranslation();

  if (!data) {
    return <BulletList />;
  }

  return (
      <div className="state-card-dividends">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="key-state">{t('Dividends')}</h2>
            <ul className="d-flex align-items-center list-dividends">
              <li>
                <span className="my-3">{t('ForwardAnnualDividendRate')}: <span className="dividendsRate">{abbreviateNumber(data.ForwardAnnualDividendRate)}</span></span>
              </li>
              <li>
                <span className="my-3">{t('ForwardAnnualDividendYield')}: <span className="dividendsRate">{abbreviateNumber(data.ForwardAnnualDividendYield)}</span></span>
              </li>
              <li>
                <span className="my-3">{t('PayoutRatio')}: <span className="dividendsRate">{abbreviateNumber(data.PayoutRatio)}</span></span>
              </li>
              <li>
                <span className="my-3">{t('DividendDate')}: <span className="dividendsRate">{data.DividendDate}</span></span>
              </li>
            </ul>
            <ul className="d-flex align-items-center list-dividends">
              <li>
                <span className="my-3">{t('ExDividendDate')}: <span className="dividendsRate">{data.ExDividendDate}</span></span>
              </li>
              <li>
                <span className="my-3">{t('LastSplitFactor')}: <span className="dividendsRate">{abbreviateNumber(data.LastSplitFactor)}</span></span>
              </li>
              <li>
                <span className="my-3">{t('LastSplitDate')}: <span className="dividendsRate">{data.LastSplitDate}</span></span>
              </li>
            </ul>

            <h2 className="key-state">{t('Number of Dividends by Year')}</h2>
            <table className="dividends-table">
              <thead>
              <tr>
                <th className="text-center">{t('Year')}</th>
                <th className="text-center">{t('Count')}</th>
              </tr>
              </thead>
              <tbody>
              {data.NumberDividendsByYear && Array.isArray(data.NumberDividendsByYear) && data.NumberDividendsByYear.map((item) => (
                  <tr key={item.Year}>
                    <td className="text-center">{item.Year}</td>
                    <td className="text-center">{item.Count}</td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
};

export default Dividends;