import { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {apiUrlServer} from "../apiConfig";
import {useLanguage} from "../Context/LanguageContext";

const useCompanyLiveData = (id) => {
    const [companyLiveDate, setCompanyLiveDate] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [cookies] = useCookies(['token']);
    const {language} = useLanguage();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = cookies.token;
                const response = await axios.get(`${apiUrlServer}stocks?search[id]=${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': language,
                    },
                    params: {
                        with_api: true,
                    }
                });
                const result = response.data;
                if (result.status) {
                    setCompanyLiveDate(result.data);
                } else {
                    console.error('Error fetching stock data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching stock data:', error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData(id);
    }, [id, cookies.token]);

    return { companyLiveDate, isLoading };
};

export default useCompanyLiveData;