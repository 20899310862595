import React from 'react'
import CommonBanner from '../CommonBanner/CommonBanner'
import PriceTable from './PriceTable'
import useCustomTranslation from "../../Hooks/useTranslation";

const PriceAlerts = () => {
    const {t} = useCustomTranslation();
  return (
    <>
     <CommonBanner title={t("Price Alerts")} />
      <PriceTable/>

    </>
  )
}

export default PriceAlerts