import React from 'react';
import './ContactForm.css';
import axios from "axios";
import {apiUrlServer, contactUs} from "../../apiConfig";
import * as Yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from "formik";
import Swal from "sweetalert2";
import useCustomTranslation from "../../Hooks/useTranslation";
import CommonBanner from "../CommonBanner/CommonBanner";


const ContactForm = () => {
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await axios.post(`${apiUrlServer}${contactUs}`, values);
            // console.log('Form submitted successfully:', response.data);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Message sent successfully!',
            });
            resetForm();
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to send message. Please try again later.',
            });
        } finally {
            setSubmitting(false);
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string().required('Phone number is required'),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required'),
    });
    const {t} = useCustomTranslation();
    return (
        <>
        <CommonBanner title={t('Contact Us')}/>
        <div className="container-form">
        <div className="contact-container">
            <img src="img/shape.png" className="square" alt="" />
            <div className="form">
                <div className="contact-info">
                    <h3 className="title">{t("Let's get in touch")}</h3>
                    <p className="text">
                        {t("We'd love to hear from you! Whether you have questions about our services, need support, or just want to provide feedback, feel free to reach out to us using the form below. Our team is here to assist you and will respond as soon as possible.")}
                    </p>

                    <div className="info">
                        <div className="information">
                            <i class="fa-solid fa-map-location-dot"></i> &nbsp; &nbsp;
                            <p className='m-0'>92 Cherry Drive Uniondale, NY 11553</p>
                        </div>
                        <div className="information">
                            <i className="fas fa-envelope"></i> &nbsp; &nbsp;
                            <p className='m-0'>lorem@ipsum.com</p>
                        </div>
                        <div className="information">
                            <i className="fas fa-phone"></i>&nbsp; &nbsp;
                            <p className='m-0'>123-456-789</p>
                        </div>
                    </div>

                    <div className="social-media">
                        <p>{t("Connect with us")} :</p>
                        <div className="social-icons">
                            <a href="#">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="#">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="#">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="contact-form">
                    <span className="circle one"></span>
                    <span className="circle two"></span>

                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            subject: '',
                            message: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="contant-form">
                                {/*<h3 className="title" class="text-muted">{t("Contact us")}</h3>*/}
                                <div className="input-container">
                                    <Field type="text" name="name" className="form-control" placeholder={t("Enter Your User Name")} />
                                    <ErrorMessage name="name" component="div" className="error-message" />
                                </div>
                                <div className="input-container">
                                    <Field type="email" name="email" className="form-control" placeholder={t("email")} />
                                    <ErrorMessage name="email" component="div" className="error-message" />
                                </div>
                                <div className="input-container">
                                    <Field type="tel" name="phone" className="form-control" placeholder={t("phone")} />
                                    <ErrorMessage name="phone" component="div" className="error-message" />
                                </div>
                                <div className="input-container">
                                    <Field type="text" name="subject" className="form-control" placeholder={t("subject")} />
                                    <ErrorMessage name="subject" component="div" className="error-message" />
                                </div>
                                <div className="input-container textarea">
                                    <Field as="textarea" name="message" className="form-control" placeholder={t("message")} />
                                    <ErrorMessage name="message" component="div" className="error-message" />
                                </div>
                                <button type="submit" disabled={isSubmitting} className="btn-contact">
                                    {isSubmitting ? 'Submitting...' : 'Send'}
                                </button>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default ContactForm;
