import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from "./Context/ThemeContext";
import useCustomTranslation from "./Hooks/useTranslation";
import KSADAY from "./assets/icons/KSA-Flag.svg"
import DefaultTheme from "./assets/icons/regular.svg"

const ThemeToggle = () => {
    const { theme, toggleTheme } = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { t } = useCustomTranslation();

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (selectedTheme) => {
        if (selectedTheme !== theme) {
            toggleTheme();
        }
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="theme-toggle-container" ref={dropdownRef}>
            <button className="theme-toggle-button" onClick={handleToggle}>
                <span className="theme-icon">
                    <img src={theme === 'default' ? DefaultTheme : KSADAY} alt={theme === 'default' ? "Default Theme" : "National Day Theme"} />
                </span>
                <span className="theme-text">{theme === 'default' ? t('Default') : t('National Day')}</span>
                <span className={`arrow ${isOpen ? 'open' : ''}`}>▼</span>
            </button>
            {isOpen && (
                <div className="theme-options">
                    <div className="theme-option" onClick={() => handleOptionClick('default')}>
                        <span className="theme-icon">
                            <img src={DefaultTheme} alt="Default Theme" />
                        </span>
                        <span>{t('Default')}</span>
                    </div>
                    <div className="theme-option" onClick={() => handleOptionClick('nationalDay')}>
                        <span className="theme-icon">
                            <img src={KSADAY} alt="National Day Theme" />
                        </span>
                        <span>{t('National Day')}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ThemeToggle;