import React, {useEffect, useState} from 'react';
import axios from "axios";
import {apiUrlServer, getAllIndexes, getAllStocks} from "../../apiConfig";
import {useCookies} from "react-cookie";
import Select from "react-select";
import styles from "../MyPortfolio/EntryandUpload/ManualAdd.module.css";
import positiveChangeArrow from '../../assets/icons/FrameGreen.png'
import negativeChangeArrow from '../../assets/icons/FrameRed.png'
import useCustomTranslation from '../../Hooks/useTranslation';
import { selectStyle } from '../../Hooks/SelectElStyle';

const MarketInformation = () => {
const { t } = useCustomTranslation();
const [cookies] = useCookies(['token']);
const [stocks, setStocks] = useState([]);
const [indexes, setIndexes] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const [selectedOption, setSelectedOption] = useState('');
const [selectedOptionIndex, setSelectedOptionIndex] = useState('');
const [limit, setLimit] = useState(10);
const [isInputFocused, setIsInputFocused] = useState(false);
const [showModal, setShowModal] = useState(false);
const [selectedStock, setSelectedStock] = useState(null);
    useEffect(() => {
        const fetchStocksData = async () => {
            const token = cookies.token;
            try {
                const response = await axios.get(`${apiUrlServer}${getAllStocks}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        limit: limit,
                    },
                });
                const result = response.data;
                // console.log(result);

                if (result.status) {
                    setStocks((prevStocks) => [...prevStocks, ...result.data]);
                } else {
                    console.error('Error fetching data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };
        const fetchIndexesData = async () => {
            const token = cookies.token;
            try {
                const response = await axios.get(`${apiUrlServer}${getAllIndexes}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                const result = response.data;
                // console.log(result);

                if (result.status) {
                    setIndexes(result.data);
                } else {
                    console.error('Error fetching data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        }
        fetchIndexesData();
        fetchStocksData();
    }, [limit, cookies.token]);
    const handleMenuScroll = async (e) => {
        const target = e.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            // User has scrolled to the bottom
            setLimit((prevLimit) => prevLimit + 10);
        }
    };
    const uniqueStockIds = new Set();
    const uniqueStockOptions = stocks.filter((stock) => {
        if (!uniqueStockIds.has(stock.id)) {
            uniqueStockIds.add(stock.id);
            return true;
        }
        return false;
    }).map((stock) => ({ value: stock.id, label: `${stock.name} - ${stock.code}` }));
    const getChangeArrow = (change_p) => {
        if (change_p > 0) {
            return positiveChangeArrow; // Image for positive change
        } else {
            return negativeChangeArrow; // Image for non-positive change
        }
    };

    return (
        <>
            <div className="market-card position-relative z-2 ">
                <h2 className="head-card-market">{t('Market Information')}</h2>
                <div>
                    <form className="market-form">
                        <div className="d-flex justify-content-between align-items-start gap-3">
                            <div className="mb-10 w-100">
                                <label htmlFor="market" className="form-label">{t('Select Market')}</label>
                                <Select
                                    options={indexes.map((index) => ({ value: index.id, label: index.name }))}
                                    onMenuScrollToBottom={handleMenuScroll}
                                    onChange={(selectedOptionIndex) => {
                                        // formik.setFieldValue('index_id', selectedOptionIndex?.value || '');
                                        setSelectedOptionIndex(selectedOptionIndex);
                                    }}
                                    onFocus={() => setIsInputFocused(true)}
                                    // onBlur={formik.handleBlur}
                                    value={selectedOptionIndex}
                                    placeholder={selectedOptionIndex.label}
                                    styles={selectStyle}
                                />
                                {/* {selectedOptionIndex && (
                                    <>
                                        <div className="d-flex align-items-center justify-content-start">
                                            <div className={`${styles['selcet-stock-label']}`}> Selected Option:</div>
                                            <div className={`${styles['selcet-stock-box']}`} >
                                                {selectedOptionIndex.label}
                                            </div>
                                        </div>
                                    </>
                                )} */}
                            </div>

                            <div className="mb10 w-100">
                                <label htmlFor="market" className="form-label">{t('Select Stock')}</label>
                                <Select
                                    options={uniqueStockOptions}
                                   onMenuScrollToBottom={handleMenuScroll}
                                    onChange={(selectedOption) => {
                                        setSelectedOption(selectedOption);
                                        const selectedStockData = stocks.find((stock) => stock.id === selectedOption?.value);
                                        setSelectedStock(selectedStockData);
                                    }}
                                    onFocus={() => setIsInputFocused(true)}
                                    //onBlur={formik.handleBlur}
                                    value={selectedOption}
                                    placeholder={selectedOption?.label}
                                    styles={selectStyle}
                                />
                                {/* {selectedOption && (
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className={`${styles['selcet-stock-box']}`}>
                                            {selectedOption.label}
                                        </div>
                                    </div>
                                )} */}
                            </div>
                        </div>

                    </form>
                </div>

                {selectedStock  &&(<div className="results">
                    <div className="row">
                        <div className="col-md-3">
                            <ul className="market-list">
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Gainers')}:</span>
                                    {/*    <span className="gainers-arrowNumber">*/}
                                    {/*<svg width="8" height="7"*/}
                                    {/*     viewBox="0 0 8 7"*/}
                                    {/*     fill="none" xmlns="http://www.w3.org/2000/svg"><path*/}
                                    {/*    d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"*/}
                                    {/*    fill="#049C6B"></path></svg> 810%</span>*/}
                                        <span>-</span>
                                    </div>

                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">  {t('UnChanged')}:</span>
                                        {/*<span className="gainers-arrowNumber losers-arrowNumber"><svg*/}
                                        {/*    width="8" height="6" viewBox="0 0 8 6" fill="none"*/}
                                        {/*    xmlns="http://www.w3.org/2000/svg"><path*/}
                                        {/*    d="M3.13179 5.3718C3.52761 5.96553 4.40006 5.96553 4.79589 5.3718L6.92737 2.17457C7.37041 1.51002 6.89401 0.619873 6.09532 0.619873H1.83235C1.03366 0.619873 0.557267 1.51002 1.0003 2.17457L3.13179 5.3718Z"*/}
                                        {/*    fill="#FD2523"></path></svg>810%</span>*/}
                                        <span>-</span>
                                    </div>

                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">  {t('Losers')}:</span>
                                        {/*<span className="gainers-arrowNumber losers-arrowNumber"><svg*/}
                                        {/*    width="8" height="6" viewBox="0 0 8 6" fill="none"*/}
                                        {/*    xmlns="http://www.w3.org/2000/svg"><path*/}
                                        {/*    d="M3.13179 5.3718C3.52761 5.96553 4.40006 5.96553 4.79589 5.3718L6.92737 2.17457C7.37041 1.51002 6.89401 0.619873 6.09532 0.619873H1.83235C1.03366 0.619873 0.557267 1.51002 1.0003 2.17457L3.13179 5.3718Z"*/}
                                        {/*    fill="#FD2523"></path></svg>810%</span>*/}
                                        <span>-</span>
                                    </div>

                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="market-list">
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Value')}:</span>
                                        <span className="">{selectedStock?.api?.close || '-'}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Change')}:</span>
                                        <span className="d-flex align-items-center">
                                         {selectedStock?.api?.change !== undefined ? (
                                             <>
                                                 <img
                                                     className='img-change'
                                                     src={getChangeArrow(selectedStock?.api?.change)}
                                                     alt="Change Image"
                                                 />
                                                 <span style={{color: selectedStock?.api?.change > 0 ? 'green' : 'red'}}>
                                                  {selectedStock?.api?.change}
                                                </span>
                                             </>
                                         ) : (
                                             <span>-</span>
                                         )}
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                    <span className="w-50">{t('Change')}%:</span>

                                        {selectedStock?.api?.change_p !== undefined ? (
                                            <span
                                                style={{
                                                    color: selectedStock?.api?.change_p > 0 ? 'green' : 'red',
                                                }}
                                            >
                                            {selectedStock?.api?.change_p}%
                                            </span>
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </div>

                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="market-list">
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Trade Value')}:</span>
                                        <span className="">{selectedStock?.api?.volume || '-'}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Trade Volume')}:</span>

                                        <span className="">-</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Traders')}:</span>

                                        <span className="">-</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="market-list last-market-list">
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Status')}:</span>
                                        <span className="">-</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">-</span>

                                        <span className="gainers-arrowNumber losers-arrowNumber">{t('Live')}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span className="w-50">{t('Source of Informations')}:</span>
                                       <span>-</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>)}

            </div>
        </>
    );
};

export default MarketInformation;