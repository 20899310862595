import React from 'react';
import './Footer.css';
import logowhite from '../../assets/images/logo-white.svg'
import {FaFacebookSquare} from "react-icons/fa";
import {Link} from "react-router-dom";
import useCustomTranslation from "../../Hooks/useTranslation";

const Footer = () => {
    const year = new Date().getFullYear(); // This makes sure the year is always current.
    const { t } = useCustomTranslation();

    return (
        <footer className="footer-section">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget company-intro-widget">
                                <Link to={'home'}  className="site-logo">
                                    <img src={logowhite} alt="logo" loading="lazy"/></Link>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget course-links-widget">
                                <h5 className="widget-title">{t('Quick Links')}</h5>
                                <ul className="courses-link-list">
                                    <li><Link to={'home'}>{t('Home')}</Link>
                                    </li>
                                    <li><Link  to={'indices'}>{t('Indcies')}</Link>
                                    </li>
                                    <li><Link to={'stocks'}>{t('Stocks')}</Link></li>
                                    <li><Link to={'news'}>{t('News')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget course-links-widget">
                                <h5 className="widget-title">{t('Quick Links')}</h5>
                                <ul className="courses-link-list">
                                    <li><Link to={'faqs'}>{t('FAQ')}</Link>
                                    </li>
                                    <li><Link to={'contactus'}>{t('Contact Us')}</Link>
                                    </li>
                                    <li><Link to={'chatbot'}>{t('Chat')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget latest-news-widget">
                                <h5 className="widget-title">{t('Quick Links')}</h5>
                                <ul className="courses-link-list">
                                    <li><Link to={'faqs'}>{t('Terms & Conditions')}</Link>
                                    </li>
                                    <li><Link to={'/'}>{t('Privacy Policy')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*<div className="col-lg-3 col-md-6 col-sm-6">*/}
                        {/*    <div className="widget newsletter-widget">*/}
                        {/*        <h5 className="widget-title">Newsletter</h5>*/}
                        {/*        <div className="footer-newsletter">*/}
                        {/*            <p>Sign Up to Our Newsletter to Get Latest Updates & Services</p>*/}
                        {/*            <form className="news-letter-form">*/}
                        {/*                <input type="email" name="news-email" id="news-email"*/}
                        {/*                       placeholder="Your email address"/>*/}
                        {/*                <input type="submit" value="Send"/>*/}
                        {/*            </form>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 text-sm-left text-center">
                            <span className="copy-right-text">{t('Key Al Tharwa Information Systems Technology Company. All rights reserved 2024 ©')}</span>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            {/*<ul className="terms-privacy d-flex justify-content-sm-end justify-content-center mb-0">*/}
                            {/*    <li><a href="#">{t('Terms & Conditions')}</a></li>*/}
                            {/*    <li><a href="#">{t('Privacy Policy')}</a></li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;