import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { apiUrlServer } from "../apiConfig";
import {formatNumber} from '../Hooks/useNumberFormatting';

const useGroupData = (groupId) => {
    const [groupData, setGroupData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cookies] = useCookies(['token']);
    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Stock Code',
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Stock Name',
            selector: row => row.name,
            sortable: true,
        },
        // {
        //     name: 'Country',
        //     selector: row => row.country,
        //     sortable: true,
        // },
        // {
        //     name: 'Exchange',
        //     selector: row => row.exchange,
        //     sortable: true,
        // },
        // {
        //     name: 'Currency',
        //     selector: row => row.currency,
        //     sortable: true,
        // },
        // {
        //     name: 'ISIN',
        //     selector: row => row.isin,
        //     sortable: true,
        // },
        // {
        //     name: 'APICode',
        //     selector: row => row.api.code,
        //     sortable: true,
        // },
        // {
        //     name: 'API TimeStamp',
        //     selector: row => row.api.timestamp,
        //     sortable: true,
        // },
        {
            name: 'Open',
            selector: row => row.api.open,
            sortable: true,
        },
        {
            name: 'Close',
            selector: row => row.api.close,
            sortable: true,
        },
        {
            name: 'Volume',
            selector: row => row.api.volume,
            sortable: true,
        },
        {
            name: 'Change',
            selector: row => (
                <span style={{ color: row.api.change < 0 ? 'red' : 'green' }}>
                    {formatNumber(row.api.change)}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Change %',
            selector: row => (
                <span style={{ color: row.api.change_p < 0 ? 'red' : 'green' }}>
                    {formatNumber(row.api.change_p)}
                </span>
            ),
            sortable: true,
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (!groupId) return;  // Exit if no groupId is provided
            setLoading(true);
            try {
                const token = cookies.token;
                const response = await axios.get(`${apiUrlServer}groups/${groupId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                setGroupData(response.data);
                // console.log("Fetched group data:", response.data);  // Console log the result
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [groupId, cookies.token]);

    return { groupData, loading, error, columns };
};

export default useGroupData;