// axios-setup.js
import axios from 'axios';
import Swal from 'sweetalert2';

// Function to clear a specific cookie
const clearCookie = (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};

// Set up the interceptor on the default Axios instance
axios.interceptors.response.use(
    response => response,
    error => {
        if ( error.response.status === 401) {
            clearCookie('token'); // Clear the 'token' cookie

            Swal.fire({
                icon: 'warning',
                title: 'Unauthorized',
                text: 'Your session has expired. Please log in again.',
                confirmButtonText: 'OK'
            }).then(() => {
                window.location.href = '/login'; // Redirect to login page
            });
        }
        return Promise.reject(error);
    }
);