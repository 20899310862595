import React, { useEffect, useState } from 'react'
import styles from '../WatchList/watchlist.module.css'
import DataTable from 'react-data-table-component'
import DeletePrice from './PriceActions/DeletePrice'
import EditPrice from './PriceActions/EditPrice'
import axios from "axios";
import {apiUrlServer, getStockAlert} from "../../apiConfig";
import { useCookies } from "react-cookie";
import { BulletList } from "react-content-loader";
import useCustomTranslation from "../../Hooks/useTranslation";
import useStocks from "../../Hooks/useStocks";
import useCustomeLoader from "../../Hooks/useCustomeLoader";
import AddPrice from './PriceActions/AddPrice';

const PriceTable = () => {
  const [prices, setPrices] = useState([]);
  console.log(prices);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['token']);
  const [currentPage, setCurrentPage] = useState(1);
  const {t} = useCustomTranslation();
  const fetchPricesData = async () => {
    const token = cookies.token;
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlServer}${getStockAlert}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const result = response.data;

      if (result.status) {
        setPrices(result.data);
      } else {
        console.error('Error fetching data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPricesData();
  }, [cookies.token]);

  const handleEditGroupSuccess = () => {
    fetchPricesData(currentPage);
  };

  const handelDeleteGroupSuccess = () => {
    fetchPricesData(currentPage);
  }

  const columns = [
    {
        name: t('Stock Code'),
      selector: row => row.stock_id.code,
      sortable: true,
    },
    {
      name: t('Stock ID'),
      selector: row => row.stock_id.name,
      sortable: true,
    },
    {
      name: t('Operator'),
      selector: row =>(row.operator === '=' ? 'Equal' : row.operator === '>' ? 'Greater Than' : 'Less Than'),
      sortable: true,
    },
    {
      name: t('Value'),
      selector: row => row.value,
      sortable: true,
    },
    {
      name: t('Actions'),
      sortable: false,
      cell: (row) => (
          <div className="d-flex align-items-center justify-content-center">
            <EditPrice stock={row.stock_id} value={row.value} groupId={row.id} onEditGroupSuccess={handleEditGroupSuccess} fetchData={fetchPricesData} operator={row.operator} />
            <DeletePrice onDeleteGroupSuccess={handelDeleteGroupSuccess} fetchData={fetchPricesData} groupId={row.id} />
          </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '64px',
        color: '#6F6F6F',
        textAlign: 'center',
      },
    },
    headCells: {
      style: {
        color: '#1D1A39',
        fontSize: '18px',
        fontWeight: '700',
        textAlign: 'center',
      },
    },
  };
const {paginationComponentOptions} = useStocks();
const {customLoader} = useCustomeLoader();
  return (
      <>
            <AddPrice addPriceSuccess={setPrices}/>
            <DataTable
                columns={columns}
                pagination
                data={prices}
                className={`${styles['table-watchList']}`}
                customStyles={customStyles}
                progressPending={isLoading}
                progressComponent={customLoader()} // Use your custom loader
                noDataComponent={<div style={{ padding: '200px' , display:"none"}}>No records to display</div>}
                paginationComponentOptions={paginationComponentOptions}
            />
      </>
  )
}

export default PriceTable;