import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyCSa-gYIN4fEgDDF_EpRurGsy4PriM4gDs",
    authDomain: "k-wealth-745f7.firebaseapp.com",
    projectId: "k-wealth-745f7",
    storageBucket: "k-wealth-745f7.appspot.com",
    messagingSenderId: "205578399871",
    appId: "1:205578399871:web:66608e85c421440d350f4a",
    measurementId: "G-H7ZZKW7GJ1"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);
export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    // console.log(permission);
    if (permission === "granted") {
        const token = await getToken(messaging, {
            vapidKey: "BNzDAxsyrxPDxdSfPS4QeVG-Zanqp6JBXoU4HNHOPx-ndwn6zTutOZnh1LeQuRzmjE94_l1fjuQIqs1kQNENS9A"
        });
        // console.log("token:", token);
    }
}