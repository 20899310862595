import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import API_URL, {apiUrlServer} from '../../../apiConfig';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AuthContext } from '../../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import defaultLogo from "../../../assets/images/logo_white-main.webp";
import nationalDayLogo from '../../../assets/images/K-Wealth-Saudi-national-Day Identity_Main-Logo.png';import style from '../Auth.module.css';
import { BsLockFill } from 'react-icons/bs';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet'
import {useTheme} from "../../../Context/ThemeContext";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const { setIsUserLoggedIn } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();

  async function handelResetPassword(values) {
    setIsLoading(true);

    try {
      const { data } = await axios.post(`${apiUrlServer}customer/resetPassword`, values);
      if(data.message === 'done') {
        sessionStorage.removeItem('otp_code', values.otp_code);
        sessionStorage.removeItem('phone', values.phone);
        toast.success('Reset Password Completed', {
          position: 'top-right',
          duration: 3000,
          className: 'text-success px-5 fw-bolder my-3',
        });

        setMessageError('');
        setIsLoading(false);
        // console.log(data);
        navigate('/login');
      }


    } catch (err) {
      setIsLoading(false);
      setMessageError(err.response.data.message);
      toast.error(err.response.data.message, {
        position: 'top-right',
        duration: 3000,
        className: 'text-danger px-5 fw-bolder my-3',
      });
    }
  }
  // async function handelResetPassword(values) {
  //   setIsLoading(true);
  //
  //   try {
  //     // Check if the new password is the same as the old password
  //     if (values.password === values.password_confirmation) {
  //       const { data } = await axios.post(`${API_URL}customer/resetPassword`, values);
  //
  //       if(data.message === 'done') {
  //         sessionStorage.removeItem('otp_code', values.otp_code);
  //         sessionStorage.removeItem('phone', values.phone);
  //         toast.success('Reset Password Completed', {
  //           duration: 3000,
  //           className: 'text-success px-5 fw-bolder my-3',
  //         });
  //
  //         setMessageError('');
  //         setIsLoading(false);
  //         console.log(data);
  //         navigate('/login');
  //       }
  //     }
  //   } catch (err) {
  //     if (err.response && err.response.data) {
  //       const errorMessage = err.response.data.message;
  //       if (errorMessage === 'The selected phone is invalid.') {
  //         toast.error("This phone number doesn’t have an account yet", {
  //           duration: 3000,
  //           className: "text-danger px-5 fw-bolder my-3",
  //         });
  //       }
  //     }
  //     // setIsLoading(false);
  //     // setMessageError(err.response.data.message);
  //     // toast.error(err.response.data.message, {
  //     //   duration: 3000,
  //     //   className: 'text-danger px-5 fw-bolder my-3',
  //     // });
  //   }
  // }
  let validationSchema = Yup.object({
    otp_code: Yup.string().required('OTP code is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        'Password must contain at least one letter, one number, and one special character'
      )
      .required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password does not match')
      .required('Confirm Password is required'),
    phone: Yup.string()
      .matches(
        /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
        'Invalid ksa phone number'
      )
      .required('Phone number is required'),
  });


  let otp_code = sessionStorage.getItem('otp_code');
  let storedPhone = sessionStorage.getItem('phone');

  const formik = useFormik({
    initialValues: {
      otp_code: otp_code,
      phone:  storedPhone,
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    onSubmit: handelResetPassword,
  });

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div>
        <div className="py-5 h-screen">
          <div className="container">
            <div className="row justify-content-center align-items-center min-h-screen-authForm">
              <div className={`col-xxl-4 col-lg-5 col-md-6 col-10 bg-white p-4 ${style.formContainer}`}>
                <div className="text-center my-16">
                  <img className="max-w" src={logo} alt="logo" />
                </div>
                <h2 className={`${style['head-form']}`}>Reset Password</h2>

                <form onSubmit={formik.handleSubmit}>
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    <BsLockFill className={`${style['icon-input']} me-3`} />
                    <div className="w-100 position-relative">
                      <input
                        type={`${showPassword ? 'text' : 'password'}`}
                        className={`${style['input-custom']} form-control`}
                        placeholder="Password"
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div
                        className={`${style['show-password']} position-absolute top-50 end-0 translate-middle-y`}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    {formik.errors.password && formik.touched.password && (
                      <small className={`${style['text-error']}`}>{formik.errors.password}</small>
                    )}
                  </div>

                  <div className="d-flex align-items-center justify-content-center mb-3">
                    <BsLockFill className={`${style['icon-input']} me-3`} />
                    <div className="w-100 position-relative">
                      <input
                        type={`${showPassword ? 'text' : 'password'}`}
                        className={`${style['input-custom']} form-control`}
                        placeholder="Confirm Password"
                        id="password_confirmation"
                        name="password_confirmation"
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div
                        className={`${style['show-password']} position-absolute top-50 end-0 translate-middle-y`}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    {formik.errors.password_confirmation && formik.touched.password_confirmation && (
                      <small className={`${style['text-error']}`}>
                        {formik.errors.password_confirmation}
                      </small>
                    )}
                  </div>

                  <div className={`${style['btn-container']}`}>
                    {isLoading ? (
                      <button type="button"  className={`${style['btn-signup']} btn opacity-75 fw-bolder px-4 w-100`}>
                        <span  className="spinner-border spinner-border-sm " role="status" aria-hidden="false"></span>
                      </button>
                    ) : (
                      <button disabled={!formik.isValid} type="submit" className={`btn ${style['btn-signup']}`}>
                        Reset Password
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default ResetPassword;