import React, { useEffect, useState } from 'react';
import { messaging } from '../Context/FCMTokenContext';
import { onMessage } from 'firebase/messaging';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useLanguage } from '../Context/LanguageContext';
import { useCookies } from 'react-cookie';
import { apiUrlServer } from '../apiConfig';

// Create a custom hook for managing notifications with React Query
export const useNotifications = () => {
  const [cookies] = useCookies(['token']);
  const { language } = useLanguage();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);

  const fetchNotifications = async ({ pageParam = currentPage}) => {
    let token = cookies.token;
    const response = await axios.get(`${apiUrlServer}notifications?page=${pageParam}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'Accept-Language': language,
      },
    });
    return response.data;
  };

  const { 
    isLoading:loading,
    data: notificationsData,
    refetch 
  } = useQuery({
    queryKey: ['notifications', language, cookies.token,currentPage],
    queryFn: fetchNotifications,
    onError: (e) => {
      console.error(e);
      toast.error('Failed to fetch notifications');
    },
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const markAsReadMutation = useMutation({
    mutationFn: async (id) => {
      let token = cookies.token;
      await axios.post(
        `${apiUrlServer}notifications/${id}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept-Language': language,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      toast.success('Notification marked as read');
    },
    onError: (e) => {
      console.error(e);
      toast.error('Failed to mark notification as read');
    },
  });

  const deleteNotificationMutation = useMutation({
    mutationFn: async (id) => {
      let token = cookies.token;
      await axios.delete(`${apiUrlServer}notifications/${id}/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept-Language': language,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      toast.success('Notification deleted successfully!');
    },
    onError: (e) => {
      console.error(e);
      toast.error('Failed to delete notification');
    },
  });

  const deleteAllNotificationsMutation = useMutation({
    mutationFn: async () => {
        let token = cookies.token;
        await axios.post(
        `${apiUrlServer}notifications/clear`,
        {},
        {
            headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept-Language': language,
            },
        }
        );
    },
    onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] });
        toast.success('All notifications cleared successfully!');
    },
    onError: (e) => {
        console.error(e);
        toast.error('Failed to clear notifications');
    },
  });

  const markAsRead = (id) => {
    markAsReadMutation.mutate(id);
  };

  const deleteNotification = (id) => {
    deleteNotificationMutation.mutate(id);
  };

  const deleteAllNotifications = () => {
      deleteAllNotificationsMutation.mutate();
  };

  // Handle incoming notifications
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      toast.success('New notification arrived');
      refetch();
    });

    return () => unsubscribe();
  }, [refetch]);

  // Return the values needed by components
  return {
    notifications: notificationsData?.data || [], // Access notifications from data.data
    meta: notificationsData?.meta || {},
    markAsRead, 
    loading, 
    deleteNotification, 
    deleteAllNotifications,
    currentPage,
    handlePageChange, 
  };
};