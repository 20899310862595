import React, { useEffect, useState } from 'react';
import LatestNews from "../components/Home/LatestNews";
import axios from "axios";
import { apiUrlServer } from "../apiConfig";
import './New.css';
import NewsChart from './NewsChart';
import useCustomTranslation from '../Hooks/useTranslation';
import CommonBanner from '../components/CommonBanner/CommonBanner';
import { Helmet } from "react-helmet";
import { useLanguage } from "../Context/LanguageContext";
import {Link} from "react-router-dom";
import MarketTabs from "../components/Home/MarketTabs";

const News = () => {
    const { t } = useCustomTranslation();
    const currentDate = new Date();
    const previousYearDate = new Date(currentDate);
    previousYearDate.setFullYear(currentDate.getFullYear() - 1);

    const [NewsChartData, setNewsChartData] = useState([]);
    const [news, setNews] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [stocks, setStocks] = useState([]);
    const [searchStocks, setSearchStocks] = useState('');
    const [tags, setTags] = useState([]);
    const [tagsSearchTerm, setTagsSearchTerm] = useState('');
    const [symbolsSearchTerm, setSymbolsSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { language } = useLanguage();
    let parsNewsChartData;

    const fetchNews = async (page = 1) => {
        try {
            const params = {
                page: page,
                per_page: 10,
                filter: {}
            };

            if (searchStocks) params.filter['symbols'] = searchStocks;
            if (searchTerm) params.filter['title'] = searchTerm;
            if (tagsSearchTerm) params.filter['tags'] = tagsSearchTerm;
            if (dateFrom) params.filter['from'] = dateFrom;
            if (dateTo) params.filter['to'] = dateTo;

            const response = await axios.get(`${apiUrlServer}news`, {
                params,
                headers: {
                    'Accept-Language': language,
                }
            });

            setNews(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setTotalPages(response.data.meta.total_pages);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };

// Function to handle search operation
    const handleSearch = async () => {
        setCurrentPage(1); // Reset to first page on new search
        await fetchNews(1); // Fetch news for the first page
    };

// Effect to fetch news when current page changes
    useEffect(() => {
        fetchNews(currentPage);
    }, [currentPage]);

    const fetchTags = async () => {
        try {
            const response = await axios.get(`${apiUrlServer}tag?filter[type]=news`);
            setTags(response.data.data);
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    useEffect(() => {
        fetchTags();
    }, []);

    const truncateText = (text, maxLength) => {
        const words = text.split(' ');
        if (words.length > maxLength) {
            return words.slice(0, maxLength).join(' ') + '...';
        }
        return text;
    };

    const handleResetFilters = () => {
        setSearchTerm('');
        setDateFrom('');
        setDateTo('');
        setTagsSearchTerm('');
        setSearchStocks('');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleDateFromChange = (e) => {
        setDateFrom(e.target.value);
    };

    const handleDateToChange = (e) => {
        setDateTo(e.target.value);
    };

    const handleStocksChange = (e) => {
        setSearchStocks(e.target.value);
    };

    const handleTagsChange = (e) => {
        setTagsSearchTerm(e.target.value);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const renderPageNumbers = () => {
        const maxPagesToShow = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
        let endPage = startPage + maxPagesToShow - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - maxPagesToShow + 1, 1);
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const fetchNewsChart = async () => {
        try {
            const params = {
                from: previousYearDate,
                to: currentDate,
                code: '1020.SR'
            };

            if (dateFrom) params.from = dateFrom;
            if (dateTo) params.to = dateTo;
            if (searchStocks) params.code = `${searchStocks}.SR`;

            const response = await axios.get(`${apiUrlServer}stock-details/eod`, { params });
            parsNewsChartData = response.data.data.map(dataItem => ({
                time: dataItem.date,
                open: dataItem.open,
                high: dataItem.high,
                low: dataItem.low,
                close: dataItem.close
            }));

            setNewsChartData(parsNewsChartData);
        } catch (error) {
            console.error('Error fetching news chart:', error);
        }
    };

    useEffect(() => {
        fetchNewsChart();
    }, [dateFrom, dateTo, searchStocks]);


    return (
        <>
            <Helmet>
                <title>{t('News')}</title>
            </Helmet>
            <CommonBanner title={`${t('News')}`} />
            <div className=" ">
                <div className='row'>
                    <div className='filter_container'>
                        <div className='shadow-sm p-4 bg-white mb-24 mt-8 '>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 className="tasi_summery mb-3">{t('Filters')}</h3>
                                <div className="collapsible rotate align-items-center collapsed"
                                     style={{height: 'fit-content', cursor: 'pointer'}} data-bs-toggle="collapse"
                                     href="#CollapseFilter" aria-expanded="false" aria-controls="CollapseFilter">
                                    <i className="fas fa-chevron-up fillter_collapse_btn fs-4"
                                       style={{height: 'fit-content'}}></i>
                                </div>
                            </div>
                            <div className='collapse' id="CollapseFilter">
                                <div className='filter_inputs'>
                                    <div className='form-group mb-3'>
                                        <label htmlFor="searchTerm"
                                               className='form-label mb-1 mx-1 fs-14'>{t('Title')}</label>
                                        <input   onKeyPress={handleKeyPress} type="text" placeholder={t("Enter Title name")} value={searchTerm}
                                               onChange={handleSearchChange} className='form-control border-radius-16'/>
                                    </div>

                                    <div className='form-group mb-3'>
                                        <label htmlFor="tagsSearchTerm"
                                               className='form-label mb-1 mx-1 fs-14'>{t('Tags')}</label>
                                        <input   onKeyPress={handleKeyPress} type="text" placeholder={t("Enter Tag name")} value={tagsSearchTerm}
                                               onChange={handleTagsChange} className='form-control border-radius-16'/>
                                    </div>

                                    <div className='form-group mb-3'>
                                        <label htmlFor="searchStocks"
                                               className='form-label mb-1 mx-1 fs-14'>{t('Symbols')}</label>
                                        <input   onKeyPress={handleKeyPress} type="text" placeholder={t("Enter Symbols name")} value={searchStocks}
                                               onChange={handleStocksChange} className='form-control border-radius-16'/>
                                    </div>


                                    <div className='form-group mb-3'>
                                        <label htmlFor="dateFrom"
                                               className='form-label mb-1 mx-1 fs-14'>{t('Start Date')}</label>
                                        <input  type="date" name='dateFrom' value={dateFrom}
                                               onChange={handleDateFromChange}
                                               className='form-control border-radius-16'/>
                                    </div>

                                    <div className='form-group mb-3'>
                                        <label htmlFor="dateTo"
                                               className='form-label mb-1 mx-1 fs-14'>{t('End Date')}</label>
                                        <input type="date" name='dateTo' value={dateTo} onChange={handleDateToChange}
                                               className='form-control border-radius-16'/>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-3 align-items-center'>
                                    <button onClick={handleSearch} className='btn btn-success'>{t('Apply')}</button>
                                    <button onClick={handleResetFilters}
                                            className='btn btn-danger'>{t('Reset Filters')}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-9 col-sm-12">
                        <div className='shadow-sm p-4  bg-white  mb-3'>
                            <h3 className="card_title">{t('Latest News')}</h3>
                            <ul className='p-0'>
                                {news.map((item, index) => (
                                    <li className="news-list card mb-3  border-0" key={index}>
                                        <div className=''>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h2 className="title-head"> {truncateText(item.title, 30)}</h2>

                                            </div>
                                            <div className="d-flex align-items-center justify-content-start">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                                     viewBox="0 0 16 17" fill="none">
                                                    <g clip-path="url(#clip0_3390_76579)">
                                                        <path
                                                            d="M14.125 1.97656H13.375V0.726562H12.125V1.97656H3.875V0.726562H2.625V1.97656H1.875C0.841125 1.97656 0 2.81769 0 3.85156V14.8516C0 15.8854 0.841125 16.7266 1.875 16.7266H14.125C15.1589 16.7266 16 15.8854 16 14.8516V3.85156C16 2.81769 15.1589 1.97656 14.125 1.97656ZM14.75 14.8516C14.75 15.1962 14.4696 15.4766 14.125 15.4766H1.875C1.53038 15.4766 1.25 15.1962 1.25 14.8516V6.60156H14.75V14.8516ZM14.75 5.35156H1.25V3.85156C1.25 3.50694 1.53038 3.22656 1.875 3.22656H2.625V4.47656H3.875V3.22656H12.125V4.47656H13.375V3.22656H14.125C14.4696 3.22656 14.75 3.50694 14.75 3.85156V5.35156Z"
                                                            fill="#1D1A39"/>
                                                        <path
                                                            d="M2.375 7.91406H3.625V9.16406H2.375V7.91406ZM4.875 7.91406H6.125V9.16406H4.875V7.91406ZM7.375 7.91406H8.625V9.16406H7.375V7.91406ZM9.875 7.91406H11.125V9.16406H9.875V7.91406ZM12.375 7.91406H13.625V9.16406H12.375V7.91406ZM2.375 10.4141H3.625V11.6641H2.375V10.4141ZM4.875 10.4141H6.125V11.6641H4.875V10.4141ZM7.375 10.4141H8.625V11.6641H7.375V10.4141ZM9.875 10.4141H11.125V11.6641H9.875V10.4141ZM2.375 12.9141H3.625V14.1641H2.375V12.9141ZM4.875 12.9141H6.125V14.1641H4.875V12.9141ZM7.375 12.9141H8.625V14.1641H7.375V12.9141ZM9.875 12.9141H11.125V14.1641H9.875V12.9141ZM12.375 10.4141H13.625V11.6641H12.375V10.4141Z"
                                                            fill="#1D1A39"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_3390_76579">
                                                            <rect width="16" height="16" fill="white"
                                                                  transform="translate(0 0.726562)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="date-news">{item.date}</span>
                                            </div>

                                            <p className="text-news"
                                               style={{margin: "16px 0"}}>{truncateText(item.content, 50)}</p>
                                            <div className="card-insights-new">
                                                {((item.symbols && item.symbols.length > 0) || (item.tags && item.tags.length > 0) || (item.sentiment)) && (
                                                    <div
                                                        className="d-flex flex-column justify-content-between my-3 mb-0 gap-2">
                                                        {item.symbols && item.symbols.length > 0 && (
                                                            <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">{t('Symbols')} :
                                                                <div className='d-flex gap-2 flex-wrap'>
                                                                    {item.symbols.map((symbol, index) => (
                                                                        <span key={index}
                                                                              className="symbol-item">{symbol}</span>
                                                                    ))}
                                                                </div>
                                                            </p>
                                                        )}

                                                        {item.tags && item.tags.length > 0 && (
                                                            <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">{t('Tags')}:
                                                                <div className='d-flex gap-2 flex-wrap'>
                                                                    {item.tags.map((tag, index) => (
                                                                        <span key={index}
                                                                              className="tags-item">{tag}</span>
                                                                    ))}
                                                                </div>
                                                            </p>
                                                        )}

                                                        {item.sentiment && (
                                                            <p className="news-infos m-0 d-flex text-nowrap gap-1 mb-2">{t('Sentiment')}:
                                                                <div className='d-flex gap-2 flex-wrap'>
                                                                <span
                                                                    className="sentiment-item">Neg: {item.sentiment.neg ?? 0}</span>
                                                                    <span
                                                                        className="sentiment-item">Neu: {item.sentiment.neu ?? 0}</span>
                                                                    <span
                                                                        className="sentiment-item">Pos: {item.sentiment.pos ?? 0}</span>
                                                                    <span
                                                                        className="sentiment-item">Polarity: {item.sentiment.polarity ?? 0}</span>
                                                                </div>
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="d-flex align-items-center justify-content-end gap-3 mt-2">
                                                <a className=" btn-open-link btn-view-link" target="_blank"
                                                   href={item.link}>{t('View Link')}</a>
                                                <Link to={`/news/${item.id}`}
                                                      className="btn-open-link ">{t('Read More')}</Link>
                                            </div>

                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <nav aria-label="Page navigation">
                                <ul className="pagination d-flex justify-content-center">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}
                                                tabIndex="-1" aria-disabled={currentPage === 1}>Previous
                                        </button>
                                    </li>
                                    {renderPageNumbers().map((page) => (
                                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link"
                                                    onClick={() => handlePageChange(page)}>{page}</button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}
                                                aria-disabled={currentPage === totalPages}>Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>

                    <div className="col-lg-3 col-sm-12">
                        <div className="sticky-column">
                            <div className='shadow-sm p-4 bg-white  mb-3'>
                                <h3 className="card_title">{t('Chart')}</h3>
                                <NewsChart data={NewsChartData}/>
                            </div>
                            <div className='shadow-sm p-4 bg-white  mb-3'>
                                <MarketTabs/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default News;