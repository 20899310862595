import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import style from '../Auth.module.css'
import { BsFillTelephoneFill, BsLockFill } from 'react-icons/bs'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { AuthContext } from '../../../Context/AuthContext'
import defaultLogo from "../../../assets/images/logo_white-main.webp";
import nationalDayLogo from '../../../assets/images/K-Wealth-Saudi-national-Day Identity_Main-Logo.png';
import { useCookies } from 'react-cookie'
import { apiUrlServer } from '../../../apiConfig'
import SendOtp from '../SendOtp/SendOtp'
import { Helmet } from 'react-helmet'
import { FCMTokenContext } from "../../../Context/FCMTokenContext";
import useCustomTranslation from "../../../Hooks/useTranslation";
import { useTheme } from "../../../Context/ThemeContext";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setIsUserLoggedIn } = useContext(AuthContext);
  const [cookies, setCookie] = useCookies(['token']);
  const [isOtpConfirmed, setIsOtpConfirmed] = useState(false);
  const deviceToken = useContext(FCMTokenContext);
  const { t } = useCustomTranslation();
  const { theme } = useTheme();

  useEffect(() => {
    const token = cookies.token;
    if (token) {
      setIsUserLoggedIn(true);
      navigate('/home');
    }
  }, [cookies.token, setIsUserLoggedIn, navigate]);
  useEffect(() => {
    if (deviceToken) {
      formik.setFieldValue('device_token', deviceToken);
    }
  }, [deviceToken]);
  useEffect(() => {
    console.log('Current device token:', deviceToken);
  }, [deviceToken]);
  const validationSchema = Yup.object({
    phone: Yup.string()
        .matches(/^(00965|965|\+965|5)([0-9]{8})$/, 'Invalid phone number')
        .required(t('Phone number is required')),
    password: Yup.string()
        .required(t('Password is required'))
        .min(8, t('Password must be at least 8 characters'))
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            t('Password must contain at least one letter, one number, and one special character')
        ),
    device_token: Yup.string().required('Device Token is required'),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
      device_token: deviceToken ,
    },
    validationSchema,
    onSubmit: handleLogin,
  });

  async function handleLogin(values) {
    console.log('Login attempt started', values);
    setIsLoading(true);
    try {
      console.log('Sending request to server');
      const { data } = await axios.post(`${apiUrlServer}customer/login`, values);
      console.log('Server response:', data);

      if (data.message === "Data Retrieved Successfully") {
        console.log('Login successful');
        setCookie('token', data.data.token, { path: '/' });
        setIsUserLoggedIn(true);
        toast.success("Login Successfully", {
          position: 'top-right',
          duration: 3000,
          className: "text-success px-5 fw-bolder my-3"
        });
        setIsLoading(false);
        setIsOtpConfirmed(true);
        navigate("/home");
      }
    } catch (err) {
      console.error('Login error:', err);
      setIsLoading(false);
      if (err.response && err.response.data) {
        const errorMessage = err.response.data.message;
        if (errorMessage === "Invalid Phone number or password") {
          toast.error(errorMessage, {
            position: "top-right",
            duration: 3000,
            className: "text-danger px-5 fw-bolder my-3"
          });
        } else if (errorMessage === "Attempt to read property \"password\" on null") {
          toast.error("This number is not registered", {
            position: "top-right",
            duration: 3000,
            className: "text-danger px-5 fw-bolder my-3"
          });
        } else if (errorMessage === "Your account not verified yet") {
          toast.error(errorMessage, {
            position: "top-right",
            duration: 3000,
            className: "text-danger px-5 fw-bolder my-3"
          });
          navigate('/sendotp');
        }
      }
    }
  }

  console.log('Form is valid:', formik.isValid);
  console.log('Form errors:', JSON.stringify(formik.errors, null, 2));

  return (
      <>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <div className="py-5 h-screen">
          <div className="container">
            <div className="row justify-content-center align-items-center min-h-screen-authForm">
              <div className={`col-xxl-4 col-lg-5 col-md-6 col-10 bg-white p-4 ${style.formContainer}`}>
                <div className="text-center my-16">
                  <img
                      src={theme === 'default' ? defaultLogo : nationalDayLogo}
                      className=""
                      style={{ width: "124px" }}
                      alt="k wealth logo"
                  />
                </div>
                {isOtpConfirmed ? (
                    <>
                      <h2 className={`${style['head-form']}`}>Send OTP</h2>
                      <SendOtp phoneNumber={formik.values.phone} />
                    </>
                ) : (
                    <>
                      <h2 className={`${style['head-form']}`}>{t('Login')}</h2>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="d-flex align-items-center justify-content-center mb-3 div-input">
                          <BsFillTelephoneFill className={`${style['icon-input']} me-3`} />
                          <input
                              type="text"
                              className={`${style['input-custom']} form-control`}
                              placeholder={t('phone')}
                              id="phone"
                              name="phone"
                              value={formik.values.phone}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                console.log('Phone input changed:', e.target.value);
                                formik.handleChange(e);
                              }}
                              autoComplete="username"
                          />
                        </div>
                        <div className="mb-3">
                          {formik.errors.phone && formik.touched.phone &&
                              <small className={`${style['text-error']}`}>
                                {formik.errors.phone}
                              </small>
                          }
                        </div>

                        <div className="d-flex align-items-center justify-content-center mb-3">
                          <BsLockFill className={`${style['icon-input']} me-3`} />
                          <div className="w-100 position-relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  console.log('Password input changed:', e.target.value);
                                  formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                autoComplete="current-password"
                                placeholder={t('password')}
                            />
                            {!formik.errors.password && (
                                <div
                                    className={`${style['show-password']} position-absolute top-50 end-0 translate-middle-y`}
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          {formik.errors.password && formik.touched.password && (
                              <small className={`${style['text-error']}`}>
                                {formik.errors.password}
                              </small>
                          )}
                        </div>
                        <div className={`${style['btn-container']}`}>
                          <button
                              disabled={!formik.isValid || isLoading}
                              type="submit"
                              className={`btn ${style['btn-signup']} w-100`}
                          >
                            {isLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : t("Login")}
                          </button>
                        </div>
                        <div className="d-flex mt-3 flex-column align-items-center justify-content-between">
                          <Link className={`${style['icon-input']} fw-800 text-secondary-color-3`} to="/forgetPassword">
                            {t("Forget Password")}
                          </Link>
                          <Link className={`${style['icon-input']} fw-800`} to="/register">
                            <span className='fw-600 text-natural-color-3`'>{t("Not Have an Account?")} </span>
                            {t('Sign Up')}
                          </Link>
                        </div>
                      </form>
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default Login