import React, { useState } from 'react'
import style from '../Auth.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineNumber } from 'react-icons/ai'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import axios from 'axios'
import defaultLogo from "../../../assets/images/logo_white-main.webp";
import nationalDayLogo from '../../../assets/images/K-Wealth-Saudi-national-Day Identity_Main-Logo.png';
import {useTheme} from "../../../Context/ThemeContext";
const VerifyResetCode = () => {
  let [isLoading, setIsLoading] = useState(false)
  let [messageError, setMessageError] = useState("");
  let navigate = useNavigate();
  const { theme } = useTheme();

  async function handleResetCode(values){
      setIsLoading(true);
      const {data} = await axios.post('https://route-ecommerce.onrender.com/api/v1/auth/verifyResetCode',values).catch((err) => {
        setIsLoading(false);
        setMessageError(err.response.data.message);
        toast.error(err.response.data.message,{
          duration: 3000,
          className: "text-danger px-5 fw-bolder my-3"
        })
      })
    if (data.status === 'Success'){

      toast.success("Success! Your reset code has been confirmed. You can now proceed to reset your password",{
        duration: 3000,
        className: "text-success px-5 fw-bolder my-3"
      })
      setIsLoading(false);
      navigate("/resetPassword");
    }
    // console.log(data);

  }
  const validationSchema = Yup.object({
    resetCode: Yup.string().required('Reset Code is required')
  });

  let formik = useFormik({
    initialValues:{
      'resetCode': '',
    },
    validationSchema,
    onSubmit:handleResetCode
  })

  return (
    <>
      <div className="container">
        <div className="bg-white rounded shadow my-5 p-4">
          <div className="row align-items-center">
            <div className="col-md-12 bg-white shadow rounded p-4">
              <div className="text-center my-16">
                <img className="" src={logo} alt="logo"/>
              </div>
              <h2 className={`${style['head-form']}`}>{t('Use to Reset Your Password')}</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <AiOutlineNumber className={`${style[ 'icon-input']} me-3`} />
                  <input
                    type="text"
                    className={`${style['input-custom']} form-control`}
                    placeholder={t('Reset Code')}
                    id="resetCode"
                    name="resetCode"
                    value={formik.values.resetCode}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-3">
                  {formik.errors.resetCode && formik.touched.resetCode &&
                    <small className={`${style['text-error']}`}>
                      {formik.errors.resetCode}
                    </small>
                  }
                </div>
                <div>
                  {isLoading ? (
                    <button type="button" className="btn btn-outline-danger opacity-75 fw-bolder px-4">
                      <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                    </button>
                  ) : (
                    <button disabled={!formik.isValid} type="submit" className={`btn ${style['btn-signup']}    mt-3`}>
                      {t('Verify Code')}
                    </button>
                  )}
                </div>
                <div className="d-flex mt-3 align-items-center justify-content-between">
                  <Link className={`${style[ 'icon-input']} me-3`} to="/forgetPassword">{t('Send Code Again')}</Link>
                  <Link className={`${style[ 'icon-input']} me-3`} to="/login">{t('Back to ? Sign In')}</Link>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default VerifyResetCode