import React from 'react'
import styles from "../Indices/DeatialsBanner/DetailsBanner.module.css";

const CommonBanner = (props) => {
  return (
      <section className={`${styles['block-text-two']} d-flex align-items-center justify-content-between`}>
        <div>
            <span className={`${styles['block-text']}`}>
              <svg className={`${styles['bannar-icon']}`} width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_676_2909" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0"
                      width="25"
                      height="40">
                  <path d="M-6.10352e-05 0L-6.10352e-05 40H24.4765L24.4765 0H-6.10352e-05Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_676_2909)">
                  <path d="M7.79515 26.4609L-6.10352e-05 19.9684L7.79515 13.4759L7.79515 26.4609Z" fill="white"/>
                  <path d="M24.4765 26.4609L16.6812 19.9684L24.4765 13.4759V26.4609Z" fill="white"/>
                  <path d="M24.4765 13.0625L16.6812 6.56997L24.4765 0.0774431V13.0625Z" fill="white"/>
                  <path d="M24.4765 39.8594L16.6812 33.3668L24.4765 26.8743V39.8594Z" fill="white"/>
                  <path d="M16.1342 33.3667L8.34216 26.8742L16.1342 20.3816V33.3667Z" fill="white"/>
                  <path d="M16.1342 19.5552L8.34216 13.0627L16.1342 6.57012V19.5552Z" fill="white"/>
                </g>
              </svg>
              {props.title}
            </span>
        </div>
              {props.actionComponent && (
                <>
                  {props.actionComponent}
                </>
              )}
      </section>

  )
}

export default CommonBanner