import React from "react";
import positiveChangeImage from "../../../assets/icons/greenflag.png";
import negativeChangeImage from "../../../assets/icons/redFlag.png";
import positiveChangeArrow from "../../../assets/icons/FrameGreen.png";
import negativeChangeArrow from "../../../assets/icons/FrameRed.png";
import useCustomTranslation from "../../../Hooks/useTranslation";
import {
  formatNumber,
  useFormattedPercentage,
} from "../../../Hooks/useNumberFormatting";
import "./BannerStocks.css";

const BannerStocksDetails = (props) => {
  const getChangeFlag = () => {
    if (props.change_p > 0) {
      return positiveChangeImage; // Image for positive change
    } else {
      return negativeChangeImage; // Image for non-positive change
    }
  };
  const getChangeArrow = () => {
    if (props.change_p > 0) {
      return positiveChangeArrow; // Image for positive change
    } else {
      return negativeChangeArrow; // Image for non-positive change
    }
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Asia/Riyadh", // Set your desired time zone
    };
    const timeString = date.toLocaleString("en-US", options);
    return `Last | ${timeString} CTT`;
  };
  const { t } = useCustomTranslation();
  // const formatNumber = (number) => {
  //     return (-number).toLocaleString('en-US', {
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2,
  //     });
  // };

  const formattedChangeP = useFormattedPercentage(props.change_p);
  // Round the number to two decimal places
  const formattedClose = formatNumber(props.close);
  const formattedOpen = formatNumber(props.open);
  const formattedChange = formatNumber(props.change);

  const formattedPreviousClose = formatNumber(props.previousClose);
  return (
    <>
      <div className=" card-banner">
        <div className="banner-head-data w-100">
          <div className="box-1 d-flex">
            <div className="stock-box-code">{props.Code}</div>
            <div className="d-block ">
              <div className="d-block">
                <span className="date-indcies">
                  {formatTimestamp(props.timestamp)}
                </span>
              </div>
              <div className="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                <div className="d-flex align-items-center ">
                  <div className="mr-24 d-flex justify-content-center align-items-center">
                    {/*<img className="flag-size mx-2" src={getChangeFlag()} alt="Change Image"/>*/}
                    <span className="close-indcies">{formattedClose}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className="change_p_indcies"
                      style={{
                        color: props.change_p > 0 ? "#049C6B" : "#FD2523",
                      }}
                    >
                      <img
                        className="arrow-indcies"
                        src={getChangeArrow()}
                        alt="Change_p"
                      />
                      {formattedChange}({formattedChangeP})
                    </span>
                  </div>
                </div>
                <div className="d-flex gap-5 align-items-center gap-row-10px flex-wrap">
                  <div className="indices-text-details">
                    <span className="mx-1">{t("open")}</span>
                    <span className="indices-text-number">{formattedOpen}</span>
                  </div>
                  <div className="indices-text-details">
                    <span className="mx-1">{t("Prev.close")}</span>
                    <span className="indices-text-number">
                      {formattedPreviousClose}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-2">
            <div className="d-flex gap-5 align-items-center gap-row-10px flex-wrap">
              <div className="stodck-details-text">
                <span className="mx-1">{t("type")}:</span>
                <span className="stodck-details-number">{props.Type}</span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("industry")}:</span>
                <span className="stodck-details-number">{props.Industry}</span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("ISIN")}:</span>
                <span className="stodck-details-number">{props.ISIN}</span>
              </div>
            </div>
            <div className="d-flex gap-5 align-items-center gap-row-10px flex-wrap">
              <div className="stodck-details-text">
                <span className="mx-1">{t("CurrencyName")}:</span>
                <span className="stodck-details-number">
                  {props.CurrencyName}
                </span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("CurrencyCode")}:</span>
                <span className="stodck-details-number">
                  {props.CurrencyCode}
                </span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("CurrencySymbol")}:</span>
                <span className="stodck-details-number">
                  {props.CurrencySymbol}
                </span>
              </div>
            </div>
          </div>
          <div className="box-2">
            <div className="d-flex gap-5 align-items-center gap-row-10px flex-wrap">
              <div className="stodck-details-text">
                <span className="mx-1">{t("Volume Total")}:</span>
                <span className="stodck-details-number">
                  {props.volumeTotal}
                </span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("Turnover Total")}:</span>
                <span className="stodck-details-number">
                  {props.turnoverTotal}
                </span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("Market Capitalisation")}:</span>
                <span className="stodck-details-number">
                  {props.marketCapitalisation}
                </span>
              </div>
            </div>
            <div className="d-flex gap-5 align-items-center gap-row-10px flex-wrap">
              <div className="stodck-details-text">
                <span className="mx-1">{t("Number Of Trades")}:</span>
                <span className="stodck-details-number">
                  {props.numberOfTrades}
                </span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("Closing Ask")}:</span>
                <span className="stodck-details-number">
                  {props.closingAsk}
                </span>
              </div>
              <div className="stodck-details-text">
                <span className="mx-1">{t("Closing Bid")}:</span>
                <span className="stodck-details-number">
                  {props.closingBid}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerStocksDetails;
