  import React, { useEffect, useState, useRef } from 'react'
  import { Modal} from 'react-bootstrap'
  import stocksicon from '../../../assets/icons/stocksp.png'
  import Select from 'react-select'
  import styles from './ManualAdd.module.css'
  import { useCookies } from 'react-cookie'
  import axios from 'axios'
  import {addPortfolioStock, apiUrlServer, getAllStocks} from '../../../apiConfig'
  import Swal from 'sweetalert2'
  import iconSuccess from '../../../assets/icons/suceessAdd.png'
  import iconError from '../../../assets/icons/errorCancel.png'
  import { useFormik } from 'formik'
  import { useParams } from 'react-router-dom'
  import useCustomTranslation from "../../../Hooks/useTranslation";
  import useDebounce from '../../../Hooks/useDebounce'
  import {selectStyle} from "../../../Hooks/SelectElStyle";
  import ProtectedButton from "../../ProtectedLink/ProtectedButton";

  let lastPageSucsses
  const ManualEntry = ({ fetchStockData }) => {
    const { id } = useParams(); // Extract the id parameter from the URL
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [cookies] = useCookies(['token']);
    const [stocks, setStocks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [limit, setLimit] = useState(10);
    const [activeTab, setActiveTab] = useState('stocks');
    const [nameFilter, setNameFilter] = useState('');
    const [page, setPage] = useState(1);
    const {t} = useCustomTranslation();
    const debouncedNameFilter = useDebounce(nameFilter, 500);
    const [firstFilter, setFirstFilter] = useState(false);
    const cancelTokenSource = useRef(null);
    const [meta,setMeta] = useState({total_pages:2})
    const [selectSearchInput,setSelectSearchInput] = useState('')
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
    const handleMenuScroll = async (e) => {
      const target = e.target;
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        // User has scrolled to the bottom
        setPage((prevPage) => prevPage + 1);
      }
    };

    useEffect(() => {
      if(firstFilter){
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Operation canceled due to new request.');
        }
        cancelTokenSource.current = axios.CancelToken.source();
        const params = {
          limit: limit,
          page: page,
          filter: {
          },
        };
        const fullUrlContent = {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
            'Content-Type': 'multipart/form-data',
          },
          params: params,
          cancelToken: cancelTokenSource.current.token
        }
        if (debouncedNameFilter) params.filter['name'] = debouncedNameFilter;
        fetchStocksData(fullUrlContent,true);
      }

      return () => {
          if (cancelTokenSource.current) {
              cancelTokenSource.current.cancel();
          }
      };
    }, [debouncedNameFilter, cookies]);

    const handleSearch = (inputValue) => {
      setPage(1);
      setFirstFilter(true);
      setNameFilter(inputValue);
    };

    const fetchStocksData = async (fullUrlContent,isFilter = false) => {
      try {
        const response = await axios.get(`${apiUrlServer}${getAllStocks}`, fullUrlContent);
        const result = response.data;
        console.log(result);

        if (result.status) {
          lastPageSucsses = page
          setMeta(result.meta)
          if(isFilter){
            setStocks(result.data);
          }else{
            setStocks((prevStocks) => [...prevStocks, ...result.data]);
          }
        } else {
          console.error('Error fetching data:', result.message);
        }
      } catch (error) {
        setPage((prevPage) => {
          if(prevPage > 1 && lastPageSucsses){
            return lastPageSucsses
          }else if (!lastPageSucsses){
            return 1
          }
        });
        console.error('Error fetching data:', error.message);
      }
    };

    useEffect(() => {
      const params = {
        limit: limit,
        page: page,
        filter: {
        },
      };
      const fullUrlContent = {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
          'Content-Type': 'multipart/form-data',
        },
        params: params,
      }
      if (nameFilter) params.filter['name'] = nameFilter;
      if(page < meta.total_pages+1){
        fetchStocksData(fullUrlContent);
      }
    }, [page, cookies.token]);

    async function handleAddStock(values) {
      // console.log(values);
      // Calculate value based on rate and quantity
      const value = parseFloat(values.rate) * parseFloat(values.quantity);

      // Update the values object with the calculated value
      const updatedValues = {
        ...values,
        value: isNaN(value) ? '' : value.toFixed(2),
      };

      setIsLoading(true);
      try {
        const token = cookies.token;
        const response = await axios.post(`${apiUrlServer}${addPortfolioStock}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        // console.log('watchlist updated successfully:', response.data.data);

        // Show SweetAlert success notification
        Swal.fire({
          title: 'Your Watchlist Group Added successfully',
          iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
          text: 'Start Adding Stocks to your Group',
          confirmButtonText: 'Back to Portfolio',
          cancelButtonText: 'Add Another',
          customClass: {
            confirmButton: 'swal-btn-confirmButton', // Apply your custom class to the confirm button
            cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
          },
        }).then((result) => {
          fetchStockData('addStockData',null,response.data.data)
          if (result.isConfirmed) {
            // User clicked "Back to Portfolio"
            setShowModal(false); // Close the modal
            formik.resetForm(); // Reset the form when closing the modal
            // window.location.reload(); // Reload the page
          } else {
            // User clicked "Add Another"
            // You can add additional logic if needed
            setShowModal(true); // Open the modal
            formik.resetForm();
          }
        });
      } catch (error) {
        console.error('Server error:', error.response?.data);
        console.error('Error updating watchlist:', error);

        // Log the specific error from the server
        console.error('Server error:', error.response?.data);

        // Show SweetAlert error notification or update the UI to inform the user
        const responseMessage = "The name has already been taken."; // Replace this with the actual response message
        Swal.fire({
          title: 'Something went Wrong',
          text: responseMessage === "The name has already been taken." ? 'Please choose a different name.' : 'Please, Check your Internet connection and try again',
          iconHtml: `<img src="${iconError}" alt="Success Icon" class="custom-icon" />`,
          confirmButtonText: 'Back to Watchlist',
          customClass: {
            confirmButtonText: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
          },

        });
      } finally {
        setIsLoading(false);
      }
    }

    const formik = useFormik({
      initialValues: {
        portfolio_id: id,
        stock_id: '',
        quantity: '',
        rate: '',
        value: '',

      },
      onSubmit: handleAddStock,
    });

    const handleEditClick = async () => {
      setShowModal(true); // Open the modal when the edit button is clicked
    };
    const handleCloseModal = () => {
      setShowModal(false); // Close the modal
      formik.resetForm(); // Reset the form when closing the modal
    };

    // Update the quantity based on rate and value changes
    useEffect(() => {
      const calculateValue = () => {
        const rate = parseFloat(formik.values.rate) || 0;
        const quantity = parseFloat(formik.values.quantity) || 0;
        const value = rate * quantity;
        // console.log('rate:', rate);
        // console.log('quantity:', quantity);
        // console.log('calculated value:', value);
        formik.setFieldValue('value', isNaN(value) ? '' : value.toFixed(0));
      };


      calculateValue();
      // console.log('useEffect executed'); // Add this line
    }, [formik.values.rate, formik.values.quantity]);


    // Filter Search with code and name
    const uniqueStockIds = new Set();
    const uniqueStockOptions = stocks.filter((stock) => {
      if (!uniqueStockIds.has(stock.id)) {
        uniqueStockIds.add(stock.id);
        return true;
      }
      return false;
    }).map((stock) => ({ value: stock.id, label: `${stock.name} - ${stock.code}` }));
    // END Search
    return (
      <>
        <ProtectedButton
            permission="portfolio_stock"
            className={styles['manual-entry-btn']}
            onClick={handleEditClick}
        >
          {t("Manual Entry")}
        </ProtectedButton>
        {/*<button permission="portfolio_stock" className={`${styles['manual-entry-btn']}`} onClick={handleEditClick}>{t("Manual Entry")}</button>*/}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  {/* <nav className={`${styles.dailyTabsNav}`}>
                    <ul className={`${styles.dailyTabsList}`}>
                      <li>
                        <button
                          className={
                            activeTab === "stocks" ? styles.active : undefined
                          }
                          onClick={() => handleTabClick("stocks")}
                        >
                          {t('Stocks')}
                        </button>
                      </li>
                      <li>
                        <button
                          className={
                            activeTab === "matualFund" ? styles.active : undefined
                          }
                          onClick={() => handleTabClick("matualFund")}
                        >
                          {t('Mutual Funds')}
                        </button>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
              <div className={styles.tabContent}>
                {activeTab === 'stocks' && (
                  // Content for Tab 1
                  <>
                    <div className="text-center"><img src={stocksicon} alt="stocks"/></div>
                    <div className="text-center"><span className="head-title">Add Stock</span></div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="d-flex flex-column position-relative">
                        <Select
                            options={uniqueStockOptions}
                            onMenuScrollToBottom={handleMenuScroll}
                            onChange={(selectedOption) => {
                              formik.setFieldValue('stock_id', selectedOption?.value || '');
                              setSelectedOption(selectedOption);
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={formik.handleBlur}
                            // value={stocks.find((stock) => stock.id === formik.values.stock_id)}
                            value={selectedOption}
                            placeholder={selectedOption.label || `${t("select")}`}
                            styles={selectStyle}
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center gap-3 mt-16">
                        <div className="form-group mt-16 edit-form">
                          <label htmlFor="quantity" className="form-label">
                            {t('Stock Quantity')}
                          </label>
                          <input type="number"
                                 className="form-control"
                                 id="inputQuantity"
                                 name="quantity"
                                 placeholder="Quantity"
                                 value={formik.values.quantity}
                                 onBlur={formik.handleBlur}
                                 onChange={formik.handleChange}
                          />
                        </div>
                        <div className="form-group mt-16 edit-form">
                          <label htmlFor="rate" className="form-label">
                            {t('Stock Rate')}
                          </label>
                          <input type="number"
                                 name="rate"
                                 value={formik.values.rate}
                                 onBlur={formik.handleBlur}
                                 onChange={formik.handleChange}
                                 className="form-control"
                                 id="rate"
                                 placeholder="Rate" />
                        </div>
                        <div className="form-group mt-16 edit-form">
                          <label htmlFor="value" className="form-label">
                            {t('Stock Value')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="value"
                            name="value"
                            value={formik.values.value}  // Use formik.values.value instead of formik.values.value directly
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}  // Use formik.handleChange to handle changes
                            placeholder="Value"
                            readOnly
                          />
                        </div>
                      </div>
                      <Modal.Footer>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <button  className="btn btn-save mt-24">
                            {t('Add')}
                          </button>
                          {/*<div className="form-check form-add-another mt-24">*/}
                          {/*  <input*/}
                          {/*    type="checkbox"*/}
                          {/*    className="form-check-input"*/}
                          {/*    id="addAnotherCheckbox"*/}
                          {/*    checked={formik.values.addAnother}*/}
                          {/*    onChange={handleCheckboxChange}*/}
                          {/*  />*/}
                          {/*  <label className="form-check-label" htmlFor="addAnotherCheckbox">*/}
                          {/*    Add Another*/}
                          {/*  </label>*/}
                          {/*</div>*/}
                        </div>
                      </Modal.Footer>
                    </form>
                  </>
                )}
                {activeTab === 'matualFund' && (
                  // Content for Tab 2
                  <>
                    {/* ... */}
                  </>
                )}
            </div>
          </>
          </Modal.Body>

        </Modal>
      </>
    )
  }

  export default ManualEntry