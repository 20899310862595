import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import axios from 'axios';
import {apiUrlServer, WEB_SOCKET_URL} from "../../../apiConfig";

import {abbreviateNumber, formatNumber} from "../../../Hooks/useNumberFormatting";
import {Helmet} from "react-helmet";
import CommonBanner from "../../CommonBanner/CommonBanner";
import useCustomTranslation from "../../../Hooks/useTranslation";
import {useLanguage} from "../../../Context/LanguageContext";
import {Link} from "react-router-dom";
import {CircularProgress, Paper} from "@mui/material";
import {DataGrid, GridToolbar, gridClasses} from "@mui/x-data-grid";
import {ShimmerTable} from "react-shimmer-effects";
import styles from './StockList.module.css';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useTheme} from "../../../Context/ThemeContext";
import DelayedPriceMessage from "../../DisplayMessages/DelayedPriceMessage";

const AllStocks = () => {
    const socketRef = useRef(null);
    const lastUpdateRef = useRef({});
    const {t} = useCustomTranslation();
    const {language} = useLanguage();
    const [updatedCells, setUpdatedCells] = useState({});
    const [lastUpdateTime, setLastUpdateTime] = useState({});
    const queryClient = useQueryClient();
    const {theme} = useTheme();


    const {data: stocks, isLoading} = useQuery({
        queryKey: ['allStocks', language],
        queryFn: async () => {
            const response = await axios.get(`${apiUrlServer}all_stocks`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });
            initializeWebSocket(response.data);
            return response.data;
        },
        staleTime: 15 * 60 * 1000, // Set stale time to Infinity to prevent automatic refetches
        gcTime: 15 * 60 * 1000, // Set gc time to Infinity to keep the data in cache indefinitely
    });

    const initializeWebSocket = useCallback((stocksArray) => {
        socketRef.current = new WebSocket(`${WEB_SOCKET_URL}`);
        socketRef.current.onopen = () => {
            const subscriptionList = stocksArray
                .filter(stock => stock.inis_bc)
                .map(stock => stock.inis_bc.trim())
                .join(',');

            if (subscriptionList) {
                socketRef.current.send(JSON.stringify({type: 'subscribe', stockId: subscriptionList}));
            }
        };

        socketRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            handleWebSocketMessage(data);
        };

        socketRef.current.onclose = () => console.log('WebSocket disconnected');
        socketRef.current.onerror = (error) => console.error('WebSocket error:', error);
    }, []);

    const handleWebSocketMessage = useCallback((data) => {
        if (data.data && data.data.startStream) {
            updateStocksData(data.data.startStream);
        }
    }, []);

    const updateStocksData = useCallback((streamData) => {
        queryClient.setQueryData(['allStocks', language], (oldData) => {
            if (!oldData) return oldData;

            const updatedStocks = [...oldData];
            const newUpdatedCells = {};
            const newLastUpdateTime = {};

            streamData.forEach(item => {
                const stockIndex = updatedStocks.findIndex(s => s.inis_bc === item.requestedId);
                if (stockIndex !== -1) {
                    const stock = updatedStocks[stockIndex];
                    newLastUpdateTime[stock.code] = Date.now();

                    const lastClose = item.last?.value ?? stock.api?.last ?? '-';
                    const newTimestamp = item.last?.timestamp ? new Date(item.last.timestamp).getTime() : stock.api?.timestamp ?? Date.now();
                    const numberOfTrades = item.numberOfTrades?.value ?? stock.api?.numberOfTrades ?? '-';
                    const volumeTotal = item.volumeTotal?.value ?? stock.api?.volumeTotal ?? '-';
                    const turnoverTotal = item.turnoverTotal?.value ?? stock.api?.turnoverTotal ?? '-';
                    const open = item.open?.value ?? stock.api?.open ?? '-';
                    const close = item.close?.value ?? stock.api?.close ?? '-';
                    const change = close !== '-' && lastClose !== '-' ? lastClose - close : '-';
                    const changePercent = change !== '-' && lastClose !== '-' && lastClose !== 0
                        ? ((change / lastClose) * 100)
                        : '-';
                    // Only update cells that have changed
                    ['last', 'bestBid', 'bestAsk'].forEach(key => {
                        if (item[key]?.value !== undefined && item[key].value !== stock.api?.[key] && item.type === "UPDATE") {
                            const direction = item[key].value > stock.api?.[key] ? 'increase' : 'decrease';
                            newUpdatedCells[`${stock.code}-${key}`] = {timestamp: Date.now(), direction};
                        }
                    });

                    updatedStocks[stockIndex] = {
                        ...stock,
                        api: {
                            ...stock.api,
                            close: close,
                            last: lastClose,
                            timestamp: newTimestamp,
                            numberOfTrades: numberOfTrades,
                            volumeTotal: volumeTotal,
                            turnoverTotal: turnoverTotal,
                            open: open ?? '-',
                            high: item.high?.value ?? stock.api?.high,
                            low: item.low?.value ?? stock.api?.low,
                            vwap: item.vwap?.value ?? stock.api?.vwap,
                            bestBid: item.bestBid?.value ?? stock.api?.bestBid,
                            bestAsk: item.bestAsk?.value ?? stock.api?.bestAsk,
                        },
                        // lastUpdated: Date.now(),
                        change: change,
                        changePercent: changePercent
                    };

                    lastUpdateRef.current[stock.code] = Date.now();
                }
            });

            setUpdatedCells(prev => ({...prev, ...newUpdatedCells}));
            setLastUpdateTime(prev => ({...prev, ...newLastUpdateTime}));

            return updatedStocks;
        });
    }, [queryClient, language]);

    useEffect(() => {
        console.log("nostocks");
        if (stocks) {
            console.log("stocks", stocks);
            initializeWebSocket(stocks);
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            const now = Date.now();
            setUpdatedCells(prev => {
                const updated = {...prev};
                Object.keys(updated).forEach(key => {
                    if (now - updated[key].timestamp > 2000) {
                        delete updated[key];
                    }
                });
                return updated;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const getCellClassName = useCallback((params) => {
        const cellKey = `${params.row.code}-${params.field}`;
        const cellInfo = updatedCells[cellKey];
        if (cellInfo && ['last', 'bestBid', 'bestAsk'].includes(params.field)) {
            return cellInfo.direction === 'increase' ? styles['updated-cell-increase'] : styles['updated-cell-decrease'];
        }
        return '';
    }, [updatedCells]);

    const columns = useMemo(() => [
        {
            field: 'code',
            headerName: t('Code'),
            width: 80,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            // textDecoration: 'none',
            renderCell: (params) => (
                <Link style={{
                    textDecoration: 'none',
                    color: '#451952'
                }}
                      to={`/stocks/${params.row.id}/${params.row.code}`}>
                    {params.value}
                </Link>
            ),
            renderHeader: () => <strong>{t('Code')}</strong>
        },
        {
            field: 'name',
            headerName: t('Name'),
            width: 200,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Link style={{
                    textDecoration: 'none',
                    color: '#451952'
                }}
                      to={`/stocks/${params.row.id}/${params.row.code}`}>
                    {params.value}
                </Link>
            ),
            renderHeader: () => <strong>{t('Name')}</strong>
        },
        {
            field: 'last',
            headerName: t('Last Trade Price'),
            width: 150,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Last Trade Price')}</strong>,
            cellClassName: 'last-trade-price-column',
        },
        {
            field: 'change',
            headerName: t('Change'),
            width: 80,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Change')}</strong>,
            renderCell: (params) => {
                const value = params.value;
                let color = 'black';
                if (value > 0) color = 'green';
                if (value < 0) color = 'red';
                return (
                    <div style={{color}}>
                        {formatNumber(value)}
                    </div>
                );
            }
        },
        {
            field: 'changePercent',
            headerName: t('Change %'),
            width: 80,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Change %')}</strong>,
            renderCell: (params) => {
                const value = params.value;
                let color = 'black';
                let displayValue = '-';

                if (value !== '-') {
                    const numericValue = parseFloat(value);
                    if (numericValue < 0) color = 'red';
                    else if (numericValue > 0) color = 'green';
                    // If numericValue is exactly 0, color remains black

                    // Format the value with 2 decimal places and add the % symbol
                    displayValue = `${numericValue.toFixed(2)}%`;
                }

                return (
                    <div style={{color}}>
                        {displayValue}
                    </div>
                );
            }
        },
        {
            field: 'open',
            headerName: t('Open'),
            width: 80,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Open')}</strong>
        },
        {
            field: 'close',
            headerName: t('Close'),
            width: 80,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Close')}</strong>
        },
        {
            field: 'high',
            headerName: t('High'),
            width: 80,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('High')}</strong>
        },
        {
            field: 'low',
            headerName: t('Low'),
            width: 80,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Low')}</strong>
        },
        {
            field: 'volumeTotal',
            headerName: t('Volume Total'),
            width: 150,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Volume Total')}</strong>
        },
        {
            field: 'numberOfTrades',
            headerName: t('Number of Trades'),
            width: 150,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Number of Trades')}</strong>
        },
        {
            field: 'turnoverTotal',
            headerName: t('Turnover Total'),
            width: 150,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Turnover Total')}</strong>
        },
        {
            field: 'vwap',
            headerName: t('VWAP'),
            width: 150,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('VWAP')}</strong>
        },
        {
            field: 'bestBid',
            headerName: t('Best Ask'),
            width: 150,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Best Ask')}</strong>,
            cellClassName: 'best-ask-column',
        },
        {
            field: 'bestAsk',
            headerName: t('Best Bid'),
            width: 150,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Best Bid')}</strong>,
            cellClassName: 'best-bid-column',
        },
        {
            field: 'timestamp',
            headerName: t('Last Update'),
            width: 200,
            resizable: true,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <strong>{t('Last Update')}</strong>
        }
    ], [t, language]);

    const rows = useMemo(() => (stocks || []).map(stock => ({
        ...stock,
        id: stock.id,
        code: stock.code,
        name: stock.name[language] || stock.name.en,
        last: stock.api?.last ?? '-',
        change: formatNumber(stock.change) ?? '-',
        changePercent: stock.changePercent !== '-' ? parseFloat(stock.changePercent) : '-',
        open: abbreviateNumber(stock.api?.open) ?? '-',
        close: abbreviateNumber(stock.api?.close) ?? '-',
        high: abbreviateNumber(stock.api?.high) ?? '-',
        low: abbreviateNumber(stock.api?.low) ?? '-',
        volumeTotal: abbreviateNumber(stock.api?.volumeTotal) ?? '-',
        numberOfTrades: abbreviateNumber(stock.api?.numberOfTrades) ?? '-',
        turnoverTotal: abbreviateNumber(stock.api?.turnoverTotal) ?? '-',
        vwap: abbreviateNumber(stock.api?.vwap) ?? '-',
        bestBid: abbreviateNumber(stock.api?.bestBid) ?? '-',
        bestAsk: abbreviateNumber(stock.api?.bestAsk) ?? '-',
        timestamp: stock.api?.timestamp ? new Date(stock.api.timestamp).toLocaleString() : '-'
    })), [stocks, language, lastUpdateTime]);

    return (
        <>
            <Helmet>
                <title>{t('Stocks')}</title>
            </Helmet>

            <CommonBanner title={t('All Stocks')}/>
            <DelayedPriceMessage
                messageKey="The stock prices displayed are not real-time and reflect market data from 15 minutes ago"/>

            {isLoading ? (
                <ShimmerTable/>
            ) : (
                <Paper style={{width: '100%'}}>
                    <DataGrid
                        sx={{
                            '& .last-trade-price-column': {
                                backgroundColor: '#461a51c2',
                                color: 'white',
                                '--column-bg': '#461a51c2',
                            },
                            '& .best-bid-column': {
                                backgroundColor: '#e1f8eb',
                                color: '#40ad8b',
                                '--column-bg': '#e1f8eb',
                            },
                            '& .best-ask-column': {
                                backgroundColor: '#fbe5e3',
                                color: '#ed4674',
                                '--column-bg': '#fbe5e3',
                            },
                            '& .MuiDataGrid-cell': {
                                transition: 'all 0.5s ease-out',
                            },
                            [`.${gridClasses.columnHeaders}`]: {
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                backgroundColor: 'var(--natural-color)',
                                color: 'var(--secondary-color-1)',
                            },
                            [`.${gridClasses.virtualScroller}`]: {
                                height: 'calc(100vh - 200px)',
                                overflowY: 'auto !important',
                            },
                        }}
                        rows={rows}
                        columns={columns}
                        // pageSizeOptions={500}
                        paginationMode={false}
                        // autoPageSize={true}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        columnBuffer={2}
                        checkboxSelection={false}
                        getCellClassName={getCellClassName}
                        disableSelectionOnClick
                        hideFooterPagination
                        autoHeight={false}
                        key={language}
                        hideFooter
                        rowHeight={52}
                    />
                </Paper>
            )}
        </>
    );
};

export default React.memo(AllStocks);