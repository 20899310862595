import React from 'react';
import { useLanguage } from "./Context/LanguageContext";
import arabicIcon from '../src/assets/icons/flag-ar.svg';
import englishIcon from '../src/assets/icons/flag-en.svg';

const LanguageSwitch = () => {
    const { toggleLanguage, language } = useLanguage();

    return (
        <a className="language-icons" onClick={toggleLanguage}>
            {language === 'en' ?
                <img src={arabicIcon} style={{ width: "30px" }} alt="Arabic" /> :
                <img src={englishIcon} style={{ width: "30px" }} alt="English" />
            }
        </a>
    );
};

export default LanguageSwitch;
