import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Faq.css';
import {apiUrlServer} from "../../apiConfig";
import useCustomTranslation from "../../Hooks/useTranslation";
import CommonBanner from "../CommonBanner/CommonBanner";
import {useLanguage} from "../../Context/LanguageContext";

const Faqs = () => {
    const [faqs, setFaqs] = useState([]);
    const {language} = useLanguage();
    useEffect(() => {
        // Fetch data from the API
        axios.get(
            `${apiUrlServer}faq`,{
                headers:{
                    'Accept-Language': language,
                }
            })
            .then(response => {
                const fetchedFaqs = response.data.data;
                setFaqs(fetchedFaqs);
            })
            .catch(error => {
                console.error('Error fetching FAQs:', error);
            });
    }, [language]);
    const {t} = useCustomTranslation();

    return (
        <div className="">
            <CommonBanner title={t('Frequently Asked Questions')}/>
            {/* Page Header Close */}
            {/* Start::row-1 */}
            <div className="row justify-content-center mt-5">
                <div className="col-xl-12">
                    <div className="">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="text-center p-3 faq-header mb-4">
                                    {/*<h5 className="mb-1  op-5 fw-semibold">{t('F.A.Q')}</h5>*/}
                                    {/*<h4 className="mb-1  fw-semibold">{t('Frequently Asked Questions')}</h4>*/}
                                    {/*<p className="fs-15  text-muted  op-7">{t('We have shared some of the most frequently asked questions to help you out!')} </p>*/}
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="accordion accordion-customicon1 "
                                                 id="accordionFAQ1">
                                                {/* Render accordion items dynamically */}
                                                {faqs.map(faq => (
                                                    <div className="accordion-item" key={faq.id}>
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapsecustomicon${faq.id}`}
                                                                    aria-expanded="false"
                                                                    aria-controls={`collapsecustomicon${faq.id}`}>
                                                                {faq.question}
                                                            </button>
                                                        </h2>
                                                        <div id={`collapsecustomicon${faq.id}`}
                                                             className="accordion-collapse collapse"
                                                             aria-labelledby="headingcustomicon2One"
                                                             data-bs-parent="#accordionFAQ1">
                                                            <div className="accordion-body" style={{textAlign:'start'}}>
                                                                {faq.answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End::row-1 */}
        </div>
    );
}

export default Faqs;
