import { useState, useEffect } from "react";

const useCountdown = (initialSeconds) => {
  const [secondsRemaining, setSecondsRemaining] = useState(initialSeconds);

  useEffect(() => {
    let intervalId;

    if (secondsRemaining > 0) {
      intervalId = setInterval(() => {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [secondsRemaining]);


  return {
    secondsRemaining,
    setSecondsRemaining,
  };
};

export default useCountdown;
