import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import axios from "axios";
import {apiUrlServer, getStockAlert, getStockAlertTags} from "../../../apiConfig";
import EditTagsPrice from "./TagsPriceActions/EditTagsPrice";
import DeleteTagsPrice from "./TagsPriceActions/DeleteTagsPrice";
import useStocks from "../../../Hooks/useStocks";
import useCustomeLoader from "../../../Hooks/useCustomeLoader";
import DataTable from "react-data-table-component";
import styles from "../../WatchList/watchlist.module.css";
import useCustomTranslation from "../../../Hooks/useTranslation";
import AddTagsPrice from "./TagsPriceActions/AddTagsPrice";
import Swal from "sweetalert2";
import {FaSquareEnvelope} from "react-icons/fa6";
import {FaPen} from "react-icons/fa";
import {GrSend} from "react-icons/gr";

const PriceTagsTable = () => {
    const [pricesTags, setPricesTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cookies] = useCookies(['token']);
    const { t } = useCustomTranslation();

    const fetchPricesTagsData = async () => {
        const token = cookies.token;
        setIsLoading(true);
        try {
            const response = await axios.get(`${apiUrlServer}${getStockAlertTags}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            const result = response.data;
            console.log("ahmed", result)

            if (result.status) {
                setPricesTags(result.data);
            } else {
                console.error('Error fetching data:', result.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchPricesTagsData();
    }, [cookies.token]);

    const handleEditGroupSuccess = () => {
        fetchPricesTagsData();
    };

    const handelDeleteGroupSuccess = () => {
        fetchPricesTagsData();
    }

    const handleRevokeResend = async (id) => {
        try {
            const token = cookies.token;
            await axios.put(
                `${apiUrlServer}${getStockAlertTags}/${id}`,
                { is_sent: 1 },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            await Swal.fire(t('Success'), t('Stock Alert resent successfully'), 'success');
            fetchPricesTagsData();
        } catch (error) {
            console.error('Error resending alert:', error);
            await Swal.fire(t('Error'), t('Failed to resend Stock Alert. Please try again.'), 'error');
        }
    };

    const columns = [
        {
            name: t('Stock Code'),
            selector: row => row.stock_id.code,
            sortable: true,
        },
        {
            name: t('Stock ID'),
            selector: row => row.stock_id.name,
            sortable: true,
        },
        {
            name: t('tags'),
            selector: row => row.tags,
            sortable: true,
        },
        {
            name: t('Status'),
            selector: row => row.is_sent,
            sortable: true,
            cell: row => (
                <span>
                    {row.is_sent === 1 ? t('Sent') : t('Not Sent')}
                </span>
            ),
        },
        {
            name: t('Actions'),
            sortable: false,
            cell: (row) => (
                <div className="d-flex align-items-center justify-content-center">
                    <EditTagsPrice stock={row.stock_id} groupId={row.id} onEditGroupSuccess={handleEditGroupSuccess} fetchData={fetchPricesTagsData} tags={row.tags} />
                    <DeleteTagsPrice onDeleteGroupSuccess={handelDeleteGroupSuccess} fetchData={fetchPricesTagsData} groupId={row.id} />
                    {row.is_sent === 1 && (
                        <button className={`btn ${styles['edit-price-btn']}`}
                                onClick={() => handleRevokeResend(row.id)}>
                            <div className="d-flex justify-content-center align-items-center actions-ar">
                                <div className={`${styles['icon-edit-box']}`}>
                                    <GrSend className={`${styles['icon-send']}`}/>
                                </div>
                                {/*<span>{t('Edit')}</span>*/}
                            </div>
                        </button>

                    )}
                </div>
            ),
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '64px',
                color: '#6F6F6F',
                textAlign: 'center',
            },
        },
        headCells: {
            style: {
                color: '#1D1A39',
                fontSize: '18px',
                fontWeight: '700',
                textAlign: 'center',
            },
        },
    };
    const { paginationComponentOptions } = useStocks();
    const { customLoader } = useCustomeLoader();
    return (
        <>
            <AddTagsPrice addPriceSuccess={setPricesTags}  />
            <DataTable
                columns={columns}
                pagination
                data={pricesTags}
                className={`${styles['table-watchList']}`}
                customStyles={customStyles}
                progressPending={isLoading}
                progressComponent={customLoader()}
                noDataComponent={<div style={{ padding: '200px', display: "none" }}>No records to display</div>}
                paginationComponentOptions={paginationComponentOptions}
            />
        </>
    );
};

export default PriceTagsTable;