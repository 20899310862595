import React from 'react'
import styles from '../watchlist.module.css';
import { FaPen } from 'react-icons/fa'
import Swal from 'sweetalert2'
import iconEdit from '../../../assets/icons/editIcon.png'
import axios from 'axios'
import { apiUrlServer } from '../../../apiConfig'
import { useCookies } from 'react-cookie'
import useCustomTranslation from '../../../Hooks/useTranslation';

const EditGroup = ({groupId, onEditGroupSuccess, fetchData }) => {
  const [cookies] = useCookies(['token']);
  const { t } = useCustomTranslation();

  const handleEditClick = async () => {
    const { value: name } = await Swal.fire({
      iconHtml: `<img src="${iconEdit}" alt="Success Icon" class="" />`,
      title: `${t("Edit Your Group")}`,
      input: 'text',
      inputValue: '', // You can pre-fill the input field if needed
      showCancelButton: true,
      confirmButtonText: `${t("Save Changes")}`,
      cancelButtonText: `${t('Back to Portfolio List')}`,
      customClass: {
        confirmButton: 'swal-btn-confirmButton', // Apply your custom class to the confirm button
        cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
      },
      inputValidator: (value) => {
        if (!value) {
          return 'You need to enter a new name!';
        }
      },
      inputAttributes: {
        class: 'form-control',
        placeholder: `${t('New Group Name')}`,
      },
    });

    if (name) {
      try {
        const token = cookies.token;
        const response = await axios.put(
          `${apiUrlServer}groups/${groupId}?name=${name}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.status) {
          Swal.fire({
            title: `${t('Group Updated Successfully')}`,
            icon: 'success',
            confirmButtonText: `${t('OK')}`,
          });
          onEditGroupSuccess();
          fetchData();
        } else {
          Swal.fire({
            title: `${t('Error')}`,
            text: `${t('Failed to update group. Please try again.')}`,
            icon: 'error',
            confirmButtonText: `${t('OK')}`,
          });
        }
      } catch (error) {
        console.error('Error updating group:', error.message);
        Swal.fire({
          title: `${t('Error')}`,
          text: `${t('Failed to update group. Please try again.')}`,
          icon: 'error',
          confirmButtonText: `${t('OK')}`,
        });
      }
    }
  };

  return (
    <button className={`btn ${styles['edit-group-btn']}`} onClick={handleEditClick}>
      <div className="d-flex justify-content-center align-items-center gap-2 actions-ar">
        <div className={`${styles['icon-edit-box']}  `}>
          <FaPen className={`${styles['icon-edit']}`} />
        </div>
        <span className='m-0'>{t('Edit')}</span>
      </div>
    </button>
  );
}

export default EditGroup