import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Button,
    Paper,
    Grid,
    useMediaQuery,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import useCustomTranslation from "../../../Hooks/useTranslation";
import { abbreviateNumber } from "../../../Hooks/useNumberFormatting";
import { useTheme } from "../../../Context/ThemeContext";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EarningsVisualization = () => {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [selectedYears, setSelectedYears] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { statementType, id, code } = useParams();
    const { t } = useCustomTranslation();
    const muiTheme = useMuiTheme();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
    const { theme } = useTheme();
    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${year.slice(2)}/${month}${day ? `/${day}` : ''}`;
    };

    useEffect(() => {
        const storedData = sessionStorage.getItem(`${statementType}Data`);
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setData(parsedData);
            setFilteredData(parsedData);
            const extractedYears = [...new Set(Object.keys(parsedData).map(date => date.split('-')[0]))];
            setYears(extractedYears);
            setSelectedYears([extractedYears[0]]);
        }
    }, [statementType]);

    useEffect(() => {
        if (data) {
            const filtered = Object.keys(data)
                .filter(date => selectedYears.includes(date.split('-')[0]))
                .reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                }, {});
            setFilteredData(filtered);
        }
    }, [selectedYears, data]);

    const dates = useMemo(() => Object.keys(filteredData || {}), [filteredData]);

    const transposedRows = useMemo(() => {
        if (!filteredData || !dates.length) return [];
        return Object.keys(filteredData[dates[0]])
            .filter((key) => key !== 'date' && key !== 'reportDate' && key !== 'currency')
            .map((key, index) => ({
                id: index,
                field: t(key.replace(/([A-Z])/g, ' $1').toLowerCase()),
                ...dates.reduce((acc, date) => {
                    acc[date] = typeof filteredData[date][key] === 'number'
                        ? abbreviateNumber(filteredData[date][key])
                        : abbreviateNumber(filteredData[date][key]);
                    return acc;
                }, {}),
            }));
    }, [filteredData, dates, t]);

    const handleYearChange = (event) => {
        const value = event.target.value;
        setSelectedYears(typeof value === 'string' ? value.split(',') : value);
    };

    const handleToggleAllYears = () => {
        if (selectedYears.length === years.length) {
            setSelectedYears([years[0]]);
        } else {
            setSelectedYears([...years]);
        }
    };

    const handleRowClicked = useCallback((row) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.some((selected) => selected.id === row.id)
                ? prevSelectedRows.filter((selected) => selected.id !== row.id)
                : [...prevSelectedRows, row]
        );
    }, []);

    const chartOptions = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const value = abbreviateNumber(context.raw);
                        return `${context.dataset.label}: ${value}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return abbreviateNumber(value);
                    },
                },
            },
        },
    }), []);

    const generateRandomColor = useCallback(() => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r},${g},${b},0.4)`;
    }, []);

    const generateRandomColors = useCallback((count) =>
            Array.from({ length: count }, generateRandomColor),
        [generateRandomColor]);

    const chartData = useMemo(() => {
        if (selectedRows.length === 0 || !dates.length) return null;

        const labels = dates;
        const randomColors = generateRandomColors(selectedRows.length);
        const datasets = selectedRows.map((row, index) => ({
            label: row.field,
            data: dates.map(date => {
                const value = row[date];
                return typeof value === 'number' ? value : parseFloat(value) || 0;
            }),
            backgroundColor: randomColors[index],
        }));

        return { labels, datasets };
    }, [selectedRows, dates, generateRandomColors]);

    const getStatementTypeTitle = () => {
        switch (statementType) {
            case 'earningsHistory':
                return 'Earnings History';
            case 'earningsAnnual':
                return 'Annual Earnings';
            case 'earningsTrend':
                return 'Earnings Trend';
            default:
                return 'Earnings Data';
        }
    };

    if (!filteredData) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Typography>Loading...</Typography></Box>;
    const buttonColor = theme === 'nationalDay' ? 'success' : 'secondary';
    const stickyHeaderStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 3,
        backgroundColor: 'var(--secondary-color-20)',
        color: 'var(--natural-color)',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        fontWeight: 'bold',
        fontSize: '12px',
    };

    const stickyColumnStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: 'var(--secondary-color-20)',
        color: 'var(--natural-color)',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        fontSize: '12px',
    };
    return (
        <Box sx={{ maxWidth: 'auto', margin: '0 auto', p: 3 }}>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography className="main-color" variant="h4" component="h1" gutterBottom>
                    {getStatementTypeTitle()} for {code}
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="year-select-label">Years</InputLabel>
                            <Select
                                labelId="year-select-label"
                                id="year-select"
                                multiple
                                value={selectedYears}
                                onChange={handleYearChange}
                                renderValue={(selected) => selected.join(', ')}
                                label="Years"
                            >
                                {years.map(year => (
                                    <MenuItem key={year} value={year}>
                                        <Checkbox checked={selectedYears.indexOf(year) > -1} />
                                        <ListItemText primary={year} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color={buttonColor}
                            onClick={handleToggleAllYears}
                            fullWidth
                        >
                            {selectedYears.length === years.length ? 'Unselect All Years' : 'Select All Years'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Paper elevation={3} sx={{ mb: 3, overflow: 'hidden' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="earnings table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={stickyHeaderStyle}>Field</TableCell>
                                {dates.map((date) => (
                                    <TableCell key={date} align="right">{formatDate(date)}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transposedRows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        cursor: 'pointer',
                                        backgroundColor: selectedRows.some(selected => selected.id === row.id)
                                            ? muiTheme.palette.action.selected
                                            : 'inherit',
                                        '&:hover': {
                                            backgroundColor: muiTheme.palette.action.hover,
                                        },
                                    }}
                                    onClick={() => handleRowClicked(row)}
                                >
                                    <TableCell component="th" scope="row" sx={stickyColumnStyle}>
                                        {row.field}
                                    </TableCell>
                                    {dates.map((date) => (
                                        <TableCell key={date} align="right">{row[date]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {chartData && (
                <Paper elevation={3} sx={{ p: 3 }}>
                    <Typography className="main-color" variant="h5" gutterBottom>Selected Data Visualization</Typography>
                    <Box height={isMobile ? 300 : 500}>
                        <Bar data={chartData} options={chartOptions} />
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default EarningsVisualization;