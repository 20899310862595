import React from 'react';
import { useCookies } from "react-cookie";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import { useFormik } from "formik";
import axios from "axios";
import {apiUrlServer, editStockAlertTags, getStockAlertTags, getTag} from "../../../../apiConfig";
import Swal from "sweetalert2";
import styles from "../../Price.module.css";
import { FaPen } from "react-icons/fa";

const EditTagsPrice = ({ groupId, onEditGroupSuccess, fetchData, stock, tags }) => {
    const [cookies] = useCookies(['token']);
    const { t } = useCustomTranslation();

    const formik = useFormik({
        initialValues: {
            stock_id: stock,
            tags: tags
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const token = cookies.token;
                await axios.put(
                    `${apiUrlServer}${editStockAlertTags}/${groupId}`,
                    {
                        stock_id: values.stock_id.id,
                        tags: values.tags,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                onEditGroupSuccess();
                fetchData();
                await Swal.fire(t('Success'), t('Stock Alert updated successfully'), 'success');
            } catch (error) {
                console.error('Error updating group:', error);
                await Swal.fire(t('Error'), t('Failed to update Stock Alert. Please try again.'), 'error');
            }
        },
    });

    const fetchGroupData = async () => {
        try {
            const token = cookies.token;
            const response = await axios.get(`${apiUrlServer}${getStockAlertTags}/${groupId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching group data:', error);
            return null;
        }
    };

    const fetchTags = async () => {
        try {
            const token = cookies.token;
            const response = await axios.get(`${apiUrlServer}${getTag}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data.status && response.data.data) {
                return response.data.data;
            }
            return [];
        } catch (error) {
            console.error('Error fetching tags:', error);
            return [];
        }
    };

    const handleEditClick = async () => {
        const [groupData, availableTags] = await Promise.all([fetchGroupData(), fetchTags()]);

        if (!groupData) {
            await Swal.fire(t('Error'), t('Failed to fetch group data. Please try again.'), 'error');
            return;
        }

        formik.setValues({
            stock_id: groupData.stock_id,
            tags: groupData.tags
        });

        Swal.fire({
            title: t('Edit Your Stock Alert'),
            html: `
            <div class="d-flex align-content-center align-items-center justify-content-start gap-2 position-relative">
                <div class="form-group mt-16 edit-form w-100">
                    <label for="tags" class="form-label d-flex">
                        ${t('Tags')}
                    </label>
                    <select id="tags" class="form-control">
                        ${availableTags.map(tag => `<option value="${tag.name}">${t(tag.name)}</option>`).join('')}
                    </select>
                </div>
                <input 
                    type="text" 
                    id="stock_id" 
                    class="form-control" 
                    placeholder="${t('Enter Stock ID')}"
                    disabled
                    hidden
                >
                <div class="form-group mt-16 edit-form w-100 ">
                    <label for="stock_name" class="form-label d-flex">
                        ${t('Stock Name')}
                    </label>
                    <input 
                        type="text" 
                        id="stock_name" 
                        class="form-control" 
                        placeholder="${t('Stock Name')}"
                        disabled
                    >
                </div>
            </div>
        `,
            focusConfirm: false,
            didOpen: () => {
                setTimeout(() => {
                    document.getElementById('stock_id').value = formik.values.stock_id.id || '';
                    document.getElementById('stock_name').value = formik.values.stock_id.name || '';
                    document.getElementById('tags').value = formik.values.tags || '';
                }, 0);
            },
            preConfirm: () => {
                const stockIdValue = document.getElementById('stock_id').value;
                const tagsValue = document.getElementById('tags').value;
                if (!stockIdValue || !tagsValue) {
                    Swal.showValidationMessage(t('Please enter both stock ID and tags.'));
                    return false;
                }

                return {
                    stock_id: stockIdValue,
                    tags: tagsValue
                };
            },
            showCancelButton: true,
            confirmButtonText: t('Save Changes'),
            cancelButtonText: t('Back to Alert Center'),
            customClass: {
                confirmButton: 'swal-btn-confirmButton',
                cancelButton: 'swal-btn-cancelButton',
            },
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                formik.setValues({
                    stock_id: result.value.stock_id,
                    tags: result.value.tags,
                });
                formik.handleSubmit();
            }
        });
    };

    return (
        <>
            <button className={`btn ${styles['edit-price-btn']}`} onClick={handleEditClick}>
                <div className="d-flex justify-content-center align-items-center actions-ar">
                    <div className={`${styles['icon-edit-box']}`}>
                        <FaPen className={`${styles['icon-edit']}`}/>
                    </div>
                </div>
            </button>
        </>
    );
};

export default EditTagsPrice;