import { useState, useEffect } from 'react';

const formatNumber = (number) => {
    if (number === undefined || number === null) {
        return ''; // Return a placeholder or handle as needed
    }

    // Format the number to include commas and up to 2 decimal places
    let formattedNumber = number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    // Remove trailing ".00" if present
    if (formattedNumber.endsWith('.00')) {
        formattedNumber = formattedNumber.slice(0, -3);
    }

    return formattedNumber;
};

function useFormattedPercentage(initialPercentage) {
    const [formattedPercentage, setFormattedPercentage] = useState('');

    useEffect(() => {
        if (initialPercentage !== undefined && initialPercentage !== null) {
            const percentage = parseFloat(initialPercentage);
            const formatted = percentage.toFixed(2) + '%';
            setFormattedPercentage(formatted);
        } else {
            setFormattedPercentage('');
        }
    }, [initialPercentage]);

    return formattedPercentage;
}
function formatNumbers(number) {
    // Check if the input is a number and not NaN
    if (typeof number === 'number' && !isNaN(number)) {
        return Math.floor(number * 100) / 100;
    } else {
        return '0.00';
    }
}
function abbreviateNumber(number) {
    // Convert the input to a number (handling potential strings)
    const num = parseFloat(number);

    // Handle NaN (not a number) and zero
    if (isNaN(num) || num === 0) {
        return '-';
    }

    const abbreviations = ['', 'K', 'M', 'B', 'T'];
    const tier = Math.floor(Math.log10(Math.abs(num)) / 3);
    let scaledNumber = num / Math.pow(10, tier * 3);

    // Handle the edge case where scaledNumber is very close to the next tier
    if (Math.abs(scaledNumber - 1000) < 1e-6) {
        tier++;
        scaledNumber = num / Math.pow(10, tier * 3);
    }

    // Round scaledNumber to two decimal places before returning
    scaledNumber = parseFloat(scaledNumber.toFixed(2));

    return tier > 0
        ? scaledNumber + abbreviations[tier]
        : scaledNumber.toString();
}






export {  formatNumber, useFormattedPercentage, formatNumbers, abbreviateNumber };
