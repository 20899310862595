import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../Context/AuthContext";
import { usePermissions } from "../../Context/PermissionContext";
import Swal from 'sweetalert2';

const ProtectedRoute = ({ children, requiredPermission }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { hasPermission, isLoading, permissions, usage } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const checkPermission = async () => {
      console.log("ProtectedRoute - Current permissions:", permissions);
      console.log("ProtectedRoute - Current usage:", usage);
      console.log("ProtectedRoute - Required permission:", requiredPermission);
      console.log("ProtectedRoute - Is Loading:", isLoading);
      console.log("ProtectedRoute - Is Authenticated:", isAuthenticated());
      console.log("ProtectedRoute - Has permission:", hasPermission(requiredPermission));

      if (!isLoading) {
        if (!isAuthenticated()) {
          navigate('/login', { state: { from: location }, replace: true });
        } else if (!hasPermission(requiredPermission)) {
          // Redirect to home page first
          navigate('/', { replace: true });

          // Then show the alert
          setTimeout(() => {
            Swal.fire({
              title: 'Upgrade Required',
              text: 'You need to upgrade your plan to access this feature.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Upgrade Now',
              cancelButtonText: 'Maybe Later',
              customClass: {
                confirmButton: 'swal-btn-confirmButton', // Apply your custom class to the confirm button
                cancelButton: 'swal-btn-cancelButton', // Apply your custom class to the cancel button
              },
            }).then((result) => {
              if (result.isConfirmed) {
                // Redirect to upgrade page
                navigate('/subscription', { state: { from: location }, replace: true });
              }
            });
          }, 100); // Small delay to ensure navigation completes
        } else {
          setShowContent(true);
        }
      }
    };

    checkPermission();
  }, [isLoading, isAuthenticated, hasPermission, requiredPermission, location, navigate]);

  if (isLoading) {
    return <div className="bg-white" style={{backgroundColor:"#fff"}}></div>;
  }

  return showContent ? children : null;
};

export default ProtectedRoute;