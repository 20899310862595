import React, {memo, useEffect, useRef} from 'react';
import {apiUrlServer} from "./apiConfig";
import {useCookies} from "react-cookie";
import axios from "axios";
import Datafeed from './datafeed.js';
import {useParams} from "react-router-dom";
import  ReactDOM from 'react-dom';

const TradingViewChart = () => {
    const chartContainerRef = useRef(null);
    const [cookies] = useCookies(['token']);
    document.getElementById('root').style.minHeight = '0';

    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => {
                    // console.log(`Script loaded: ${src}`);
                    resolve();
                };
                script.onerror = (error) => {
                    console.error(`Error loading script: ${src}`, error);
                    reject(error);
                };
                document.body.appendChild(script);
            });
        };


        const loadChartingLibrary = async () => {
            try {
                // console.log('Loading charting library...');
                await loadScript('/charting_library/charting_library.standalone.js');
                await loadScript('/datafeeds/udf/dist/bundle.js');

                // console.log('Scripts loaded, initializing TradingView...');
                if (window.TradingView && chartContainerRef.current) {
                    // console.log('Chart container ref:', chartContainerRef.current);
                    new window.TradingView.widget({
                        container: chartContainerRef.current,
                        locale: 'en',
                        library_path: 'charting_library/',
                        datafeed: Datafeed,
                        symbol: "symbol: 'riyad bank 1010'",
                        interval: 'D',
                        fullscreen: true,
                    });
                    // console.log('TradingView widget initialized');
                } else {
                    console.error('TradingView or chart container not available');
                }
            } catch (error) {
                console.error('Error loading TradingView scripts', error);
            }
        };

        loadChartingLibrary();
    }, [cookies.token]);

    return ReactDOM.createPortal(
        <>
            <div id="chartContainer" ref={chartContainerRef} style={{width: '100%', height: '500px'}}></div>
        </>,
        document.getElementById('tradingviewchart')
    );
};

export default memo(TradingViewChart);