import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, Button } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import useCustomTranslation from '../../../../Hooks/useTranslation';
import { abbreviateNumber } from '../../../../Hooks/useNumberFormatting';
import Header from "../../../Header/Header";
// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BalanceSheetVisualization = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('quarterly');
    const [selectedYears, setSelectedYears] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { t } = useCustomTranslation();

    useEffect(() => {
        const fetchData = () => {
            const storedData = sessionStorage.getItem('balanceSheetData');
            if (storedData) {
                try {
                    const parsedData = JSON.parse(storedData);
                    setData(parsedData);
                } catch (error) {
                    console.error('Error parsing stored data:', error);
                    setError('Error parsing stored data');
                }
            } else {
                console.log('No data found in sessionStorage');
                setError('No data found in storage');
            }
        };

        fetchData();
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSelectedYears([]);
    };

    const financialData = data?.[`financials_balancesheet_${activeTab}`] || {};

    const rows = useMemo(() => {
        if (!financialData || typeof financialData !== 'object') {
            console.log('Invalid financialData:', financialData);
            return [];
        }
        return Object.entries(financialData).map(([date, values], index) => ({
            id: index,
            ...(typeof values === 'object' ? values : {}),
            date,
        }));
    }, [financialData]);

    const uniqueYears = useMemo(() => {
        return [...new Set(rows.map((row) => row.date?.substring(0, 4) || ''))];
    }, [rows]);

    const handleYearChange = (year) => {
        setSelectedYears((prev) =>
            prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
        );
    };

    const filteredRows = useMemo(() => {
        return selectedYears.length
            ? rows.filter((row) => selectedYears.includes(row.date?.substring(0, 4)))
            : rows;
    }, [rows, selectedYears]);

    const formatHeader = (header) => {
        return header.replace(/([A-Z])/g, ' $1').toLowerCase();
    };

    const columns = useMemo(() => {
        if (!filteredRows.length) return [{ field: 'field', headerName: t('Field'), width: 300 }];
        return [
            { field: 'field', headerName: t('Field'), width: 300 },
            ...filteredRows.map((row, index) => ({
                field: `value${index}`,
                headerName: formatHeader(row.date || ''),
                width: 200,
                renderCell: (params) => params.row[`displayValue${index}`],
            })),
        ];
    }, [filteredRows, t]);

    const transposedRows = useMemo(() => {
        if (!filteredRows.length) return [];
        const firstRow = filteredRows[0] || {};
        return Object.keys(firstRow).map((key) => {
            if (key === 'id' || key === 'date' || key === 'filing_date') return null;
            return {
                id: key,
                field: formatHeader(key),
                ...filteredRows.reduce((acc, row, index) => {
                    const originalValue = row[key];
                    acc[`value${index}`] = originalValue; // Store original value
                    acc[`displayValue${index}`] = abbreviateNumber(originalValue); // Store abbreviated value for display
                    return acc;
                }, {}),
            };
        }).filter(Boolean);
    }, [filteredRows]);

    const handleRowClicked = (params) => {
        const row = params.row;
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.some((selected) => selected.id === row.id)
                ? prevSelectedRows.filter((selected) => selected.id !== row.id)
                : [...prevSelectedRows, row]
        );
    };

    // const colors = ['rgba(75,192,192,0.4)', 'rgba(255,99,132,0.4)', 'rgba(54,162,235,0.4)', 'rgba(255,206,86,0.4)', 'rgba(153,102,255,0.4)'];

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const value = context.raw;
                        const formattedValue = abbreviateNumber(value);
                        return `${context.dataset.label}: ${formattedValue}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return abbreviateNumber(value);
                    },
                },
            },
        },
    };
    // Function to generate a random color
    const generateRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r},${g},${b},0.4)`;
    };

    // Function to generate an array of random colors
    const generateRandomColors = (count) => {
        return Array.from({ length: count }, generateRandomColor);
    };
    const getChartData = () => {
        if (selectedRows.length === 0 || filteredRows.length === 0) return null;

        const labels = filteredRows.map(row => row.date);
        const randomColors = generateRandomColors(selectedRows.length);
        const datasets = selectedRows.map((row, index) => ({
            label: row.field,
            data: filteredRows.map((_, i) => {
                const value = row[`value${i}`];
                return typeof value === 'number' ? value : parseFloat(value) || 0;
            }),
            backgroundColor: randomColors[index],
        }));

        return { labels, datasets };
    };

    const chartData = getChartData();

    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    if (!data || Object.keys(data).length === 0) {
        return <Typography>No data available</Typography>;
    }

    return (
        <>
        <Header/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <Box>
                            <Typography color mt={2} variant="h4" gutterBottom>Balance Sheet Visualization</Typography>
                            <div className="mb-2">
                                <Button
                                    variant={activeTab === 'quarterly' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handleTabChange('quarterly')}
                                    style={{marginRight: '8px'}}
                                >
                                    {t('Quarterly')}
                                </Button>
                                <Button
                                    variant={activeTab === 'yearly' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handleTabChange('yearly')}
                                >
                                    {t('Yearly')}
                                </Button>
                            </div>


                            <Box mb={2}>
                                {uniqueYears.map((year) => (
                                    <Button
                                        key={year}
                                        variant={selectedYears.includes(year) ? "contained" : "outlined"}
                                        onClick={() => handleYearChange(year)}
                                        style={{ marginRight: '10px' }}
                                    >
                                        {year}
                                    </Button>
                                ))}
                            </Box>
                            <Box style={{ height: "auto", width: '100%', overflow:"scroll" }}>
                                <DataGrid
                                    hideFooter
                                    rows={transposedRows}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    disableSelectionOnClick
                                    autoHeight
                                    onRowClick={handleRowClicked}
                                    getRowClassName={(params) =>
                                        selectedRows.some(row => row.id === params.id) ? 'selected-row' : 'unselected-row'
                                    }

                                    hideFooterPagination
                                    sx={{
                                        '& .selected-row': {
                                            backgroundColor: 'rgba(255, 0, 0, 0.1) !important',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 0, 0, 0.2) !important',
                                            },
                                        },
                                        '& .unselected-row': {
                                            backgroundColor: 'rgba(255, 255, 255, 255)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 255, 0.1)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            {chartData && (
                                <Box className="" mt={4} height={600}>
                                    <Typography variant="h5" gutterBottom>Selected Data Visualization</Typography>
                                    <Bar data={chartData} options={chartOptions} />
                                </Box>
                            )}
                        </Box>
                    </div>
                </div>
            </div>


        </>
    );
};

export default BalanceSheetVisualization;