import React, {useEffect, useState} from 'react';
import Select from "react-select";
import styles from "../MyPortfolio/EntryandUpload/ManualAdd.module.css";
import { Modal } from "react-bootstrap";
import { useFormik } from 'formik';
import axios from 'axios';
import {addSimulation, apiUrlServer} from "../../apiConfig";
import Swal from "sweetalert2";
import iconSuccess from "../../assets/icons/suceessAdd.png";
import iconError from "../../assets/icons/errorCancel.png";
import {useCookies} from "react-cookie";
import stocksicon from "../../assets/icons/stocksp.png";
import useCustomTranslation from '../../Hooks/useTranslation';

const SimulationBuy = ({ wallets, fetchWalletData,setShouldUpdateOrders }) => {
    const { t } = useCustomTranslation();
    const [cookies] = useCookies(['token']);
    const [stocks, setStocks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [limit, setLimit] = useState(10);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handeAddClick = () => {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false);
    }


    async function handleAddStock(values) {
        setIsLoading(true);
        try {
            const token = cookies.token;
            const response = await axios.post(`${apiUrlServer}${addSimulation}`, values, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Show SweetAlert success notification
            Swal.fire({
                title: 'Your Stock Buy successfully',
                iconHtml: `<img src="${iconSuccess}" alt="Success Icon" class="custom-icon" />`,
                text: 'Start Sell Stocks',
                confirmButtonText: 'Back to Simulation',
                cancelButtonText: 'Add Another',
                customClass: {
                    confirmButton: 'swal-btn-confirmButton',
                    cancelButton: 'swal-btn-cancelButton',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    setShowModal(false);
                    formik.resetForm();
                    fetchWalletData();
                    setShouldUpdateOrders(true); // Add this line
                } else {
                    setShowModal(true);
                    formik.resetForm();
                }
            });
        } catch (error) {
            console.error('Server error:', error.response?.data);

            const errorMessage = error.response?.data?.message || 'Something went wrong.';
            Swal.fire({
                title: 'Error',
                text: errorMessage,
                iconHtml: `<img src="${iconError}" alt="Error Icon" class="custom-icon" />`,
                confirmButtonText: 'OK',
                customClass: {
                    confirmButtonText: 'swal-btn-cancelButton',
                },
            });
        } finally {
            setIsLoading(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            stock_id: '',
            quantity: '',
            rate: '',
            value: '',
            type: 'buy',
            expiration_count: '',
            simulation_wallet_id: '3',
        },
        onSubmit: handleAddStock,
    });
    useEffect(() => {
        const fetchStocksData = async () => {
            const token = cookies.token;
            try {
                const response = await axios.get(`${apiUrlServer}stocks`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        limit: limit,
                    },
                });
                const result = response.data;
                // console.log(result);

                if (result.status) {
                    setStocks((prevStocks) => [...prevStocks, ...result.data]);
                } else {
                    console.error('Error fetching data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        fetchStocksData();
    }, [limit, cookies.token]);
    const handleMenuScroll = async (e) => {
        const target = e.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            // User has scrolled to the bottom
            setLimit((prevLimit) => prevLimit + 10);
        }
    };

    useEffect(() => {
        const calculateValue = () => {
            const rate = parseFloat(formik.values.rate) || 0;
            const quantity = parseFloat(formik.values.quantity) || 0;
            const value = rate * quantity;
            // console.log('rate:', rate);
            // console.log('quantity:', quantity);
            // console.log('calculated value:', value);
            formik.setFieldValue('value', isNaN(value) ? '' : value.toFixed(0));
        };


        calculateValue();
        // console.log('useEffect executed'); // Add this line
    }, [formik.values.rate, formik.values.quantity]);

    const uniqueStockIds = new Set();
    const uniqueStockOptions = stocks.filter((stock) => {
        if (!uniqueStockIds.has(stock.id)) {
            uniqueStockIds.add(stock.id);
            return true;
        }
        return false;
    }).map((stock) => ({ value: stock.id, label: `${stock.name} - ${stock.code}` }));
    const walletOptions = wallets.map((wallet) => ({
        value: wallet.id,
        label: `Wallet ${wallet.id} - Balance: ${wallet.value.toLocaleString()}`,
    }));
    return (
        <>
            <button className="btn btn-buy w-100" onClick={handeAddClick}>{t('Buy')}</button>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="d-flex justify-content-center align-items-center mb-16">
                            <img src={stocksicon} alt="stocks"/>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="d-flex flex-column position-relative">
                                <Select
                                    options={uniqueStockOptions}
                                    onMenuScrollToBottom={handleMenuScroll}
                                    onChange={(selectedOption) => {
                                        formik.setFieldValue('stock_id', selectedOption?.value || '');
                                        setSelectedOption(selectedOption);
                                    }}
                                    onFocus={() => setIsInputFocused(true)}
                                    onBlur={formik.handleBlur}
                                    value={selectedOption}
                                    placeholder={selectedOption?.label}
                                />
                                {selectedOption && (
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className={`${styles['selcet-stock-box']}`}>
                                            {selectedOption.label}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="d-flex justify-content-between align-items-center gap-3 mt-16">
                                <div className="form-group mt-16 edit-form">
                                    <label htmlFor="quantity" className="form-label">
                                        {t('Stock Quantity')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputQuantity"
                                        name="quantity"
                                        placeholder="Quantity"
                                        value={formik.values.quantity}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="form-group mt-16 edit-form">
                                    <label htmlFor="rate" className="form-label">
                                        {t('Stock Rate')}
                                    </label>
                                    <input
                                        type="text"
                                        name="rate"
                                        value={formik.values.rate}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        className="form-control"
                                        id="rate"
                                        placeholder="Rate"
                                    />
                                </div>
                                <div className="form-group mt-16 edit-form">
                                    <label htmlFor="value" className="form-label">
                                        {t('Stock Value')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="value"
                                        name="value"
                                        value={formik.values.value}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        placeholder="Value"
                                        readOnly
                                    />
                                </div>

                            </div>
                            <div className="d-flex  align-items-center gap-3 mt-16">
                                <div className="form-group mt-16 edit-form w-100">
                                    <label htmlFor="expiration_count" className="form-label">
                                        {t('Expiration Count')}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="expiration_count"
                                        name="expiration_count"
                                        placeholder="Expiration Count"
                                        value={formik.values.expiration_count}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="form-group mt-16 edit-form w-100">
                                    <label htmlFor="simulation_wallet_id" className="form-label">
                                        {t('Select Wallet')}
                                    </label>
                                    <Select
                                        options={walletOptions}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue('simulation_wallet_id', selectedOption?.value || '');
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={walletOptions.find((option) => option.value === formik.values.simulation_wallet_id)}
                                        placeholder="Select a wallet"
                                    />
                                </div>
                            </div>
                            <Modal.Footer>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <button className="btn btn-save mt-16 w-100" type="submit">
                                        {t('Buy')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </form>
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SimulationBuy;