// UserProfileContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {apiUrlServer} from '../apiConfig';

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [cookies] = useCookies(['token']);

  const setProfile = (profile) => {
    setUserProfile(profile);
    // Store the user profile in localStorage
    localStorage.setItem('userProfile', JSON.stringify(profile));
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (cookies.token) {
          const response = await axios.get(`${apiUrlServer}customer/profile`, {
            headers: {
              Authorization: `Bearer ${cookies.token}`,
            },
          });

          // Set the user profile based on the API response
          setProfile(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    // Load user profile data from localStorage on component mount
    const storedProfile = localStorage.getItem('userProfile');
    try {
      if (storedProfile) {
        setUserProfile(JSON.parse(storedProfile));
      }
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
    }

    // Fetch user profile data if token is present
    fetchUserProfile();
  }, [cookies.token]);

  return (
    <UserProfileContext.Provider value={{ userProfile, setProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => {
  return useContext(UserProfileContext);
};
