import React from 'react';
import CommonBanner from "../CommonBanner/CommonBanner";
import ChatMenu from "./ChatMenu";
import ChatWindow from "./ChatWindow";
import ChatShortCut from "./ChatShortCut";
import {ChatProvider} from "../../Context/ChatContext";
import useCustomTranslation from "../../Hooks/useTranslation";
import Header from "../Header/Header";

const ChatBot = () => {
    const {t} = useCustomTranslation();

    return (
        <>
            {/*<CommonBanner title={t('The Key')}/>*/}
            {/*<Header/>*/}
            <div className="chat">
                <ChatProvider>
                    <div className="container-fluid">
                    <div className="row">
                        {/*<div className="col-lg-4">*/}
                        {/*    <ChatMenu/>*/}
                        {/*</div>*/}
                        <div className="col-lg-12 p-0">
                            <ChatWindow/>
                        </div>
                    </div>
                    </div>
        </ChatProvider>
        </div>
</>
);
};

export default ChatBot;