import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { apiUrlServer, getMySubscription } from "../apiConfig";
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const PermissionContext = createContext();

export const usePermissions = () => useContext(PermissionContext);

const REFRESH_INTERVAL = 15 * 60 * 1000; // 15 minutes in milliseconds

export const PermissionProvider = ({ children }) => {
    const [cookies] = useCookies(['token']);
    const queryClient = useQueryClient();

    const fetchPrivatePermissions = async () => {
        const response = await axios.get(`${apiUrlServer}private_permission`, {
            headers: {
                Authorization: `Bearer ${cookies.token}`,
                'Content-Type': 'application/json',
            }
        });

        if (response.data.status && response.data.data) {
            const initialPermissions = {};
            response.data.data.forEach(permission => {
                if (permission.url && permission.url.trim() !== '') {
                    initialPermissions[permission.url] = {
                        type: 'locked',
                        is_active: permission.is_active === 1,
                        permission_name: permission.permission_name
                    };
                }
            });
            return initialPermissions;
        }
        return {};
    };

    const fetchSubscriptionData = async () => {
        const response = await axios.get(`${apiUrlServer}${getMySubscription}`, {
            headers: {
                Authorization: `Bearer ${cookies.token}`,
                'Content-Type': 'application/json',
            }
        });

        if (response.data.status && response.data.data.length > 0) {
            return response.data.data.find(sub => sub.is_current === 1) || null;
        }
        return null;
    };

    const { data: permissionsData, isLoading } = useQuery({
        queryKey: ['permissions', cookies.token],
        queryFn: async () => {
            const initialPermissions = await fetchPrivatePermissions();
            const subscriptionData = await fetchSubscriptionData();

            if (subscriptionData) {
                const updatedPermissions = {...initialPermissions};
                const newUsage = {};

                subscriptionData.plan.features.forEach(feature => {
                    if (feature.private_permission) {
                        const { url, permission_name } = feature.private_permission;
                        if (url) {
                            if (feature.key === 'Permission') {
                                updatedPermissions[url] = {
                                    type: 'unlimited',
                                    is_active: true,
                                    permission_name
                                };
                            } else if (feature.key === 'PermissionAndValue') {
                                updatedPermissions[url] = {
                                    type: 'limited',
                                    is_active: true,
                                    permission_name,
                                    limit: parseInt(feature.value, 10)
                                };
                                const usageItem = subscriptionData.usage.find(u => u.feature.id === feature.id);
                                if (usageItem) {
                                    newUsage[url] = parseInt(usageItem.used, 10) || 0;
                                }
                            }
                        }
                    }
                });

                return { permissions: updatedPermissions, usage: newUsage };
            } else {
                return { permissions: initialPermissions, usage: {} };
            }
        },
        enabled: !!cookies.token,
        refetchInterval: REFRESH_INTERVAL,
        refetchIntervalInBackground: true,
    });

    const hasPermission = (url) => {
        if (!url || !permissionsData) return true;
        const permission = permissionsData.permissions[url];
        if (!permission) return true;
        if (!permission.is_active) return false;
        if (permission.type === 'unlimited') return true;
        if (permission.type === 'limited') {
            return (permissionsData.usage[url] || 0) < permission.limit;
        }
        return false;
    };

    const usePermission = (url) => {
        if (hasPermission(url)) {
            const permission = permissionsData?.permissions[url];
            if (permission && permission.type === 'limited') {
                const newUsage = (permissionsData.usage[url] || 0) + 1;
                queryClient.setQueryData(['permissions', cookies.token], (old) => ({
                    ...old,
                    usage: { ...old.usage, [url]: newUsage }
                }));
            }
            return true;
        }
        return false;
    };

    return (
        <PermissionContext.Provider value={{
            permissions: permissionsData?.permissions || {},
            usage: permissionsData?.usage || {},
            isLoading,
            hasPermission,
            usePermission
        }}>
            {children}
        </PermissionContext.Provider>
    );
};