import React from "react";
import useCustomTranslation from "../../../../Hooks/useTranslation";
import {abbreviateNumber} from "../../../../Hooks/useNumberFormatting"; // Assuming this is your translation hook

const Technicals = (props) => {
const {t} = useCustomTranslation();
  return (
      <div className="row">
        <div className="col-lg-12 my-24">
          <div className="card card-highlighter h-100">
            <div className="card-body">
              <h5 className="card-title text-center">{t("Technicals")}</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("Beta")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.Beta)}
                </span>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("52WeekHigh")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.WeekHigh)}
                </span>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("52WeekLow")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.WeekLow)}
                </span>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("50DayMA")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.DayMA)}
                </span>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("200DayMA")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.DayMA200)}
                </span>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("SharesShort")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.SharesShort)}
                </span>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("ShortRatio")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.ShortRatio)}
                </span>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between">
                  <span className="fw-bold">{t("ShortPercent")}: </span>
                  <span className="text-secondary ms-2">
                  {abbreviateNumber(props.ShortPercent)}
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Technicals;