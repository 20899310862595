import React, { useState, useMemo } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,gridClasses } from '@mui/material';
import useCustomTranslation from "../../../Hooks/useTranslation";
import { abbreviateNumber } from "../../../Hooks/useNumberFormatting";
import OpenFinancialChart from "./OpenFinancialChart";
import { useTheme } from "../../../Context/ThemeContext";

const FinancialStatement = ({ data, statementType, id, code }) => {
    const { t } = useCustomTranslation();
    const [activeTab, setActiveTab] = useState('quarterly');
    const [selectedYears, setSelectedYears] = useState([]);
    const { theme } = useTheme();

    const financialData = data?.[`financials_${statementType}_${activeTab}`] || {};
    const dates = Object.keys(financialData);

    const uniqueYears = useMemo(() => [...new Set(dates.map((date) => date.substring(0, 4)))], [dates]);

    const transposedRows = useMemo(() => {
        return dates.length
            ? Object.keys(financialData[dates[0]])
                .filter((key) => key !== 'id' && key !== 'date' && key !== 'filing_date' && key !== 'currency_symbol')
                .map((key, index) => ({
                    id: index,
                    field: t(key.replace(/([A-Z])/g, ' $1').toLowerCase()),
                    ...dates.reduce((acc, date) => {
                        acc[date] = typeof financialData[date][key] === 'number'
                            ? abbreviateNumber(financialData[date][key])
                            : abbreviateNumber(financialData[date][key]);
                        return acc;
                    }, {}),
                }))
            : [];
    }, [dates, financialData, t]);

    const filteredDates = dates.filter((date) => selectedYears.length === 0 || selectedYears.includes(date.substring(0, 4)));

    const handleYearChange = (event) => {
        setSelectedYears(event.target.value);
    };

    const handleSelectAllYears = () => {
        if (selectedYears.length === uniqueYears.length) {
            setSelectedYears([]);
        } else {
            setSelectedYears([...uniqueYears]);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSelectedYears([]);
    };

    if (dates.length === 0) {
        return <div>No data available for {statementType} statement.</div>;
    }

    const buttonColor = theme === 'nationalDay' ? 'success' : 'secondary';

    const stickyHeaderStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 3,
        backgroundColor: 'var(--secondary-color-1)',
        color: 'var(--natural-color)',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        fontWeight: 'bold',
        fontSize: '12px',
        width: 'auto',
    };

    const stickyColumnStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: 'var(--secondary-color-1)',
        color: 'var(--natural-color)',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        fontSize: '12px',
        width: 'auto',
    };

    return (
        <div>
            <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                        variant={activeTab === 'quarterly' ? 'contained' : 'outlined'}
                        color={buttonColor}
                        onClick={() => handleTabChange('quarterly')}
                    >
                        {t('Quarterly')}
                    </Button>
                    <Button
                        variant={activeTab === 'yearly' ? 'contained' : 'outlined'}
                        color={buttonColor}
                        onClick={() => handleTabChange('yearly')}
                    >
                        {t('Yearly')}
                    </Button>
                </Box>
                <FormControl sx={{ minWidth: 120, flexGrow: 1 }}>
                    <InputLabel id="year-select-label">{t('Select Years')}</InputLabel>
                    <Select
                        labelId="year-select-label"
                        id="year-select"
                        multiple
                        value={selectedYears}
                        onChange={handleYearChange}
                        renderValue={(selected) => selected.join(', ')}
                        label={t('Select Years')}
                    >
                        {uniqueYears.map((year) => (
                            <MenuItem key={year} value={year}>
                                <Checkbox checked={selectedYears.indexOf(year) > -1} />
                                <ListItemText primary={year} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color={buttonColor}
                    onClick={handleSelectAllYears}
                >
                    {selectedYears.length === uniqueYears.length ? t('Unselect All') : t('Select All')}
                </Button>
                <OpenFinancialChart
                    path={`/${statementType}/${id}/${code}`}
                    dataKey={`${statementType}Data`}
                    data={data}
                    buttonText={`Open ${statementType.replace('_', ' ')} Visual`}
                />
            </Box>
            <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
                <Table stickyHeader aria-label="financial statement table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={stickyHeaderStyle}>
                                {t('Field')}
                            </TableCell>
                            {filteredDates.map((date) => (
                                <TableCell key={date} sx={{
                                    width:"auto",
                                    backgroundColor: 'var(--natural-color)',
                                    color: 'var(--secondary-color-1)',
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    padding:'0'
                                }}>
                                    {date}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transposedRows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row" sx={stickyColumnStyle}>
                                    {row.field}
                                </TableCell>
                                {filteredDates.map((date) => (
                                    <TableCell style={{fontSize:"12px"}}  key={date}>
                                        {row[date]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default FinancialStatement;