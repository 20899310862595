import React, {createContext, useContext, useEffect, useState} from "react";
import patternImageDefault from '../assets/images/pattern-colored-transparent.webp';
import patternImageNationalDay from '../assets/images/K-WealthnationalDayIdentity_LongPattern.png';
import patternImageDefaultTASImage from '../assets/icons/b4-heading.svg'
import patternImageDefaultTASINationalDaymage from '../assets/icons/icon-national-day-head.svg'
import defaultLoginScreen from '../assets/images/loginbg.png';
import nationaltLoginScreen from '../assets/images/weblogin.png';
import defaultArrowRight from '../assets/icons/arrowright.svg';
import nationalDayArrowRight from '../assets/icons/arrowrightgreen.svg';
const ThemeContext = createContext();

const defaultTheme = {
    '--primary-color': '#1D1A39',
    '--body-color': '#451952',
     '--secondary-color-1': '#451952',
    '--footer-color': '#1D1A39',
    '--accent-color': '#AE445A',
    '--secondary-color-2': '#E8BCB9',
    '--secondary-color-3': '#F39F5A',
    '--secondary-color-4': '#F99F01',
    '--secondary-color-5': 'rgba(4, 156, 107, 0.94)',
    '--secondary-color-6': '#FD2523',
    '--secondary-color-7': '#6F6F6F',
    '--secondary-color-8': '#F61C7A',
    '--secondary-color-9': '#f61c7a66',
    '--secondary-color-10': '#2D2D2D',
    '--secondary-color-11': '#282828',
    '--secondary-color-12': '#d34645',
    '--secondary-color-13': '#662549',
    '--secondary-color-14': '#1573FE',
    '--secondary-color-15': '#F6462E',
    '--secondary-color-16': '#190760',
    '--secondary-color-17': '#BC3A59',
    '--secondary-color-18': '#000',
    '--secondary-color-19': '#cf30ff',
    '--secondary-color-20': '#986AA7',
    '--secondary-color-21': '#32123B',
    '--natural-color': '#fff',
    '--natural-color-2': '#FEFAFA',
    '--natural-color-3': '#959798',
    '--natural-color-4': 'rgba(33, 123, 168, 0.5)',
    '--natural-color-5': '#f5f7f999',
    '--natural-color-6': '#F5F7F9',
    '--natural-color-7': '#F7F6FF',
    '--light-gray': '#424242',
    '--pattern-image': `url(${patternImageDefault})`,
    '--pattern-Tasi': `url(${patternImageDefaultTASImage})`,
    '--login-screen': `url(${defaultLoginScreen})`,
    '--button-select-color': 'secondary',
    '--arrow-right-image': `url(${defaultArrowRight})`,



};

const nationalDayTheme = {
    '--primary-color': '#004a24',
    '--body-color': '#004422',
    '--footer-color': 'transparent',
    '--secondary-color-1': '#008c45',
    '--accent-color': '#006c35',
    '--secondary-color-2': '#008c45',
    '--secondary-color-3': '#00a651',
    '--secondary-color-4': '#F99F01',
    '--secondary-color-5': 'rgba(4, 156, 107, 0.94)',
    '--secondary-color-6': '#FD2523',
    '--secondary-color-7': '#6F6F6F',
    '--secondary-color-8': '#F61C7A',
    '--secondary-color-9': '#f61c7a66',
    '--secondary-color-10': '#2D2D2D',
    '--secondary-color-11': '#282828',
    '--secondary-color-12': '#d34645',
    '--secondary-color-13': '#662549',
    '--secondary-color-14': '#1573FE',
    '--secondary-color-15': '#F6462E',
    '--secondary-color-16': '#02361CFF',
    '--secondary-color-17': '#BC3A59',
    '--secondary-color-18': '#000',
    '--secondary-color-19': '#00a651',
    '--secondary-color-20': '#00a651',
    '--secondary-color-21': '#177746',
    '--natural-color': '#fff',
    '--natural-color-2': '#FEFAFA',
    '--natural-color-3': '#959798',
    '--natural-color-4': 'rgba(33, 123, 168, 0.5)',
    '--natural-color-5': '#f5f7f999',
    '--natural-color-6': '#F5F7F9',
    '--natural-color-7': '#F7F6FF',
    '--light-gray': '#424242',
    '--pattern-image': `url(${patternImageNationalDay})`,
    '--pattern-Tasi': `url(${patternImageDefaultTASINationalDaymage})`,
    '--login-screen': `url(${nationaltLoginScreen})`,
    '--button-select-color': 'success',
    '--arrow-right-image': `url(${nationalDayArrowRight})`,

};

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => {
        // Try to get the theme from localStorage when the component mounts
        const savedTheme = localStorage.getItem('userTheme');
        return savedTheme || 'nationalDay'; // Use 'nationalDay' if no saved theme
    });

    const toggleTheme = () => {
        setTheme(prevTheme => {
            const newTheme = prevTheme === 'default' ? 'nationalDay' : 'default';
            // Save the new theme to localStorage
            localStorage.setItem('userTheme', newTheme);
            return newTheme;
        });
    };

    useEffect(() => {
        const root = document.documentElement;
        const themeValues = theme === 'default' ? defaultTheme : nationalDayTheme;

        Object.entries(themeValues).forEach(([key, value]) => {
            root.style.setProperty(key, value);
        });
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);